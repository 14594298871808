import React from "react";
import {Box, Button, CircularProgress, ToggleButton, ToggleButtonGroup} from "@mui/material";
import styled from "@emotion/styled";


import {Bar, Doughnut, Line} from 'react-chartjs-2';

import {
    CategoryScale,
    Chart,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
    Legend, BarElement, ArcElement,
} from "chart.js";

import annotationPlugin from 'chartjs-plugin-annotation';
import {useAppState} from "../../state/AppStateProvider";
import ErrorBoundary from "../error-handling/ErrorBoundary";
import {graphColorsArray} from "../../views/simulation/create-simulation/setup-simulation/constants";
import {AlmanakLogo} from "../AlmanakLogo";
import ShareIcon from "@mui/icons-material/Share";
import html2canvas from "html2canvas";
import {LoadingButton} from "@mui/lab";
import {Download} from "@mui/icons-material";

Chart.register(CategoryScale, LinearScale, BarElement, ArcElement, PointElement, LineElement, Tooltip, Filler, Legend, annotationPlugin);

const LoadingOverlay = () => {
    return <Box id="loading-overlay" style={{position: "absolute"}}>
        <CircularProgress color="success"/>
    </Box>;
}

const PanelContainer = styled(`div`)({
    height: "100%",
    position: "relative"
})


export const GraphToggleButtonGroup = styled(ToggleButtonGroup)`
    background-color: #fff;
    border-radius: 12px;
    height: 45px;
`;
export const GraphToggleButton = styled(ToggleButton)`
    border-radius: 12px !important;
    text-transform: capitalize;
    font-size: 14px;
    color: #000;
    border: none;
    margin: 4px;
`;


//TODO: TYPE
export type AlmanakChartData = any;
export type AlmanakChartOptions = any;
export type GraphProps = {
    data: AlmanakChartData,
    options: AlmanakChartOptions,
    type?: "line" | "bar" | "doughnut",
    isLoading?: boolean;
    height?: string;
    error?: string;
    hideYAxis?: boolean;
    hideWatermark?: boolean;
    hideSharing?: boolean;
}

const generateScales = (datasets: any, hideYAxis?: boolean) => {
    const scales = {
        x: {
            grid: {
                display: false
            },
        }
    }
    datasets.forEach((dataset: any, index: number) => {
        const scaleId = `y${dataset?.data?.[0]?.y}`;
        // @ts-ignore
        scales[scaleId] = {
            type: 'linear',
            display: hideYAxis ? false : true,
            color: graphColorsArray[index],
            grid: {
                display: false
            },
        };
    });
    return scales;
};

const AlmanakChart = (props: GraphProps) => {

    const {theme, setSnackBar} = useAppState();

    const [isSharing, setIsSharing] = React.useState(false);
    const themedOptions = {
        ...props.options,
        animation: false,
        parsing: false,
        responsive: true,
        plugins: {
            ...props.options.plugins,
        },
        scales: generateScales(props.data.datasets, props.hideYAxis)
    }

    const normalizedData = {
        ...props.data,
        datasets: props.data.datasets.map((dataset: any, index: number) => {
            return ({
                ...dataset,
                yAxisID: `y${dataset?.data?.[0]?.y}`
            })
        })
    };


    const data = {
        labels: normalizedData.labels,
        datasets: normalizedData.datasets,
    };


    const captureChartScreenshot = async () => {
        const element = document.getElementById('almanakShareableChart'); // Replace with your target element ID
        const saveButton = document.querySelector('.save-button'); // Add a class to your save button

        if (!element) {
            console.error("Element not found");
            return;
        }

        // Hide the save button
        // @ts-ignore
        if (saveButton) saveButton.style.display = 'none';

        const canvas = await html2canvas(element);

        // Show the save button after the screenshot
        // @ts-ignore
        if (saveButton) saveButton.style.display = 'block';

        const dataUrl = canvas.toDataURL('image/png');
        return dataUrl;
    };


    const onShareChart = async () => {
        setIsSharing(true);
        const dataUrl = await captureChartScreenshot();
        if (!dataUrl) {
            setSnackBar({open: true, message: "Failed to capture screenshot", severity: "error"});
            return;
        }

        const blob = await (await fetch(dataUrl)).blob();
        const file = new File([blob], 'almanak-chart.png', {type: 'image/png'});

        // if (!navigator.share) {
        //     // Use Web Share API if available
        //     navigator.share({
        //         title: 'Check this out!',
        //         text: 'Here is a screenshot from the app.',
        //         files: [file],
        //     }).catch((error) => console.error('Error sharing:', error));
        // } else {
        // Fallback to downloading the image
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'almanak-chart.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        setSnackBar({open: true, message: "Chart saved", severity: "success"});
        // }

        setIsSharing(false);
    };


    // const onShareChart = async () => {
    //
    //     console.log("share shart");
    //     const screenshot = await captureChartScreenshot();
    //
    //     console.log("screenshot", screenshot);
    // };

    return <div>
        <ErrorBoundary>
            {props.error && <Box>{props.error}</Box>}
            <PanelContainer
                id={"almanakShareableChart"}
                style={{height: props.height ?? "300px"}}>

                {props.isLoading && <LoadingOverlay/>}

                {!props?.hideWatermark && <>
                    <Box sx={{
                        opacity: 0.2,
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        textAlign: "center",
                        marginLeft: "120px",
                        marginTop: "24px"
                    }} className={"floating-watermark"}>
                        <h5 style={{marginBottom: "-8px"}}>Made With</h5>
                        <AlmanakLogo size={64}/>
                    </Box>


                    <Box sx={{
                        opacity: 0.2,
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        textAlign: "center",
                        right: "40px",
                        top: "64px",
                    }} className={"floating-watermark"}>
                        <h5 style={{marginBottom: "-8px"}}>Made With</h5>
                        <AlmanakLogo size={64}/>
                    </Box>

                    <Box sx={{
                        opacity: 0.2,
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        textAlign: "center",
                        right: "40%",
                        bottom: "64px",
                        marginLeft: "120px",
                        marginTop: "24px"
                    }} className={"floating-watermark"}>
                        <h5 style={{marginBottom: "-8px"}}>Made With</h5>
                        <AlmanakLogo size={64}/>
                    </Box>

                </>}
                {props.type === "bar" ?
                    <Bar options={themedOptions} data={props.data}/> :
                    props.type === "doughnut" ?
                        <Doughnut options={props.options} data={props.data}/> :

                        <Line
                            data={data}
                            options={themedOptions}
                        />
                }
                {!props.hideSharing && <Box className="save-button" sx={{m: 2, textAlign: "end"}}>
                    <LoadingButton loading={isSharing} onClick={onShareChart} startIcon={<Download/>}
                                   variant={"contained"}>Save</LoadingButton>
                </Box>}
            </PanelContainer>
        </ErrorBoundary>
    </div>
}

export default AlmanakChart
