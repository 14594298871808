import {styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {WalletHolding} from "../../modals/AddWalletModal";
import {useAppState} from "../../state/AppStateProvider";
import {getDisplayAmount} from "../../utils/moneyUtils";
import {formatMonetaryValue} from "../../views/simulation/create-simulation/PoolDetails";


const StyledTableContainer = styled(TableContainer)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#EEEEF02F" : "#F9FAFB",
    borderRadius: "12px",
    "& th.MuiTableCell-root": {
        borderColor: "#D6D8DD"
    }
}));
export const HoldingsTable = ({holdings}: { holdings?: WalletHolding[] }) => {

    const {availableTokens} = useAppState();
    const getTokenDisplayAmount = (holding: WalletHolding) => {
        const token = availableTokens?.find(t => t.token === holding.token);
        const value = holding.displayAmount ? holding.displayAmount : token?.decimalPlaces ? getDisplayAmount(holding.amount, token.decimalPlaces) : "";
        return formatMonetaryValue(value);
    }

    if (!holdings || holdings.length === 0) {
        return null;
    }
    return <StyledTableContainer>
        <Table aria-label="simple table">
            <TableHead sx={{borderBottom: "1px solid #D6D8DD"}}>
                <TableRow>
                    <TableCell sx={{width: "180px"}}>Asset</TableCell>
                    <TableCell>Token Balance</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {holdings?.map((holding) => (
                    <TableRow
                        key={holding.token}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell scope="row">
                            {holding.token}
                        </TableCell>
                        <TableCell align="left">{getTokenDisplayAmount(holding)} {holding.token}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </StyledTableContainer>
};
