import axios from "axios";

export const generatePriceSimulatorTrajectoriesDirectly = (payload?: any) => {
    const apiUrl = process.env.REACT_APP_PRICE_SIM_DIRECT_URL
    const accessToken = localStorage.getItem("accessToken");


    var data = JSON.stringify(payload ?? {
        "assets": [
            {
                "symbol": [
                    "ETH",
                    "USDT"
                ],
                "config": {}
            }
        ],
        "start_block": 16085600,
        "end_block": 16086600,
        "granularity": 1,
        "config": {
            "market_drift": 1.2,
            "volatility_scale": 1,
            "enable_black_swan_event": false
        },
        "steps": 500,
        "volatility_mode": "cccgarch",
        "trajectories": 20,
        "maturity": 1
    });

    const prepperData = data.replaceAll("\\n", "").replaceAll("\\", " ").replaceAll(" ", "").slice(1, -1);
    var config = {
        method: 'post',
        url: apiUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        data : prepperData
    };

    return axios(config);
}
