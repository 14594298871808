import Box from "@mui/material/Box";
import * as React from "react";
import {useTheme, Typography, Button, Card, CardContent} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Link} from 'react-router-dom';
import {PageContentContainer} from "../styled/styled";

export const FailedPurchase = () => {
    const theme = useTheme();

    return (
        <PageContentContainer>
            <Box sx={{padding: theme.spacing(4), textAlign: 'center'}}>
                <Box sx={{
                    maxWidth: 600,
                    margin: '0 auto',
                    padding: theme.spacing(4)
                }}>
                    <CardContent>
                        <ErrorOutlineIcon sx={{fontSize: 60, color: '#d32f2f', marginBottom: theme.spacing(2)}}/>
                        <Typography variant="h4" gutterBottom sx={{fontWeight: 'bold'}}>
                            Something Went Wrong
                        </Typography>
                        <Typography variant="body1" sx={{marginBottom: theme.spacing(4)}}>
                            We encountered an error processing your request. Please try again later.
                        </Typography>
                        <Link to={"/buy-credits"}>

                            <Button variant="contained">
                                Try again
                            </Button>
                        </Link>
                    </CardContent>
                </Box>
            </Box>
        </PageContentContainer>
    );
};
