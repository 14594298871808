import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import {CenteredColumnFlexBox} from "../views/simulation/create-simulation/styled";
import {Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField} from "@mui/material";
import * as React from "react";
import {AlmanakModalContent} from "./ScenarioCustomizationModal";
import {StrategyParameter} from "../views/strategy/StrategyLibrary";
import {useAppState} from "../state/AppStateProvider";
import {getGranularityInSeconds} from "../views/simulation/create-simulation/setup-simulation/utilities";

type StrategyParameterModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSave: (data: StrategyParameter) => void;
    data: StrategyParameter
};

export const TitleValueColumn = (props: { title: string, value: string | number }) => {
    return <CenteredColumnFlexBox sx={{paddingRight: 1, py: 1, justifyContent: "space-between"}}>
        <span style={{fontSize: "14px", fontWeight: "bold"}}>{props.title}</span>
        <span style={{fontSize: "14px"}}>{props.value}</span>
    </CenteredColumnFlexBox>
}
export const StrategyParameterModal = ({isOpen, onClose, data, onSave}: StrategyParameterModalProps) => {

    const {selectedSimulation} = useAppState();
    const granularityInSeconds = getGranularityInSeconds(selectedSimulation?.frontend_state?.granularity, selectedSimulation?.frontend_state?.granularitySize);
    const initialValue = data.value && data.name === "lookback_period" ? Number(data.value) / granularityInSeconds : data.value;

    const [parameterValue, setParameterValue] = React.useState(initialValue);
    const onChange = (event: any) => {
        setParameterValue(event.target.value)
    }

    const RenderValue = () => {
        if (!data.editable) {
            return <TitleValueColumn title={"Value"} value={data?.value?.toString() ?? ""}/>

        }
        if (data.name === "lookback_period") {
            const displayValue = selectedSimulation?.frontend_state?.granularitySize;
            return <TextField type={data.type} name={"lookback_period"} onChange={onChange} sx={{my: 2}} fullWidth
                              label={`Period in ${displayValue}s`}
                              placeholder={`Number of ${displayValue}s`} value={parameterValue}/>
        }
        if (data.type === "boolean") {
            return <FormControl sx={{my: 2}} fullWidth>
                <InputLabel id="select-label">Parameter Value</InputLabel>

                <Select labelId="select-label"
                        label={"Parameter Value"} value={parameterValue} onChange={onChange}>
                    <MenuItem value={true as any}>True</MenuItem>
                    <MenuItem value={false as any}>False</MenuItem>
                </Select>
            </FormControl>
        }
        return <TextField type={data.type} onChange={onChange} sx={{my: 2}} fullWidth label="Parameter Value"
                          placeholder="Parameter Value" value={parameterValue}/>;
    }
    return <Modal
        sx={{display: "flex"}}
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <AlmanakModalContent>
            <Box sx={{
                p: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold"
            }}>
                Adjust Strategy Parameter
                <IconButton onClick={onClose}><CloseIcon/></IconButton>
            </Box>
            <Divider/>

            <Box sx={{p: 2}}>
                {data.displayName && <TitleValueColumn title={"Display name"} value={data.displayName}/>}
                <TitleValueColumn title={"Parameter name"} value={data.name}/>
                <TitleValueColumn title={"Description"} value={data.description}/>
                <TitleValueColumn title={"Type"} value={data.type}/>
                {RenderValue()}
            </Box>

            <Box sx={{display: "flex", gap: 1, p: 3, justifyContent: "flex-end"}}>
                <Button onClick={onClose} variant={"outlined"}>Close</Button>
                <Button onClick={() => onSave({...data, value: parameterValue})} variant={"contained"}>Save</Button>
            </Box>
        </AlmanakModalContent>
    </Modal>
}
