import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CallMadeIcon from "@mui/icons-material/CallMade";
import {styled} from "@mui/material";
import Chip from "@mui/material/Chip";

type TokenPairHeaderProps = {
    token1: string;
    token2: string;
    feeTier: number;
    network: string;
    dexProtocol: string;
    contractAddress: string;
}
const CustomLink = styled("a")`
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

export const TokenPairHeader = (props: TokenPairHeaderProps) => {
    const {
        token1,
        token2,
        feeTier,
        network,
        dexProtocol,
        contractAddress,
    } = props;

    return <Box>
        <Box sx={{display: "flex", alignItems: "center", my: 1}}>
                            <span style={{
                                fontSize: "24px",
                                fontWeight: "700"
                            }}>{token1} - {token2} </span>
            <Chip sx={{marginLeft: "8px"}} size="small" label={feeTier / 1000 + "%"}/>
        </Box>
        <Box sx={{display: "flex", alignItems: "center", fontSize: "12px"}}>
            <span>{network}</span>
            <Divider sx={{borderColor: "#6B7280", mx: 1, height: "16px"}} orientation="vertical"/>
            <span>{dexProtocol}</span>
            <Divider sx={{borderColor: "#6B7280", mx: 1, height: "16px"}} orientation="vertical"/>
            <Box sx={{display: "flex", alignItems: "center"}}>
                <CustomLink onClick={() => window.open(`https://etherscan.io/address/${contractAddress}`)}>
                    Contract address
                </CustomLink>
                <CallMadeIcon sx={{height: "12px", width: "16px"}}/>
            </Box>
        </Box>
    </Box>
};
