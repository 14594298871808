import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import {CenteredColumnFlexBox, FlexBox} from "../views/simulation/create-simulation/styled";
import {Button, MenuItem, Modal, Select, Slider, styled, Tooltip} from "@mui/material";
import {initialMarketScenarioOptions} from "../views/simulation/create-simulation/setup-simulation/constants";
import InfoIcon from "@mui/icons-material/Info";
import * as React from "react";
import {MarketScenarioOption} from "../state/types";
import {useState} from "react";

const StyledSlider = styled(Slider)`
  *[class*='MuiSlider-markLabel'][data-index='0'] {
    transform: none;
  }
;

  *[class*='MuiSlider-markLabel'][data-index='1'] {
    transform: translateX(-100%);
  }
;
  margin-top: 16px;
`;
export const AlmanakModalContent = styled(Box)(({theme}) => ({
    margin: "auto",
    width: "clamp(600px, 40%, 1020px)",
    backgroundColor: theme.palette.mode === "light" ? "#FFF" : "#373737",
    borderRadius: "10px"
}));

type ScenarioCustomizationModalProps = {
    isOpen: boolean;
    onSave: (data: MarketScenarioOption) => void;
    onClose: () => void;
    data: MarketScenarioOption
};
export const ScenarioCustomizationModal = ({isOpen, onSave, onClose, data}: ScenarioCustomizationModalProps) => {
    const [modalData, setModalData] = useState<MarketScenarioOption>(data)

    const stepSize = (modalData?.maxVolatility! - modalData?.minVolatility!) / 100;
    const onChange = (e: any, isNumber?: boolean) => {
        // @ts-ignore
        setModalData(prevState => {
            return {
                ...(prevState ?? {}),
                [e.target.name]: isNumber ? Number(e.target.value) : e.target.value
            }
        })
    };


    function valueLabelFormat(value: number) {
        return ((value / modalData.maxVolatility) * 100).toFixed(0) + "%";
    }

    return <Modal
        sx={{display: "flex"}}
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <AlmanakModalContent>
            <Box sx={{
                p: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold"
            }}>
                Customize Market Scenario
                <IconButton onClick={onClose}><CloseIcon/></IconButton>
            </Box>
            <Divider/>
            <CenteredColumnFlexBox sx={{p: 3}}>
                <span style={{padding: "8px 0", fontSize: "14px", fontWeight: "bold"}}>Market Scenario</span>
                <Select
                    disabled
                    name="scenario"
                    value={modalData?.type}
                    onChange={e => onChange(e, false)}
                    size={"small"}>
                    {initialMarketScenarioOptions.map(option =>
                        <MenuItem key={option.title} value={option.type}>{option.title}</MenuItem>
                    )}
                </Select>
                {modalData?.minDrift && modalData?.maxDrift && (
                    <>
                        <Box style={{display: "flex", padding: "24px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>
                            Adjust Drift
                            <Tooltip
                                title={"The drift parameter controls the magnitude of the trend (e.g. a higher drift value will positively increase the slope in a linear uptrend)."}>
                                <InfoIcon sx={{marginLeft: "4px"}} fontSize={"small"}/>
                            </Tooltip>
                        </Box>
                        <FlexBox>
                            <StyledSlider
                                name="drift"
                                size="small"
                                disableSwap
                                step={0.01}
                                value={modalData?.drift}
                                onChange={e => onChange(e, false)}
                                valueLabelDisplay="on"
                                min={modalData?.minDrift}
                                max={modalData?.maxDrift}
                            />
                        </FlexBox>
                    </>)}

                <Box style={{display: "flex", padding: "24px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>
                    <span>Adjust Volatility Multiplier</span>
                    <Tooltip
                        title={"The volatility parameter controls the magnitude of the volatility (e.g. a higher volatility value will increase the dispersion of the price trajectory)."}>
                        <InfoIcon sx={{marginLeft: "4px"}} fontSize={"small"}/>
                    </Tooltip>
                </Box>


                <FlexBox>
                    {modalData?.minVolatility && modalData?.maxVolatility && <StyledSlider
                        name="volatilityMultiplier"
                        size="small"
                        step={stepSize}
                        value={modalData?.volatilityMultiplier}
                        onChange={e => onChange(e, false)}
                        valueLabelDisplay="on"
                        valueLabelFormat={valueLabelFormat}
                        min={modalData?.minVolatility}
                        max={modalData?.maxVolatility}
                    />}

                </FlexBox>
            </CenteredColumnFlexBox>

            <Box sx={{p: 3, textAlign: "end"}}>
                <Button onClick={() => onSave(modalData!)} variant={"contained"}>Save Scenario</Button>
            </Box>
        </AlmanakModalContent>
    </Modal>
}
