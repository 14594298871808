import React, { useState, useEffect } from 'react';
import {Control, Controller} from 'react-hook-form';
import {Alert, Button, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

interface TokenPair {
    symbol: string;
    token0_name: string;
    token1_name: string;
}

interface MultiSelectAssetPairProps {
    selectedAssetPairs?: string[],
    control: Control<any>;
    setAssetPairs: any;
    availableTokenPairs: TokenPair[] | null;
}
const MultiSelectAssetPair: React.FC<MultiSelectAssetPairProps> = ({ selectedAssetPairs, control, setAssetPairs, availableTokenPairs }) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [filteredTokenPairs, setFilteredTokenPairs] = useState<TokenPair[]>(availableTokenPairs ?? []);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    useEffect(() => {
        // Check if availableTokenPairs is not null before filtering
        if (availableTokenPairs) {
            if (inputValue === '' || inputValue === undefined) {
                setFilteredTokenPairs(availableTokenPairs);
            } else {
                const filtered = availableTokenPairs.filter(tokenPair =>
                    tokenPair.token0_name.toLowerCase().includes(inputValue.toLowerCase()) ||
                    tokenPair.token1_name.toLowerCase().includes(inputValue.toLowerCase())
                );
                setFilteredTokenPairs(filtered);
            }
        }
    }, [inputValue, availableTokenPairs]);

    const handleDelete = (valueToRemove: string) => (e: React.MouseEvent) => {
        const newPairs = selectedAssetPairs?.filter((pair) => pair !== valueToRemove) ?? [];
        setAssetPairs({ target: { value: newPairs } });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleOptionClick = (value: string) => {
        if (!selectedAssetPairs?.includes(value)) {
            setAssetPairs({ target: { value: [...selectedAssetPairs ?? [], value] } });
        } else {
            setAssetPairs({target: {value: selectedAssetPairs?.filter((pair) => pair !== value)}});
        }
    };

    return (
        <Box sx={{flex: 1, flexGrow: 1}}>
            <span style={{fontSize: '16px', fontWeight: 'bold'}}>Step 1: <span style={{fontWeight: 'normal'}}>Select Asset Pair(s)</span></span>
            {selectedAssetPairs?.[0] ? <div>
                <span style={{fontSize: '12px', fontWeight: 'bold'}}>Selected pairs:</span>
            </div> : <Alert sx={{my: 1}} severity="error">Please select at least one asset pair</Alert>}
            <Box sx={{gap: "4px", display: "flex", flexWrap: "wrap"}}>

                {selectedAssetPairs?.map((value: any) => (
                    <Chip
                        key={value}
                        label={availableTokenPairs?.find((tokenPair: any) => tokenPair.symbol === value)?.token0_name + " - " + availableTokenPairs?.find(tokenPair => tokenPair.symbol === value)?.token1_name ?? value}
                        onDelete={handleDelete(value)}
                    />
                ))}
            </Box>
            <Box sx={{py: 2}}>
                <TextField
                    onClick={(e) => {
                        e.stopPropagation()
                        setInputValue("")
                        setIsDropdownOpen(true)
                    }}
                    fullWidth
                    value={inputValue}
                    onChange={handleInputChange}
                    label={"Search and select asset pair(s)"}
                    // InputProps={{
                    //     startAdornment: selectedAssetPairs?.map((value: any) => (
                    //         <Chip
                    //             key={value}
                    //             label={availableTokenPairs?.find((tokenPair: any) => tokenPair.symbol === value)?.token0_name + " - " + availableTokenPairs?.find(tokenPair => tokenPair.symbol === value)?.token1_name ?? value}
                    //             onDelete={handleDelete(value)}
                    //         />
                    //     )),
                    //     style: { flexWrap: 'wrap' },
                    // }}
                    variant="outlined"
                />

                {isDropdownOpen &&
                    <Box sx={{
                        position: 'absolute',
                        zIndex: 'modal',
                        backgroundColor: 'background.paper',
                        maxWidth: "400px",
                        border: "1px solid",
                        borderColor: "divider",
                        width: "100%",
                        borderRadius: "4px"
                    }}>
                        <Box sx={{p: 2, display: "flex", justifyContent: "space-between", alignItems: "center", fontWeight: "bold"}}>
                            {selectedAssetPairs?.length} pairs selected <Button onClick={() => setIsDropdownOpen(false)} variant={"contained"}>Finish Selection</Button></Box>
                        <Divider />
                        {filteredTokenPairs.map((tokenPair: any) => (
                            <Box
                                key={tokenPair.symbol}
                                onClick={() => handleOptionClick(tokenPair.symbol)}
                                sx={{display: "flex", justifyContent: "space-between", p: 1, cursor: 'pointer', '&:hover': {backgroundColor: 'action.hover'}}}
                            >
                                <Box>
                                {tokenPair.token0_name} - {tokenPair.token1_name}
                                </Box>
                                {selectedAssetPairs?.includes(tokenPair.symbol) &&
                                    <Chip label={"Selected"} />}
                            </Box>
                        ))}
                    </Box>
                }
            </Box>
        </Box>
    );
};

export default MultiSelectAssetPair;
