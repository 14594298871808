export const assetPairsWithAddresses = [
    {
        "instrument": "ethusdc",
        "token0": "eth",
        "token1": "usdc",
        "token0_address": "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        "token1_address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
    },
    {
        "instrument": "opusdt",
        "token0": "op",
        "token1": "usdt",
        "token0_address": null,
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "apeusdt",
        "token0": "ape",
        "token1": "usdt",
        "token0_address": "0x4d224452801aced8b2f0aebe155379bb5d594381",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "arbusdt",
        "token0": "arb",
        "token1": "usdt",
        "token0_address": "0xb50721bcf8d664c30412cfbc6cf7a15145234ad1",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "axlusdt",
        "token0": "axl",
        "token1": "usdt",
        "token0_address": "0x467719ad09025fcc6cf6f8311755809d45a5e5f3",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "axsusdt",
        "token0": "axs",
        "token1": "usdt",
        "token0_address": "0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "balusdt",
        "token0": "bal",
        "token1": "usdt",
        "token0_address": "0xba100000625a3754423978a60c9317c58a424e3d",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "chzusdt",
        "token0": "chz",
        "token1": "usdt",
        "token0_address": "0x3506424f91fd33084466f402d5d97f05f8e3b4af",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "crvusdt",
        "token0": "crv",
        "token1": "usdt",
        "token0_address": "0xd533a949740bb3306d119cc777fa900ba034cd52",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "cvxusdt",
        "token0": "cvx",
        "token1": "usdt",
        "token0_address": "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "daiusdt",
        "token0": "dai",
        "token1": "usdt",
        "token0_address": "0x6b175474e89094c44da98b954eedeac495271d0f",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "enjusdt",
        "token0": "enj",
        "token1": "usdt",
        "token0_address": "0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "ensusdt",
        "token0": "ens",
        "token1": "usdt",
        "token0_address": "0xc18360217d8f7ab5e7c516566761ea12ce7f9d72",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "ethusdt",
        "token0": "eth",
        "token1": "usdt",
        "token0_address": "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "fxsusdt",
        "token0": "fxs",
        "token1": "usdt",
        "token0_address": "0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "gmxusdt",
        "token0": "gmx",
        "token1": "usdt",
        "token0_address": null,
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "gnousdt",
        "token0": "gno",
        "token1": "usdt",
        "token0_address": "0x6810e776880c02933d47db1b9fc05908e5386b96",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "grtusdt",
        "token0": "grt",
        "token1": "usdt",
        "token0_address": "0xc944e90c64b2c07662a292be6244bdf05cda44a7",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "ilvusdt",
        "token0": "ilv",
        "token1": "usdt",
        "token0_address": "0x767fe9edc9e0df98e07454847909b5e959d7ca0e",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "kncusdt",
        "token0": "knc",
        "token1": "usdt",
        "token0_address": "0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "ldousdt",
        "token0": "ldo",
        "token1": "usdt",
        "token0_address": "0x5a98fcbea516cf06857215779fd812ca3bef1b32",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "mkrusdt",
        "token0": "mkr",
        "token1": "usdt",
        "token0_address": "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "mntusdt",
        "token0": "mnt",
        "token1": "usdt",
        "token0_address": "0x3c3a81e81dc49a522a592e7622a7e711c06bf354",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "rlbusdt",
        "token0": "rlb",
        "token1": "usdt",
        "token0_address": "0x046eee2cc3188071c02bfc1745a6b17c656e3f3d",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "rplusdt",
        "token0": "rpl",
        "token1": "usdt",
        "token0_address": "0xd33526068d116ce69f19a9ee46f0bd304f21a51f",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "snxusdt",
        "token0": "snx",
        "token1": "usdt",
        "token0_address": "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "uniusdt",
        "token0": "uni",
        "token1": "usdt",
        "token0_address": "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "yfiusdt",
        "token0": "yfi",
        "token1": "usdt",
        "token0_address": "0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "zrxusdt",
        "token0": "zrx",
        "token1": "usdt",
        "token0_address": "0xe41d2489571d322189246dafa5ebde1f4699f498",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "aaveusdt",
        "token0": "aave",
        "token1": "usdt",
        "token0_address": "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "avaxusdt",
        "token0": "avax",
        "token1": "usdt",
        "token0_address": null,
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "bandusdt",
        "token0": "band",
        "token1": "usdt",
        "token0_address": "0xba11d00c5f74255f56a5e366f4f77f5a186d7f55",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "blurusdt",
        "token0": "blur",
        "token1": "usdt",
        "token0_address": "0x5283d291dbcf85356a21ba090e6db59121208b44",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "compusdt",
        "token0": "comp",
        "token1": "usdt",
        "token0_address": "0xc00e94cb662c3520282e6f5717214004a7f26888",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "galausdt",
        "token0": "gala",
        "token1": "usdt",
        "token0_address": "0xd1d2eb1b1e90b638588728b4130137d262c87cae",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "instusdt",
        "token0": "inst",
        "token1": "usdt",
        "token0_address": "0x6f40d4a6237c257fff2db00fa0510deeecd303eb",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "linkusdt",
        "token0": "link",
        "token1": "usdt",
        "token0_address": "0x514910771af9ca656af840dff83e8264ecf986ca",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "manausdt",
        "token0": "mana",
        "token1": "usdt",
        "token0_address": "0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "pepeusdt",
        "token0": "pepe",
        "token1": "usdt",
        "token0_address": "0x6982508145454ce325ddbe47a25d4ec3d2311933",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "rndrusdt",
        "token0": "rndr",
        "token1": "usdt",
        "token0_address": "0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "sandusdt",
        "token0": "sand",
        "token1": "usdt",
        "token0_address": "0x3845badade8e6dff049820680d1f14bd3903a5d0",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "shibusdt",
        "token0": "shib",
        "token1": "usdt",
        "token0_address": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "usdcusdt",
        "token0": "usdc",
        "token1": "usdt",
        "token0_address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "wbtcusdt",
        "token0": "wbtc",
        "token1": "usdt",
        "token0_address": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "1inchusdt",
        "token0": "1inch",
        "token1": "usdt",
        "token0_address": "0x111111111117dc0aa78b770fa6a738034120c302",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "maticusdt",
        "token0": "matic",
        "token1": "usdt",
        "token0_address": "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "stethusdt",
        "token0": "steth",
        "token1": "usdt",
        "token0_address": "0xae7ab96520de3a18e5e111b5eaab095312d7fe84",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "sushiusdt",
        "token0": "sushi",
        "token1": "usdt",
        "token0_address": "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
        "token1_address": "0xdac17f958d2ee523a2206206994597c13d831ec7"
    },
    {
        "instrument": "wbtceth",
        "token0": "eth",
        "token1": "wbtc",
        "token0_address": "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        "token1_address": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599"
    }
];
