import {EGranularitySize, TokenPair} from "../state/types";
import {
    assetPairsWithAddresses
} from "../views/simulation/create-simulation/setup-simulation/market-scenarios/asset_pair_addresses";

export type AssetInputObject = {
    Instrument: string;
    Timeframe: string;
    earliest_date: string;
    end_date: string;
    token1: string;
    token2: string;
};

export type OutputObject = {
    symbol: string;
    timeframes: string[];
    token0_name: string;
    token1_name: string;
    earliest_date: string;
    end_date: string;
};


const mockFeeTiers = [0.1, 0.2, 0.3, 0.4, 0.5];

export const timeframeToSeconds = (timeframe: string): number => {
    const unit = timeframe.slice(-1);
    const value = parseInt(timeframe.slice(0, -1), 10);
    switch (unit) {
        case 'm':
            return value * 60;
        case 'h':
            return value * 60 * 60;
        case 'd':
            return value * 1440 * 60;
        default:
            return 0;
    }
};

export const timeframeToGranularitySize = (timeframe: string): EGranularitySize => {
    const unit = timeframe.slice(-1);
    switch (unit) {
        case 'm':
            return EGranularitySize.Minute;
        case 'h':
            return EGranularitySize.Hour;
        case 'd':
            return EGranularitySize.Day;
        default:
            return EGranularitySize.Hour;
    }
}


export function remapAssets(inputArray: AssetInputObject[]): TokenPair[] {

    const combinedMap: { [key: string]: TokenPair } = {};



    inputArray.forEach((item) => {
        const { Instrument, Timeframe, earliest_date, end_date, token1, token2 } = item;

        const assetPairWithAddresses = assetPairsWithAddresses.find((pair) => pair.instrument === Instrument);

        if (!assetPairWithAddresses?.token0_address || !assetPairWithAddresses?.token1_address) {
            console.error(`Could not find addresses for ${Instrument}`);
            return;
        }

        if (!combinedMap[Instrument]) {
            combinedMap[Instrument] = {
                symbol: Instrument,
                timeframes: [Timeframe],
                token0_name: token1.toUpperCase(),
                token1_name: token2.toUpperCase(),
                token0_address: assetPairWithAddresses?.token0_address!,
                token1_address: assetPairWithAddresses?.token1_address!,
                earliest_date,
                end_date,
                feeTiers: mockFeeTiers
            };
        } else {
            const existingEntry = combinedMap[Instrument];
            if (!existingEntry.timeframes.includes(Timeframe)) {
                existingEntry.timeframes.push(Timeframe);
                existingEntry.timeframes.sort((a, b) => timeframeToSeconds(a) - timeframeToSeconds(b));

            }

        }
    });

    return Object.values(combinedMap);
}
