import TimelineIcon from "@mui/icons-material/Timeline";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import * as React from "react";
import {MarketScenarioOption} from "../../../../state/types";


export enum EMarketScenarioColors {
    rangebound = "#000000",
    uptrend = "#4D5B9E",
    downtrend = "#EF8F3B",
}

export const graphColorsArray = [
    "#000000",
    "#A463A8",
    "#9C424A",
    "#857555",
    "#E86F99",
    "#4D5B9E",
    "#FF907C",
    "#FFC166",
    "#CFA616",
    "#609997",
    "#EEE8A9",
    "#EF8F3B"
]

// uptrend: max range: [1.0,2.0], default: [1.3,1.7] ;
// downtrend: max range: [-1.0, -2.0], default: [-1.3,-1.7];
// rangebound: max range: [-0.2,0.2], default: [-0.1,0.1]

export const initialMarketScenarioOptions: MarketScenarioOption[] = [
    {
        type: "rangebound", //MarketScenarioEnum.Rangebound,
        icon: <TimelineIcon style={{fontSize: "16px"}}/>,
        title: "Rangebound",
        color: EMarketScenarioColors.rangebound,
        drift: undefined,
        volatilityMultiplier: 0.2,
        minVolatility: 0.5,
        maxVolatility: 50,
        // @ts-ignore
        shape: "sinusoidal", //PriceSimulationShapeEnum.Linear
        marketDriftMode: "ranging",
        selected: true,
    },
    {
        type: "downtrend", //MarketScenarioEnum.Downtrend,
        icon: <TrendingDownIcon style={{fontSize: "16px"}}/>,
        title: "Downtrend",
        color: EMarketScenarioColors.downtrend,
        drift: [-1.8, -1.6],
        minDrift: -3,
        maxDrift: -1,
        volatilityMultiplier: 0.2,
        minVolatility: 0.01,
        maxVolatility: 1,
        marketDriftMode: "linear",
        shape: "linear", //PriceSimulationShapeEnum.Linear,
        selected: false,
    },
    {
        type: "uptrend", //MarketScenarioEnum.Uptrend,
        icon: <TrendingUpIcon style={{fontSize: "16px"}}/>,
        title: "Uptrend",
        color: EMarketScenarioColors.uptrend,
        drift: [1.0, 1.1],
        minDrift: 0.5,
        maxDrift: 2,
        minVolatility: 0.01,
        maxVolatility: 1,
        volatilityMultiplier: 0.2,
        marketDriftMode: "linear",
        shape: "linear", //PriceSimulationShapeEnum.Linear,
        selected: false,
    },
]
export const defaultPriceScenarioChartOptions = {
    interaction: {
        intersect: false
    },
    animation: false,
    parsing: false,
    elements: {
        line: {
            borderWidth: 1,
        },
        point: {
            display: false,
            borderWidth: 0,
            backgroundColor: "transparent",
            hoverBorderWidth: 0
        }
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            //what should be shown in the tooltip?
            enabled: false
        },
    },
    scales: {
        x: {
            display: true,
            title: {
                display: true
            },
            grid: {
                display: false
            }
        },
        y: {
            position: "right",
            display: true,
            title: {
                display: true,
                text: 'Price',
                font: {
                    size: 14,
                    weight: 'bold',
                },
            },
            grid: {
                display: false
            },
        }
    }
};
export const tradingStrategyOptions = [
    {
        id: "fixed",
        title: "Fixed strategy",
        description: "A fixed strategy opens an LP position with the desired range centered on the current price of the pool. The position does not change throughout its lifetime and is closed at the end of the simulation ",
        bestUse: "Useful as a benchmark and mimics how most LPs act in reality.",
    },
    {
        id: "dynamic_uniform",
        title: "Dynamic uniform",
        description: "A dynamic rebalancing strategy automatically updates your position given market conditions or other factors. This is a family of strategies that can use simple rules or sophisticated models to update the position's location and range. In the uniform case, the bounds are always kept the same but its location is updated to center on the new market price. The current update rule rebalances the position when the market price exceeds the bounds of the currently supplied range. The bounds are set based on the historical volatility of the asset and remain static throughout the simulation.",
        bestUse: "This is an active strategy that is useful for keeping your position in range of the current market price when volatility is relatively constant.",
    },
    {
        id: "dynamic_range",
        title: "Dynamic range",
        description: "A dynamic rebalancing strategy automatically updates your position given market conditions or other factors. This is a family of strategies that can use simple rules or sophisticated models to update the position's location and range. In the proportional case, the location and bounds of the position are dynamically updated.  The current update rule rebalances the position when the market price exceeds the bounds of the currently supplied range. The bounds are set based on the historical volatility of the asset and are updated based on the current volatility throughout the simulation.",
        bestUse: "This is an active strategy that is useful for keeping your position in range of the current market price during frequent fluctuations in market volatility.",
    },
]
