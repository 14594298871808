import * as React from "react";
import {PageContentContainer} from "../views/styled/styled";
import {CenteredRowFlexBox, FlexCol} from "../views/simulation/create-simulation/styled";
import {Alert, CircularProgress, InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {useNavigate} from "react-router-dom";
import {LDKeyStrategyLibraryMaintenance, useAppState} from "../state/AppStateProvider";
import useSWR from "swr";
import {getArtifactLibraryItems} from "../api/hasura";
import Fuse from "fuse.js";
import {useEffect, useState} from "react";
import {ArtifactItem} from "../views/strategy/ArtifactItem";
import Box from "@mui/material/Box";
import {LDKeyStrategyLibraryWidget} from "../components/layout/Sidebar";


// Fuse.js configuration
const options = {
    includeScore: true,
    threshold: 0.2,
    // Fields to search in
    keys: ['author_user.email', 'author_user.name', 'latest_version_artifact.name', 'metadata.input_features', 'metadata.output_structure', 'metadata?.tags', 'name', 'description'],
};

export type ArtifactFile = {
    id: string;
    uri: string
    authenticated_url: string;
    date_created: string;
    description: string;
}

export type LibraryArtifactVersion = {
    artifact_files: ArtifactFile[],
    author: string,
    date_created: string,
    description: string,
    id: string,
    is_public: boolean,
    metadata: any,
    name: string,
    type: string,
    author_user: { email: string, name: string | null }
}
export type LibraryArtifact = {
    latest_version_artifact: LibraryArtifactVersion,
    artifact_versions: LibraryArtifactVersion[],
    author: string,
    date_created: string,
    description: string,
    id: string,
    is_public: boolean,
    latest_public_version: string | null,
    metadata: any,
    name: string,
    type: string,
    author_user: { email: string, name: string | null }
}
export const StrategyLibraryWidget = () => {

    // const formController = useForm<StrategyLibraryForm>({
    //         defaultValues: {},
    //         reValidateMode: "onChange"
    //     }
    // );
    const navigate = useNavigate();

    const {featureFlags} = useAppState();
    const isStrategyLibraryMaintenanceMode = featureFlags?.[LDKeyStrategyLibraryMaintenance];
    const isStrategyLibraryEnabled = featureFlags?.[LDKeyStrategyLibraryWidget];


    const {
        data: artifactList,
        isLoading,
    } = useSWR("strategy-templates", getArtifactLibraryItems)

    const openDetails = (strategy: LibraryArtifact) => {
        navigate(`/strategy-library/${strategy.id}`)
    }


    const [displayedArtifacts, setDisplayedArtifacts] = React.useState<LibraryArtifact[]>(artifactList);

    const [searcher, setSearcher] = useState(new Fuse(artifactList ?? [], options))

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;

        if (query === "") {
            setDisplayedArtifacts(artifactList);
            return;
        }
        const result = searcher.search(query);
        setDisplayedArtifacts(result.map((r: any) => r.item));
    }


    useEffect(() => {
        setDisplayedArtifacts(artifactList);
        const newSearcher = new Fuse(artifactList ?? [], options)
        setSearcher(newSearcher)
    }, [artifactList]);


    if (!isStrategyLibraryEnabled) {
        return <PageContentContainer>
            <Box sx={{m: 4, display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <h2>Strategy library feature is coming soon!</h2>
            </Box>
        </PageContentContainer>
    }


    return <PageContentContainer>


        <CenteredRowFlexBox sx={{gap: "16px", justifyContent: "space-between"}}>
            <h2>Strategies</h2>

            <TextField
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    )
                }}
                onChange={onSearch}
                size={"small"} variant={"outlined"} placeholder="Search by name, description, tags etc..."
                sx={{width: "100%", maxWidth: "400px"}}/>
        </CenteredRowFlexBox>


        {isStrategyLibraryMaintenanceMode &&
            <Alert severity={"error"} sx={{mb: 2}}>
                Strategy Library is currently in maintenance mode. Unexpected errors may occur.
            </Alert>
        }

        {displayedArtifacts?.length === 0 &&
            <Alert severity={"info"} sx={{mb: 2}}>
                No artifacts found. Please try a different search.
            </Alert>
        }

        {isLoading && <CenteredRowFlexBox sx={{justifyContent: "center", m: 4}}>
            <CircularProgress/>
        </CenteredRowFlexBox>
        }
        <FlexCol sx={{gap: "16px"}}>
            {displayedArtifacts?.map((artifact: LibraryArtifact) =>
                <ArtifactItem key={artifact?.id} artifact={artifact} />
            )}
        </FlexCol>
    </PageContentContainer>

}
