import * as React from "react";
import Box from "@mui/material/Box";
import {MenuItem, Select, SelectChangeEvent, Slider, styled, TextField} from "@mui/material";
import {CenteredColumnFlexBox, CenteredRowFlexBox} from "../../styled";
import {useState} from "react";
import {NumericFormat, NumericFormatProps} from "react-number-format";
import {feesChartMock} from "../../../../../constants";
import {useAppState} from "../../../../../state/AppStateProvider";
import {StrategyParameter} from "../../../../strategy/StrategyLibrary";
import {getDisplayAmount, getNativeAmount} from "../../../../../utils/moneyUtils";
import Big from "big.js";


function valuetext(value: number) {
    return `${value * 10}°C`;
}

const minDistance = 300;
const minValue = 500;
const maxValue = 3000;

const PriceCard = styled(CenteredColumnFlexBox)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#EEEEF02F" : "#F3F4F6",
    display: "flex",
    flexDirection: "column",
    padding: "10px 20px",
    alignItems: "center",
    height: "88px",
    border: "none",
    borderRadius: "12px !important",
    width: "100%"
}));

const PriceCardSecondaryText = styled("span")(({theme}) => ({
    color: theme.palette.mode === "dark" ? "white" : "#6B7280",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
}));

const PriceCardPrimaryText = styled("span")`
  font-size: 18px;
`

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const {onChange, ...other} = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="$"
            />
        );
    },
);
export const depositAmountChartOptions = {
    interaction: {
        intersect: false,
    },
    elements: {
        line: {
            borderWidth: 1,
        },
        point: {
            borderWidth: 0,
            backgroundColor: "transparent",
            hoverBorderWidth: 0
        }
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            //TODO: Do we need tooltip on this graph?
            enabled: true,
            filter: (tooltipItem: any) => tooltipItem.datasetIndex === 0,
        }
    },
    scales: {
        x: {
            display: false,
            title: {
                display: true
            },
            grid: {
                display: false
            }
        },
        y: {
            position: "left",
            display: true,
            title: {
                display: true,
                text: 'Price',
                font: {
                    size: 14,
                    weight: 'bold',
                },
            },
            grid: {
                display: false
            },
        }
    }
};
export const depositAmountParameterName = "total_deposit_token1";

export const minPriceBoundParameterName = "min_price_bound"; //this is not yet introduced from agent code
export const maxPriceBoundParameterName = "max_price_bound"; //this is not yet introduced from agent code
const depositAmountDecimalPlaces = 6; //hardcoded, as now we only have pools of USDT as the second token

export type DepositAmountChangeProps = {
    uniswapv3_pool_token0?: string,
    uniswapv3_pool_token1?: string,
    uniswapv3_pool_fee?: string,
    [depositAmountParameterName]?: any,
    [minPriceBoundParameterName]?: any,
    [maxPriceBoundParameterName]?: any,
};

type DepositAmountProps = {
    onChange: (values: DepositAmountChangeProps) => void;
    parameters: StrategyParameter[];
}


export const DepositAmountContent = ({onChange, parameters}: DepositAmountProps) => {

    const {theme, selectedSimulation, selectedAgent, availableTokenPairs} = useAppState();

    const isDepositAmountAvailable = !!parameters.find(p => p.name === depositAmountParameterName);

    const isPriceBoundsAvailable = !!parameters.find(p => p.name === minPriceBoundParameterName);


    const initialFeeTier = parameters.find(p => p.name === "uniswapv3_pool_fee")?.value;
    const initialToken0 = parameters.find(p => p.name === "uniswapv3_pool_token0")?.value;
    const initialToken1 = parameters.find(p => p.name === "uniswapv3_pool_token1")?.value;

    const [priceRangeValue, setPriceRangeValue] = useState<number[]>([900, 2000]);
    const [feeTier, setFeeTier] = useState<any>(initialFeeTier);
    const [depositAmount, setDepositAmount] = useState<any>(parameters.find(p => p.name === depositAmountParameterName)?.value);
    const [selectedPool, setSelectedPool] = useState(availableTokenPairs?.find(p => p.token0_name === initialToken0 && p.token1_name === initialToken1));

    const selectedChartData = feesChartMock;

    //TODO: this should be changed to the available amount of the selected token and not hardcoded to USDT
    const availableAmount = selectedAgent?.holdings?.find(h => h.token === "USDT")?.amount ?? 0;
    // const availableAmount = selectedAgent?.holdings?.find(h => h.token === selectedPool?.uniswap_token1)?.amount ?? 0;

    const availableFeeTiers = selectedPool?.feeTiers.map(feeTier => ({
        value: feeTier,
        displayValue: `${feeTier / 10000}%`
    }));


    const selectTokenPair = (e: any) => {
        const selectedPool = availableTokenPairs?.find((p) => p.symbol === e.target.value);
        setSelectedPool(selectedPool);
        setFeeTier(null);
        onChange({
            uniswapv3_pool_token0: selectedPool?.token0_name,
            uniswapv3_pool_token1: selectedPool?.token1_name,
            uniswapv3_pool_fee: undefined,
        })
    };


    const dynamicOptions = {
        ...depositAmountChartOptions,
        plugins: {
            ...depositAmountChartOptions.plugins,
            annotation: {
                annotations: {
                    label1: {
                        // Indicates the type of annotation
                        type: 'label',
                        xValue: selectedChartData.length - 10,
                        yValue: priceRangeValue[0],
                        content: " Min ",
                        backgroundColor: '#000',
                        color: "#fff",
                        font: {
                            size: 14,
                        }
                    },
                    label2: {
                        // Indicates the type of annotation
                        type: 'label',
                        xValue: selectedChartData.length - 10,
                        yValue: priceRangeValue[1],
                        content: " Max ",
                        backgroundColor: '#000',
                        color: "#fff",
                        font: {
                            size: 14,
                        }
                    }
                }
            }
        }
    };
    const chartLabels = selectedChartData?.map((d: any) => new Date(d.date * 1000).toDateString());
    const dataForChart = {
        labels: chartLabels,
        datasets: [
            {
                label: 'Price',
                data: selectedChartData?.map((d: any) => d.priceUSD),
                borderColor: theme === "dark" ? "#FFF" : "#000000",
            },
            {
                label: 'min-value',
                data: selectedChartData?.map((d: any) => priceRangeValue[0]),
                borderColor: theme === "dark" ? "#FFF" : "#000000",
                borderDash: [2, 2]
            },
            {
                label: 'max-value',
                data: selectedChartData?.map((d: any) => priceRangeValue[1]),
                borderColor: theme === "dark" ? "#FFF" : "#000000",
                borderDash: [2, 2],
            }
        ],
    }

    const onPriceRangeChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        let finalValues = newValue;
        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], maxValue - minDistance);
                finalValues = [clamped, clamped + minDistance];
                setPriceRangeValue([clamped, clamped + minDistance]);

            } else if (newValue[1] > minValue + minDistance) {
                const clamped = Math.max(newValue[1], minDistance);
                finalValues = [clamped - minDistance, clamped];
                setPriceRangeValue([clamped - minDistance, clamped]);
            }
        } else {
            setPriceRangeValue(newValue as number[]);
        }

        if (isPriceBoundsAvailable) {
            onChange({
                [minPriceBoundParameterName]: finalValues[0],
                [maxPriceBoundParameterName]: finalValues[1],
            });
        }
    };

    return <Box>
        <span style={{fontSize: "16px", fontWeight: "bold"}}>Open Position</span>

        <Box sx={{my: 2, gap: 2, display: "flex", width: "100%"}}>
            <Box sx={{marginRight: 4, flexGrow: 2, minWidth: "400px", width: "100%", maxWidth: "480px"}}>
                <Box sx={{py: 2, mb: 1}}>
                    <CenteredRowFlexBox sx={{gap: 2}}>
                        <CenteredColumnFlexBox sx={{flexGrow: 2, gap: 1}}>
                            <span style={{fontWeight: "bold"}}>Pool</span>
                            <Select fullWidth
                                    error={!selectedPool?.symbol}
                                    value={selectedPool?.symbol}
                                    onChange={(e) => selectTokenPair(e)}
                                    size={"small"}>
                                {availableTokenPairs?.map(tokenPair => <MenuItem key={tokenPair.symbol}
                                                                                 value={tokenPair.symbol}>{tokenPair.token0_name} - {tokenPair.token1_name}</MenuItem>)
                                }
                            </Select>
                        </CenteredColumnFlexBox>

                        <CenteredColumnFlexBox sx={{flexGrow: 1, gap: 1}}>
                            <span style={{fontWeight: "bold"}}>Fee tier</span>
                            <Select fullWidth
                                    value={feeTier}
                                    onChange={(e: SelectChangeEvent) => {
                                        setFeeTier(e.target.value)
                                        onChange({uniswapv3_pool_fee: e.target.value})
                                    }}
                                    size={"small"}
                                    error={!feeTier}>
                                {availableFeeTiers?.map(feeTier => <MenuItem key={feeTier.value}
                                                                             value={feeTier.value}>{feeTier.displayValue}</MenuItem>)
                                }
                            </Select>
                        </CenteredColumnFlexBox>
                    </CenteredRowFlexBox>
                </Box>
                {isDepositAmountAvailable &&
                <TextField
                    value={getDisplayAmount(depositAmount, depositAmountDecimalPlaces)}
                    onChange={(e) => {
                        setDepositAmount(getNativeAmount(e.target.value, depositAmountDecimalPlaces));
                        onChange({[depositAmountParameterName]: getNativeAmount(e.target.value, depositAmountDecimalPlaces)})
                    }}
                    type={"number"}
                    error={!depositAmount || depositAmount === "0" || Big(depositAmount).gte(Big(availableAmount))}
                    helperText={!depositAmount || depositAmount === "0" ? "Amount is required" : Big(depositAmount).gte(Big(availableAmount)) ? `Amount must be less than ${getDisplayAmount(availableAmount, depositAmountDecimalPlaces)}` : undefined}
                    fullWidth
                    sx={{marginBottom: 4}} id="outlined-basic"
                    label={`Deposit Amount (USDT)`}
                    placeholder={"Enter Deposit Amount"}
                    variant="outlined"/>

                }


                {isPriceBoundsAvailable && <Box><CenteredRowFlexBox sx={{gap: 2}}>
                    <PriceCard sx={{}}>
                        <PriceCardSecondaryText>Min Price</PriceCardSecondaryText>
                        <PriceCardPrimaryText>{priceRangeValue[0]}</PriceCardPrimaryText>
                        <PriceCardSecondaryText>USDC per ETH</PriceCardSecondaryText>
                    </PriceCard>
                    <PriceCard>
                        <PriceCardSecondaryText>Max Price</PriceCardSecondaryText>
                        <PriceCardPrimaryText>{priceRangeValue[1]}</PriceCardPrimaryText>
                        <PriceCardSecondaryText>USDC per ETH</PriceCardSecondaryText>
                    </PriceCard>
                </CenteredRowFlexBox>
                    <Slider
                        sx={{my: 4}}
                        disableSwap
                        getAriaLabel={() => 'Price range'}
                        value={priceRangeValue}
                        onChange={onPriceRangeChange}
                        valueLabelDisplay="auto"
                        min={minValue}
                        max={maxValue}
                        getAriaValueText={valuetext}
                    />
                </Box>}
            </Box>

            {/*{isPriceBoundsAvailable && <Box sx={{width: "100%"}}>*/}
            {/*    <PanelBox sx={{p: 2}}>*/}
            {/*        <CenteredRowFlexBox sx={{mx: 4, justifyContent: "space-between"}}>*/}
            {/*            <Box>*/}
            {/*                <span style={{fontSize: "12px"}}>Current price:</span>*/}
            {/*                <span style={{fontSize: "12px"}}>1891.91 USDC per ETH</span>*/}
            {/*            </Box>*/}
            {/*        </CenteredRowFlexBox>*/}
            {/*        <AlmanakChart height={"auto"} data={dataForChart} options={dynamicOptions}/>*/}
            {/*    </PanelBox>*/}
            {/*</Box>}*/}

        </Box>
    </Box>
}
