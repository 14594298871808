import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {drawerWidth} from "../../constants";

export const PageContentContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-left: ${drawerWidth + 16}px;
    max-width: 1400px;
    width: 100%;
    margin: auto;
    //margin: 64px 16px;
`
