import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom"
import {AppStateProvider} from "./state/AppStateProvider";
import LogRocket from 'logrocket';

import {Theme} from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";

import {Analytics} from "@vercel/analytics/react"

const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === "production";
if (IS_PRODUCTION && process.env.REACT_APP_LOGROCKET_ID) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
}

const root = ReactDOM.createRoot(
    document.getElementById('root')!,
);
root.render(
    <React.StrictMode>
            <AppStateProvider>

                <BrowserRouter>
                    <App/>
                </BrowserRouter>
                {IS_PRODUCTION && <Analytics/>}
            </AppStateProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
