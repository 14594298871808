import { Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../../state/AppStateProvider";
import * as React from "react";
import UserDropdown from "../../components/wallet-connect/UserDropdown";
import { AlmanakLogo } from "../../components/AlmanakLogo";

const Home = () => {
  let navigate = useNavigate();

  const { user, theme, setCliAuthPort, setCliAuthentication } = useAppState();
  const isAuthenticated = !!localStorage.getItem("accessToken");

  if (isAuthenticated && user?.isOnboarded) {
    navigate("/");
  }
  //check if there's params in the url
  //look for params "cli"=true and port
  //if true save the cli port
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("cli") === "true") {
    const cliPort = Number(urlParams.get("port"));
    if (cliPort) {
      window.history.replaceState({}, document.title, "/");
      setCliAuthPort(cliPort);
      setCliAuthentication(true);

    }
  }

  return (
    <div className="container">
      <Box sx={{ display: "flex", marginTop: "120px", alignItems: "center" }}>
        <AlmanakLogo size={96} />
        <Chip label="Alpha" color={"success"} />
      </Box>

      <p>Enter the multiverse</p>

      {!user?.publicKey && <UserDropdown />}
    </div>
  );
};

export default Home;
