import * as d3 from 'd3';
import * as math from 'mathjs';


// function generateHistogramData(data: number[], numberOfBins: number, range: [number, number]) {
    // Calculate the minimum and maximum values from the data if not provided
    // if (!range) {
    //     range = [Math.min(...data), Math.max(...data)];
    // }

    // Calculate bin width
    // const binWidth = (range[1] - range[0]) / numberOfBins;

    // Initialize an array to store bin counts
    // const binCounts = Array(numberOfBins).fill(0);

    // Fill the bins with counts
    // for (const value of data) {
    //     const binIndex = Math.floor((value - range[0]) / binWidth);
    //     if (binIndex >= 0 && binIndex < numberOfBins) {
    //         binCounts[binIndex]++;
    //     }
    // }
    // let bins = d3.bin();
    // const d3Hist = bins(data);

    //
    // // Generate bin edges
    // const binEdges = Array.from({length: numberOfBins}, (_, i) => {
    //     return range[0] + i * binWidth;
    // });
    // binEdges.push(range[1]); // Add the right edge of the last bin

    // Combine bin edges and counts into an array of objects
    // return d3Hist.map((edge, index) => ({
    //     binStart: edge.x0,
    //     binEnd: edge.x1,
    //     count: edge.length,
    // }));
// }

function calculateHistogram(data: number[]): any {
    // Calculate the number of bins using the Freedman-Diaconis rule
    // @ts-ignore
    let iqr = math.quantileSeq(data, 0.75) - math.quantileSeq(data, 0.25);
    if (iqr === 0) {
        iqr = 1;
    }

    // @ts-ignore
    let numBins = Math.ceil((math.max(data) - math.min(data)) / (2 * (iqr / math.pow(data.length, 1 / 3))));

    if (Math.abs(numBins) < 7) {
        numBins = 7;
    }

    let bins = d3.bin();
    const d3Hist = bins(data);

    // Combine bin edges and counts into an array of objects
    return d3Hist.map((edge, index) => ({
        binStart: edge.x0,
        binEnd: edge.x1,
        count: edge.length,
    }));

    // return generateHistogramData(data, numBins, [math.min(data), math.max(data)]);
}

//TODO: type
export function createHistograms(simulationArray: any[], types: string[]) {

    let result: any = {};
    if (!simulationArray) {
        return {}
    }

    simulationArray.forEach((simulation) => {
        if (!simulation.totalSteps || simulation.totalSteps === 0) {
            return {};
        }
        const step = simulation.environment_step[simulation.totalSteps - 1];
        types.forEach((type) => {
            if (step?.[type] === undefined) {
                return;
            }
            if (!result?.[type]) {
                result[type] = {
                    key: type,
                    data: []
                }
            }
            result?.[type].data.push(step[type]);
        });
    });

    types.forEach((type) => {

        const data = result[type].data;
        result[type].histogramData = calculateHistogram(data);
    });

    return result;
}
