import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import {useAppState} from "../../../../state/AppStateProvider";
import FloatingControls from "../../../../components/FloatingControls";
import {UseFormReturn, UseFormTrigger} from "react-hook-form";
import {SimulationMetadata} from "../../../../state/types";
import {GroupSimulation, updateGroupSimulation} from "../../../../api/hasura";
import {assetPairsWithAddresses} from "./market-scenarios/asset_pair_addresses";

type Holding = {
    mint: boolean;
    token: string;
    amount: string;
};

type Settings = {
    cex_price_token0: string;
    cex_price_token1: string;
    min_eth_in_balance: number;
    uniswapv3_pool_fee: number;
    total_deposit_token1: number;
    max_fraction_to_trade: number;
    min_fraction_to_trade: number;
    uniswapv3_pool_token0: string;
    uniswapv3_pool_token1: string;
};

type Agent = {
    id: string;
    type: string;
    alias: string;
    class: string;
    address: string;
    holdings: Holding[];
    settings: Settings;
};

type AgentConfig = {
    agents: Agent[];
    price_config_hashkey: string;
}
const remapSimulationToUseFirstPriceConfig = (simulation: GroupSimulation, assetPair: any, priceConfigs: string[]) => {

    const remappedAgentConfigs = simulation.frontend_state.agentConfigs.map((agent: Agent) => {


        const newHoldings = agent.holdings;

        if (!newHoldings.find((holding) => holding.token === assetPair.token0_name)) {
            newHoldings.push({
                mint: true,
                token: assetPair.token0_name,
                amount: "10000000000000"
            })
        }

        if (!newHoldings.find((holding) => holding.token === assetPair.token1_name)) {
            newHoldings.push({
                mint: true,
                token: assetPair.token1_name,
                amount: "10000000000000"
            })
        }


        let uniswapToken0 = assetPair.token0_name;
        let uniswapToken1 = assetPair.token1_name;

        if (uniswapToken0 === "ETH") {
            uniswapToken0 = "WETH";
        }
        if (uniswapToken1 === "ETH") {
            uniswapToken1 = "WETH";
        }

        const newSettings = {
            ...agent.settings,
            cex_price_token0: assetPair.token0_name,
            cex_price_token1: assetPair.token1_name,
            uniswapv3_pool_token0: uniswapToken0,
            uniswapv3_pool_token1: uniswapToken1,
            uniswap3_pool_fee: assetPair.feeTiers[0] * 1000,
        }

        return {
            ...agent,
            holdings: newHoldings,
            settings: newSettings
        }
    });

    const newFrontendState = {
        ...simulation.frontend_state,
        agentConfigs: remappedAgentConfigs,
        activeStep: (simulation.frontend_state?.activeStep ?? 0) + 1
    }

    const newMetadata = {
        "block": newFrontendState.block, //start block on simulation
        "steps": newFrontendState.steps, //how many steps, should be equal to the price_confg
        "tokens": [
            {
                "name": assetPair.token0_name,
                "address": assetPair.token0_address,
                "isNative": assetPair?.token0_address === "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
            },
            {
                "name": assetPair.token1_name,
                "address": assetPair.token1_address,
                "isNative": assetPair.token1_address === "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
            }
        ]
    }

    if (!newMetadata.tokens.find((token) => token.isNative)) {
        newMetadata.tokens.push({
            "name": "ETH",
            "address": "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
            "isNative": true,
            // @ts-ignore
            "nativeLabel": "Uniswap"
        })
    }

    if (!newMetadata.tokens.find((token) => token.name === "WETH")) {
        newMetadata.tokens.push({
            "name": "WETH",
            "address": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
            "isNative": false,
        })
    }

    if (!newMetadata.tokens.find((token) => token.name === "USDT")) {
        newMetadata.tokens.push({
            "name": "USDT",
            "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
            "isNative": false,
        })
    }

    if (!newMetadata.tokens.find((token) => token.name === "WBTC")) {
        newMetadata.tokens.push({
            "name": "WBTC",
            "address": "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
            "isNative": false
        })
    }




    const finalAgentConfigs = priceConfigs.map((priceConfig: any, index) => {
        return {
            price_config_hashkey: priceConfig,
            agents: remappedAgentConfigs
        }
    });

    return {
        ...simulation,
        price_configs: newFrontendState.priceConfigs,
        frontend_state: newFrontendState,
        agent_configs: finalAgentConfigs,
        metadata: newMetadata

    };

};

export default function SimulationStepper({
                                              steps,
                                              children,
                                              trigger,
                                              formController
                                          }: {
    steps: string[],
    children: React.ReactNode,
    trigger: UseFormTrigger<SimulationMetadata>,
    formController: UseFormReturn<SimulationMetadata, any, undefined>
}) {

    const {setSnackBar, simulatorPriceTrajectoryHashes, availableTokenPairs} = useAppState();
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const {selectedSimulation, setSelectedSimulation} = useAppState();

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return selectedSimulation?.frontend_state.activeStep! === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const updateSimulation = async (selectedSimulation: GroupSimulation) => {


        //TODO: this is temporary before the selection in the frontend is implemented

        const firstAssetPair = selectedSimulation.frontend_state.assetPair?.[0];

        const temporaryAssetPairWithDetails = availableTokenPairs?.find((tokenPair) => tokenPair.symbol === firstAssetPair);

        const usedPriceTrajectories = selectedSimulation.frontend_state.priceConfigs;


        const remappedSimulation = remapSimulationToUseFirstPriceConfig(selectedSimulation, temporaryAssetPairWithDetails, usedPriceTrajectories);



        setSelectedSimulation({
            ...remappedSimulation,
            isLoading: true,
        })

        // const remappedAgentConfigs = frontendState.agentConfigs.map((agentConfig) => {
        //     const {strategy, wallet, holdings, ...rest} = agentConfig;
        //     const remappedHoldings = holdings?.map((holding: any) => {
        //         const {displayAmount, ...rest} = holding;
        //         return rest;
        //     });
        //     return {...rest, holdings: remappedHoldings};
        // });


        // //console.log("remappedAgentConfigs", remappedAgentConfigs);


        // const metadata = {
        //     "block": frontendState.block, //start block on simulation
        //     "steps": frontendState.steps, //how many steps, should be equal to the price_confg
        //     "tokens": [
        //         {
        //             "name": "USDT",
        //             "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        //             "isNative": false
        //         },
        //         {
        //             "name": "WETH",
        //             "address": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        //             "isNative": false
        //         },
        //         {
        //             "name": "WBTC",
        //             "address": "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
        //             "isNative": false
        //         },
        //         {
        //             "name": "ETH",
        //             "address": "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        //             "isNative": true,
        //             "nativeLabel": "Uniswap"
        //         }
        //     ]
        // }


        //
        // const agentConfigs = usedPriceTrajectories?.map((priceConfig: any, index) => {
        //     return {
        //         price_config_hashkey: priceConfig,
        //         agents: remappedSimulation.agent_configs[index]
        //     }
        // });
        // //console.log("agentConfigs", agentConfigs);



        const updatedSimulation = await updateGroupSimulation(selectedSimulation.id!, remappedSimulation);

        setSelectedSimulation({
            ...updatedSimulation,
            isLoading: false,
            activeStep: 2
        })


    }
    const handleNext = async () => {
        const isFormCorrect = await trigger();
        if (isFormCorrect && selectedSimulation) {
            if (!selectedSimulation.frontend_state.priceTrajectoryResults || selectedSimulation.frontend_state.priceTrajectoryResults.length === 0) {
                setSnackBar({open: true, severity: "error", message: "Please generate price trajectories first!"})
                return;
            }
            updateSimulation(selectedSimulation)
        } else {
            setSnackBar({open: true, severity: "error", message: "Please fill in required fields"})
        }

    };

    const handleStep = async (step: number) => {
        const isFormCorrect = await trigger();

        if (isFormCorrect && selectedSimulation) {
            const frontendState = {...selectedSimulation.frontend_state, activeStep: step - 1}
            const newSimulation = {
                ...selectedSimulation,
                frontend_state: frontendState
            }
            setSelectedSimulation(newSimulation)
            formController.setValue("activeStep", step)
            updateSimulation(newSimulation)
        }

    };

    return (
        <Box sx={{width: '100%'}}>
            <Stepper nonLinear activeStep={selectedSimulation?.frontend_state.activeStep!} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={() => handleStep(index)}>
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            {children}

            {selectedSimulation?.frontend_state.activeStep !== 2 && <FloatingControls
                primary={{isLoading: selectedSimulation?.isLoading, title: "Next", onClick: handleNext}}
            />}
        </Box>
    );
}
