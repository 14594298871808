import {Radio} from "@mui/material";
import Chip from '@mui/material/Chip';
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {useAppState} from "../../state/AppStateProvider";
import FloatingControls from "../../components/FloatingControls";
import {CustomRadio} from "../simulation/create-simulation/styled";
import {PageContentContainer} from "../styled/styled";



const SelectUseCase = () => {

    let navigate = useNavigate();
    const {user} = useAppState();
    const createSimulation = () => {
        navigate("/simulation/new/create")
    };

    return (
        <PageContentContainer sx={{alignItems: "center"}}>
            <Box sx={{textAlign: "center", width: "100%", justifyContent: "center", paddingTop: 4}}>
                <span className="title">
                    {`Hi ${user?.name?.split(" ")[0] ?? ""}, what would you like to simulate today?`}
                </span>
            </Box>

            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center"
            }}>
                <div style={{marginTop: "64px", width: "100%", maxWidth: "640px"}}>
                    <Chip label="Alpha" color={"success"}/>
                    <CustomRadio>
                        <Radio
                            checked={true}
                            onChange={() => null}
                            value="a"
                            name="radio-buttons"
                            inputProps={{'aria-label': 'A'}}
                        />
                        <span>LPs Dynamic Multi-Strategies Simulations</span>
                    </CustomRadio>
                </div>

                <div style={{marginTop: "24px", width: "100%", maxWidth: "640px"}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Chip label="Soon!" color={"warning"}/>
                    </div>
                    <CustomRadio style={{opacity: "0.5"}}>
                        <Radio
                            disabled
                            sx={{color: "black !important"}}
                            checked={false}
                            onChange={() => null}
                            value="a"
                            name="radio-buttons"
                            inputProps={{'aria-label': 'A'}}
                        />
                        <span>Collateral Management Optimization</span>
                    </CustomRadio>

                    <CustomRadio style={{opacity: "0.5"}}>
                        <Radio
                            disabled
                            sx={{color: "black !important"}}
                            checked={false}
                            onChange={() => null}
                            value="a"
                            name="radio-buttons"
                            inputProps={{'aria-label': 'A'}}
                        />
                        <span>Fee Switch Simulations - Generalized Fee/Taxation Optimization</span>
                    </CustomRadio>

                    <CustomRadio style={{opacity: "0.5"}}>
                        <Radio
                            disabled
                            sx={{color: "black !important"}}
                            checked={false}
                            onChange={() => null}
                            value="a"
                            name="radio-buttons"
                            inputProps={{'aria-label': 'A'}}
                        />
                        <span>Automated Market Making Vault Strategies Simulations</span>
                    </CustomRadio>

                    <CustomRadio style={{opacity: "0.5"}}>
                        <Radio
                            disabled
                            sx={{color: "black !important"}}
                            checked={false}
                            onChange={() => null}
                            value="a"
                            name="radio-buttons"
                            inputProps={{'aria-label': 'A'}}
                        />
                        <span>Protocol Liquidity Bootstrapping & Management</span>
                    </CustomRadio>

                </div>
            </Box>
            <FloatingControls primary={{title: "Next", onClick: createSimulation}}/>
        </PageContentContainer>


    );
}

export default SelectUseCase;
