import {PageContentContainer} from "../styled/styled";
import {
    Alert,
    Badge,
    Box,
    CircularProgress, Modal,
    Paper,
    StepLabel,
    TableContainer,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";


import {ArrowBack, ArrowBackIosNew, ContentCopy, OpenInBrowser, Refresh} from "@mui/icons-material";
import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {CenteredColumnFlexBox, CenteredRowFlexBox, FlexBox, PanelBox} from "../simulation/create-simulation/styled";
import Divider from "@mui/material/Divider";
import {formatMonetaryValue} from "../simulation/create-simulation/PoolDetails";
import Chip from "@mui/material/Chip";
import {ChainBadge} from "./ChainBadge";
import {formatPubKey} from "../../utils/commonUtils";
import {DataGrid} from "@mui/x-data-grid";
import useSWR from "swr";
import {getSafeWalletHoldings, getSmartWalletById} from "../../api/hasura";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import {useEffect, useMemo} from "react";
import Safe from "@safe-global/protocol-kit";
import {useAppState} from "../../state/AppStateProvider";
import {ROLES_MOD_ADDRESS} from "./WalletCreationFlow";
import {ethers} from "ethers";
import {setUpRolesMod, applyMembers} from "zodiac-roles-sdk";
import {TransactionResponse} from "@ethersproject/providers";
import {useFundWallet, useWallets} from "@privy-io/react-auth";
import IconButton from "@mui/material/IconButton";
import WalletStatus from "./WalletStatus";
import {allow, apply} from "defi-kit/eth";
import {encodeBytes32String} from "defi-kit";
import {fetchRole} from "zodiac-roles-deployments";
import {LabelValueChangeCard} from "../simulation/LabelValueChangeCard";
import {TitleValueColumn} from "../../modals/AddWalletModal";
import {AlmanakModalContent} from "../../modals/ScenarioCustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import {
    BackpackIcon,
    CopyIcon,
    ExclamationTriangleIcon,
    ExternalLinkIcon,
    CheckIcon,
    ArrowLeftIcon, ReloadIcon
} from "@radix-ui/react-icons";
import {Theme, Tabs, Flex, Card, Text, Table, Button, Callout, Spinner} from '@radix-ui/themes';
import '@radix-ui/themes/styles.css';


const transactions = [
    {type: "Deposit", amount: "1.5 ETH", date: "2024-06-01", status: "Completed"},
    {type: "Withdraw", amount: "2,400.00 USDC", date: "2024-05-27", status: "Completed"},
    {type: "Deposit", amount: "3,400.00 USDC", date: "2021-10-03", status: "Completed"},
];


const columns = [
    {field: 'asset', headerName: 'Asset', flex: 1},
    {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
        renderCell: (item: any) => <span>{item.row.amount}</span>
    },
    {
        field: 'usdValue',
        headerName: 'USD Value',
        flex: 1,
        renderCell: (item: any) => <span>${formatMonetaryValue(item.row.usdValue)}</span>
    },
];

export const walletHoldingsMock = [
    {id: 1, asset: "ETH", amount: 10, usdValue: 35000.11},
    {id: 2, asset: "BTC", amount: 0.5, usdValue: 35000.12},
    {id: 3, asset: "USDC", amount: 10000, usdValue: 10001.47},
    {id: 4, asset: "LINK", amount: 1000, usdValue: 13000.24},
    {id: 5, asset: "AAVE", amount: 5, usdValue: 530.4},
];

interface WalletHolding {
    id: number;
    asset: string;
    amount: number;
    usdValue: number;
    token?: Array<{
        symbol: string;
        total_token_amount: number;
        price_usd: number;
    }>;
}

interface WalletTransaction {
    transaction_hash: string;
    tx_name: string;
    user_address: string;
    chain: string;
    is_scam: boolean;
}

interface WalletDetails {
    id: string;
    name: string;
    address: string;
    chain_id: string;
    status: string;
    transactions?: WalletTransaction[];
    external_own_accounts: Array<{ address: string }>;
}

interface TokenData {
    symbol: string;
    total_token_amount: number;
    price_usd: number;
}

interface HoldingData {
    token: TokenData[];
    total_usd_balance?: number;
    profit_losses_display?: string;
    profit_losses_1day?: number;
}

export const WalletDetails = () => {

    const {wallets} = useWallets();
    const {fundWallet} = useFundWallet();

    const connectedWallet = wallets[0];

    const {id} = useParams();

    const {provider, setSnackBar} = useAppState();


    const [withdrawModalOpen, setWithdrawModalOpen] = React.useState(false);

    const [isRolesModEnabled, setIsRolesModEnabled] = React.useState(false);
    const [isRoleSetup, setIsRoleSetup] = React.useState(false);


    const [selectedTab, setSelectedTab] = React.useState<'holdings' | 'transactions'>('holdings');


    const {
        data: walletDetails,
        isLoading: isWalletLoading,
        isValidating: isWalletValidating,
        mutate: mutateWalletDetails,
    } = useSWR<WalletDetails>(id ? `wallets/${id}` : null, () => getSmartWalletById(id));


    const {
        data: walletHoldings,
        isLoading: isHoldingsLoading,
        isValidating: isHoldingsValidating,
        mutate: mutateWalletHoldings
    } = useSWR<HoldingData[]>(
        walletDetails?.address ? `wallets/${walletDetails.address}/holdings` : null,
        () => getSafeWalletHoldings(walletDetails?.address || "")
    );


    const walletHoldingsRows: WalletHolding[] = React.useMemo(() =>
            walletHoldings
                ?.filter((holding: HoldingData) => holding.token?.length > 0)
                ?.map((holding: HoldingData, index: number) => ({
                    id: index + 1,
                    asset: holding.token[0]?.symbol?.toUpperCase(),
                    amount: holding.token[0]?.total_token_amount,
                    usdValue: holding.token[0]?.price_usd
                })) ?? [],
        [walletHoldings]
    );


    const walletTransactions = walletDetails?.transactions;

    let totalBalance = walletHoldings?.[0]?.total_usd_balance ?? 0;
    let balanceChange24 = walletHoldings?.[0]?.profit_losses_display ?? '0';
    const isProfit = (walletHoldings?.[0]?.profit_losses_1day ?? 0) > 0;

    // Uncomment this to calculate total balance across all holdings instead of just first one
    // let totalBalance = walletHoldings?.reduce((acc: number, holding: any) => {
    //     return acc + (holding?.token?.[0]?.price_usd ?? 0)
    // }, 0);

    if (isNaN(totalBalance)) {
        totalBalance = 0;
    }

    console.log("walletHoldings", walletHoldings);
    console.log("walletDetails", walletDetails);
    const navigate = useNavigate()


    const onHoldingSelect = (holding: WalletHolding): void => {
        console.log("Holding selected", holding);
    }


    const isRolesSetup = true;
    const isWalletSetupComplete = walletDetails?.address && isRolesModEnabled && isRolesSetup;

    // const protocolKit = useMemo(async () => {
    //
    //     if (!provider) {
    //         return;
    //     }
    //
    //
    //     const safe = await Safe.init({provider: provider, safeAddress: walletDetails.address});
    //     console.log("Safe", safe);
    //
    //
    //     const modules = await safe.getModules();
    //
    //     console.log("Modules", modules);
    //     console.log("modules?.length > 0", modules?.length > 0);
    //     //TODO: is there a way to check if this module is actually a roles module?
    //     const isRolesModEnabled = modules?.length > 0;
    //     // await safe.isModuleEnabled(ROLES_MOD_ADDRESS);
    //
    //     console.log("isRolesModuleEnabled", isRolesModEnabled);
    //
    //     setIsRolesModEnabled(isRolesModEnabled);
    //
    //     return safe;
    //
    // }, [walletDetails, provider])


    const fetchRolesModStatus = async () => {
        console.log("fetching roles mod status");
        if (!provider) {
            console.log("NO PROVIDER");
            return;
        }

        if (!walletDetails) {
            console.error("NO WALLET DETAILS");
            return;
        }

        const safe = await Safe.init({provider: provider, safeAddress: walletDetails.address});
        console.log("Safe", safe);

        //this is not consistent, it does not always return true even though module is enabled.
        let isRolesModEnabled = await safe.isModuleEnabled(ROLES_MOD_ADDRESS);
        const modules = await safe.getModules();
        console.log("modules", modules);

        const roleName = "AlmanakAgentRole";
        const roleKey = encodeBytes32String(roleName);
        const role = await fetchRole({
            address: modules[0] as `0x${string}`,
            roleKey,
            chainId: Number(walletDetails.chain_id) as 1 | 10 | 100 | 137 | 42161 | 43114 | 8453 | 11155111,
        });

        console.log("role", role);

        const isRoleAdded = role !== null && role?.members[0] === walletDetails.external_own_accounts[0].address;
        console.log("isRoleAdded", isRoleAdded);

        console.log("modules", modules);
        isRolesModEnabled = modules?.length > 0;


        console.log("isRolesModuleEnabled", isRolesModEnabled);

        setIsRolesModEnabled(isRolesModEnabled);

        setIsRoleSetup(isRoleAdded);

    }
    useEffect(() => {
        if (walletDetails?.chain_id) {
            connectedWallet?.switchChain(Number(walletDetails.chain_id));
        }
        fetchRolesModStatus();
    }, [provider, walletDetails, connectedWallet]);

    // const getDeployRolesModuleTrx = async () => {
    //
    //
    //     const saltBytes = ethers.utils.randomBytes(32);
    //     const saltHex = ethers.utils.hexlify(saltBytes);
    //
    //
    //     const saltNonce = saltHex as `0x${string}`;
    //
    //
    //     const rolesModTrx = setUpRolesMod({
    //         avatar: walletDetails?.address,
    //         saltNonce: saltNonce
    //     });
    //
    //     return rolesModTrx
    //
    // };


    // const getApplyRoleTRX = async (zodiacModuleAddress: string, roleName: string) => {
    //
    //     const EURE = '0xcB444e90D8198415266c6a2724b7900fb12FC56E'
    //     const USDC = '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83'
    //
    //     const permissions = [
    //         allow.cowswap.swap({buy: [EURE, USDC], sell: [EURE, USDC]})
    //     ]
    //
    //     const roleKey = encodeBytes32String(roleName)
    //     const calls = await apply(roleKey, permissions, {
    //         address: zodiacModuleAddress as `0x${string}`,
    //         mode: 'replace', // replace the current permissions with the new ones
    //         log: console.debug
    //     })
    //     return calls;
    // }

    // const getDelegateRoleTRX = async (zodiacModuleAddress: string, chainId: any, roleName: string, members: `0x${string}`[]) => {
    //
    //     const roleKey = encodeBytes32String(roleName)
    //
    //     const calls = await applyMembers(
    //         roleKey,
    //         members,
    //         {
    //             chainId: chainId,
    //             address: zodiacModuleAddress as `0x${string}`,
    //             mode: "replace",
    //         }
    //     );
    //     const transactions = calls.map(data => ({to: zodiacModuleAddress, value: '0', data}));
    //
    //     return transactions;
    //
    //     // const safeTransaction = await protocolKit.createTransaction({ transactions })
    //     // const txResponse = await protocolKit.executeTransaction(safeTransaction)
    //     // if (txResponse.transactionResponse) {
    //     //     const transactionResponse = txResponse.transactionResponse as TransactionResponse;
    //     //     await transactionResponse.wait(); // Call wait() on transactionResponse
    //     //     console.log(`Role "${roleName}" now has members ${members}`);
    //     // } else {
    //     //     console.error('transactionResponse is undefined.');
    //     // }
    //
    // }


    // const delegateRole = async () => {
    //     if (!provider) {
    //         console.error('provider not initialized!');
    //         return
    //
    //     }
    //
    //     const protocolKit = await Safe.init({provider: provider, safeAddress: walletDetails.address});
    //
    //     const roleName = "AlmanakAgentRole";
    //
    //     const almanakEOAAddress = walletDetails?.external_own_accounts[0].address;
    //
    //     const modules = await protocolKit.getModules();
    //
    //     const delegateRolTrx = await getDelegateRoleTRX(modules[0], walletDetails.chain_id, roleName, [almanakEOAAddress]);
    //
    //     console.log("delegateRolTrx", delegateRolTrx);
    //
    //     const safeTransaction = await protocolKit.createTransaction({transactions: delegateRolTrx})
    //     const txResponse = await protocolKit.executeTransaction(safeTransaction)
    //
    //
    // }
    // const enableAccessForAlmanakAgent = async () => {
    //
    //     if (!provider) {
    //         console.error('provider not initialized!');
    //         return
    //
    //     }
    //
    //     const roleName = "AlmanakAgentRole";
    //
    //     const roleKey = encodeBytes32String(roleName);
    //
    //
    //     const protocolKit = await Safe.init({provider: provider, safeAddress: walletDetails.address});
    //
    //     const rolesModTrx = await getDeployRolesModuleTrx();
    //     const expectedZodiacModuleAddress = rolesModTrx[1].to;
    //
    //     const applyRoleTrx = await getApplyRoleTRX(expectedZodiacModuleAddress, roleName)
    //
    //     const transactions = [...rolesModTrx, ...applyRoleTrx]
    //
    //
    //     // return;
    //
    //     const safeTransaction = await protocolKit.createTransaction({transactions: transactions})
    //     const txResponse = await protocolKit.executeTransaction(safeTransaction)
    //
    //     if (txResponse.transactionResponse) {
    //         const transactionResponse = txResponse.transactionResponse as TransactionResponse;
    //         await transactionResponse.wait(); // Call wait() on transactionResponse
    //         console.log('Zodiac Roles modifier module is deployed and enabled');
    //         console.log('Contract address: ', expectedZodiacModuleAddress);
    //     } else {
    //         console.error('transactionResponse is undefined.');
    //     }
    //
    //     // const safeTransaction = await protocolKit.crea
    //
    //
    // };

    const depositToWallet = async () => {
        if (!walletDetails?.address) {
            return;
        }
        await fundWallet(walletDetails.address, {amount: "0.1"});
    }

    const withdrawFromWallet = () => {
        setWithdrawModalOpen(true);
    }


    const onTabChange = (e: any) => {
        if (e.target.value) {
            setSelectedTab(e.target.value);
        }
    };

    const openSafeWallet = () => {
        if (!walletDetails?.address) {
            return;
        }
        //arb1 || sep || eth
        const safeChain = Number(walletDetails?.chain_id) === 42161 ? "arb1" : Number(walletDetails?.chain_id) === 11155111 ? "sep" : "eth";

        window.open(`https://app.safe.global/home?safe=${safeChain}:${walletDetails?.address}`)
    }

    const refreshWallet = () => {
        fetchRolesModStatus()
        mutateWalletDetails()
        mutateWalletHoldings()
    }

    const copyAddress = async () => {
        if (!walletDetails?.address) {
            return;
        }
        await navigator.clipboard.writeText(walletDetails.address)
        setSnackBar({open: true, message: "Copied to clipboard"})
    }

    const openTransaction = (tx: WalletTransaction): void => {

        switch (Number(tx.chain)) {
            case 1:
                window.open(`https://etherscan.io/tx/${tx.transaction_hash}`)
                break;
            case 42161:
                window.open(`https://explorer.arbitrum.io/tx/${tx.transaction_hash}`)
                break;
            case 11155111:
                window.open(`https://sepolia.explorer.io/tx/${tx.transaction_hash}`)
                break;
        }
    }


    const WalletSetupStepper = () => {
        const steps = [
            'Enable Roles Module',
            'Setup Permissions',
            'Allow Actions',
        ];


        return (
            <Box>
                {/*<Typography variant="h3" gutterBottom>*/}
                {/*    Setup Access for Almanak Agent*/}
                {/*</Typography>*/}

                {/*<Stepper sx={{maxWidth: "600px"}} activeStep={0} alternativeLabel>*/}
                {/*    {steps.map((label, index) => (*/}
                {/*        <Step key={index}>*/}
                {/*            <StepLabel>{label}</StepLabel>*/}
                {/*        </Step>*/}
                {/*    ))}*/}
                {/*</Stepper>*/}


                {/*{!isRolesModEnabled && <Alert severity={"info"}*/}
                {/*                              action={<Button style={{whiteSpace: "nowrap"}}*/}
                {/*                                              onClick={() => enableAccessForAlmanakAgent()}*/}
                {/*                                              variant={"solid"}>Enable*/}
                {/*                                  Access</Button>}>*/}
                {/*    Please enable the Almanak Agent access to this wallet*/}
                {/*</Alert>}*/}

                {/*{!isRoleSetup && isRolesModEnabled && <Box sx={{my: 2}}>*/}

                {/*    <Chip color={"success"} label={"Roles Module is Enabled"}/>*/}
                {/*    <Button variant={"solid"} onClick={delegateRole}>Delegate Access</Button>*/}

                {/*</Box>*/}
                {/*}*/}


                {/*{isRoleSetup && isRolesModEnabled &&*/}
                {/*    <Chip color={"success"} label={"Almanak Agent is fully setup"}/>}*/}

            </Box>
        );
    };


    return (
        <PageContentContainer sx={{py: 2}}>
            <FlexBox sx={{alignItems: "center", justifyContent: "space-between"}}>
                <FlexBox sx={{alignItems: "center", gap: "16px"}}>
                    <Button variant={"outline"} onClick={() => navigate("/wallets")}>
                        <ArrowLeftIcon/>Back</Button>
                    <h2>{walletDetails?.name}</h2>

                    {/*{!isWalletValidating && <WalletStatus wallet={walletDetails}></WalletStatus>}*/}

                    <Box>
                        <IconButton onClick={refreshWallet} size="small">{isWalletValidating || isWalletLoading ?
                            <Spinner/> :
                            <ReloadIcon/>}</IconButton>
                    </Box>

                </FlexBox>

                <FlexBox sx={{gap: 2}}>
                    <Button onClick={withdrawFromWallet} variant={"solid"}>Withdraw</Button>
                    <Button onClick={depositToWallet} variant={"solid"}>Deposit</Button>
                </FlexBox>
            </FlexBox>

            {/* <Divider sx={{mb: 2}}/> */}


            <Box>
                {WalletSetupStepper()}
            </Box>


            {isWalletLoading ? (
                <Flex direction="column" align="center" gap="3" py="8">
                    <Text size="3" weight="medium" color="gray">
                        Loading Wallet Details
                    </Text>
                    <Spinner size="2"/>
                </Flex>) : <>
                {/*Upper side of wallet overview*/}
                <Box>


                    <Box sx={{mb: 2}}>
                        <Flex direction="column" gap="4">
                            {/* Balance Section */}
                            <Flex direction="column" gap="2">
                                <Text size="2" color="gray">Wallet Balance</Text>
                                <Flex align="baseline" gap="2">
                                    <Text size="8" weight="bold">${formatMonetaryValue(totalBalance)}</Text>
                                    <Text
                                        size="2"
                                        style={{
                                            color: isProfit ? "green" : "red",
                                            fontWeight: 600
                                        }}
                                    >
                                        {Number(balanceChange24) !== 0 ? balanceChange24 : ''}
                                    </Text>
                                </Flex>
                            </Flex>

                            {(walletDetails?.status !== "READY" && walletDetails?.status !== "IN_USE") && (
                                <Callout.Root color="blue">
                                    <Callout.Icon>
                                        <ExclamationTriangleIcon/>
                                    </Callout.Icon>
                                    <Callout.Text>
                                        It might take a minute for the wallet status to update after finishing the
                                        setup.
                                    </Callout.Text>
                                </Callout.Root>
                            )}

                            {walletDetails?.status === "READY" && (
                                <Callout.Root color="green">
                                    <Callout.Icon>
                                        <CheckIcon/>
                                    </Callout.Icon>
                                    <Callout.Text>
                                        Wallet is ready for use.
                                    </Callout.Text>
                                </Callout.Root>
                            )}


                            {/* Wallet Details Card */}
                            <Card>
                                <Flex direction="column" gap="4" p="4">
                                    <Flex justify="between" align="center">
                                        <Text size="2" color="gray">Wallet Name</Text>
                                        <Text size="2">{walletDetails?.name || 'Unnamed Wallet'}</Text>
                                    </Flex>

                                    <Flex justify="between" align="center">
                                        <Text size="2" color="gray">Status</Text>
                                        <WalletStatus wallet={walletDetails}/>
                                    </Flex>

                                    <Flex justify="between" align="center">
                                        <Text size="2" color="gray">Chain</Text>
                                        <ChainBadge chainId={walletDetails?.chain_id}/>
                                    </Flex>

                                    <Flex justify="between" align="center">
                                        <Text size="2" color="gray">Address</Text>
                                        <Flex gap="2" align="center">
                                            <Text size="2" style={{fontFamily: 'monospace'}}>
                                                {formatPubKey(walletDetails?.address)}
                                            </Text>
                                            <IconButton onClick={copyAddress} size="small">
                                                <CopyIcon/>
                                            </IconButton>
                                            <IconButton onClick={openSafeWallet} size="small">
                                                <ExternalLinkIcon/>
                                            </IconButton>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Card>
                        </Flex>
                    </Box>


                    <FlexBox sx={{justifyContent: "space-between", width: "100%", gap: 4}}>

                        {/*Wallet holdings side*/}

                        <Box sx={{borderRight: "1px solid #eaeaea"}}/>


                        {/*Transactions side*/}
                        {/*<CenteredColumnFlexBox sx={{alignItems: "center", width: "100%"}}>*/}
                        {/*    <h3 style={{textAlign: "center"}}>Transactions</h3>*/}


                        {/*    <CenteredColumnFlexBox sx={{alignItems: "center", width: "100%", gap: 2}}>*/}

                        {/*        {transactions.map((transaction, index) => (*/}
                        {/*            <FlexBox key={index} sx={{*/}
                        {/*                gap: 2,*/}
                        {/*                width: "100%",*/}
                        {/*                justifyContent: "space-between",*/}
                        {/*                border: "1px solid #eaeaea",*/}
                        {/*                p: "8px 16px",*/}
                        {/*                borderRadius: "8px"*/}
                        {/*            }}>*/}
                        {/*                <FlexBox sx={{gap: 1}}>*/}
                        {/*                    <div>{transaction.type}</div>*/}
                        {/*                    <div>{transaction.amount}</div>*/}
                        {/*                </FlexBox>*/}
                        {/*                <CenteredColumnFlexBox sx={{gap: 1}}>*/}
                        {/*                    <div>{transaction.date}</div>*/}
                        {/*                </CenteredColumnFlexBox>*/}
                        {/*            </FlexBox>*/}
                        {/*        ))}*/}
                        {/*    </CenteredColumnFlexBox>*/}
                        {/*</CenteredColumnFlexBox>*/}

                    </FlexBox>


                    <Tabs.Root defaultValue="holdings" value={selectedTab}
                               onValueChange={(value: string) => setSelectedTab(value as 'holdings' | 'transactions')}>
                        <Tabs.List>
                            <Tabs.Trigger value="holdings">Holdings</Tabs.Trigger>
                            <Tabs.Trigger value="transactions">Transactions</Tabs.Trigger>
                        </Tabs.List>

                        <Box pt="3">
                            <Tabs.Content value="holdings">
                                {isHoldingsLoading ? (
                                    <Text>Loading holdings...</Text>
                                ) : (
                                    <Table.Root>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.ColumnHeaderCell>Asset</Table.ColumnHeaderCell>
                                                <Table.ColumnHeaderCell>Amount</Table.ColumnHeaderCell>
                                                <Table.ColumnHeaderCell>USD Value</Table.ColumnHeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {walletHoldingsRows.length === 0 ? (
                                                <Table.Row>
                                                    <Table.Cell colSpan={3}>No holdings found</Table.Cell>
                                                </Table.Row>
                                            ) : (
                                                walletHoldingsRows.map((holding: WalletHolding) => (
                                                    <Table.Row key={holding.id}
                                                               onClick={() => onHoldingSelect(holding)}>
                                                        <Table.Cell>{holding.asset}</Table.Cell>
                                                        <Table.Cell>{holding.amount}</Table.Cell>
                                                        <Table.Cell>${formatMonetaryValue(holding.usdValue)}</Table.Cell>
                                                    </Table.Row>
                                                ))
                                            )}
                                        </Table.Body>
                                    </Table.Root>
                                )}
                            </Tabs.Content>

                            <Tabs.Content value="transactions">
                                {isWalletLoading ? (
                                    <Text>Loading transactions...</Text>
                                ) : (
                                    <Table.Root>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                                                <Table.ColumnHeaderCell>TX Hash</Table.ColumnHeaderCell>
                                                <Table.ColumnHeaderCell>User Address</Table.ColumnHeaderCell>
                                                <Table.ColumnHeaderCell>Chain</Table.ColumnHeaderCell>
                                                <Table.ColumnHeaderCell>Scam</Table.ColumnHeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {!walletDetails?.transactions || walletDetails.transactions.length === 0 ? (
                                                <Table.Row>
                                                    <Table.Cell colSpan={5}>No transactions found</Table.Cell>
                                                </Table.Row>
                                            ) : (
                                                walletDetails.transactions.map((tx: WalletTransaction) => (
                                                    <Table.Row
                                                        key={tx.transaction_hash}
                                                        onClick={() => openTransaction(tx)}
                                                    >
                                                        <Table.Cell>{tx.tx_name}</Table.Cell>
                                                        <Table.Cell>{tx.transaction_hash}</Table.Cell>
                                                        <Table.Cell>{tx.user_address}</Table.Cell>
                                                        <Table.Cell>{tx.chain}</Table.Cell>
                                                        <Table.Cell>{tx.is_scam ? 'Yes' : 'No'}</Table.Cell>
                                                    </Table.Row>
                                                ))
                                            )}
                                        </Table.Body>
                                    </Table.Root>
                                )}
                            </Tabs.Content>
                        </Box>
                    </Tabs.Root>


                </Box>

            </>}

            {withdrawModalOpen &&
                <Modal
                    sx={{display: "flex"}}
                    open={withdrawModalOpen}
                    onClose={() => setWithdrawModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >


                    <AlmanakModalContent>
                        <Box sx={{
                            p: 3,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "bold"
                        }}>
                            Withdraw from Wallet
                            <IconButton onClick={() => setWithdrawModalOpen(false)}><CloseIcon/></IconButton>
                        </Box>

                        <Alert severity={"info"} sx={{m: 1}}>
                            You will be redirected to app.safe.global to complete the withdrawal process.
                        </Alert>

                        <Box sx={{display: "flex", gap: 1, p: 3, justifyContent: "flex-end"}}>
                            <Button style={{borderRadius: "4px", padding: "4px 12px"}} variant="soft"
                                    onClick={() => setWithdrawModalOpen(false)}>Cancel</Button>
                            <Button style={{borderRadius: "4px", padding: "4px 12px"}} color="green" variant="soft"
                                    onClick={openSafeWallet}>Proceed</Button>
                        </Box>

                    </AlmanakModalContent>
                </Modal>}
        </PageContentContainer>
    )
}
