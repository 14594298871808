import axios from 'axios';

const handleError = (error: any) => {
    if (error.extensions.code === "invalid-jwt") {
        //console.log("Session expired, please log in again!");
        localStorage.removeItem("accessToken");
        window.location.reload();
    }
};

const post = async (url: string, payload: any, noBearer = false): Promise<any> => {
    const config: any = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    if (!noBearer) {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
    }
    const res = await axios.post(url, payload, config);
    if (res.data.errors && res.data.errors.length > 0) {
        handleError(res.data.errors[0]);
    }
    return res;
};

const get = async (url: string, noBearer = false): Promise<any> => {
    const config: any = {
        headers: {
            "Content-Type": "application/json",
        }
    };
    if (!noBearer) {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
    }
    const res = await axios.get(url, config);
    if (res.data.errors && res.data.errors.length > 0) {
        handleError(res.data.errors[0]);
    }
    return res;
};

export {post, get};
