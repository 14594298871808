import {
    Checkbox,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {StrategyParameter} from "../../views/strategy/StrategyLibrary";
import IconButton from "@mui/material/IconButton";
import {EditOutlined, VisibilityOutlined} from "@mui/icons-material";
import * as React from "react";
import Box from "@mui/material/Box";
import {useState} from "react";
import {getGranularityInSeconds} from "../../views/simulation/create-simulation/setup-simulation/utilities";
import {useAppState} from "../../state/AppStateProvider";


const StyledTableContainer = styled(TableContainer)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#EEEEF02F" : "#F9FAFB",
    borderRadius: "12px",
    "& th.MuiTableCell-root": {
        borderColor: "#D6D8DD"
    }
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:last-child td, &:last-child th': {border: 0},
    '& td': {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
}));
export const StrategyParamsTable = ({
                                        params,
                                        onEdit
                                    }: { params?: StrategyParameter[], onEdit: (param: StrategyParameter) => void }) => {


    const {selectedSimulation} = useAppState();
    const [isAllParametersVisible, setIsAllParametersVisible] = useState(false);
    const visibleParams = params?.filter((param) => !param.hidden);

    // const toggleParametersVisibility = (e: any) => {
    //     setIsAllParametersVisible(e.target.checked)
    //     if (e.target.checked) {
    //         setVisibleParams(params);
    //     } else {
    //         const filteredParams = params?.filter((param) => !param.hidden);
    //         setVisibleParams(filteredParams)
    //     }
    // }

    if (!visibleParams || visibleParams.length === 0) {
        return null;
    }
    return <Box>
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
            <span style={{fontSize: "16px", fontWeight: "bold"}}>Adjust Parameters</span>
            {/*<Box>*/}
            {/*    <span style={{fontSize: "13px"}}> Show read only parameters</span>*/}
            {/*    <Checkbox*/}
            {/*        size="small"*/}
            {/*        onChange={toggleParametersVisibility} value={isAllParametersVisible}/>*/}
            {/*</Box>*/}
        </Box>
        <StyledTableContainer sx={{my: 2}}>
            <Table sx={{tableLayout: "fixed"}} aria-label="simple table">
                <TableHead sx={{borderBottom: "1px solid #D6D8DD"}}>
                    <TableRow>
                        <TableCell sx={{width: "25%"}}>Name</TableCell>
                        <TableCell sx={{width: "25%"}}>Value</TableCell>
                        <TableCell sx={{width: "50%"}}>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {visibleParams?.map((param) => {
                        let displayValue = param.value?.toString();

                        if (param.name === "lookback_period") {
                            const granularityInSeconds = getGranularityInSeconds(selectedSimulation?.frontend_state?.granularity, selectedSimulation?.frontend_state?.granularitySize);
                            displayValue = `${Number(param.value) / granularityInSeconds} ${selectedSimulation?.frontend_state?.granularitySize}s`;
                        }
                        return (
                            <StyledTableRow
                                key={param.name}
                            >
                                <Tooltip
                                    arrow={true}
                                    placement="right"
                                    title={param.displayName ?? param.name}>
                                    <TableCell scope="row">{param.displayName ?? param.name}</TableCell>
                                </Tooltip>
                                <Tooltip
                                    arrow={true}
                                    placement="left"
                                    title={displayValue}>
                                    <TableCell
                                        scope="row">
                                        {displayValue}
                                        <IconButton
                                            sx={{marginLeft: "8px"}}
                                            onClick={() => onEdit(param)}>
                                            {param.editable ? <EditOutlined/> : <VisibilityOutlined />}
                                        </IconButton>
                                    </TableCell>
                                </Tooltip>
                                <Tooltip
                                    arrow={true}
                                    placement="left"
                                    title={param.description}>
                                    <TableCell>{param.description}</TableCell>
                                </Tooltip>
                            </StyledTableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </StyledTableContainer>
    </Box>
};
