import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import {CenteredColumnFlexBox} from "../views/simulation/create-simulation/styled";
import {Button, Modal} from "@mui/material";
import * as React from "react";
import {AlmanakModalContent} from "./ScenarioCustomizationModal";
import {AgentCatalog} from "../views/simulation/create-simulation/setup-simulation/agents/AgentsConfigurationContent";
import {formatDateValue, formatMonetaryValue} from "../views/simulation/create-simulation/PoolDetails";

type AgentDetailsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    data: AgentCatalog
};

export const TitleValueColumn = (props: { title: string, value: string | number }) => {
    return <CenteredColumnFlexBox sx={{paddingRight: 1, py: 1, justifyContent: "space-between"}}>
        <span style={{fontSize: "14px", fontWeight: "bold"}}>{props.title}</span>
        <span style={{fontSize: "14px", textTransform: "capitalize"}}>{props.value}</span>
    </CenteredColumnFlexBox>
}
export const AgentDetailsModal = ({isOpen, onClose, data}: AgentDetailsModalProps) => {

    return <Modal
        sx={{display: "flex"}}
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <AlmanakModalContent>
            <Box sx={{
                p: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold"
            }}>
                {data.class}
                <IconButton onClick={onClose}><CloseIcon/></IconButton>
            </Box>
            <Divider/>

            <Box sx={{p: 2}}>
                <TitleValueColumn title={"Description"} value={data.description}/>
                <Box sx={{display: "grid", gap: "12px 40px", gridTemplateColumns: "auto auto auto"}}>
                    <TitleValueColumn title={"Agent Class"} value={data.class}/>
                    <TitleValueColumn title={"Date Created"} value={formatDateValue(data.created_at)}/>
                    <TitleValueColumn title={"Author"} value={data.author}/>
                    <TitleValueColumn title={"Wallet Addresses"} value={"TODO"}/>
                    <TitleValueColumn title={"Net Worth"} value={formatMonetaryValue(data.total_usd_equivalent)}/>
                </Box>
            </Box>

            <Box sx={{p: 3, textAlign: "end"}}>
                <Button onClick={onClose} variant={"contained"}>Close</Button>
            </Box>
        </AlmanakModalContent>
    </Modal>
}
