import React from "react";
import {useParams, useNavigate} from "react-router-dom";
import useSWR from "swr";
import {deployLiveAgent, fetchArtifactFilesWithUrl, getLiveAgentById} from "../../api/hasura";
import {PageContentContainer} from "../styled/styled";
import {Flex, Text, Button, Card, Tabs, Box, Callout, Spinner} from "@radix-ui/themes";
import {ArrowLeftIcon, ExclamationTriangleIcon} from "@radix-ui/react-icons";
import {useAppState} from "../../state/AppStateProvider";
import {AgentStatus} from "../../components/AgentStatus";

interface Agent {
    id: string;
    name: string;
    status: string;
    artifact_id_version_id: string;
    created_at: string;
    config: {
        agent_config: any;
    };
}

export const AgentDetails = () => {

    const {setSnackBar} = useAppState();
    const {id} = useParams();
    const navigate = useNavigate();


    const [isDeploying, setIsDeploying] = React.useState(false);
    const {
        data: agent,
        isLoading,
        mutate: mutateAgent
    } = useSWR<Agent>(
        id ? `agents/${id}` : null,
        () => getLiveAgentById(id as string)
    );

    console.log("agent", agent);


    // const {
    //     data: strategyFiles
    // } = useSWR("strategy-templates", fetchArtifactFilesWithUrl())

    if (isLoading) {
        return (
            <PageContentContainer>
                <Flex direction="column" align="center" gap="3" py="8">
                    <Text size="3" weight="medium" color="gray">
                        Loading Agent Details
                    </Text>
                    <Spinner size="2"/>
                </Flex>
            </PageContentContainer>
        );
    }

    if (!agent) {
        return (
            <PageContentContainer>
                <Text>Agent not found</Text>
            </PageContentContainer>
        );
    }


    const onDeployAgent = async () => {

        try {
            setIsDeploying(true);

            const deployedAgent = await deployLiveAgent(agent.id);

            console.log("deployedAgent", deployedAgent);

            if (deployedAgent.valid) {
                setSnackBar({open: true, message: deployedAgent.message, severity: "success"});
            } else {
                setSnackBar({open: true, message: deployedAgent.message, severity: "error"});
            }
        } catch (e) {
            console.error("Error deploying agent", e);
            setSnackBar({open: true, message: "Error deploying agent", severity: "error"});
        } finally {
            setIsDeploying(false);
            mutateAgent();
        }

    }

    return (
        <PageContentContainer>
            <Flex direction="column" gap="6">
                <Flex justify="between" align="center">
                    <Flex gap={"16px"}>
                        <Button variant={"outline"} onClick={() => navigate("/agents")}>
                            <ArrowLeftIcon/>Back</Button>
                        <Text size="6" weight="bold">Agent Details</Text>
                    </Flex>

                    <>
                    </>
                </Flex>

                <Card>
                    <Flex direction="column" gap="4" p="4">
                        <Flex justify="between" align="center">
                            <Text size="2" color="gray">Agent Name</Text>
                            <Text size="2">{agent.name || 'Unnamed Agent'}</Text>
                        </Flex>

                        <Flex justify="between" align="center">
                            <Text size="2" color="gray">Artifact Version ID</Text>
                            <Text size="2" style={{fontFamily: 'monospace'}}>
                                {agent.artifact_id_version_id}
                            </Text>
                        </Flex>

                        <Flex justify="between" align="center">
                            <Text size="2" color="gray">Created At</Text>
                            <Text size="2">
                                {new Date(agent.created_at).toLocaleString()}
                            </Text>
                        </Flex>

                        <Flex justify="between" align="center">
                            <Text size="2" color="gray">Status</Text>
                            <AgentStatus status={agent.status}/>
                        </Flex>
                    </Flex>
                </Card>


                {agent.status === "PENDING" &&
                    <Flex direction={"row"} align={"center"} style={{gap: "16px"}}>

                        <Callout.Root color="yellow">
                            <Flex align={"center"} gap={"8px"}>
                                <Callout.Icon>
                                    <ExclamationTriangleIcon/>
                                </Callout.Icon>
                                <Callout.Text>
                                    <Flex justify={"between"} align={"center"} gap={"64px"}>
                                        <span>Agent is pending deployment.</span>
                                        <Button disabled={isDeploying} onClick={onDeployAgent}>

                                            Deploy Agent
                                            {isDeploying && <Spinner/>}
                                        </Button>
                                    </Flex>
                                </Callout.Text>
                            </Flex>

                        </Callout.Root>

                    </Flex>
                }

                <Tabs.Root defaultValue="dashboard">
                    <Tabs.List>
                        <Tabs.Trigger value="dashboard">Dashboard</Tabs.Trigger>
                        <Tabs.Trigger value="configuration">Configuration</Tabs.Trigger>
                        <Tabs.Trigger value="wallet">Wallet</Tabs.Trigger>
                    </Tabs.List>

                    <Box pt="4">
                        <Tabs.Content value="dashboard">
                            <Text>Dashboard content coming soon...</Text>
                        </Tabs.Content>

                        <Tabs.Content value="configuration">
                            <Card>
                                <pre style={{
                                    padding: '16px',
                                    overflow: 'auto',
                                    backgroundColor: 'var(--gray-2)',
                                    borderRadius: '6px'
                                }}>
                                    {agent.config?.agent_config
                                        ? JSON.stringify(agent.config.agent_config, null, 2)
                                        : 'No configuration available'}
                                </pre>
                            </Card>
                        </Tabs.Content>

                        <Tabs.Content value="wallet">
                            <Text>Wallet content coming soon...</Text>
                        </Tabs.Content>
                    </Box>
                </Tabs.Root>
            </Flex>
        </PageContentContainer>
    );
};
