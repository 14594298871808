import React from 'react';
import useAuth from "../hooks/useAuth";
import {useAppState} from "../state/AppStateProvider";
import {Box, Button} from "@mui/material";
import {AlmanakLogo} from "../components/AlmanakLogo";
import Chip from "@mui/material/Chip";
import UserDropdown from "../components/wallet-connect/UserDropdown";

type WhitelistManagerProps = {
    children: React.ReactNode;
};

const WhitelistManager = ({children}: WhitelistManagerProps): JSX.Element | null => {
    const {user, isWalletConnecting} = useAppState();


    const isWhitelisted = user && user?.isWhitelisted;

    const openEmail = () => {
        window.open("https://forms.gle/iRegMgxoRSnfMtkp6", "_blank");
    }

    if (user && !isWalletConnecting && !isWhitelisted) {
        return <div className="container">
            <Box sx={{display: "flex", marginTop: "120px", alignItems: "center"}}>
                <AlmanakLogo size={96}/>
                <Chip label="Alpha" color={"success"}/>
            </Box>

            <span style={{fontSize: "24px", marginTop: "-8px"}}>Almanak is currently in Early Access.</span>


            <span style={{textAlign: "center", fontSize: "16px", marginTop: "16px", width: "360px"}}>If you would like to participate in Alpha Testing, please apply below!</span>

            {/*{!user?.publicKey && <UserDropdown/>}*/}

                <Button sx={{my: 2}} variant={"contained"} onClick={openEmail}>Apply for Access</Button>
        </div>
    }

    return <>{children}</>;
}

export default WhitelistManager;
