import Box from "@mui/material/Box";
import {FullScreenContainerSmall} from "../simulation/create-simulation/setup-simulation/SetupSimulation";
import {Button} from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import * as React from "react";
import {ReactNode, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PanelBox} from "../simulation/create-simulation/styled";
import {formatDateValue} from "../simulation/create-simulation/PoolDetails";
import {useAppState} from "../../state/AppStateProvider";
import {RemoveCircle} from "@mui/icons-material";
import {StrategyParameterModal} from "../../modals/StrategyParameterModal";
import {StrategyParamsTable} from "../../components/data-display/StrategyParamsTable";
import {
    DepositAmountChangeProps,
    DepositAmountContent
} from "../simulation/create-simulation/setup-simulation/other/DepositAmountContent";
import {getGranularityInSeconds} from "../simulation/create-simulation/setup-simulation/utilities";
import {LDKeyStrategyLibraryWidget} from "../../components/layout/Sidebar";
import {PageContentContainer} from "../styled/styled";

export type StrategyParameter = {
    name: string,
    type: string,
    description: string,
    value?: string | number | boolean | number[],
    hidden?: boolean,
    editable?: boolean,
    prefillFieldKey?: string,
    displayName?: string
};

export type ModelTemplate = {
    id: string;
    alias: string;
    title: string;
    description: string;
    agentType: string;
    modelType: string;
    dateCreated: Date;
    author: string;
    inputs: Array<StrategyParameter>;
    outputs: Array<StrategyParameter>;
};

export type StrategyTemplate = {
    id: string;
    class: string;
    title: string;
    description: string;
    bestUsedFor: string;
    agentType: string;
    dateCreated: Date;
    author: string;
    strategyParameters: Array<StrategyParameter>;
};

const traderTrendFollowingInputs: StrategyParameter[] = [
    {
        name: "Token From",
        type: "int",
        description: "Code corresponding to the token sold (added) to the pool.",
        value: ""
    },
    {
        name: "Token To",
        type: "int",
        description: "Code corresponding to the token bought (removed) from the pool.",
        value: ""
    },
    {name: "Fee", type: "int", description: "Pool Fee (e.g. 500, 3000)", value: "500"},
    {
        name: "Slope",
        type: "float",
        description: "Slope (e.r trend) of the hourly closing asset-pair price over the last 10 hours",
        value: "trend"
    },
]

const traderTrendFollowingOutputs: StrategyParameter[] = [
    {
        name: "Token From Ratio",
        type: "float",
        description: "Ratio of the Token From amount traded w.r.t. to the balance before swaping (swap amount/balance pre swap).",
        value: ""
    },
]

//TODO: Marcia, strategy parameters below, if you add them here, they will appear in FE table as well
const lpsStrategyParameters = [
    {
        displayName: "Is Dynamic",
        name: "dynamic_strat",
        type: "boolean",
        description: `Whether the strategy is fixed (False) or dynamic (True).`,
        value: true,
        editable: true
    },
    {
        value: true,
        name: "open_position_on_init",
        type: "boolean",
        description: `Whether or not to open a new position on initialization. Needed when a new lp agent is create that has no positions.`,
        editable: false,
        hidden: true
    },
    {
        value: "ETH",
        name: "cex_price_token0",
        type: "string",
        description: `The first token symbol in the name of the price series (which must be in the yaml). E.g., "ETH" if the price simulator price name is ["ETH", "USDT"]`,
        prefillFieldKey: "token0",
        hidden: true
    },
    {
        value: "USDT",
        name: "cex_price_token1",
        type: "string",
        description: `The second token symbol in the name of the price series (which must be in the yaml). E.g., "USDT" if the price simulator price name is ["ETH", "USDT"]`,
        prefillFieldKey: "token1",
        hidden: true
    },
    {
        value: "",
        name: "uniswapv3_pool_token0",
        type: "string",
        description: `The first token symbol of the uniswap v3 pool. Note this token symbol must also be set in the tokens block in the yaml. E.g., "WETH".`,
        hidden: true
    },
    {
        value: "USDT",
        name: "uniswapv3_pool_token1",
        type: "string",
        description: `The second token symbol of the uniswap v3 pool. Note this token symbol must also be set in the tokens block in the yaml. E.g., "USDT".`,
        hidden: true
    },
    {
        value: 500, name: "uniswapv3_pool_fee", type: "number", description: `The fee of the uniswap pool. E.g., 500`,
        prefillFieldKey: "fee", editable: true, hidden: true
    },
    {
        value: 5000000000,
        name: "total_deposit_token1",
        type: "number",
        description: `The total amount that an agent wants to deposit if opening a new position, in units of token1 of the pool.`,
        editable: true, hidden: true
    },
    {
        displayName: "Volatility Scalar",
        value: 2,
        name: "volatility_scalar",
        type: "number",
        description: `How much to multiple the price series volatility by when opening up new positions. Larger numbers will increase the price bounds.`,
        editable: true
    },
    {
        displayName: "Lookback Period",
        value: 3600,
        name: "lookback_period",
        type: "number",
        description: `How far back in time to look when calculating the price series standard deviation. E.g., look back 10 price steps.`,
        editable: true
    },
    {
        value: false,
        name: "always_rebalance",
        type: "boolean",
        description: `Used for debugging to rebalance every step.`,
        hidden: true
    }
]

const tradersParameters: StrategyParameter[] = [
    {
        value: "",
        name: "cex_price_token0",
        type: "string",
        description: `The first token symbol in the name of the price series (which must be in the yaml). E.g., "ETH" if the price simulator price name is ["ETH", "USDT"]`,
        prefillFieldKey: "token0",
        hidden: true
    },
    {
        value: "",
        name: "cex_price_token1",
        type: "string",
        description: `The second token symbol in the name of the price series (which must be in the yaml). E.g., "USDT" if the price simulator price name is ["ETH", "USDT"]`,
        prefillFieldKey: "token1",
        hidden: true
    },
    {
        value: "",
        name: "uniswapv3_pool_token0",
        type: "string",
        description: `The first token symbol of the uniswap v3 pool. Note this token symbol must also be set in the tokens block in the yaml. E.g., "WETH".`,
        hidden: true
    },
    {
        value: "",
        name: "uniswapv3_pool_token1",
        type: "string",
        description: `The second token symbol of the uniswap v3 pool. Note this token symbol must also be set in the tokens block in the yaml. E.g., "USDT".`,
        prefillFieldKey: "token1",
        hidden: true
    },
    {
        value: 500,
        name: "uniswapv3_pool_fee",
        type: "number",
        description: `The fee of the uniswap pool. E.g., 500`,
        prefillFieldKey: "poolFee",
        editable: false,
        hidden: true
    },
    {
        displayName: "Minimum Trade Fraction",
        value: 0.1,
        name: "min_fraction_to_trade",
        type: "number",
        description: `The minimum fraction of the on-chain balance to trade`,
        editable: true
    },
    {
        displayName: "Maximum Trade Fraction",
        value: 0.9,
        name: "max_fraction_to_trade",
        type: "number",
        description: `The maximum fraction of the on-chain balance to trade`,
        editable: true
    },
    {
        value: 0.001,
        name: "min_eth_in_balance",
        type: "number",
        description: `The minimum amount of ETH to keep in the agents balance.`,
        editable: false,
        hidden: true
    },
    {
        value: 5000000000,
        name: "total_deposit_token1",
        type: "number",
        description: `The total amount that an agent wants to deposit if opening a new position, in units of token1 of the pool.`,
        editable: true, hidden: true
    },
];

const tradersWithModelParameters = [
    ...tradersParameters,
    {
        name: "model_1_alias",
        type: "string",
        description: `The model alias assigned to this strategy`,
        value: "trader-trend-following",
        hidden: true
    }
];

const arberParameters = [
    {
        value: "",
        name: "cex_price_token0",
        type: "string",
        description: `The first token symbol in the name of the price series (which must be in the yaml). E.g., "ETH" if the price simulator price name is ["ETH", "USDT"]`,
        prefillFieldKey: "token0",
        hidden: true
    },
    {
        value: "",
        name: "cex_price_token1",
        type: "string",
        description: `The second token symbol in the name of the price series (which must be in the yaml). E.g., "USDT" if the price simulator price name is ["ETH", "USDT"]`,
        prefillFieldKey: "token1",
        hidden: true
    },
    {
        value: "",
        name: "uniswapv3_pool_token0",
        type: "string",
        description: `The first token symbol of the uniswap v3 pool. Note this token symbol must also be set in the tokens block in the yaml. E.g., "WETH".`,
        hidden: true
    },
    {
        value: "",
        name: "uniswapv3_pool_token1",
        type: "string",
        description: `The second token symbol of the uniswap v3 pool. Note this token symbol must also be set in the tokens block in the yaml. E.g., "USDT".`,
        prefillFieldKey: "token1",
        hidden: true
    },
    {
        value: 500,
        name: "uniswapv3_pool_fee",
        type: "string",
        description: `The fee of the uniswap pool. E.g., 500`,
        prefillFieldKey: "fee",
        hidden: true
    },
    {
        value: [0.3, 0.6, 0.9],
        name: "volume_to_check",
        type: "string",
        editable: false,
        hidden: true,
        description: `The fractions of the agents on-chain balance to check whether trading will produce a profitable arb. I.e, if [0.5] then check of 50% of the agents on-chain balance produces a profitable arb.`
    },
    {
        value: 0.9,
        name: "volume_buffer",
        type: "string",
        description: `The upper bound of an agents balance to trade when arbing`,
        editable: false,
        hidden: true
    },
    {
        displayName: "CEX Trade Fee",
        value: 0.001,
        name: "cex_trade_fee",
        type: "string",
        description: `The fee to trade on the CEX. Binance has a 0.1% taker fee.`,
        editable: true
    },
];

const managerParameters = [
    ...lpsStrategyParameters,
    {
        value: true,
        name: "create_vault_on_init",
        type: "boolean",
        description: `Whether or not to create a new vault on initialization. Must be true until existing vaults are supported.`,
        hidden: true
    },
    {
        value: true,
        name: "fund_vault_on_init",
        type: "boolean",
        description: `Whether or not to fund the vault on initialization. Must be true until dynamic funding is supported.`,
        hidden: true
    },
    {
        value: 0,
        displayName: "Vault Manager Fees",
        name: "vault_manager_fees",
        type: "int",
        description: `Manager fees in BPS. E.g., 100 = 1%`,
        editable: true
    },
    {
        displayName: "Token 0 vault ratio",
        value: 0,
        name: "vault_ratio_token0",
        type: "int",
        description: `Initial token0 ratio (for first mint)`,
        editable: true
    },
    {
        displayName: "Token 1 vault ratio",
        value: 0,
        name: "vault_ratio_token1",
        type: "int",
        description: `Initial token1 ratio (for first mint)`,
        editable: true
    },
    {
        displayName: "Vault Fee Tiers",
        value: "[500]",
        name: "vault_fee_tiers",
        type: "List[int]",
        description: `List of fee tiers. E.g., [500, 3000, 10000]`,
        editable: true
    },
]

export const availableStrategyTemplates: StrategyTemplate[] = [
    {
        class: "trader-univ3-trend",
        id: "0",
        title: "Trader UniV3 Trending Strategy",
        description: "This is a simple trend-following trading strategy that seeks to identify and capitalize on trending price movements. The stronger the trend, the more the agent will trade.",
        bestUsedFor: "This strategy is best in markets with strong clear trends and substantial momentum. ",
        agentType: "trader",
        dateCreated: new Date(),
        author: "Ciaran Hughes",
        strategyParameters: tradersParameters
    },
    {
        class: "trader-univ3-trend-model",
        id: "1",
        title: "Trader UniV3 Trending Strategy with Model",
        description: "This is a simple trend-following trading strategy that seeks to identify and capitalize on trending price movements. A machine learning model is used to predict the amount to trade (i.e. % of asset) based on the current price trend.",
        bestUsedFor: "This strategy is best in markets with strong clear trends and substantial momentum.",
        agentType: "trader",
        dateCreated: new Date(),
        author: "Yannick Roy",
        strategyParameters: tradersWithModelParameters,
    },
    {
        class: "lp-univ3-dynamic",
        id: "2",
        title: "LP UniV3 Strategy",
        description: "A dynamic rebalancing strategy automatically updates your position given market conditions or other factors. This is a family of strategies that can use simple rules or sophisticated models to update the position's location and range. In the uniform case, the bounds are always kept the same but its location is updated to center on the new market price. The current update rule rebalances the position when the market price exceeds the bounds of the currently supplied range. The bounds are set based on the historical volatility of the asset and remain static throughout the simulation.",
        bestUsedFor: "This is an active strategy that is useful for keeping your position in range of the current market price when volatility is relatively constant.",
        agentType: "lp",
        dateCreated: new Date(),
        author: "Eric Hart",
        strategyParameters: lpsStrategyParameters
    },
    {
        class: "arber-cex-dex-cyclic",
        id: "4",
        title: "Arber Cex Dex Cyclic Strategy",
        description: "This is a cyclical arbitrage strategy that exploits discrepancies between DEX and CEX prices. In a Multiverse simulation, it’s important to have arbitrage agents to equalize the price of DEX pools with external price feeds (e.g. from a CEX) in order to mimic realistic trading environments.",
        bestUsedFor: "This is a non-directional strategy designed to make small but frequent profits from market inefficiencies.",
        agentType: "arb",
        dateCreated: new Date(),
        author: "Ciaran Hughes",
        strategyParameters: arberParameters
    },
    {
        class: "manager-arrakisv2-dynamic",
        id: "5",
        title: "Arrakis Vault Manager - Dynamic",
        description: "An Arrakis Vault Manager implementing a dynamic rebalancing strategy that automatically rebalance the vault's position(s) given market conditions or other factors. This strategy is a fork of the LP UniV3 Strategy with the functionalities of a vault manager added on top. This is a family of strategies that can use simple rules or sophisticated models to update the position's location and range. In the uniform case, the bounds are always kept the same but its location is updated to center on the new market price. The current update rule rebalances the position when the market price exceeds the bounds of the currently supplied range. The bounds are set based on the historical volatility of the asset and remain static throughout the simulation.",
        bestUsedFor: "This is an active strategy that is useful for rebalancing a new Arrakis V2 Vault, keeping the vault's position in range of the current market price when volatility is relatively constant.",
        agentType: "arrakisManager",
        dateCreated: new Date(),
        author: "Yannick Roy",
        strategyParameters: managerParameters
    }
];

export const availableModelTemplates: ModelTemplate[] = [
    {
        alias: "trader-trend-following",
        id: "0",
        title: "Trader - Trend Following",
        description: "The model is a basic trend following trader. It takes the token pair (from -> to) and the slope (i.e. trend) of the last 10 closing prices of the hourly OHLC of the asset pair (e.g. WETH-USDT) to predict what % (ratio: [0,1]) of the from asset to trade.",
        agentType: "trader",
        modelType: "Catboost - Regression",
        dateCreated: new Date(),
        author: "Yannick Roy",
        inputs: traderTrendFollowingInputs,
        outputs: traderTrendFollowingOutputs,
    }
]


const populateStrategyParameters = (strategy?: StrategyTemplate, settings?: any): StrategyTemplate => {
    if (!strategy) {
        throw new Error("Strategy not found");
    }
    if (!settings) {
        return strategy;
    }
    const updatedParams = strategy.strategyParameters.map(param => {
        return {
            ...param,
            value: settings[param.name]
        }
    });
    return {...strategy, strategyParameters: updatedParams};
};

export const StrategyContainer = ({
                                      strategyClass,
                                      settings,
                                      isAdded,
                                      minimal,
                                      actionButton
                                  }: {
    strategyClass: string,
    settings?: any,
    isAdded?: boolean,
    minimal?: boolean
    actionButton?: ReactNode
}) => {
    const {setSelectedAgent, setSnackBar, selectedSimulation, selectedAgent, featureFlags} = useAppState();
    const navigate = useNavigate();
    const [selectedParameter, setSelectedParameter] = useState<any>(null);

    const initialStrategy = availableStrategyTemplates.find(s => s.class === strategyClass) ?? availableStrategyTemplates[0];
    const populatedStrategy = populateStrategyParameters(initialStrategy, settings);

    const [strategy, setStrategy] = useState(populatedStrategy);
    const tableRows = populatedStrategy?.strategyParameters?.filter((param: any) => !param.hidden);

    const strategyModel = initialStrategy.strategyParameters.find(s => s.name === "model_1_alias");



    const addStrategy = (strategy: StrategyTemplate) => {
        let settings: any = {};
        strategy?.strategyParameters.forEach(parameter => {
            if (parameter.prefillFieldKey && parameter.prefillFieldKey !== "") {
                // @ts-ignore
                const prefilledValue = selectedSimulation?.frontend_state?.[parameter.prefillFieldKey];
                settings[parameter.name] = prefilledValue ?? parameter.value ?? "";
                onSaveParameter({name: parameter.name, value: prefilledValue ?? parameter.value ?? ""});
            } else {
                settings[parameter.name] = parameter.value ?? "";
            }
        });


        setSelectedAgent(prevState => ({
            ...prevState,
            class: strategy.class,
            type: strategy.agentType,
            settings
        }));

        setSnackBar({open: true, message: "Strategy added", severity: "success"});
        navigate(-1);
    };

    const removeStrategy = () => {
        setSelectedAgent(prevState => ({...prevState, class: null, settings: null}));
        setSnackBar({open: true, message: "Strategy removed", severity: "success"});
    };

    const adjustParameter = (param: any) => {
        setSelectedParameter(param);
    };

    const onSaveParameter = (data: StrategyParameter | { name: string, value: any }) => {
        let newSettings: any = selectedAgent?.settings ?? {};

        if (data.name === "lookback_period") {
            if (selectedSimulation?.frontend_state?.granularity && selectedSimulation?.frontend_state?.granularitySize) {
                newSettings[data.name] = data.value * getGranularityInSeconds(selectedSimulation?.frontend_state?.granularity, selectedSimulation?.frontend_state?.granularitySize);
            }
        } else {
            newSettings[data.name] = data.value;
        }

        setSelectedAgent(prevState => ({
            ...prevState,
            class: initialStrategy.class,
            settings: newSettings
        }));
        const updatedStrategy = populateStrategyParameters(initialStrategy, newSettings);
        setStrategy(updatedStrategy);
        setSelectedParameter(null);
    }

    const onDepositAmountChange = (values: DepositAmountChangeProps) => {
        const keys = Object.keys(values);
        keys.forEach(key => {
            // @ts-ignore
            onSaveParameter({name: key, value: values[key]})
        })
    }

    const RenderActionButton = () => {
        if (actionButton) {
            return actionButton;
        }

        return !isAdded ? <Button onClick={() => addStrategy(strategy)} startIcon={<AddCircle/>}
                                  variant="contained">Add</Button> :
            <Button onClick={removeStrategy}
                    startIcon={<RemoveCircle sx={{color: "#f44336"}} color="error"/>}
                    color="error" variant="outlined">Remove</Button>
    }

    return <Box>
        <PanelBox sx={{p: 3}}>
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <span style={{fontSize: "18px", fontWeight: "bold"}}>{strategy.title}</span>
                <Box sx={{display: "flex", gap: 1}}>
                    {RenderActionButton()}
                </Box>
            </Box>
            <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Description</div>
                <span style={{fontSize: "14px"}}>{strategy.description}</span>
            </Box>

            {!minimal && <Box>
                <Box>
                    <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Best Used For</div>
                    <span style={{fontSize: "14px"}}>{strategy.bestUsedFor}</span>
                </Box>
                <Box sx={{display: "flex", gap: 3}}>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Agent Type</div>
                        <span style={{fontSize: "14px"}}>{strategy.agentType}</span>
                    </Box>

                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Date Created</div>
                        <span style={{fontSize: "14px"}}>{formatDateValue(strategy.dateCreated)}</span>
                    </Box>

                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Author</div>
                        <span style={{fontSize: "14px"}}>{strategy.author}</span>
                    </Box>

                    {!!strategyModel &&
                        <Box sx={{display: "flex", flexDirection: "column"}}>
                            <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Model</div>
                            <span style={{fontSize: "14px"}}>{strategyModel.value}</span>
                        </Box>
                    }
                </Box>
            </Box>}


            {isAdded && tableRows &&
                <Box sx={{display: "flex", flexDirection: "column", my: 4, gap: 2}}>
                    <StrategyParamsTable params={strategy?.strategyParameters} onEdit={adjustParameter}/>

                    {strategy?.strategyParameters.find(p => p.name === "uniswapv3_pool_token0") &&
                        <DepositAmountContent onChange={onDepositAmountChange}
                                              parameters={strategy?.strategyParameters}
                        />
                    }
                </Box>
            }
        </PanelBox>

        {!!selectedParameter && <StrategyParameterModal isOpen={!!selectedParameter}
                                                        data={selectedParameter}
                                                        onClose={() => setSelectedParameter(null)}
                                                        onSave={(data: StrategyParameter) => onSaveParameter(data)}
        />}
    </Box>
}
export const StrategyLibrary = () => {
    const navigate = useNavigate();
    return <FullScreenContainerSmall sx={{px: 2}}>
        <Box>
            <Button variant="text"
                    sx={{px: 0}}
                    onClick={() => navigate(-1)}
                    startIcon={<ArrowBackIosNew/>}>Back</Button>
        </Box>
        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <span style={{
            fontSize: "18px",
            fontWeight: "700"
        }}>Browse strategy templates</span>

        </Box>
        <Box sx={{py: 2, display: "flex", flexDirection: "column", gap: 2}}>
            {availableStrategyTemplates.map(strategy => <StrategyContainer strategyClass={strategy.class}
                                                                           key={strategy.id}/>)}
        </Box>
    </FullScreenContainerSmall>
}
