import styled from "@emotion/styled";
import {LDKeySimulatorMaintanance, useAppState} from "../../../../state/AppStateProvider";
import SimulationStepper from "./SimulationStepper";
import * as React from "react";
import Box from "@mui/material/Box";
import {MarketScenariosContent} from "./market-scenarios/MarketScenariosContent";
import {ReviewSummaryContent} from "./summary/ReviewSummaryContent";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {getAvailableTokens, getGroupSimulation} from "../../../../api/hasura";
import {useForm} from "react-hook-form";
import {ESimulationStatus, SimulationMetadata} from "../../../../state/types";
import {PageLoadingView} from "../PageLoadingView";
import {AgentsConfigurationContent} from "./agents/AgentsConfigurationContent";
import {Alert} from "@mui/material";

export const FullScreenContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 32px auto;
  max-width: 1600px;
`

export const FullScreenContainerSmall = styled(FullScreenContainer)`
  max-width: 892px;
`

const ReviewNewSimulation = () => {
    let {id} = useParams();
    const navigate = useNavigate();
    const {
        selectedSimulation,
        setSelectedSimulation,
        setAvailableTokens,
        setSimulationFormController,
        featureFlags
    } = useAppState();

    const isSimulationMaintenanceMode  = featureFlags?.[LDKeySimulatorMaintanance];

    const formController = useForm<SimulationMetadata>({
            defaultValues: selectedSimulation?.frontend_state,
            reValidateMode: "onChange"
        }
    );

    setSimulationFormController(formController);

    const {
        trigger,
        getValues,
        watch,
        reset
    } = formController;


    useEffect(() => {
        const fetchAvailableTokens = async () => {
            const tokens = await getAvailableTokens()
            setAvailableTokens(tokens);
        }
        const fetchSimulation = async (id: string) => {
            const simulation = await getGroupSimulation(id);
            setSelectedSimulation(simulation)
            if (simulation.status === ESimulationStatus.Pending) {
                reset(simulation.frontend_state)
            } else {
                navigate(`/simulation/${simulation.id}/results`, {replace: true});
            }
        }
        if (id) {
            fetchSimulation(id);
            fetchAvailableTokens();
        }
    }, [id])


    //start watching form changes
    useEffect(() => {
        // const triggerUpdate = async (id: string, value: any) => {
        //     ////console.log("value that is sent to API", value);
        //     const updatedSimulation = await updateGroupSimulation(id, value);
        // }
        const subscription = watch((value, {name, type}) => {

                trigger();
                // @ts-ignore
                setSelectedSimulation(prevState => {
                    if (prevState) {
                        return {...prevState, frontend_state: value}
                    } else {
                        return undefined;
                    }
                })

                // selectedSimulation?.id && triggerUpdate(selectedSimulation.id, value);
            }
        )
        return () => subscription.unsubscribe()
    }, [watch])


    const stepDetails = [
        {
            id: "1",
            tabTitle: "Environment",
            title: "Setup Environment & Generate Price Trajectories",
            description: "Simulate LP position under a specific price scenario"
        },
        {
            id: "2",
            tabTitle: "Agents",
            title: "Agents",
            description: "Select from agent classes provided to you relevant to your simulation needs."
        },
        {id: "3", tabTitle: "Summary", title: "Review & Confirm", description: ""},

    ]
    const RenderStep = (activeStep?: number) => {
        if (activeStep === undefined) return null;
        return (
            <Box sx={{my: 2, maxWidth: "1200px", mx: "auto"}}>
                {/*<Box*/}
                {/*    sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>*/}
                {/*    <Box sx={{m: 2}}>*/}
                {/*        <h2 style={{marginBottom: "8px"}}>{stepDetails[activeStep]?.title}</h2>*/}
                {/*        <span>{stepDetails[activeStep]?.description}</span>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
                {selectedSimulation && <form noValidate>
                    {selectedSimulation.frontend_state.activeStep === 0 &&
                    <MarketScenariosContent formController={formController}/>}
                    {selectedSimulation.frontend_state.activeStep === 1 && <AgentsConfigurationContent formController={formController} />}
                    {selectedSimulation.frontend_state.activeStep === 2 && <ReviewSummaryContent formController={formController}/>}
                </form>}

            </Box>

        )
    }
    const isLoading = (!selectedSimulation || selectedSimulation.id !== id);

    return (
        <FullScreenContainer>
            {isLoading && <PageLoadingView fullscreen title={"Loading Simulation..."} subTitle={id}/>}
            {!isLoading && <SimulationStepper formController={formController} steps={stepDetails.map(s => s.tabTitle)}
                                              trigger={trigger}>

                {isSimulationMaintenanceMode &&
                    <Alert severity={"error"} sx={{mt: 2}}>
                        Simulation is currently in maintenance mode. Unexpected errors may occur.
                    </Alert>
                }


                {RenderStep(selectedSimulation?.frontend_state.activeStep)}
            </SimulationStepper>}
        </FullScreenContainer>

    );
}

export default ReviewNewSimulation;
