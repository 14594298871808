import Box from "@mui/material/Box";
import {SimulationResultsValues} from "../../../../state/types";
import {EValueType, LabelValueChangeCard} from "../../LabelValueChangeCard";
import * as React from "react";
import dayjs from "dayjs";
import {useAppState} from "../../../../state/AppStateProvider";

const headerCards = [
    {title: "Expected P/L (%)", key: "expectedPL", type: EValueType.monetary},
    {title: "Max Drawdown", key: "maxDrawdown", type: EValueType.monetary},
    {title: "Max Profits", key: "maxProfits", type: EValueType.monetary},
    {title: "Deposit Amount", key: "depositAmount", type: EValueType.monetary},
    {title: "Simulation Horizon", key: "simulationHorizon", type: EValueType.text}
]


export const SimulationResultsCards = ({results, settings}: { results: SimulationResultsValues, settings?: any }) => {

    let cards = headerCards;
    const {selectedSimulation} = useAppState();

    const diffInS = dayjs(selectedSimulation?.frontend_state?.simulation_end).diff(selectedSimulation?.frontend_state?.simulation_start) / 1000;
    const diffInDays = diffInS / 60 / 60 / 24;
    const simulationHorizon = dayjs.duration(diffInDays, "days").format(`${diffInDays > 365 ? "Y[ years]" : ""} ${diffInDays > 28 ? "M[ months]" : ""} D[ days]`)

    results.simulationHorizon = {PNL: simulationHorizon};
    if (settings?.total_deposit_token1) {
        const decimalPlaces = 6; //TODO: this is assumed to be 6 for now, since we always have token_1 USDT!
        results.depositAmount = {
            PNL: settings?.total_deposit_token1 / (10 ** decimalPlaces)
        }
    } else {
        cards = headerCards.filter(c => c.key !== "depositAmount");
    }
    return <Box sx={{display: "flex", flexDirection: "column", gap: 4}}>
        <Box sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
        }}>
            {results && cards.map(card =>
                <LabelValueChangeCard key={card.title}
                                      title={card.title}
                                      value={results[card.key]?.PNL}
                                      change={results[card.key]?.ROI}
                                      type={card.type}/>)}

        </Box>
    </Box>
}
