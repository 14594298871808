export const formatPriceConfigsToDisplay = (priceConfigs: string[]) => {
    let returnString = '[\n';
    priceConfigs.forEach((priceConfig) => {
        returnString += `  "${priceConfig}",\n`;
    });

    return returnString + ']';
};

export const formatMetadataToDisplay = (values: any) => {
    let returnString = '{\n';

    const metadataObject: {[index: string]: any} = {
        assetPairs: values["assetPair"],
        steps: "",
        step_size: values["granularitySize"],
        title: "",
        simulation_start: "",
        simulation_end: "",
        historical_start: "",
        historical_end: "",
    }
    Object.keys(metadataObject).forEach((key) => {
       returnString += `  "${key}": "${values[key] ?? metadataObject[key]}",\n`;
    });

    returnString += '}';
    return returnString;

}
