import Box from "@mui/material/Box";
import AlmanakChart from "../../../../components/almanak-chart/AlmanakChart";
import {useAppState} from "../../../../state/AppStateProvider";
import {ChartLabel, ChartTitle} from "./TimeSeriesResultsChart";
import {DistributionOfReturns} from "../../../../state/types";
import {PanelBox} from "../../create-simulation/styled";
import {Bar} from "react-chartjs-2";
import React from "react";

export const DistributionOfReturnsView = ({results, type}: { results: DistributionOfReturns[], type: string }) => {
    const {theme} = useAppState();

    const displayType = type === "ROI" ? "Return on Investment (%)" : type === "PNL" ? "Profit and Loss (USDT)" : type === "IL" ? "Impermanent Loss (USDT)" : "Fees (USDT)";

    const isHistogramAvailable = results.length > 0;
    const chartData = {
        labels: results.map((item: any, i) => `${item.binStart.toFixed(2)} - ${item.binEnd?.toFixed(2)}`),
        datasets: [
            {
                label: "0.00 - 5.00",
                fill: true,
                title: type,
                data: [2, 3, 4, 5], //results.map(item => item.count),
                borderColor: theme === "dark" ? "#FFF" : "#000",
                backgroundColor: theme === "dark" ? "#90C4D4" : "#2A5E6E",
                barPercentage: 1.0,
                categoryPercentage: 0.95

            }
        ],
    }

    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            x: {
                label: type,
                grid: {
                    display: false
                },
            },
            y: {
                title: {
                    display: false,
                    text: "Frequency"
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            }
        }
    }
    return <Box>
        <PanelBox sx={{my: 1, p: 3}}>

            <ChartTitle>
                Monte Carlo Simulations
            </ChartTitle>

            <ChartLabel sx={{paddingTop: 3}}>Frequency</ChartLabel>
            <div style={{height: "280px"}}>
                {/*@ts-ignore*/}
                <Bar data={chartData} options={chartOptions}/>
            </div>
        </PanelBox>
    </Box>
}
