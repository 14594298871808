import Box from "@mui/material/Box";
import {CircularProgress, styled} from "@mui/material";
import {formatMonetaryValue} from "./create-simulation/PoolDetails";

type LabelValueChangeCardProps = {
    title: string;
    type: EValueType;
    value?: string | number;
    change?: number;
    isLoading?: boolean;

}
const CardListItem = styled(Box)(({theme}) => ({
    padding: "8px",
    flexGrow: 1,
    border: "1px solid",
    backgroundColor: theme.palette.mode === "dark" ? "#EEEEF02F" : "#FFF",
    borderColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "#D6D8DD",
    borderRadius: "12px",
}));


export enum EValueType {monetary = "monetary", number = "number", text = "text"}


const formatValue = (value?: string | number, type?: EValueType) => {
    if (value === undefined) {
        return "N/A"
    }
    if (type === EValueType.monetary) {
        return formatMonetaryValue(Number(value), {compact: true, currency: "USD"});
    }
    return value;
}

const PercentChange = styled("span")`
  color: #107200;
  font-size: 12px;
  font-weight: 700;
  padding-left: 4px;
`


export const LabelValueChangeCard = ({title, value, change, type, isLoading}: LabelValueChangeCardProps) => {

    return <CardListItem>
        <span style={{fontSize: "14px"}}>{title}</span>
        <div style={{
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
            fontWeight: "700",

        }}>
            {isLoading ? <CircularProgress size={"16px"}/> : <Box>
                <span>{formatValue(value, type)}</span>
                {change && <PercentChange sx={{color: change > 0 ? "#107200" : "#D32F2F"}}>({change}%)</PercentChange>}
            </Box>}
        </div>
    </CardListItem>
}
