export const getGranularityInSeconds = (granularity: number = 1, granularityStep: string = "hour") => {
    if (granularityStep === "minute") {
        return granularity * 60; //convert to seconds
    } else if (granularityStep === "hour") {
        return granularity * 3600; //convert to seconds
    } else if (granularityStep === "day") {
        return granularity * 3600 * 24; //convert to seconds
    } else if (granularityStep === "week") {
        return granularity * 3600 * 24 * 7; //convert to seconds
    }
    return granularity;
}

export const getGranularityInStepSize = (granularityInSeconds: number, granularityStep: string) => {
    if (granularityStep === "day") {
        return granularityInSeconds / 86400; //convert to seconds
    } else if (granularityStep === "minute") {
        return granularityInSeconds / 60; //convert to seconds
    } else if (granularityStep === "hour") {
        return granularityInSeconds / 3600; //convert to seconds
    }
    return granularityInSeconds;
}

export const calculateSimulationHorizon = (granularity: number, granularityStep: string, steps: number) => {
    const convertedToSeconds = getGranularityInSeconds(granularity, granularityStep);
    const seconds = convertedToSeconds * steps;
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor(seconds % (3600 * 24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d === 1 ? " day " : " days ") : "";
    const hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

export const findEarliestDate = (data: any[], key: string) => {
    if (data.length === 0) return null;
    let earliestDate = data[0][key];

    for (let i = 1; i < data.length; i++) {
        if (data[i][key] < earliestDate) {
            earliestDate = data[i][key];
        }
    }

    return earliestDate;
}

export const findLatestDate = (data: any[], key: string) => {
    if (data.length === 0) return null; // Return null if the array is empty
    let latestDate = data[0][key]; // Assume the first one is the latest

    for (let i = 1; i < data.length; i++) {
        if (data[i][key] > latestDate) {
            latestDate = data[i][key]; // Update if a new latest date is found
        }
    }

    return latestDate;
}
