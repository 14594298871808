import * as React from "react";
import {PageContentContainer} from "../views/styled/styled";
import {CenteredRowFlexBox, FlexCol} from "../views/simulation/create-simulation/styled";
import Box from "@mui/material/Box";
import {Button, CircularProgress, MenuItem, Select, Tooltip} from "@mui/material";
import {ArrowBackIosNew} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import CodeMirror from "@uiw/react-codemirror";
import {useEffect, useState} from "react";
import {useAppState} from "../state/AppStateProvider";
import {python} from '@codemirror/lang-python';
import {ArtifactVersionItem} from "../views/strategy/ArtifactVersionItem";
import useSWR from "swr";
import {getArtifactLibraryItems} from "../api/hasura";
import {LibraryArtifact, LibraryArtifactVersion} from "./StrategyLibraryWidget";
import {formatDateValue} from "../views/simulation/create-simulation/PoolDetails";
import Chip from "@mui/material/Chip";

export const StrategyLibraryDetailsWidget = () => {

    const navigate = useNavigate();
    let {artifactId} = useParams();

    const {theme} = useAppState();

    const {
        data: artifactList
    } = useSWR("strategy-templates", getArtifactLibraryItems)


    const artifact = artifactList?.find((s: LibraryArtifact) => s.id === artifactId);

    const [artifactVersion, setArtifactVersion] = useState<LibraryArtifactVersion>(artifact?.latest_version_artifact);
    const [code, setCode] = useState<any>(null);
    const [selectedFile, setSelectedFile] = useState(artifactVersion?.artifact_files?.[0]);

    const {
        data: selectedFileContents,
        isLoading: fileLoading,
    } = useSWR(selectedFile?.authenticated_url, async () => {
        const res = await fetch(selectedFile?.authenticated_url)
        return res.text()
    });



    const onVersionChange = (version: string) => {
        const selectedVersion = artifact?.artifact_versions?.find((s: LibraryArtifact) => s.id === version);
        setArtifactVersion(selectedVersion);

        setSelectedFile(selectedVersion?.artifact_files?.[0])
    }

    return <PageContentContainer>

        <Box>

        </Box>
        <CenteredRowFlexBox sx={{gap: "16px"}}>
            <Button variant="contained"
                    onClick={() => navigate(-1)}
                    startIcon={<ArrowBackIosNew/>}>Back</Button>
            <h2>Artifact details</h2>
        </CenteredRowFlexBox>

        <Box sx={{display: "flex", justifyContent: "space-between"}}>

            <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Name</div>
                <span style={{fontSize: "14px"}}>{artifact?.name}</span>
            </Box>

            <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Creation Date</div>
                <span style={{fontSize: "14px"}}>{formatDateValue(artifact?.date_created)}</span>
            </Box>
        </Box>

        <Box>
            <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Description</div>
            <span style={{fontSize: "14px"}}>{artifact?.description}</span>
        </Box>

        <Box>
            {artifact?.metadata?.tags && <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Tags</div>
                <CenteredRowFlexBox sx={{gap: "8px"}}>
                    {artifactVersion.metadata?.tags.map((tag: string) => <Chip label={tag}/>)}
                </CenteredRowFlexBox>
            </Box>}
        </Box>

        <FlexCol sx={{gap: "16px"}}>
            <Box>
                <Box sx={{my: 2}} style={{fontSize: "14px", fontWeight: "bold"}}>Artifact Versions</Box>
                <Select value={artifactVersion?.id} onChange={e => onVersionChange(e.target.value)} size={"small"}>
                    {artifact?.artifact_versions?.map((version: LibraryArtifactVersion) => <MenuItem
                        value={version.id}>{version.name} - {formatDateValue(version.date_created)}</MenuItem>)}
                </Select>
            </Box>

            <h2>Version Details</h2>

            {artifactVersion &&
                <ArtifactVersionItem artifact={artifact} key={artifact.id} artifactVersion={artifactVersion}
                                     isDetails/>}

            {artifactVersion?.artifact_files?.length > 0 && <>
                <h2>Artifact Files</h2>
                <Box sx={{display: "flex", marginLeft: "-16px"}}>

                    <Box sx={{display: "flex", flexDirection: "column", maxWidth: "180px"}}>

                        {artifactVersion?.artifact_files?.map(file => {
                            const fileName = file?.uri?.split("/")?.pop();
                            return <Tooltip title={fileName}>
                                <MenuItem onClick={() => setSelectedFile(file)} sx={{paddingLeft: "4px"}}
                                          selected={file.id === selectedFile?.id}
                                          value={"python"}>{fileName}</MenuItem>
                            </Tooltip>
                        })}

                    </Box>
                    {fileLoading && <CenteredRowFlexBox sx={{width: "100%", justifyContent: "center", m: 4}}>
                        <Box sx={{display: "flex", alignItems: "center", gap: "16px"}}>
                            <div><CircularProgress/></div>
                            <div>Loading file contents...</div>
                        </Box>
                    </CenteredRowFlexBox>}
                    {!fileLoading && selectedFileContents &&
                        <CodeMirror readOnly={true} maxWidth={"60vw"} value={selectedFileContents} theme={theme} extensions={[python()]}/>}
                </Box>
            </>}

        </FlexCol>
    </PageContentContainer>

}
