import * as React from "react";
import Box from "@mui/material/Box";
import {CenteredRowFlexBox} from "../../styled";
import styled from "@emotion/styled";
import {Button} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import {useNavigate} from "react-router-dom";
import {useAppState} from "../../../../../state/AppStateProvider";
import {UseFormReturn} from "react-hook-form";
import {SimulationMetadata} from "../../../../../state/types";
import {getGroupSimulation, startGroupSimulation} from "../../../../../api/hasura";
import {useState} from "react";
import {SimulationInputParameters} from "./SimulationInputParameters";
import FloatingControls from "../../../../../components/FloatingControls";


const TitleRow = styled(Box)`
  font-size: 14px;
  font-weight: 700;
`


type HeaderComponentProps = {
    title: string;
    onEdit?: () => void;
    noBorder?: boolean;
}
type TitleValueRowProps = {
    title: string;
    value?: string | number;
    bold?: boolean;
}
export const HeaderComponent = (props: HeaderComponentProps) => {
    return <CenteredRowFlexBox
        sx={{
            justifyContent: "space-between",
            borderBottom: props.noBorder ? "none" : "1px solid #D1D5DB",
            paddingBottom: "12px"
        }}>
        <TitleRow>{props.title}</TitleRow>
        {props.onEdit &&
        <Button onClick={props.onEdit} sx={{p: 0}} startIcon={<EditIcon/>} variant="text">Edit</Button>}
    </CenteredRowFlexBox>
}

export const TitleValueRow = (props: TitleValueRowProps) => {
    const {theme} = useAppState();
    return <CenteredRowFlexBox
        sx={{paddingRight: 1, py: 0.5, justifyContent: "space-between", fontWeight: props.bold ? "bold" : "normal"}}>
        <span style={{color: theme === "dark" ? "#FFFFFF99" : "#6B7280", fontSize: "14px"}}>{props.title}</span>
        <span style={{fontSize: "14px", paddingLeft: "4px", overflow: "hidden", textOverflow: "ellipsis"}}>{props.value?.toString() ?? "-"}</span>
    </CenteredRowFlexBox>
}
type ReviewSummaryContentProps = {
    formController: UseFormReturn<SimulationMetadata, any, undefined>
}
export const ReviewSummaryContent = ({formController}: ReviewSummaryContentProps) => {

    const {simulatorPriceTrajectoryHashes, selectedSimulation, setSelectedSimulation, setSnackBar, setReloadCreditBalance} = useAppState();
    const [isStartingSimulation, setIsStartingSimulation] = useState(false);
    const navigate = useNavigate();


    const isStartSimulationLoading = isStartingSimulation || selectedSimulation?.isLoading;
    const {setValue} = formController;

    const fetchSimulation = async (id: string) => {
        try {
            const simulation = await getGroupSimulation(id);
            setSelectedSimulation(simulation.data?.data?.group_simulation_by_pk);
        } catch (e) {
            console.log(e);
        }
    }


    const onStartSimulation = async () => {
        //console.log("onStartSimulation selectedSimulation", selectedSimulation);
        if (selectedSimulation?.id) {
            setIsStartingSimulation(true);
            const simulationRes = await startGroupSimulation(selectedSimulation.id);
            if (simulationRes.data?.errors?.[0].message) {
                setSnackBar({
                    message: `Failed to start simulation: ${simulationRes.data?.errors?.[0].message}`,
                    severity: "error",
                    open: true
                });
                setIsStartingSimulation(false);
                return;
            }
            if (simulationRes.data?.data?.startGroupSimulation?.valid === true) {
                await fetchSimulation(selectedSimulation.id);
                setIsStartingSimulation(false);
                setSnackBar({message: "Simulation started", open: true, severity: "success"})
                navigate(`/simulation/${selectedSimulation.id}/results`, {replace: true});
                setReloadCreditBalance(true);
            } else {
                setSnackBar({
                    message: `Failed to start simulation: ${simulationRes.data?.data?.startGroupSimulation?.message}`,
                    severity: "error",
                    open: true
                });
                setIsStartingSimulation(false);
                setReloadCreditBalance(true);
            }
        }
    }

    return selectedSimulation ? <Box sx={{display: "flex", gap: 4, m: 2}}>
        <SimulationInputParameters setValue={setValue} selectedSimulation={selectedSimulation} isEditable/>

        <FloatingControls primary={{title: "Start Simulation", onClick: onStartSimulation, isLoading: isStartSimulationLoading }} />

    </Box> : <Box>No simulation available</Box>
}
