import {useEffect} from "react";
import useAuth from "../hooks/useAuth";

const AuthorizationManager = () => {

    useEffect(() => {
        // this is required to only run once, when the component is loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}

export default AuthorizationManager;
