import * as React from "react";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';

import {Button, LinearProgress, Paper, TableContainer,} from "@mui/material";
import {AgentDetailsModal} from "../../../../../modals/AgentDetailsModal";
import {MarketScenarioContentProps} from "../market-scenarios/MarketScenariosContent";
import {generateWallets, getAgentsLibrary, updateGroupSimulation} from "../../../../../api/hasura";
import {useAppState} from "../../../../../state/AppStateProvider";
import {AddCircle, Check, ContentCopy, DeleteOutline, EditOutlined} from "@mui/icons-material";
import {PanelBox, ThemedGreyBox} from "../../styled";
import {useNavigate} from "react-router-dom";
import {CloneAgentsModal} from "../../../../../modals/CloneAgentsModal";
import {AgentConfigurationDoughnutChart} from "./AgentConfigurationDoughnutChart";


interface HoldingsItem {
    mint: boolean;
    token: string;
    amount: number;
}

interface AgentConfigItem {
    address: string;
    holdings: HoldingsItem[];
}

interface AgentsConfig {
    default: AgentConfigItem[];
}

export interface AgentCatalog {
    author: string;
    available: boolean;
    agents_config: AgentsConfig;
    class: string;
    created_at: string;
    total_usd_equivalent: number;
    description: string;
    id: string;
    version: string;
    address: string;
    holdings: HoldingsItem[];
}

type AgentConfig = {
    id: string;
    address: string;
    holdings: Array<{ mint: boolean, token: string, amount: number }>
};
// export type AgentCatalog = {
//     id: string,
//     author: string, //probably optional
//     agents_config: { default: AgentConfig[] }
//     class: string,
//     description: string,
//     created_at: Date,
//     available: boolean,
//     total_usd_equivalent: number
// }


const extractAgentConfigs = (agentCatalogs: AgentCatalog[]) => {
    let agentConfigs: AgentConfig[] = [];
    agentCatalogs.forEach(agent => {
        const {agents_config, ...rest} = agent;
        agents_config?.default.forEach(config => {
            agentConfigs.push({...rest, ...config})
        })
    });
    return agentConfigs;
};

//TODO: move to utils
export const groupByCategory = (category: string, items?: any[]) => {
    if (!items) return [];
    const returnItems: any = [];

    items.reduce((group: any, item: any) => {
        const agentClass = item[category];
        if (!returnItems[agentClass]) {
            returnItems[agentClass] = [];
        }
        returnItems[agentClass].push(item);
        return group;
    }, {});

    return returnItems;
}

export const AgentsConfigurationContent = ({formController}: MarketScenarioContentProps) => {

    const {setSnackBar, selectedSimulation, setSelectedAgent} = useAppState();
    const frontendState = selectedSimulation?.frontend_state;
    const {getValues, setValue} = formController;
    const {selectedAgentConfigs} = getValues();

    const [isBulkEditEnabled, setIsBulkEditEnabled] = useState(false);

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
    const [isCloning, setIsCloning] = useState(false);

    const [displayedAgentDetails, setDisplayedAgentDetails] = useState<AgentCatalog | null>(null);
    const [availableAgentCatalogs, setAvailableAgentCatalogs] = useState<any[] | undefined>(frontendState?.agentConfigs);
    const [agentConfigs, setAgentConfigs] = useState<any[] | null>(frontendState?.agentConfigs ?? null);

    const [rowSelectionModel, setRowSelectionModel] =
        useState<GridRowSelectionModel>(frontendState?.selectedAgentConfigs ? frontendState?.selectedAgentConfigs.map(ac => ac?.id) : []);


    useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            try {
                const agentsLibrary = await getAgentsLibrary()
                if (agentsLibrary.data?.errors?.[0]) {
                    throw new Error(agentsLibrary.data.errors[0].message);
                }

                if (agentsLibrary?.data?.data?.agent_catalog?.[0]) {
                    const agentCatalogs = agentsLibrary.data.data.agent_catalog;
                    const agentConfigs = extractAgentConfigs(agentCatalogs);
                    if (frontendState?.agentConfigs?.length === 0) {
                        setAgentConfigs(agentConfigs);
                        setAvailableAgentCatalogs(agentConfigs);
                        setValue("selectedAgentConfigs", [agentConfigs?.[0]])
                        setRowSelectionModel([agentConfigs?.[0].id]);
                    }
                }

            } catch (err: any) {
                setSnackBar({open: true, severity: "error", message: `Failed to fetch: ${err.message}`})
            } finally {
                setIsLoading(false);
            }
        }
        if (!frontendState?.agentConfigs || frontendState?.agentConfigs.length === 0) {
            // init();
        }

    }, [])

    const openAgentSettings = (event: any, item: any) => {
        event.stopPropagation();
        const frontendState = {
            ...selectedSimulation?.frontend_state,
            activeStep: 1
        }
        updateGroupSimulation(selectedSimulation?.id!, {frontend_state: frontendState});
        setSelectedAgent(item.row);
        navigate(`/simulation/${selectedSimulation?.id}/agent/${item.row.id}`);
    }

    const createAgent = () => {
        setValue("activeStep", 1);
        setSelectedAgent(null);
        const frontendState = {
            ...selectedSimulation?.frontend_state,
            activeStep: 1
        }
        updateGroupSimulation(selectedSimulation?.id!, {frontend_state: frontendState});
        navigate("agent/new");
    }


    const onSelectedAgentsChange = (selections: GridRowSelectionModel) => {
        const selectedAgents = selections.map(s => agentConfigs?.find(ac => ac.id === s));
        setRowSelectionModel(selections);
        setValue("selectedAgentConfigs", selectedAgents)
    }

    const deleteSelectedAgents = () => {
        const updatedAgentConfigs = agentConfigs?.filter(ac => !rowSelectionModel.includes(ac.id));
        setAgentConfigs(updatedAgentConfigs ?? []);
        setValue("agentConfigs", updatedAgentConfigs ?? []);
        setAvailableAgentCatalogs(updatedAgentConfigs);
    };

    const cloneSelectedAgents = () => {
        setIsCloneModalOpen(true);
    }

    const generateAgents = async (quantity: number) => {
        const agentsToClone = agentConfigs?.filter(ac => rowSelectionModel.includes(ac.id));
        const clonedAgents: any = [];

        const numberOfWallets = quantity * (agentsToClone?.length ?? 0);

        const newWalletsRes = await generateWallets(numberOfWallets)
        const walletAddresses = newWalletsRes.data.data.generateManyWalletAddress.wallet_address_array
        agentsToClone?.forEach((agent, agentIndex) => {
            for (let i = 0; i < quantity; i++) {
                const agentPublicKey = walletAddresses[agentIndex * quantity + i];

                const randomAgentDigits = Math.random().toString(36).substring(9);
                const clonedAgent = {
                    ...agent,
                    id: `${agent.id}-${randomAgentDigits}`,
                    alias: agent.alias ? `${agent.alias}-${randomAgentDigits}` : undefined,
                    address: agentPublicKey,
                }
                clonedAgents.push(clonedAgent);
            }
        });
        return clonedAgents;
    }
    const onCloneAgents = async (quantity: number) => {

        setIsCloneModalOpen(false);
        setIsCloning(true);

        try {
            const generatedAgents = await generateAgents(quantity);
            const newAgentConfigs = [...agentConfigs ?? [], ...generatedAgents];
            setAgentConfigs(newAgentConfigs)
            setAvailableAgentCatalogs(newAgentConfigs);
            setValue("agentConfigs", newAgentConfigs);
            setSnackBar({open: true, severity: "success", message: `Created ${generatedAgents.length} agents (clones)`})
        } catch (e: any) {
            setSnackBar({open: true, severity: "error", message: `Failed to clone agents: ${e.message}`})
        } finally {
            setIsCloning(false);
        }
    }
    const columns: GridColDef[] = [
        {field: 'id', headerName: 'Agent ID', flex: 2},
        {
            field: 'alias',
            headerName: 'Alias',
            flex: 1,
            renderCell: (item) => <span>{item.row.alias ?? "-"}</span>
        },
        {field: 'type', headerName: 'Agent Type', flex: 1, minWidth: 80},
        {field: 'class', headerName: 'Strategy', flex: 1, minWidth: 160},
        {
            field: "action",
            headerName: "",
            width: 100,
            renderCell: (item) => <Button variant="text" onClick={(e) => openAgentSettings(e, item)}> edit</Button>
        }
    ];


    if (!availableAgentCatalogs?.length || availableAgentCatalogs?.length === 0) {
        return <PanelBox sx={{p: 2, m: 4}}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 4,
                gap: 2
            }}>
                <span style={{fontSize: "18px", fontWeight: "bold"}}>No agents created yet! </span>
                <Box>
                    <Button onClick={createAgent} startIcon={<AddCircle/>} sx={{marginLeft: "auto"}}
                            variant={"contained"}> Create an agent</Button>
                </Box>
            </Box>
        </PanelBox>
    }

    return <Box sx={{m: 2}}>
        <PanelBox sx={{p: 2, my: 2}}>
            <span style={{fontSize: "16px", fontWeight: "bold", paddingBottom: "16px"}}>Summary</span>
            <AgentConfigurationDoughnutChart availableConfigs={availableAgentCatalogs}/>
        </PanelBox>

        <Box sx={{
            fontSize: "16px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 3
        }}>
            <span>Agents</span>
            <Button onClick={createAgent} startIcon={<AddCircle/>} sx={{marginLeft: "auto"}} variant={"contained"}>
                Create Agent
            </Button>
        </Box>

        <TableContainer component={Paper}>
            <ThemedGreyBox
                sx={{
                    p: 2,
                    width: "100%",
                    height: "64px",
                    gap: 2,
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                <Box>
                    {isBulkEditEnabled && <span>{rowSelectionModel.length} agent{rowSelectionModel.length === 0 || rowSelectionModel.length > 1 ? "s" : ""} selected to edit</span>}
                </Box>
                {isBulkEditEnabled ? <Box sx={{display: "flex", gap: 1}}>
                    <Button disabled={rowSelectionModel.length === 0 || isCloning}
                            onClick={cloneSelectedAgents}
                            startIcon={<ContentCopy/>}>Clone</Button>
                    <Button disabled={rowSelectionModel.length === 0 || isCloning} color={"error"}
                            onClick={deleteSelectedAgents}
                            startIcon={<DeleteOutline/>}>Delete</Button>
                    <Button disabled={isCloning} color={"success"}
                            onClick={() => setIsBulkEditEnabled(false)}
                            startIcon={<Check/>}
                            >Done</Button>
                </Box> : <Button
                                 onClick={() => setIsBulkEditEnabled(true)}
                                 startIcon={<EditOutlined />}>Bulk edit Agents</Button>}
            </ThemedGreyBox>
            {agentConfigs && <DataGrid
                loading={isCloning}
                onRowSelectionModelChange={onSelectedAgentsChange}
                rowSelectionModel={isBulkEditEnabled ? rowSelectionModel : undefined}
                rows={agentConfigs}
                getRowId={(row) => row.id}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {page: 0, pageSize: 10},
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection={isBulkEditEnabled}
            />}
            {isLoading && <LinearProgress sx={{width: "100%"}}/>}
        </TableContainer>
        {!!displayedAgentDetails && <AgentDetailsModal
            isOpen={!!displayedAgentDetails}
            onClose={() => setDisplayedAgentDetails(null)}
            data={displayedAgentDetails}
        />}
        {isCloneModalOpen && <CloneAgentsModal onSave={onCloneAgents} isOpen={isCloneModalOpen}
                                               onClose={() => setIsCloneModalOpen(false)}/>}
    </Box>
}
