import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import {CenteredColumnFlexBox} from "../views/simulation/create-simulation/styled";
import {Button, Modal, TextField} from "@mui/material";
import * as React from "react";
import {AlmanakModalContent} from "./ScenarioCustomizationModal";

type SimulationTitleModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSave: (title: string) => void;
    title: string
};

export const TitleValueColumn = (props: { title: string, value: string | number}) => {
    return <CenteredColumnFlexBox sx={{paddingRight: 1, py: 1, justifyContent: "space-between"}}>
        <span style={{fontSize: "14px", fontWeight: "bold"}}>{props.title}</span>
        <span style={{fontSize: "14px"}}>{props.value}</span>
    </CenteredColumnFlexBox>
}
export const SimulationTitleModal = ({isOpen, onClose, title, onSave}: SimulationTitleModalProps) => {

    const [simulationTitle, setSimulationTitle] = React.useState(title)
    const onChange = (event: any) => {
        setSimulationTitle(event.target.value)
    }
    return <Modal
        sx={{display: "flex"}}
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <AlmanakModalContent>
            <Box sx={{
                p: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold"
            }}>
                Rename Simulation
                <IconButton onClick={onClose}><CloseIcon/></IconButton>
            </Box>
            <Divider/>

            <Box sx={{p: 2}}>
                <TextField onChange={onChange} sx={{my: 2}} fullWidth label="Simulation title" placeholder="Simulation title" value={simulationTitle}/>
            </Box>

            <Box sx={{display: "flex", gap: 1, p: 3, justifyContent: "flex-end"}}>
                <Button onClick={onClose} variant={"outlined"}>Close</Button>
                <Button onClick={() => onSave(simulationTitle)} variant={"contained"}>Save</Button>
            </Box>
        </AlmanakModalContent>
    </Modal>
}
