import { Outlet } from "react-router-dom";
import Onboarding from "../views/onboarding/Onboarding";
import {useAppState} from "../state/AppStateProvider";

const ProtectedRoutes = () => {
    const {user} = useAppState();
    const isAuthenticated = !!localStorage.getItem("accessToken") && !!user?.publicKey;
    return isAuthenticated ? <Outlet /> : <Onboarding />
}

export default ProtectedRoutes;
