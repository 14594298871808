import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import {Alert, Button, Modal, TextField} from "@mui/material";
import * as React from "react";
import {AlmanakModalContent} from "./ScenarioCustomizationModal";
import {useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";

type WalletModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSave: (quantity: number) => void;
};
export const CloneAgentsModal = ({isOpen, onClose, onSave}: WalletModalProps) => {

    const [quantity, setQuantity] = useState(1);


    return <Modal
        sx={{display: "flex"}}
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <AlmanakModalContent>
            <Box sx={{
                p: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold"
            }}>
                Clone Agents
                <IconButton onClick={onClose}><CloseIcon/></IconButton>
            </Box>
            <Divider/>

            <Box sx={{p: 2}}>
                <div style={{fontSize: "14px", fontWeight: "bold", paddingTop: "16px"}}>Quantity</div>
                <TextField
                    type="number"
                    InputProps={{inputProps: {min: 1}}}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuantity(Number(event.target.value))}
                    fullWidth size="small" value={quantity}/>

                <Alert severity="info" sx={{my: 2}}>
                    A new ID and wallet address will be assigned to the duplicated agents
                </Alert>
            </Box>


            <Box sx={{p: 3, display: "flex", justifyContent: "flex-end", gap: 1}}>
                <Button onClick={onClose} variant={"outlined"}>Close</Button>
                <LoadingButton onClick={() => onSave(quantity)}
                               variant="contained">Save</LoadingButton>
            </Box>
        </AlmanakModalContent>
    </Modal>
}
