import Box from "@mui/material/Box";
import {PanelBox} from "../simulation/create-simulation/styled";
import * as React from "react";
import {
    availableModelTemplates,
} from "./StrategyLibrary";
import {formatDateValue} from "../simulation/create-simulation/PoolDetails";

export const ModelContainer = ({modelAlias}: { modelAlias: string }) => {

    const model = availableModelTemplates.find(s => s.alias === modelAlias);

    if (!model) {
        return null;
    }
    const inputsTableRows = model?.inputs.filter((param: any) => !param.hidden);
    const outputsTableRows = model?.outputs.filter((param: any) => !param.hidden);

    return <Box sx={{}}>
        <PanelBox sx={{p: 3}}>
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <span style={{fontSize: "18px", fontWeight: "bold"}}>{model.title}</span>
            </Box>
            <Box sx={{display: "flex", gap: 3}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Model Type</div>
                    <span style={{fontSize: "14px"}}>{model.modelType}</span>
                </Box>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Agent Type</div>
                    <span style={{fontSize: "14px"}}>{model.agentType}</span>
                </Box>

                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Date Created</div>
                    <span style={{fontSize: "14px"}}>{formatDateValue(model.dateCreated)}</span>
                </Box>

                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Author</div>
                    <span style={{fontSize: "14px"}}>{model.author}</span>
                </Box>
            </Box>
            <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Description</div>
                <span style={{fontSize: "14px"}}>{model.description}</span>
            </Box>

            <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Inputs</div>
                <span style={{fontSize: "14px"}}>{inputsTableRows.map((param, i) =>
                    <span>{i > 0 ? ", " : ""}{param.name} </span>)}</span>
            </Box>
            <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Outputs</div>
                <span style={{fontSize: "14px"}}>{outputsTableRows.map((param, i) =>
                    <span>{i > 0 ? ", " : ""}{param.name}</span>)}</span>
            </Box>

        </PanelBox>
    </Box>
}
