import {PanelBox} from "../../styled";
import Box from "@mui/material/Box";
import * as React from "react";
import {HeaderComponent, TitleValueRow} from "./ReviewSummaryContent";
import {SxProps, Theme} from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import {WalletHolding} from "../../../../../modals/AddWalletModal";
import {Navigate, useParams} from "react-router-dom";
import {getDisplayAmount} from "../../../../../utils/moneyUtils";
import {useAppState} from "../../../../../state/AppStateProvider";
import {formatMonetaryValue} from "../../PoolDetails";

dayjs.extend(relativeTime)
dayjs.extend(duration)

type SimulationInputParametersProps = {
    selectedAgent: any;
    sx?: SxProps<Theme>;
};
export const AgentInputParameters = ({
                                         selectedAgent,
                                         sx,
                                     }: SimulationInputParametersProps) => {
    const {availableTokens} = useAppState();
    const params = useParams();
    const agentSettingsKeys = Object.keys(selectedAgent?.settings ?? {});

    if (!selectedAgent.id) {
        return <Navigate to={`/simulation/${params.id}/results`} replace/>
    }

    return <Box sx={{flexGrow: 1, ...sx}}>

        <PanelBox sx={{p: 2, marginBottom: 2, height: "auto"}}>
            <HeaderComponent noBorder title="About"/>
            <TitleValueRow title={"Type"} value={selectedAgent?.type}/>
            <TitleValueRow title={"Alias"} value={selectedAgent?.alias}/>
        </PanelBox>

        <PanelBox sx={{p: 2, marginBottom: 2, height: "auto"}}>
            <HeaderComponent noBorder title="Strategy"/>
            <TitleValueRow title={selectedAgent.class} value="" bold/>
            <TitleValueRow title={"Alias"} value={selectedAgent?.alias}/>
            {agentSettingsKeys?.map(key => <TitleValueRow key={key} title={key} value={selectedAgent?.settings[key]}/>)}
        </PanelBox>

        <PanelBox sx={{p: 2, marginBottom: 2, height: "auto"}}>
            <HeaderComponent noBorder title="Wallet Balance"/>
            {selectedAgent?.holdings?.map((holding: WalletHolding) => {
                const token = availableTokens?.find(t => t.token === holding.token);
                const displayAmount = holding.displayAmount ? holding.displayAmount : token?.decimalPlaces ? getDisplayAmount(holding.amount, token.decimalPlaces) : ""
                return <TitleValueRow key={holding.token} title={holding.token} value={`${formatMonetaryValue(displayAmount)} ${holding.token}`}/>
            })}
        </PanelBox>

    </Box>
}
