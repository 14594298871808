import axios from 'axios';

const uniswapApiUrl = "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3";


const fetchPoolForDetailsByAddress = async (address: string) => {

    var query = `{
  pool(id: "${address}") {
    id
    feesUSD
    volumeUSD
    totalValueLockedUSD
    liquidityProviderCount
    feeTier
    token0 {
      symbol
    }
    token1 {
      symbol
    }
  }
}`

    return axios.post(uniswapApiUrl, {query});

}

const fetchPoolForListByAddress = async (address: string) => {

    var query = `{
  pool(id: "${address}") {
    id
    feeTier
    token0 {
      id
      name
      symbol
    }
    token1 {
      id
      name
      symbol
    }
  }
}`

    return axios.post(uniswapApiUrl, {query});

}

const fetchTopPools = async () => {

    var query = `{
    pools(
    where: {volumeUSD_gt: "0"},
    first: 100,
    orderBy: totalValueLockedUSD,
    orderDirection: desc,
  ) {
    id
    feeTier
    totalValueLockedUSD
    volumeUSD
    token0 {
      id
      name
      symbol
    }
    token1 {
      id
      name
      symbol
    }
  }
}`
    return axios.post(uniswapApiUrl, {query});

}

// 0x2260fac5e5542a773aa44fbcfedf7c193bc2c599 WBTC address

const fetchTokensBySymbol = async (symbolInput: string) => {


    //TODO: a quick hack to check if symbol input is an address, should use web3 isAddress function
    const isInputAnAddress = symbolInput.startsWith("0x");

    const whereStatement = isInputAnAddress ? `{ id: "${symbolInput}"}` : `{ symbol_contains_nocase: "${symbolInput}"}`;
    var query = `{
        tokens(
            first: 20,
            orderBy: volumeUSD,
            orderDirection: desc,
            where: ${whereStatement}
        ) {
            id,
                symbol,
                name,
                totalSupply,
                volumeUSD,
                totalValueLockedUSD,
                txCount,
        }
    }`

    return axios.post(uniswapApiUrl, {query});

}

//using 560, which is 1 year for max chart + 200 days for maximum long moving average
const defaultTokenListLengthToFetch = 560;

const fetchTokenDayDatasById = async (id: string) => {
    var query = `
        {
            tokenDayDatas(
                first: ${defaultTokenListLengthToFetch}
                where: {
                    token: "${id}",
                    priceUSD_gt: 0
                },
                orderBy: date,
                orderDirection: desc) {
                date,
                volume,
                open,
                close,
                untrackedVolumeUSD,
                priceUSD,
            }
        }`
    return axios.post(uniswapApiUrl, {query});
}

export {fetchPoolForDetailsByAddress, fetchPoolForListByAddress, fetchTopPools, fetchTokensBySymbol, fetchTokenDayDatasById}
