import {Navigate} from 'react-router-dom';
import Box from "@mui/material/Box";
import AlmanakChart from "../../components/almanak-chart/AlmanakChart";
import {CenteredRowFlexBox, FlexCol, PanelBox, WhitePanelBox} from "../simulation/create-simulation/styled";
import {Button, CircularProgress, TextareaAutosize} from "@mui/material";
import {
    defaultPriceScenarioChartOptions,
    EMarketScenarioColors
} from "../simulation/create-simulation/setup-simulation/constants";
import * as React from "react";
import {useState} from "react";
import {generatePriceSimulatorTrajectoriesDirectly} from "../../api/priceSimulator";
import {convertDataForMultiplePairs, updateGroupSimulation} from "../../api/hasura";
import {useAppState} from "../../state/AppStateProvider";


const initialPayload = `
{
        "assets": [
            {
                "symbol": [
                    "WBTC",
                    "ETH"
                ],
                "config": {}
            },
            {
                "symbol": [
                    "WBTC",
                    "USDT"
                ],
                "config": {}
            }
        ],
        "start_block": 18987280,
        "end_block": 18997280,
        "granularity": 3600,
        "config": {
            "market_drift": 1,
            "volatility_scale": 0.5,
            "enable_black_swan_event": false,
            "max_percentage_change_historical_std_multiplier": 0.6
        },
        "steps": 480,
        "volatility_mode": "cccgarch",
        "trajectories": 5,
        "maturity": 1,
        "random_seed_price": [42, 420, 69, 1337, 143],
        "random_seed_sigma": [15, 21, 1984, 34, 66],
        "random_seed_black_swan": [1, 24, 39, 74, 182]
}
`
export const PriceSimulatorDirect = () => {

    const LDKeyPriceSimDirect = "frontend_price-sim-direct"
    const {setSnackBar, theme, featureFlags} = useAppState();
    const isPriceSimDirectEnabled = featureFlags?.[LDKeyPriceSimDirect];
    const [chartData, setChartData] = useState<any>(null)
    const [multipleChartsData, setMultipleChartsData] = useState<any>(null)
    const [isGenerating, setIsGenerating] = useState<any>(null)
    const [chartOptions, setChartOptions] = useState<any>(null)
    const [payload, setPayload] = useState<any>(initialPayload)


    const populateChartWithData = (dataList: any[]) => {
        const simulatedData: any = [];
        let chartLabels: any = [];
        let verticalLineValue = 0;
        const colors = ["red", "blue", "cyan", "pink"]; // predefined colors
        const colorMap: { [key: string]: string } = {}; // map to store colors for pair strings

        dataList.forEach((dataItem: any) => {
            Object.entries(dataItem).forEach(([pairString, data], index) => {
                // Ensure each pair string gets the same color every time
                if (!colorMap[pairString]) {
                    colorMap[pairString] = colors[Object.keys(colorMap).length % colors.length];
                }
                // @ts-ignore
                const historical = data.prices.historical;
                if (historical.length > 20) {
                    // historical.splice(0, historical.length - 20)
                }
                verticalLineValue = Math.max(verticalLineValue, historical.length);
                // @ts-ignore
                const simulated = data.prices.simulated;
                simulatedData.push({
                    data: [...historical, ...simulated].map((d: any, i: number) => ({y: d, x: i})),
                    color: colorMap[pairString], // Get the color from colorMap
                    pairString // Store the pair string for reference
                });
                chartLabels = [...historical, ...simulated].map((d, i) => `start_block + ${i}`);
            });
        });

        const priceScenarioData = {
            labels: chartLabels,
            datasets: simulatedData.map((item: any) => ({
                title: `Pair: ${item.pairString}`, // Use the stored pair string
                data: item.data,
                fill: false,
                borderColor: item.color,
                tension: 0.4,
                cubicInterpolationMode: 'monotone',
            })),
        };

        const multipleChartsData =  Object.values(priceScenarioData.datasets.reduce((acc: any, item: any) => {
            // Use the title as the key for grouping
            acc[item.title] = acc[item.title] || { title: item.title, datasets: [] };
            // Push the current item to the 'datasets' array of the grouped object
            acc[item.title].datasets.push(item);
            acc[item.title].labels = chartLabels
            return acc;
        }, {}));

        setMultipleChartsData(multipleChartsData)
        setChartData(priceScenarioData);

        const options = {
            ...defaultPriceScenarioChartOptions,
            plugins: {
                ...defaultPriceScenarioChartOptions.plugins,
                annotation: {
                    annotations: [
                        {
                            type: 'line',
                            borderColor: EMarketScenarioColors.rangebound,
                            borderWidth: 1,
                            display: (ctx: any) => ctx.chart.isDatasetVisible(1),
                            label: {
                                display: false,
                            },
                            scaleID: 'x',
                            value: verticalLineValue
                        }
                    ]
                }
            }
        };

        setChartOptions(options);
        setIsGenerating(false);
    };

    function prepareDataFromPriceSimDirect(input: any) {
        const remappedData = input.trajectories.map((trajectory: any) => {
            // Initialize the structure of the remapped object
            const remappedObject = {
                prices: {},
                volatility: {},
                volume: {}
            };

            // Iterate over each property in the trajectory
            for (const [key, value] of Object.entries(trajectory)) {
                // For prices, volatility, and volume
                if (remappedObject.hasOwnProperty(key)) {
                    // @ts-ignore
                    remappedObject[key] = {
                        // @ts-ignore
                        simulated: value.simulated,
                        // @ts-ignore
                        historical: value.historical
                    };
                }
            }

            return remappedObject;
        });

        return remappedData;
    }

    const generatePriceTrajectories = async () => {

        setChartData(null);
        setIsGenerating(true);


        try {

            const res = await generatePriceSimulatorTrajectoriesDirectly(payload);

            const convertedItems: any = [];

            const preparedData = prepareDataFromPriceSimDirect(res.data);


            preparedData.forEach((data: any) => {
                const converted = convertDataForMultiplePairs(data, {});
                convertedItems.push(converted);
            });
            populateChartWithData(convertedItems);

            return;
        } catch (e: any) {
            setSnackBar({open: true, severity: "error", message: e.message})
            setIsGenerating(false);
            console.error("error", e);
        }
    }

    return <Box
        sx={{textAlign: "center", fontSize: "48px", margin: "40px 0 0 240px", display: "flex"}}>
        {isPriceSimDirectEnabled && <PanelBox sx={{flexGrow: 4, p: 2, width: "100%", maxWidth: "1200px", minHeight: "614px"}}>
            <span style={{fontSize: "14px", textAlign: "start"}}>Payload:</span>
            <TextareaAutosize style={{minHeight: "200px", minWidth: "100%", maxWidth: "100%"}} value={payload}
                              onChange={(e: any) => setPayload(e.target.value)}/>
            <Box>
                <Button fullWidth variant={"contained"} onClick={generatePriceTrajectories}>Generate</Button>
            </Box>

            {isGenerating && <Box sx={{
                display: "flex",
                m: 4,
                fontSize: "18px",
                fontWeight: "bold",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto"
            }}>
                    <CenteredRowFlexBox>
                        <div style={{padding: "16px"}}><CircularProgress/></div>
                        <div>Generating Price Trajectories...</div>
                    </CenteredRowFlexBox>
            </Box>}

            {/*{chartData &&*/}
            {/*    <AlmanakChart height={"auto"} isLoading={isGenerating} data={chartData} options={chartOptions}/>}*/}
            <FlexCol sx={{gap: 2}}>
            {multipleChartsData?.map((chartData: any) =>
            <Box>
            <Box style={{borderBottom: "1px solid #eaeaea", padding: "8px", marginBottom: "8px", fontWeight: "bold", textAlign: "start", fontSize: "16px"}}>{chartData.title}</Box>
                <AlmanakChart height={"auto"} data={chartData} options={chartOptions}/>
            </Box>
            )}
            </FlexCol>

        </PanelBox>}
    </Box>
};
