import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import WatchLaterIcon from "@mui/icons-material/WatchLaterOutlined";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import EditOutlined from "@mui/icons-material/EditOutlined";
import {ESimulationStatus} from "../../state/types";

export const SimulationStatus = ({status}: { status?: ESimulationStatus }) => {

    if (!status) {
        return null;
    }
    if (status === ESimulationStatus.Completed) {
        return <Chip icon={<CheckIcon />} size="small" color={"success"}  label="Completed"/>
    } else if (status === ESimulationStatus.Pending) {
        return <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
            <Chip icon={<EditOutlined />} size="small"
                  color={"primary"} label="Draft"/>
        </Box>
    } else if (status === ESimulationStatus.Failed) {
        return <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
            <Chip icon={<ErrorIcon/>} size="small" color={"error"} label="Failed"/>
        </Box>
    } else if (status === ESimulationStatus.InQueue) {
        return <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
            <Chip icon={<WatchLaterIcon/>} size="small" color={"warning"}  label="In Queue"/>
        </Box>
    } else if (status === ESimulationStatus.InProgress) {
        return <Box sx={{display: "flex", alignItems: "center", gap: 1}}>

            <Chip icon={<DirectionsRunIcon/>} size="small" color={"warning"} label="In Progress"/>
        </Box>
    } else {
        return <Chip size="small"  label={status}/>
    }
}
