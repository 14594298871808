import Box from "@mui/material/Box";
import {Button, styled} from "@mui/material";
import {ReactNode} from "react";
import LoadingButton from "@mui/lab/LoadingButton";

type ControlButton = {
    title: string;
    onClick: () => any;
    icon?: ReactNode
    isLoading?: boolean;
}
type FloatingControlsProps = {
    primary: ControlButton
    secondary?: ControlButton
    tertiary?: ControlButton
}

const Container = styled(Box)(({theme}) => ({
    display: "flex",
    zIndex: "9",
    position: "fixed",
    bottom: "0",
    right: "0",
    padding: "16px 24px",
    width: "100%",
    gap: "16px",
    justifyContent: "flex-end",
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper
}));

export default function FloatingControls(props: FloatingControlsProps) {
    const {primary, secondary, tertiary} = props;
    return <Container>
        {tertiary &&
        <LoadingButton loading={tertiary.isLoading} startIcon={tertiary.icon ?? null} variant="text"
                       onClick={tertiary.onClick}> {tertiary.title} </LoadingButton>}
        {secondary && <LoadingButton loading={secondary.isLoading} startIcon={secondary.icon ?? null} variant="outlined"
                                     onClick={secondary.onClick}> {secondary.title} </LoadingButton>}
        <LoadingButton loading={primary.isLoading} startIcon={primary.icon ?? null} variant="contained"
                       onClick={primary.onClick}> {primary.title} </LoadingButton>
    </Container>
}
