import * as React from "react";
import {Divider, Menu, MenuItem} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoadingButton from '@mui/lab/LoadingButton';
import {formatPubKey} from "../../utils/commonUtils";
import {useAppState} from "../../state/AppStateProvider";
import useAuth from "../../hooks/useAuth";
import LogoutIcon from '@mui/icons-material/Logout';
import {AccountCircle} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useLogin, usePrivy} from "@privy-io/react-auth";

const menuStyles = {
    '& .MuiPaper-root': {
        minWidth: "156px",
        padding: "8px 0"
    },
    '& .MuiMenuItem-root': {
        padding: "8px 16px"
    }
}
export default function UserDropdown() {

    const navigate = useNavigate();
    const {user, isWalletConnecting, setUser, setIsWalletConnecting, setSnackBar} = useAppState();
    const {initSessionWithPrivy, logout} = useAuth();

    const {user: userFromPrivy, ready} = usePrivy();



    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const {login: loginWithPrivy} = useLogin({
        // @ts-ignore
        onComplete: (user, isNewUser, wasAlreadyAuthenticated, loginMethod, linkedAccount) => {
            console.log("loginWithPrivy on complete", user, isNewUser, wasAlreadyAuthenticated, loginMethod, linkedAccount);

            setIsWalletConnecting(true);

            const idToken = localStorage.getItem("privy:id_token")?.replaceAll("\"", "");


            if (!idToken) {
                console.error("idToken not found in local storage");
                return;
            }

            if (!user.wallet?.address) {
                console.error("wallet address not found in user object");
                return;
            }
            initSessionWithPrivy(idToken, user.wallet?.address);
        },
        onError: (error: any) => {
            console.error("loginWithPrivy error!", error);
            setIsWalletConnecting(false);
            logout();
            // Any logic you'd like to execute after a user exits the login flow or there is an error
        },
    });

    const onWalletButtonClick = (event: any) => {
        if (user?.publicKey) {
            setAnchorEl(event.currentTarget);
        } else {
            loginWithPrivy()
            // login(web3Auth);
        }
    }

    // const loginWithPrivvyFunc = async () => {
    //     const res = await loginWithPrivy();
    //     console.log("res from privy", res);
    //
    //     setSnackBar({open: true, message: "Logged in with Privy", severity: "success"});
    // }


    if (user && !isWalletConnecting && !user?.isWhitelisted) {
        return <MenuItem
            onClick={() => {
                setAnchorEl(null);
                logout();
            }}><LogoutIcon sx={{marginRight: 1}}/>Log out</MenuItem>
    }
    return (
        <div>
            {<LoadingButton id="user-dropdown" onClick={onWalletButtonClick}
                            aria-controls={isMenuOpen ? 'wallet-connect-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={isMenuOpen ? 'true' : undefined}
                            disableElevation
                            disabled={!ready}
                            loading={isWalletConnecting}
                            endIcon={user?.publicKey ? <KeyboardArrowDownIcon/> : null}
                            variant={user?.publicKey ? "outlined" : "contained"}> {user?.publicKey ? formatPubKey(user?.publicKey) : "Connect Wallet"} </LoadingButton>}
            <Menu
                sx={menuStyles}
                id="user-dropdown-menu"
                aria-labelledby="user-dropdown"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleClose}
            >

                <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        navigate("/profile");
                    }}><AccountCircle sx={{marginRight: 1}}/>Profile</MenuItem>

                <Divider sx={{my: 0}}/>

                <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        logout();
                    }}><LogoutIcon sx={{marginRight: 1}}/>Log out</MenuItem>
            </Menu>
        </div>
    )
}
