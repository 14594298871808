import React, {useMemo} from "react";
import Chip from "@mui/material/Chip";
import useSWR from "swr";
import {getSupportedChains} from "../../api/hasura";
import {SupportedChain} from "./WalletsOverview";
import {darken} from "@mui/material";
import {Text} from "@radix-ui/themes";

type ChainBadgeProps = {
    chain?: string
    chainId?: string;
}
export const ChainBadge = ({chain, chainId}: ChainBadgeProps) => {

    const {
        data: supportedChains
    } = useSWR(chainId ? "supported-chains" : null, getSupportedChains)


    const chainFromId = supportedChains?.find((c: SupportedChain) => c.chain_id === chainId)?.name;


    const RenderChainBadge = useMemo(() => {
        return (chain: string) => {
            let color = "info"

            switch (chain.toUpperCase()) {
                case "ETHEREUM":
                    color = "#dcfce7" // light green
                    break;
                case "SEPOLIA":
                    color = "#fff3dc" // light amber (same as pending status)
                    break;
                case "OPTIMISM":
                    color = "#fee2e2" // light red
                    break;
                case "ARBITRUM":
                    color = "#dbeafe" // light blue
                    break;
                default:
                    color = "#f5f5f5" // light gray
                    break;
            }

            return <Text
                size="2"
                style={{
                    padding: '4px 8px',
                    borderRadius: '4px',
                    textTransform: "capitalize",
                    backgroundColor: color,
                    color: darken(color, 0.8)
                }}
            >
                {chain.toLowerCase()}
            </Text>

        }
    }, [chain]);


    return RenderChainBadge(chain ?? chainFromId ?? "-");
}
