import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Link, matchRoutes, useLocation} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import WalletIcon from '@mui/icons-material/WalletOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LocalLibrary from '@mui/icons-material/LocalLibrary';
import Header from "./Header";
import {AppDrawer} from "./styled";
import {useAppState} from "../../state/AppStateProvider";
import {Button, CircularProgress, styled, Tooltip} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import {getDocsAccessToken, refreshJwtToken} from "../../api/hasura";
import SlideshowIcon from '@mui/icons-material/Slideshow';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import IconButton from "@mui/material/IconButton";
import {FlexBox} from "../../views/simulation/create-simulation/styled";
import {AlmanakLogo} from "../AlmanakLogo";
import jwt from "jwt-decode";
import useAuth from "../../hooks/useAuth";
import {setSelection} from "@testing-library/user-event/event/selection/setSelection";
import {usePrivy} from "@privy-io/react-auth";

const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === "production";


const menuItems1 = [
    {title: "Home", icon: HomeIcon, route: "/"},
]

const lowerMenuItems = [
    // {title: "Price Simulator", icon: AutoGraphIcon, route: "/price-simulation"}
    {title: "Agent Simulator (Demo)", icon: SlideshowIcon, route: "/demo"},
    {title: "Price Simulator", icon: AutoGraphIcon, route: "/price-simulation"},
]
const strategyLibraryItem = {title: "Strategies", icon: LocalLibrary, route: "/strategy-library"}
const agentsMenuItem = {title: "Agents", icon: SensorOccupiedIcon, route: "/agents"};
const walletsMenuItem = {title: "Wallets", icon: WalletIcon, route: "/wallets"};
const docsItem = {title: "Docs", icon: AssignmentIcon, route: "/docs"};


const ThemedLink = styled(Link)(({theme}) => ({
    textDecoration: "none",
    color: theme.palette.text.primary
}));


export const fullScreenRoutes = ['/simulation/:id', '/simulation/:id/results', '/simulation/:id/agent/:id', '/simulation/:id/agent/:id/strategies', '/simulation/:id/results/agent/:id'];

export const openAlmanakDocs = async (subUrl?: string) => {
    const response = await getDocsAccessToken();
    if (response.customToken) {
        const isStageEnv = process.env.REACT_APP_ENVIRONMENT !== "production";
        const stageUrl = "https://pde-docs.web.app/"; //stage
        const prodUrl = "https://docs.almanak.co/"; //prod
        const url = isStageEnv ? stageUrl : prodUrl;
        window.open(`${url}${subUrl ? subUrl : ""}?token=${response.customToken}`, "_blank")
    }
}
export const LDKeyStrategyLibraryWidget = "frontend_strategy-library-widget"
export const LDKeyWallets = "frontend_wallets";
export const LDKeyAgents = "frontend_agents";

export default function Sidebar() {
    const location = useLocation()
    const {user, setSnackBar, featureFlags, theme} = useAppState();
    const isUserWhitelisted = user?.isWhitelisted;
    const {initSessionWithPrivy, logout} = useAuth();
    const {getAccessToken, login} = usePrivy();

    // const {logout} = useAuth();

    const [isDocsTokenGenerating, setIsDocsTokenGenerating] = useState(false);

    const isStrategyLibraryEnabled = featureFlags?.[LDKeyStrategyLibraryWidget];
    const isWalletsEnabled = featureFlags?.[LDKeyWallets];
    const isAgentsEnabled = featureFlags?.[LDKeyAgents];


    const menuItems2 = useMemo(() => {

        let returnList = [];

        if (isStrategyLibraryEnabled) {
            returnList.push(strategyLibraryItem)
        }

        returnList.push({title: "Tests", icon: HistoryIcon, route: "/simulation-history"});

        if (isWalletsEnabled) {
            returnList.push(walletsMenuItem)
        }

        if (isAgentsEnabled) {
            returnList.push(agentsMenuItem)
        }

        return returnList;

    }, [isStrategyLibraryEnabled, isWalletsEnabled, isAgentsEnabled])

    const comingSoonItems = useMemo(() => {
        let returnList = [];

        if (!isAgentsEnabled) {
            returnList.push(agentsMenuItem)
        }

        if (!isWalletsEnabled) {
            returnList.push(walletsMenuItem)
        }

        if (!isStrategyLibraryEnabled) {
            returnList.push(strategyLibraryItem)
        }

        return returnList;

    }, [isStrategyLibraryEnabled, isWalletsEnabled, isAgentsEnabled])


    const initSessionAndLogin = async () => {
        console.log("checkTokenExpiration initSessionAndLogin beginning");
        const idToken = await getAccessToken();

        console.log("idToken", idToken);
        const userPublicKey = user?.publicKey ?? localStorage.getItem("userPublicKey");
        if (!idToken || !userPublicKey) {
            console.log("cant init session. user", user);
            console.log("cant init session. idToken", idToken);
            console.error("cant init session idToken | userPublicKey not available to init session");
            // logout()
            return;
        }
        await initSessionWithPrivy(idToken, userPublicKey);
    }

    const checkTokenExpiration = () => {

        const token = localStorage.getItem("accessToken");

        if (!token) {
            console.log("checkTokenExpiration no token, will init session again");
            // setSnackBar({open: true, message: "Session expired, restoring..", severity: "info"});
            initSessionAndLogin();
            return;
        }
        console.log("\ncheckTokenExpiration start -------------");
        const convertedToJWT = jwt(token) as any;
        const expiryTime = convertedToJWT.exp;

        console.log("checkTokenExpiration expiryTime", expiryTime);

        const tenMinutesInTheFuture = Date.now() / 1000 + 600;
        const isTokenAlmostExpired = expiryTime < tenMinutesInTheFuture;
        const isTokenExpired = expiryTime < Date.now() / 1000;

        console.log("checkTokenExpiration now", Date.now() / 1000);
        console.log("checkTokenExpiration isTokenAlmostExpired", isTokenAlmostExpired);
        console.log("checkTokenExpiration left until expiry", expiryTime - Date.now() / 1000);

        if (isTokenExpired) {
            console.log("isTokenExpired true, will init session again");
            // setSnackBar({open: true, message: "Session expired, restoring..", severity: "info"});
            initSessionAndLogin();
        }
        console.log("checkTokenExpiration end -------------\n");

    };

    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        console.log("token", token);
        if (!token) {
            return;
        }

        // Check immediately when the component mounts
        checkTokenExpiration();

        // Set interval to check every 5 minutes (300000ms)
        const intervalId = setInterval(checkTokenExpiration, 30000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);

    }, []);


    const openDocs = async () => {
        setIsDocsTokenGenerating(true);
        try {
            await openAlmanakDocs();
        } catch (err: any) {
            setSnackBar({open: true, message: "Could not open docs, please contact support", severity: "error"});
        } finally {
            setIsDocsTokenGenerating(false);
        }
    }

    const openDiscord = () => {
        window.open("https://discord.gg/c4jY28WrEB", "_blank")
    }

    const openTelegram = () => {
        window.open("https://t.me/+G1O9NFuz-AAzYmQy", "_blank")
    }

    const openX = () => {
        window.open("https://x.com/Almanak__", "_blank")
    }

    const openZealy = () => {
        window.open("https://zealy.io/cw/almanak/invite/a-T3Bp1m77bDi0-S2gDyq", "_blank")
    }


    const isOnboarded = user?.isOnboarded;
    const isAuthenticated = !!localStorage.getItem("accessToken") && !!user?.publicKey;
    const matchedRoute = matchRoutes(fullScreenRoutes.map(r => {
        return {path: r}
    }), location)?.[0]
    const isFullScreenRoute = fullScreenRoutes.includes(matchedRoute?.route.path!)

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <Header/>
            {!isFullScreenRoute && (isOnboarded || isAuthenticated) ? <AppDrawer
                variant="permanent"
                anchor="left"
            >
                <AlmanakLogo size={69}/>


                <List sx={{mt: 4}}>
                    {menuItems1.map((item, index) => (
                        <ThemedLink key={item.title} to={item.route}>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {<item.icon/>}
                                    </ListItemIcon>
                                    <ListItemText primary={item.title}/>
                                </ListItemButton>
                            </ListItem>
                        </ThemedLink>
                    ))}

                    {menuItems2.map((item, index) => (
                        <ThemedLink key={item.title} to={item.route}>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {<item.icon/>}
                                    </ListItemIcon>
                                    <ListItemText primary={item.title}/>
                                </ListItemButton>
                            </ListItem>
                        </ThemedLink>
                    ))}

                    {comingSoonItems.map((item, index) => (
                        <Tooltip key={item.title} title="Coming soon!" placement={"left-start"}>
                            <div key={item.title}>
                                <ListItem disabled disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {<item.icon/>}
                                        </ListItemIcon>
                                        <ListItemText primary={item.title}/>
                                    </ListItemButton>
                                </ListItem>
                            </div>
                        </Tooltip>
                    ))}
                </List>
                <Divider/>
                <List>

                    {lowerMenuItems.map((item, index) => (
                        <ThemedLink to={item.route} key={item.title}>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {<item.icon/>}
                                    </ListItemIcon>
                                    <ListItemText primary={item.title}/>
                                </ListItemButton>
                            </ListItem>
                        </ThemedLink>
                    ))}

                    <ListItem disabled={isDocsTokenGenerating} key={docsItem.title} disablePadding onClick={openDocs}>
                        <ListItemButton>
                            <ListItemIcon>
                                {<docsItem.icon/>}
                            </ListItemIcon>
                            <ListItemText primary={docsItem.title}/>
                            {isDocsTokenGenerating && <CircularProgress size={18}/>}
                        </ListItemButton>
                    </ListItem>


                </List>

                <FlexBox className={theme === "dark" ? "invert" : ""}
                         sx={{justifyContent: "space-around", m: 2, marginTop: "auto"}}>

                    <IconButton onClick={openDiscord}>
                        <img width={24} src={"social/discord.png"}/>

                    </IconButton>
                    <IconButton onClick={openTelegram}>
                        <img width={24} src={"social/telegram.png"}/>
                    </IconButton>
                    <IconButton onClick={openX}>
                        <img width={24} src={"social/twitter.png"}/>
                    </IconButton>

                    <IconButton onClick={openZealy}>
                        <img width={24} src={"social/zealy.png"}/>
                    </IconButton>
                </FlexBox>

            </AppDrawer> : null}

        </Box>
    );
}
