import Box from "@mui/material/Box";
import AlmanakChart from "../../../../components/almanak-chart/AlmanakChart";
import {Checkbox, CircularProgress, styled} from "@mui/material";
import {CenteredColumnFlexBox, CenteredRowFlexBox, PanelBox} from "../../create-simulation/styled";
import {LegendSquare} from "../../create-simulation/setup-simulation/styled";
import {
    defaultPriceScenarioChartOptions, EMarketScenarioColors,
    initialMarketScenarioOptions
} from "../../create-simulation/setup-simulation/constants";
import * as React from "react";
import {useAppState} from "../../../../state/AppStateProvider";
import {useEffect, useState} from "react";
import {getDateForXAxis} from "../../create-simulation/setup-simulation/market-scenarios/MarketScenariosContent";
import {fetchPriceTrajectoryResults} from "../../../../api/hasura";
import {Dayjs} from "dayjs";

export const ChartLabel = styled('div')`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
`
export const ChartTitle = styled('div')`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`
const footer = (tooltipItems: any) => {
    let sum = 0;

    tooltipItems.forEach(function (tooltipItem: any) {
        sum += tooltipItem.parsed.y;
    });
    return 'Mean: ' + sum / tooltipItems.length;
};
const chartOptions = {
    maintainAspectRatio: false,
    scales: {
        x: {
            label: "ROI",
            grid: {
                display: false
            }
        },
        y: {
            title: {
                display: false,
                text: "Fee ROI %"
            }
        }
    },
    elements: {
        point: {
            radius: 0,
            borderWidth: 0,
            backgroundColor: "none",
            hoverBorderWidth: 0
        }
    },
    plugins: {
        legend: {
            display: false,
            position: "bottom",
            onClick: null
        },
        tooltip: {
            enabled: true,
            callbacks: {
                footer: footer,
            }
        }
    },
    interaction: {
        intersect: false,
        mode: 'index',
    }
}

const formatDataForChart = (simulationArray: any[], type: string) => {
    if (!simulationArray) {
        return {
            labels: [],
            datasets: []
        }
    }

    const length = simulationArray?.[0].environment_step?.length;

    const labelsArray = Array.from(Array(length).keys())
    return {
        labels: labelsArray.map((item: any) => item + 1),
        datasets: simulationArray?.map((simulationItem: any, i: number) => {
            return {
                label: `Price trajectory ${i + 1}`,
                data: simulationItem?.environment_step?.map((step: any) => step?.[type]),
                borderWidth: 1,
                borderColor: "#000"
            }
        }),
    }


}

function getFirstElementFromDatalist(input: any) {

    // Get the first key in the historical object
    let firstKey = Object.keys(input)[0];

    // Access the array using the first key and return the first element
    let firstElement = input[firstKey];

    return firstElement;
}


const getChartDataAndOptions = (dataList: any[], steps: number, granularitySize: string, simulation_start: Dayjs) => {
    if (!dataList) {
        return;
    }

    //console.log("dataList", dataList);
    const simulatedData: any = [];
    let verticalLineValue = 0;


    let historical = getFirstElementFromDatalist(dataList[0].data.prices.historical)
    const firstSimulatedList = getFirstElementFromDatalist(dataList[0].data.prices.simulated);

    if (steps && historical.length > steps * 0.2) {
        historical = historical.slice(historical.length - (steps * 0.2), historical.length);
    }
    const labels = [...historical.map(() => ``), ...firstSimulatedList.map((d: any, i: number) => getDateForXAxis(i, granularitySize, simulation_start))];

    verticalLineValue = historical.length;


    dataList.forEach(data => {

            const simulated = getFirstElementFromDatalist(data.data.prices.simulated)

            simulatedData.push({
                data: [...historical, ...simulated].map((d: any, i: number) => {
                    return {y: d, x: i}
                }),
                color: "#000",
            });
        }
    )


    const data = {
        labels,
        datasets: [
            ...simulatedData.map((item: any, i: number) => {
                return {
                    title: `Drift: ${item.drift}`,
                    data: item.data,
                    fill: false,
                    borderColor: item.color,
                    tension: 0.4,
                    cubicInterpolationMode: 'monotone',
                }
            }),
        ],
    }


    const options = {
        ...defaultPriceScenarioChartOptions,
        plugins: {
            ...defaultPriceScenarioChartOptions.plugins,
            annotation: {
                annotations: [
                    {
                        type: 'line',
                        borderColor: EMarketScenarioColors.rangebound,
                        borderWidth: 1,
                        display: (ctx: any) => ctx.chart.isDatasetVisible(1),
                        // label: {
                        //     display: false,
                        // },
                        scaleID: 'x',
                        value: verticalLineValue
                    }
                ]
            }
        }
    }

    return {data, options}
}

export const PriceTrajectoriesUsedChart = () => {

    const {selectedSimulation} = useAppState();

    const {generatedAssetPair, steps, granularitySize, simulation_start, priceTrajectoryResults, selectedMarketScenarios} = selectedSimulation?.frontend_state ?? {};

    const [chartOptions, setChartOptions] = useState<any>({})
    const [chartData, setChartData] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false);


    const fetchTrajectoriesAndPopulateChart = async () => {
        setIsLoading(true);
        const promises = priceTrajectoryResults?.map((o: any) => {
            return fetchPriceTrajectoryResults(o.gcs_uri, o.options)
        });


        if (!promises) {
            return;
        }
        await Promise.all(promises).then(res => {
            const chartRes = getChartDataAndOptions(res, steps!, granularitySize!, simulation_start!);
           setChartData(chartRes?.data);
           setChartOptions(chartRes?.options)
        }).catch(err => {
            //console.log("error!", err);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetchTrajectoriesAndPopulateChart();
    }, []);




    return <Box>
        <PanelBox sx={{gap: 2}}>
            <Box sx={{p: 2, display: "flex", justifyContent: "space-between"}}>
                <span
                    style={{fontSize: "16px", fontWeight: "bold"}}>{generatedAssetPair} Generated Price Trajectories</span>
            </Box>
            {/*{chartData ?*/}
            {/*    <AlmanakChart height={"auto"} data={chartData}*/}
            {/*                  options={chartOptions}/> :*/}
            {/*    <Box sx={{*/}
            {/*        display: "flex",*/}
            {/*        m: 4,*/}
            {/*        fontSize: "18px",*/}
            {/*        fontWeight: "bold",*/}
            {/*        alignItems: "center",*/}
            {/*        justifyContent: "center",*/}
            {/*        margin: "auto"*/}
            {/*    }}>*/}
            {/*        {isLoading &&*/}
            {/*            <CenteredColumnFlexBox>*/}
            {/*                <CenteredRowFlexBox>*/}
            {/*                    <div style={{padding: "16px"}}><CircularProgress/></div>*/}
            {/*                    <div>Loading Price Trajectories</div>*/}
            {/*                </CenteredRowFlexBox>*/}
            {/*                <div style={{fontSize: "14px", textAlign: "center", marginBottom: "16px"}}>This might take a while...</div>*/}
            {/*            </CenteredColumnFlexBox>}*/}

            {/*    </Box>}*/}

            {chartData ?
                <AlmanakChart height={"auto"} data={chartData}
                              options={chartOptions}/> :
                <Box sx={{
                    display: "flex",
                    m: 4,
                    fontSize: "18px",
                    fontWeight: "bold",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto"
                }}>

                    {/*{isGenerating ?*/}
                    {/*    <CenteredColumnFlexBox>*/}
                    {/*        <CenteredRowFlexBox>*/}
                    {/*            <div style={{padding: "16px"}}><CircularProgress/></div>*/}
                    {/*            <div>Generating Price Trajectories</div>*/}
                    {/*        </CenteredRowFlexBox>*/}
                    {/*        <div style={{fontSize: "14px", textAlign: "center", marginBottom: "16px"}}>This might take a*/}
                    {/*            while...*/}
                    {/*        </div>*/}
                    {/*    </CenteredColumnFlexBox> :*/}
                    {/*    <Box sx={{m: 4}}>Please generate price trajectories first</Box>}*/}

                </Box>}



            {/*{chartData && <CenteredRowFlexBox sx={{justifyContent: "center"}}>*/}
            {/*    {selectedMarketScenarios?.filter(o => o.selected)?.map(option =>*/}
            {/*        <CenteredRowFlexBox key={option.type} sx={{p: 2}}>*/}
            {/*            <LegendSquare*/}
            {/*                sx={{backgroundColor: initialMarketScenarioOptions?.find(o => o?.type === option.type)?.color}}*/}
            {/*            />*/}
            {/*            {initialMarketScenarioOptions?.find(o => o?.type === option.type)?.title}*/}
            {/*        </CenteredRowFlexBox>*/}
            {/*    )}*/}
            {/*</CenteredRowFlexBox>}*/}

        </PanelBox>
    </Box>
}
