import {
    MarketScenariosContent
} from "../views/simulation/create-simulation/setup-simulation/market-scenarios/MarketScenariosContent";
import * as React from "react";
import {useForm} from "react-hook-form";
import {SimulationMetadata} from "../state/types";
import {PageContentContainer} from "../views/styled/styled";
import {priceSimulatorDefaultConfig} from "../constants";
import {LDKeyPriceSimulatorMaintanance, useAppState} from "../state/AppStateProvider";
import {Alert} from "@mui/material";

export const PriceSimulationWidget = () => {

    const {featureFlags} = useAppState();
    const isPriceSimulatorInMaintenanceMode = featureFlags?.[LDKeyPriceSimulatorMaintanance];


    const formController = useForm<SimulationMetadata>({
            defaultValues: {...priceSimulatorDefaultConfig, title: "Simulation for SDK"},
            reValidateMode: "onChange"
        }
    );

    return <PageContentContainer>

        <h2>Price Simulator</h2>
        {isPriceSimulatorInMaintenanceMode && <Alert severity={"error"}>
            Price Simulator is currently in maintenance mode. Unexpected errors may occur.
        </Alert>}

        <MarketScenariosContent formController={formController}
                                options={{displayPriceSimIds: true, displayMetadata: true}}/>
    </PageContentContainer>

}
