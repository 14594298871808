import {Avatar, FormControlLabel, styled, ToggleButton} from "@mui/material";
import Box from "@mui/material/Box";

export const CustomLabel = styled(`div`)`
  font-weight: 700;
  font-size: 14px;
  margin-right: 16px;
`
export const CustomRadio = styled(`div`)(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "16px",
    fontSize: "14px",
    height: "54px",
    cursor: "pointer",
    margin: "16px 0",
    background: "#EEEEF0",
    borderRadius: "12px",
    flex: "none",
    order: "0",
    flexGrow: "1",
    color: "black",
    "& > span > span > svg": {
        color: "black"
    }
}));

export const ThemedGreyBox = styled(Box)(({theme}) => ({
    display: "flex",
    backgroundColor: theme.palette.mode === "dark" ? "#EEEEF02F" : "#EEEEF0",
}));

export const PanelBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
    border: "1px solid",
    backgroundColor: theme.palette.mode === "dark" ? "#EEEEF02F" : "#FFF",
    borderColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "#D6D8DD",
}));

export const WhitePanelBox = styled(PanelBox)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#EEEEF00F" : "#FFF",

}));

export const TransparentPanelBox = styled(PanelBox)`
  background-color: transparent;
`
export const MarketScenarioControlLabel = styled(FormControlLabel)(({theme}) => ({
    border: "1px solid",
    backgroundColor: theme.palette.mode === "dark" ? "#EEEEF02F" : "#F9FAFB",
    borderColor: theme.palette.mode === "dark" ? "#ffffff1f" : "#D6D8DD",
    borderRadius: "12px",
    padding: "8px",
    margin: "12px 0",
    "&.MuiFormControlLabel-label": {
        fontSize: "14px"
    }
}));

export const FlexBoxSB = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export const FlexBox = styled(Box)`
  display: flex;
`

export const FlexCol = styled(Box)`
    display: flex;
    flex-direction: column;
`

export const CenteredRowFlexBox = styled(FlexBox)`
  align-items: center;
`

export const CenteredColumnFlexBox = styled(FlexBox)`
  flex-direction: column;
  justify-content: center;
`

export const CenteredRow = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const IconAvatar = styled(Avatar)`
  margin: 0 12px;
`
export const TimeRangeButton = styled(ToggleButton)`
  margin: 16px;
  padding: 20px;
  border: none;
  border-radius: 12px !important;
  width: 100%;

  &:hover {
    background: #00000030;
  }
`
