import Box from "@mui/material/Box";
import {useState} from "react";

export const AlphaCountdown = () => {
    const countDownDate = new Date("Sep 29, 2023 00:00:00");
    const countDownDateTime = new Date("Sep 29, 2023 00:00:00").getTime();

    const nowDate = new Date();
    const nowTime = new Date().getTime();

    function getBusinessDatesCount(startDate: Date, endDate: Date) {
        let count = 0;
        const curDate = new Date(startDate.getTime());
        while (curDate <= endDate) {
            const dayOfWeek = curDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
            curDate.setDate(curDate.getDate() + 1);
        }
        return count;
    }

    const [timeLeft, setTimeLeft] = useState(countDownDateTime - nowTime)
    const [businessDaysleft, setBusinessDaysLeft] = useState(getBusinessDatesCount(nowDate, countDownDate));

    const initCountdown = setInterval(function () {
        var nowDate = new Date();
        var timeleft = countDownDateTime - nowDate.getTime();
        setTimeLeft(timeleft)
    }, 1000)

    var days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    var hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return <Box
        sx={{textAlign: "center", fontSize: "48px", margin: "200px auto", display: "flex", flexDirection: "column"}}>
        <span>{days} days {hours}:{minutes}:{seconds} remaining</span>
        <span
            style={{fontSize: "14px"}}>
            If you don't wanna work the weekends, it's actually {businessDaysleft} days {hours}:{minutes}:{seconds} remaining
        </span>
        <Box>
            <img alt="this is fine" style={{height: "100%", width: "480px", margin: "32px"}}
                 src={"https://media.tenor.com/-kZOB16tELEAAAAC/this-is-fine-fire.gif"}/>
        </Box>
    </Box>
}
