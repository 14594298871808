import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {PageContentContainer} from "../../styled/styled";

type LoadingPoolViewProps = {
    title?: string;
    subTitle?: string;
    fullscreen?: boolean;
}

const loadingTextStyles = {
    fontSize: "18px",
    fontWeight: "700"
}
export const PageLoadingView = (props: LoadingPoolViewProps) => {

    const {title, subTitle, fullscreen} = props;

    return <PageContentContainer sx={fullscreen ? {paddingLeft: 0} : null}>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20%"}}>
            <span style={loadingTextStyles}>{title ?? "Loading..."}</span>
            <span>{subTitle}</span>
            <Box sx={{color: "#4B4B4B", maxWidth: "640px", width: '100%', marginTop: "24px"}}>
                <LinearProgress sx={{height: "10px", borderRadius: "50px"}}/>
            </Box>
        </Box>
    </PageContentContainer>

}
