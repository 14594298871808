export const areaChartOptions = {
    maintainAspectRatio: false,
    elements: {
        line: {
            borderWidth: 2,

        },
        point: {
            radius: 0,
            borderWidth: 0,
            backgroundColor: "none",
            hoverBorderWidth: 0
        }
    },
    // interaction: {
    //     mode: 'index' as const,
    //     intersect: false,
    // },
    stacked: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: { display: false },
        y: {
            type: 'linear' as const,
            display: false,
            position: 'left' as const,
        },
        y1: {
            type: 'linear' as const,
            display: false,
            position: 'right' as const,
            grid: {
                drawOnChartArea: false,
            },
        },
    },
}

export const barChartOptions = {
    type: "bar",
    responsive: true,
    elements: {
        line: {
            borderWidth: 2,
        },
        point: {
            radius: 0,
            borderWidth: 0,
            backgroundColor: "none",
            hoverBorderWidth: 0
        }
    },
    stacked: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: { display: false },
        y: {
            type: 'linear' as const,
            display: false,
            position: 'left' as const,
        },
        y1: {
            type: 'linear' as const,
            display: false,
            position: 'right' as const,
            grid: {
                drawOnChartArea: false,
            },
        },
    },
}
