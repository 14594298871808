import React from "react";
import {useNavigate} from "react-router-dom";
import {LDKeyAgents} from "../../components/layout/Sidebar";
import {useAppState} from "../../state/AppStateProvider";
import {PageContentContainer} from "../styled/styled";
import {Box} from "@mui/material";

import {Flex, Text, Table, Button, Card, Spinner} from "@radix-ui/themes";
import useSWR from "swr";
import {getLiveAgents} from "../../api/hasura";
import {AgentStatus} from "../../components/AgentStatus";

// Mock data for dashboard
const mockDashboardData = {
    totalProfitLoss: 2500,
    activeAgents: 2,
    totalBalance: 22500,
};

const DashboardMetric = ({label, value, isProfitLoss = false, isUsdValue = false}: {
    label: string;
    value: string | number;
    isProfitLoss?: boolean;
    isUsdValue?: boolean
}) => {
    const valueColor = isProfitLoss
        ? Number(value) >= 0 ? "green" : "red"
        : undefined;

    return (
        <Flex style={{marginRight: "24px"}}>
            <Flex direction="column" align="start" gap="2">
                <Text size="2" color="gray">{label}</Text>
                <Text size="6" weight="bold" color={valueColor}>{isUsdValue ? "$" : ""}{value.toLocaleString()}</Text>
            </Flex>
        </Flex>
    );
};

const AgentsDashboard = () => (
    <Flex gap="4" wrap="wrap">
        <DashboardMetric
            label="Total Profit/Loss"
            value={"0"}
            isProfitLoss={true}
            isUsdValue
        />
        <DashboardMetric label="Active Agents" value={"-"}/>
        <DashboardMetric label="Total Balance" value={`-`}/>
    </Flex>
);

interface Agent {
  id: string;
  name: string;
  status: string;
  artifact_id_version_id: string;
  strategy_version: string | null;
  created_at: string;
  updated_at: string;
  config: string;
  user_id: string;
}

export const AgentsOverview = () => {
    const navigate = useNavigate();
    const {featureFlags} = useAppState();
    const {data: agents, isLoading: isLoadingAgents} = useSWR<Agent[]>("live-agents", getLiveAgents);

    console.log("agents", agents);
    const isAgentsEnabled = featureFlags?.[LDKeyAgents];

    if (!isAgentsEnabled) {
        return <PageContentContainer>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <h2>Agents feature is coming soon!</h2>
            </Box>
        </PageContentContainer>
    }

    const handleDeployAgent = () => {
        navigate("/agents/deploy");
    };

    return <PageContentContainer>
        <Flex direction="column" gap="6">
            <Flex justify="between" align="center">
                <Text size="6" weight="bold">Agents Overview</Text>
                <Button onClick={handleDeployAgent}>Create Agent</Button>
            </Flex>
            <AgentsDashboard/>
            {isLoadingAgents ? (
                <Flex direction="column" align="center" gap="3" py="8">
                    <Text size="3" weight="medium" color="gray">
                        Loading Agents
                    </Text>
                    <Spinner size="2"/>
                </Flex>
            ) : (
                <Table.Root>
                    <Table.Header>
                        <Table.Row>
                            <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>Strategy ID</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>Version</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>Created At</Table.ColumnHeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {isLoadingAgents ? (
                            <Table.Row>
                                <Table.Cell colSpan={5}>Loading agents...</Table.Cell>
                            </Table.Row>
                        ) : agents && agents.length > 0 ? (
                            agents.map((agent) => (
                                <Table.Row key={agent.id} onClick={() => navigate(`/agents/${agent.id}`)} style={{ cursor: 'pointer' }}>
                                    <Table.Cell>{agent.name || 'Unnamed Agent'}</Table.Cell>
                                    <Table.Cell>{agent.artifact_id_version_id.slice(0, 8)}...</Table.Cell>
                                    <Table.Cell>{agent.strategy_version || 'N/A'}</Table.Cell>
                                    <Table.Cell>
                                       <AgentStatus status={agent.status} />
                                    </Table.Cell>
                                    <Table.Cell>{new Date(agent.created_at).toLocaleDateString()}</Table.Cell>
                                </Table.Row>
                            ))
                        ) : (
                            <Table.Row>
                                <Table.Cell colSpan={5}>No agents found</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table.Root>
            )}
        </Flex>
    </PageContentContainer>
}
