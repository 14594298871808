import Box from "@mui/material/Box";
import {FlexBoxSB} from "../views/simulation/create-simulation/styled";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIosNew";
import * as React from "react";
import {ReactNode} from "react";
import {styled} from "@mui/material";
import {useAppState} from "../state/AppStateProvider";

const StyledArrow = styled(ArrowBackIos)`
  padding-right: 3px;
  transition: transform 200ms;
`
export const ExpandableContent = (props: { title: string, children: ReactNode, expanded?: boolean }) => {


    const {isLeftSideExpanded, setIsLeftSideExpanded, theme} = useAppState();
    const paramsStyles = {
        transition: "width 200ms",
        top: "65px",
        padding: isLeftSideExpanded ? "16px" : 0,
        position: isLeftSideExpanded ? "fixed" : "absolute",
        backgroundColor: isLeftSideExpanded ? theme === "dark" ? "#000" : "#F9FAFB" : "transparent",
        overflowY: "auto",
        left: "0",
        height: "calc(100vh - 65px)",
    }
    return <Box sx={paramsStyles}>
        <FlexBoxSB sx={{marginBottom: 2, alignItems: "center"}}>
            {isLeftSideExpanded && <span style={{fontSize: "18px", fontWeight: "bold", marginRight: "16px"}}>{props.title}</span>}
            <IconButton onClick={() => setIsLeftSideExpanded(!isLeftSideExpanded)}>
                <StyledArrow sx={!isLeftSideExpanded ? {transform: "rotate(180deg)"} : null}/></IconButton>
        </FlexBoxSB>
        <Box sx={!isLeftSideExpanded ? {display: "none"} : {display: "inherit"}}>
            {props.children}
        </Box>
    </Box>
}
