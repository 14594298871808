import Box from "@mui/material/Box";
import AlmanakChart from "../../../../components/almanak-chart/AlmanakChart";
import {styled} from "@mui/material";
import {PanelBox} from "../../create-simulation/styled";

export const ChartLabel = styled('div')`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
`
export const ChartTitle = styled('div')`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`
const footer = (tooltipItems: any) => {
    let sum = 0;

    tooltipItems.forEach(function (tooltipItem: any) {
        sum += tooltipItem.parsed.y;
    });
    return 'Mean: ' + sum / tooltipItems.length;
};
const chartOptions = {
    maintainAspectRatio: false,
    scales: {
        x: {
            label: "ROI",
            grid: {
                display: false
            }
        },
        y: {
            title: {
                display: false,
                text: "Fee ROI %"
            }
        }
    },
    elements: {
        point: {
            radius: 0,
            borderWidth: 0,
            backgroundColor: "none",
            hoverBorderWidth: 0
        }
    },
    plugins: {
        legend: {
            display: false,
            position: "bottom",
            onClick: null
        },
        tooltip: {
            enabled: true,
            callbacks: {
                footer: footer,
            }
        }
    },
    interaction: {
        intersect: false,
        mode: 'index',
    }
}

const formatDataForChart = (simulationArray: any[], type: string) => {
    if (!simulationArray) {
        return {
            labels: [],
            datasets: []
        }
    }

    const length = simulationArray?.[0].environment_step?.length;

    const labelsArray = Array.from(Array(length).keys())
    return {
        labels: labelsArray.map((item: any) => item + 1),
        datasets: simulationArray?.map((simulationItem: any, i: number) => {
            return {
                label: `Price trajectory ${i + 1}`,
                data: simulationItem?.environment_step?.map((step: any, index: number) => ({y: step?.[type], x: index})),
                borderWidth: 1,
                borderColor: "#000"
            }
        }),
    }


}
export const TimeSeriesResultsChart = ({
                                               type,
                                               selectedAgentResults
                                           }: { type: string, selectedAgentResults: any }) => {

    const chartData = formatDataForChart(selectedAgentResults.simulation_array, type);
    return <Box>
        <PanelBox sx={{my: 3, p: 3}}>

            <ChartTitle sx={{paddingBottom: 2}}>
                Time Series
            </ChartTitle>

            <ChartLabel>{type}</ChartLabel>
            <AlmanakChart data={chartData} options={chartOptions} height={"280px"}/>
            <Box sx={{textAlign: "center"}}><ChartLabel>Steps</ChartLabel></Box>
        </PanelBox>
    </Box>
}
