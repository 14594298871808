import Big, {BigSource} from 'big.js';

import {
    Calculator, ComparisonOperator,
    createDinero, Currency,
    Dinero,
} from 'dinero.js';
import {getAvailableTokens} from "../api/hasura";
import {TokenDetails} from "../modals/AddWalletModal";

type CurrencySymbol = "ETH" | "BTC" | "WETH" | "USDT";
const availableTokens = getAvailableTokens();


const calculator: Calculator<Big> = {
    add: (a: Big, b: Big) => a.plus(b),
    compare: (a: Big, b: Big) => a.cmp(b) as unknown as ComparisonOperator,
    decrement: (v: Big) => v.minus(new Big(1)),
    increment: (v: Big) => v.plus(new Big(1)),
    integerDivide: (a: Big, b: Big) => a.div(b).round(0, Big.roundDown),
    modulo: (a: Big, b: Big) => a.mod(b),
    multiply: (a: Big, b: Big) => a.times(b),
    power: (a: Big, b: Big) => a.pow(Number(b)),
    subtract: (a: Big, b: Big) => a.minus(b),
    zero: () => new Big(0),
};

export const money = createDinero({calculator});


export const formatThousands = (input: string, separator?: string) => {
    const value = Number(input);
    const parts = (
        (value || value === 0 ? Number.parseFloat(value.toString()) : '') + ''
    ).split('.');
    if (parts.length) {
        parts[0] = parts[0].replace(
            /(\d)(?=(\d{3})+\b)/g,
            '$1' + (separator || "'"),
        );
    }
    return parts.join('.');
};

export const getNativeAmount = (amount: number | string | Big, decimalPlaces: number):string  => {
    if (!amount || amount === "") {
        return "";
    }
    if (amount.toString().slice(-1) === "." || amount.toString().slice(-1) === ",") {
        amount += "0"
    }
    return Big(amount).times(Big(10).pow(decimalPlaces)).toFixed(0);
}

export const getDisplayAmount = (amount: number | string | Big, decimalPlaces: number): string  => {
    if (!amount || amount === "") {
        return "";
    }
    if (amount.toString().slice(-1) === "." || amount.toString().slice(-1) === ",") {
        amount += "0"
    }
    return Big(amount).div(Big(10).pow(decimalPlaces)).toString();
}


export class Money {
    private readonly _amount: Big;
    private readonly _currency: Currency<Big>;
    private readonly _dinero: Dinero<Big>;
    private readonly decimalPoints: Big;

    constructor(
        amount: BigSource,
        token: TokenDetails,
    ) {


        this._currency = {code: token.token, base: Big(10), exponent: Big(token.decimalPlaces)};
        this._amount = Big(amount);
        this.decimalPoints = Big(token.decimalPlaces);
        this._dinero = money({
            amount: this._amount,
            currency: this._currency,
            scale: Big(token.decimalPlaces),
        });
    }

    get currency(): string {
        return this._currency.code;
    }


    get dinero(): Dinero<Big> {
        return this._dinero;
    }

    get amount(): string {
        return this._amount.toFixed(Number(this.decimalPoints));
    }

    get amountInSmallestUnits(): string {
        return this._amount.toFixed(0);
    }

    get displayAmount(): string {
        return this._amount.div(Big(10).pow(Number(this.decimalPoints))).toString()
    }
}
