import {FormControlLabel, styled} from "@mui/material";
import Box from "@mui/material/Box";
import {PanelBox} from "../styled";

export const TradingStrategyControlLabel = styled(FormControlLabel)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#EEEEF02F" : "#EEEEF0",
    borderRadius: "12px",
    padding: "16px",
    margin: "12px 0",
    alignItems: "flex-start",

    ".MuiFormControlLabel-label": {
        fontSize: "14px"
    }
}));
export const StrategyTitle = styled("span")`
  font-size: 18px;
  padding: 8px 0;
  font-weight: 700;
`
export const StrategyDescription = styled("span")`
  padding: 4px 0;
`
export const BestUse = styled("span")`
  font-size: 16px;
  padding-top: 20px;
  font-weight: 700;
`
export const LegendSquare = styled(Box)`
  height: 16px;
  width: 16px;
  border-radius: 4px;
  margin-right: 8px;
`
export const InputWithDropdownContainer = styled(Box)`
".MuiInputBase-root" {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
`
export const ResponsiveBox = styled(Box)`
  display: flex;
  flex-direction: row;

  @media (max-width: 920px) {
    flex-direction: column;
    gap: 16px;
  }
`
