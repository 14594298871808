import dayjs from "dayjs";

export const latestAvailableData = dayjs(new Date());
export const latestAvailableDataAsBlockHeight = 19229031;

export enum EGraphDateRange {
    "1D" = 1,
    "1W" = 7,
    "1M" = 30,
    "6M" = 180,
    "1Y" = 360,
    "All" = 9999
}

export const poolDetailsGraphTypes = [
    {type: "tvl", title: "TVL", value: "tvl"},
    {type: "volume", title: "Volume", value: "volume"},
    {type: "fees", title: "Fees", value: "fees"},
]

export const simulatedPriceTrajectoriesGraphTypes = [
    {type: "profitLoss", title: "P/L", value: "profitLoss"},
    {type: "roi", title: "ROI (%)", value: "roi"},
    {type: "feeRoi", title: "Fee ROI (%)", value: "feeRoi"},
    {type: "price", title: "Price", value: "price"},
]

export const graphRangeOptions = [
    // {label: "1D", value: GraphDateRangeEnum["1D"]},
    {title: "1W", value: EGraphDateRange["1W"]},
    {title: "1M", value: EGraphDateRange["1M"]},
    {title: "6M", value: EGraphDateRange["6M"]},
    {title: "1Y", value: EGraphDateRange["1Y"]},
    {title: "All", value: EGraphDateRange["All"]},
]

export const drawerWidth = 240;
export const maxPageWidth = 1200;

export const priceSimulatorDefaultConfig: any = {
    "block": null,
    "steps": null,
    "title": "Price Simulator Configuration",
    "token0": null,
    "token1": null,
    "assetPair": [],
    "feeTier": null,
    "network": "Ethereum",
    "exchange": "Uniswap",
    "end_block": null,
    "activeStep": 0,
    "granularity": 3600,
    "start_block": null,
    "selectedTimeframe": "1h",
    "priceConfigs": [],
    "depositAmount": 10000,
    "endPriceRange": 2000,
    "historical_end": null,
    "simulation_end": null,
    "granularitySize": "hour",
    "startPriceRange": 900,
    "historical_start": null,
    "simulation_start": null,
    "priceTrajectories": 5,
    "selectedAgentConfigs": [],
    "priceTrajectoryResults": [],
    "selectedMarketScenarios": [
        {
            "icon": {
                "key": null,
                "ref": null,
                "type": {
                    "type": {},
                    "compare": null
                },
                "props": {
                    "style": {
                        "fontSize": "16px"
                    }
                },
                "_owner": null,
                "_store": {}
            },
            "type": "uptrend",
            "color": "#4D5B9E",
            "drift": [
                1,
                1.1
            ],
            "shape": "linear",
            "title": "Uptrend",
            "maxDrift": 2,
            "minDrift": 0.5,
            "selected": true,
            "maxVolatility": 1,
            "minVolatility": 0.01,
            "marketDriftMode": "linear",
            "volatilityMultiplier": 0.2
        },
        {
            "icon": {
                "key": null,
                "ref": null,
                "type": {
                    "type": {},
                    "compare": null
                },
                "props": {
                    "style": {
                        "fontSize": "16px"
                    }
                },
                "_owner": null,
                "_store": {}
            },
            "type": "rangebound",
            "color": "#000000",
            "shape": "sinusoidal",
            "title": "Rangebound",
            "selected": false,
            "maxVolatility": 50,
            "minVolatility": 0.5,
            "marketDriftMode": "ranging",
            "volatilityMultiplier": 10
        },
        {
            "icon": {
                "key": null,
                "ref": null,
                "type": {
                    "type": {},
                    "compare": null
                },
                "props": {
                    "style": {
                        "fontSize": "16px"
                    }
                },
                "_owner": null,
                "_store": {}
            },
            "type": "downtrend",
            "color": "#EF8F3B",
            "drift": [
                -1.8,
                -1.6
            ],
            "shape": "linear",
            "title": "Downtrend",
            "maxDrift": -1,
            "minDrift": -3,
            "selected": false,
            "maxVolatility": 1,
            "minVolatility": 0.01,
            "marketDriftMode": "linear",
            "volatilityMultiplier": 0.2
        }
    ],
    "priceScenarioChartOptions": {
        "scales": {
            "x": {
                "grid": {
                    "display": false
                },
                "title": {
                    "display": true
                },
                "display": true
            },
            "y": {
                "grid": {
                    "display": false
                },
                "title": {
                    "font": {
                        "size": 14,
                        "weight": "bold"
                    },
                    "text": "Price",
                    "display": true
                },
                "display": true,
                "position": "right"
            }
        },
        "parsing": false,
        "plugins": {
            "legend": {
                "display": false
            },
            "tooltip": {
                "enabled": false
            },
            "annotation": {
                "annotations": [
                    {
                        "type": "line",
                        "value": 63,
                        "scaleID": "x",
                        "borderColor": "#000000",
                        "borderWidth": 1
                    }
                ]
            }
        },
        "elements": {
            "line": {
                "borderWidth": 1
            },
            "point": {
                "display": false,
                "borderWidth": 0,
                "backgroundColor": "transparent",
                "hoverBorderWidth": 0
            }
        },
        "animation": false,
        "interaction": {
            "intersect": false
        }
    },
    "selectedTradingStrategies": [
        "fixed"
    ]
};

export const lp3AgentsTemplateFrontendState: any = {
    "block": 20214216,
    "steps": 336,
    "title": "Uni LP v3 Template",
    "token0": "ETH",
    "token1": "USDT",
    "feeTier": 5000,
    "network": "Ethereum",
    "exchange": "Uniswap",
    "assetPair": [
        "ethusdt"
    ],
    "end_block": 20214216,
    "activeStep": 0,
    "granularity": 1,
    "start_block": 20114047,
    "agentConfigs": [
        {
            "id": "trader-univ3-trend-14j2oh",
            "type": "trader",
            "alias": "trader",
            "class": "trader-univ3-trend",
            "address": "0x662067f110C2C05C65427b359aCf066FdB7B1fcb",
            "holdings": [
                {
                    "mint": true,
                    "token": "ETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "WETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "USDT",
                    "amount": "10000000000"
                },
                {
                    "mint": true,
                    "token": "WBTC",
                    "amount": "1000000000"
                }
            ],
            "settings": {
                "cex_price_token0": "ETH",
                "cex_price_token1": "USDT",
                "uniswap3_pool_fee": 100,
                "min_eth_in_balance": 0.001,
                "uniswapv3_pool_fee": 500,
                "total_deposit_token1": 1000000000,
                "max_fraction_to_trade": 0.9,
                "min_fraction_to_trade": 0.1,
                "uniswapv3_pool_token0": "ETH",
                "uniswapv3_pool_token1": "USDT"
            }
        },
        {
            "id": "arber-cex-dex-cyclic-zrt3ze",
            "type": "arb",
            "alias": "arber",
            "class": "arber-cex-dex-cyclic",
            "address": "0xFc1C34a9FEb232358E8F26289eE01253b059f304",
            "holdings": [
                {
                    "mint": true,
                    "token": "ETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "WETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "USDT",
                    "amount": "10000000000"
                },
                {
                    "mint": true,
                    "token": "WBTC",
                    "amount": "1000000000"
                }
            ],
            "settings": {
                "cex_trade_fee": 0.001,
                "volume_buffer": 0.9,
                "volume_to_check": [
                    0.3,
                    0.6,
                    0.9
                ],
                "cex_price_token0": "ETH",
                "cex_price_token1": "USDT",
                "uniswap3_pool_fee": 100,
                "uniswapv3_pool_fee": 500,
                "uniswapv3_pool_token0": "ETH",
                "uniswapv3_pool_token1": "USDT"
            }
        },
        {
            "id": "trader-univ3-trend-model-midpo8",
            "type": "trader",
            "alias": "trader-model",
            "class": "trader-univ3-trend-model",
            "address": "0x1B4126370F3820D4Ee4004371248A1c21173c889",
            "holdings": [
                {
                    "mint": true,
                    "token": "ETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "WETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "USDT",
                    "amount": "10000000000"
                },
                {
                    "mint": true,
                    "token": "WBTC",
                    "amount": "1000000000"
                }
            ],
            "settings": {
                "model_1_alias": "trader-trend-following",
                "cex_price_token0": "ETH",
                "cex_price_token1": "USDT",
                "uniswap3_pool_fee": 100,
                "min_eth_in_balance": 0.001,
                "uniswapv3_pool_fee": 500,
                "total_deposit_token1": 1000000000,
                "max_fraction_to_trade": 0.9,
                "min_fraction_to_trade": 0.1,
                "uniswapv3_pool_token0": "ETH",
                "uniswapv3_pool_token1": "USDT"
            }
        },
        {
            "id": "lp-univ3-dynamic-xvb69g",
            "type": "lp",
            "alias": "lp",
            "class": "lp-univ3-dynamic",
            "address": "0x62A7FA91d283A3af35df9ADD9A901cC61b391E35",
            "holdings": [
                {
                    "mint": true,
                    "token": "ETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "WETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "USDT",
                    "amount": "10000000000"
                },
                {
                    "mint": true,
                    "token": "WBTC",
                    "amount": "1000000000"
                }
            ],
            "settings": {
                "dynamic_strat": true,
                "lookback_period": 3600,
                "always_rebalance": false,
                "cex_price_token0": "ETH",
                "cex_price_token1": "USDT",
                "uniswap3_pool_fee": 100,
                "volatility_scalar": 2,
                "uniswapv3_pool_fee": 500,
                "total_deposit_token1": 1000000000,
                "open_position_on_init": true,
                "uniswapv3_pool_token0": "ETH",
                "uniswapv3_pool_token1": "USDT"
            }
        }
    ],
    "priceConfigs": [
        "v1-cbde5bff4bde20045c32a8cdae73eb57785d4f3d39871ecf125a53f1175d1bc8",
        "v1-b7c6df773ea5d2077dd1166cd424d3d632d271ce0a0b2178c80f1dd7c6b50f0d",
        "v1-588672cc968392f82cf6977f1dc73db82b7193bb63816525397163235b8cfc11",
        "v1-73041b7c7765e84c8fcab631b1b7e981f2cc00cd0da5c634f6a90f42c063641b",
        "v1-66b319f2f53645369da9febff340bd10b7b12c94fa961d1c2b5762d060b91d0d",
        "v1-9748f8a61be970de89417748181b9c1ac2771b794d65574f385d8e383455d3cf",
        "v1-03cc4339c18eea94a720a52ff04df0a19ea1ea6acf85ec11d39b7f382204b112",
        "v1-61dca45fc428b2c73b265bc16952c12b404b72fcd5bdccacecc24051b2fe3f08",
        "v1-0212520c4cd1ce71f3927b7b0b690b7f1bafd5537b936f2c5f7e7ea4c60956b1",
        "v1-98c0957897368f278b2ad33fcc0970025d06ce1f28b78f8ed8b9f424d54bd964",
        "v1-03c1aefd27b2a963403b78d40d0a4eedcc1b9d24dcfca302e7a421b6488ba21f",
        "v1-026bb9b53a55e73af9ef9e2880bc4467c7a0c98af9dc1fc15db09f1cd24deb6f",
        "v1-53d41dd717c9b578e96400f0861ddc5edf6d419ec5a2656367789e5e2e2dc5e9",
        "v1-d3d4f801d4826c9b74db633c0d0a09a897896d4ebd713dc947058c609784ef3d",
        "v1-d3b12ced8683aade1335f8a097036e4c3dee1290bce7076984d5a39c2121d38b",
        "v1-023eda161aefb2888b6420816df84b19e2fc88538db4b1b5831a65aa835d41ae",
        "v1-976ec3504ddc43c01f840ae9da1ec89ecad6b38fe53d976fa599eac85a369c0d",
        "v1-3fb36225004df1f509be52e0f5f14780e3b12ed60b0f218033af99135917acbd",
        "v1-30e01a18cac01b42129d4d29bad3d99953b380258a25374d3f2e341476f8e870",
        "v1-6860e32f77b385dc904ac7c44494d77deb76692cf0a2e2693963fa650087ecde"
    ],
    "depositAmount": 10000,
    "endPriceRange": 2000,
    "historical_end": "2024-07-01T21:00:00.000Z",
    "simulation_end": "2024-07-15T21:00:00.000Z",
    "granularitySize": "hour",
    "startPriceRange": 900,
    "historical_start": "2024-06-17T21:00:00.000Z",
    "simulation_start": "2024-07-01T21:00:00.000Z",
    "priceTrajectories": 20,
    "selectedTimeframe": "1h",
    "selectedAgentConfigs": [
        {
            "id": "trader-univ3-trend-14j2oh",
            "type": "trader",
            "alias": "trader",
            "class": "trader-univ3-trend",
            "address": "0x662067f110C2C05C65427b359aCf066FdB7B1fcb",
            "holdings": [
                {
                    "mint": true,
                    "token": "ETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "WETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "USDT",
                    "amount": "10000000000"
                },
                {
                    "mint": true,
                    "token": "WBTC",
                    "amount": "1000000000"
                }
            ],
            "settings": {
                "cex_price_token0": "ETH",
                "cex_price_token1": "USDT",
                "min_eth_in_balance": 0.001,
                "uniswapv3_pool_fee": 500,
                "total_deposit_token1": 1000000000,
                "max_fraction_to_trade": 0.9,
                "min_fraction_to_trade": 0.1,
                "uniswapv3_pool_token0": "WETH",
                "uniswapv3_pool_token1": "USDT"
            }
        },
        {
            "id": "arber-cex-dex-cyclic-zrt3ze",
            "type": "arb",
            "alias": "arber",
            "class": "arber-cex-dex-cyclic",
            "address": "0xFc1C34a9FEb232358E8F26289eE01253b059f304",
            "holdings": [
                {
                    "mint": true,
                    "token": "ETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "WETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "USDT",
                    "amount": "10000000000"
                },
                {
                    "mint": true,
                    "token": "WBTC",
                    "amount": "1000000000"
                }
            ],
            "settings": {
                "cex_trade_fee": 0.001,
                "volume_buffer": 0.9,
                "volume_to_check": [
                    0.3,
                    0.6,
                    0.9
                ],
                "cex_price_token0": "ETH",
                "cex_price_token1": "USDT",
                "uniswapv3_pool_fee": 500,
                "uniswapv3_pool_token0": "WETH",
                "uniswapv3_pool_token1": "USDT"
            }
        },
        {
            "id": "trader-univ3-trend-model-midpo8",
            "type": "trader",
            "alias": "trader-model",
            "class": "trader-univ3-trend-model",
            "address": "0x1B4126370F3820D4Ee4004371248A1c21173c889",
            "holdings": [
                {
                    "mint": true,
                    "token": "ETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "WETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "USDT",
                    "amount": "10000000000"
                },
                {
                    "mint": true,
                    "token": "WBTC",
                    "amount": "1000000000"
                }
            ],
            "settings": {
                "model_1_alias": "trader-trend-following",
                "cex_price_token0": "ETH",
                "cex_price_token1": "USDT",
                "min_eth_in_balance": 0.001,
                "uniswapv3_pool_fee": 500,
                "total_deposit_token1": 1000000000,
                "max_fraction_to_trade": 0.9,
                "min_fraction_to_trade": 0.1,
                "uniswapv3_pool_token0": "WETH",
                "uniswapv3_pool_token1": "USDT"
            }
        },
        {
            "id": "lp-univ3-dynamic-xvb69g",
            "type": "lp",
            "alias": "lp",
            "class": "lp-univ3-dynamic",
            "address": "0x62A7FA91d283A3af35df9ADD9A901cC61b391E35",
            "holdings": [
                {
                    "mint": true,
                    "token": "ETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "WETH",
                    "amount": "10000000000000000000"
                },
                {
                    "mint": true,
                    "token": "USDT",
                    "amount": "10000000000"
                },
                {
                    "mint": true,
                    "token": "WBTC",
                    "amount": "1000000000"
                }
            ],
            "settings": {
                "dynamic_strat": true,
                "lookback_period": 3600,
                "always_rebalance": false,
                "cex_price_token0": "ETH",
                "cex_price_token1": "USDT",
                "volatility_scalar": 2,
                "uniswapv3_pool_fee": 500,
                "total_deposit_token1": 1000000000,
                "open_position_on_init": true,
                "uniswapv3_pool_token0": "WETH",
                "uniswapv3_pool_token1": "USDT"
            }
        }
    ],
    "priceScenarioChartData": null,
    "priceTrajectoryResults": [
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-cbde5bff4bde20045c32a8cdae73eb57785d4f3d39871ecf125a53f1175d1bc8/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=HbMuSmmb9UOilf%2BEbDeERHc5a02yIoQAYR0kMk%2FjGw9FR%2FtqEko3xKrZLF2uwnUc8T87B9lDLwR8c6LotEqq7qpIG93jwyfb%2FNSHTSDjKG8s1%2FkFu%2FkYpwJvGVCpZTXCpFZAiMY4b12oUs%2BhL1njNG5w49zQtHZH%2Bza9r54cbwnD2SO43ukN5OnKBe%2BYPf0A69%2BQvYU%2BL76syDoe3TRr6s%2BGZIUJKZgZThSTXjPW8Mw6z6HXZ88MlIEwvlLwPctnPPxqSeueppiUX4qyupmTU%2BdGWgORxEaMPVweYk3phcKbtrmzmxiQ2g%2BoMKK%2BHM9nwhAn2TkSjoV2HxnwPrrcGA%3D%3D",
            "price_config_hashkey": "v1-cbde5bff4bde20045c32a8cdae73eb57785d4f3d39871ecf125a53f1175d1bc8",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-b7c6df773ea5d2077dd1166cd424d3d632d271ce0a0b2178c80f1dd7c6b50f0d/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=b0GzKsneiLC%2F9WM2%2B%2BqQQstwcVhtWEzFcr55mtvaJBxhpKdgIDVDsIZVeNszvSekWxIvZ5IvDQ4DalMOXvhOkRPXL5dQz3XoNicwWKjudeg7v4n6wqF8qThldTGbk2TQJsaFI0NpfIXoXVoYENK8GGm3Hl4t8DAk4Yb%2FFtx0o1oaX8dl57HGznxQbvcCZIUFm2rQy%2FfQGSS1iBrzH2c6a6fRt99UtNzlP4SLCH9CiRNZG3CfY6Yl6nDzEMqIk%2BLro7pgXB2CbO1RZeR2I2B9vI%2F2%2F7SePCs%2FAlq1WT3UAYp78vBDHWhi2Vd4FoW%2BTRyrGZQbSsp75S6oZvj%2BDKzsSQ%3D%3D",
            "price_config_hashkey": "v1-b7c6df773ea5d2077dd1166cd424d3d632d271ce0a0b2178c80f1dd7c6b50f0d",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-588672cc968392f82cf6977f1dc73db82b7193bb63816525397163235b8cfc11/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=d3zt5kARD3pJImm0FyX2Mmub1g8mb%2BRIGMSqkczvvNVRxIrjJDW5kpCVapBjrHUiq919%2BpO1d5NdVf1muyj%2FbMuV74ID9%2FuWl0iwUk0PJi4dBOY1%2BZW%2F4CT%2F%2FgxSXQEq%2BFsgM6j9Eg3L0hDla6cNajzXe915HhA2n0E912SEP1x9GFU6ylIWau4kfbC6DJHh1LuMY9j7jn50yFi32T36Q2Q2nQ8JaJLfNK6Nx35EP6eM%2BBbHK63qDwtQIOhyXK8iUQqVNpbfR1WoQtNssTMW%2BbogqFK%2Fct%2FBZWC2Uhmxfb%2Bfqax0CRpYpiRc5xUchAjgX4HmVQWsDO0o92qRS%2Byk8g%3D%3D",
            "price_config_hashkey": "v1-588672cc968392f82cf6977f1dc73db82b7193bb63816525397163235b8cfc11",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-73041b7c7765e84c8fcab631b1b7e981f2cc00cd0da5c634f6a90f42c063641b/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=pBBSYzazDt%2ByeFJqrweedarSOeQUX9prg%2BvUHYANaZdInFgkEWR%2BKkB9IBLtBQdUvl6ulNcIGhc1D318bUTrbs%2FJt%2BjiG%2BR6UTdfpk2kdCnIooRvnFcnFg%2FO04s66yIkbKD650kACe9yuj5xaGKRo7AobQ3LKAgFifbMYI6Em5%2FgMrrVKuqzyEW15Kh3kTOQjdeIGFRDep6brak%2FacfeN7OFZl60QMT5tzSLtyKOj1G%2BP%2BgVBV0suRTNypwpFurDvIGlYT%2BtXaIn85Ikh%2B9Wwhnf6fyW2MuGCvqdIHfiUnPPoOEMQiMJkYzvD2IoWrJ4VALNO0yKFjnht8sb2K%2Br6w%3D%3D",
            "price_config_hashkey": "v1-73041b7c7765e84c8fcab631b1b7e981f2cc00cd0da5c634f6a90f42c063641b",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-66b319f2f53645369da9febff340bd10b7b12c94fa961d1c2b5762d060b91d0d/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=C%2B2Z2hvsbXOUaPv6fwg3Y1nTxhEcrUSPX%2B7UUfNx70oPzXjIYNuRhX6nEgUhEmq2M68OMS7RY1SnpZzpRFpm%2Fb3mbPQs3Y8akLmeCfi0AvMJ7940yxwOM2zup1a2CK7xxKNJ6ZUSgZlJ4LOQOlZp7uwcfKHIEXIYWc0aAjAcPwJCpsH0vRQU%2Fr2rypfbY0eFPZu1CApnk61r2LCa4gwiKKthPs9d1R5Gibs3TJoeHEsT01uTJ%2B1%2Fnt6gpQk3IHOzvI9ztGYdnq88YbbV2FD79d6KcTSGjsrjgJuPYVehg4AsjBjkFp%2F%2FzF0V2ELhEIUlGa8pPnFVSWDo2a9116T2BQ%3D%3D",
            "price_config_hashkey": "v1-66b319f2f53645369da9febff340bd10b7b12c94fa961d1c2b5762d060b91d0d",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-9748f8a61be970de89417748181b9c1ac2771b794d65574f385d8e383455d3cf/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=cITyEpZu2R2Hl0SahaupaIsM4mT%2BcMiFSSM1OxBnCvD4%2Fp2jbP0qdNwMg5AupZPS6Z6kj2uuNbGRhMw8hEKu8D38AmJi%2F1N7iwyPdRQRzsRMqDvJUjM5Bze3Z0tTo7NhYbSdwDp7xAiRNTbn9j1Llw7zp2eiWarJFS7z7Uyk7B4PXEfsmVF00jZNonx8pu75HmVSNS0ib1l%2FIY8aD0PIQb1veobbSn2Kcj%2BtpZ8eqEGF6DFAbPJKaxuu3KyNDLPkUkZnGkT51lINq%2BTMZij2oNyFWnElquutQ7rCC5kucQXqSQ6h%2FLwHWUZuwl2eA15gzf1m%2FZhcUe9RQDGhhqoyXA%3D%3D",
            "price_config_hashkey": "v1-9748f8a61be970de89417748181b9c1ac2771b794d65574f385d8e383455d3cf",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-03cc4339c18eea94a720a52ff04df0a19ea1ea6acf85ec11d39b7f382204b112/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=Wwd5FFoGISrnIZ%2BzdVXDYBbF8e%2FP1gndOjD1xtJN6m%2FUPE4LmQg8L%2F8arKfyodrnKIm6zw7L28uOd9etSWmHDy4pAWGOf534roqQsll3Qunjr3iDG4rH6OJEccZT6gLqBnuNbiL4feotJrVPAxv0Wa%2Bz6749sHVzXV8pWfP%2FmjBBDTHT2%2BsP0XdlysBoh8P8Y%2Bgrs1gomu7ZdNf289S%2F08n5FlqkBK803bBvDahugaKuUzdfgb3R4zRL0W5CrLmYrh1elUarFApRxD3%2BoTtiUTgnnC%2FWqET0rl6sgtZJ3G6enxMx3xShvdJ37ZsbUQPPfDppKAf%2BL%2B%2FvKrcUl7fvuw%3D%3D",
            "price_config_hashkey": "v1-03cc4339c18eea94a720a52ff04df0a19ea1ea6acf85ec11d39b7f382204b112",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-61dca45fc428b2c73b265bc16952c12b404b72fcd5bdccacecc24051b2fe3f08/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=fAoX4WZRiSL8g6d%2BMUWsOgPjslBRACflAIiVglSCpv4Fcitl2R3lLskgbNPmJiX709jlHXLYfxOMyhv%2FnPg8kZLpuvCNi3%2BArxtGfQ%2Fxa6hVyzXYHG4JNlzjX3Q%2BMk2SnXtALWw6%2FVYYBHXnU7gf75s%2BIM1ysd%2FtVby4csudTfNpudLHyMYB5QnKD4dB5ou6IsvIlWVnzYZoXXigcLoZhvAFLIp69dny17JbXGWsz8IchcMsZpyPiXV7RK6z0y8I1oPPgqTec330BZQaluObn59e4HjN3konjoHkJkssZ%2BAsiCXCF1tYQm0qvx5re1Ut8GWfzv2Q4UoFqiBBk3dTPQ%3D%3D",
            "price_config_hashkey": "v1-61dca45fc428b2c73b265bc16952c12b404b72fcd5bdccacecc24051b2fe3f08",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-0212520c4cd1ce71f3927b7b0b690b7f1bafd5537b936f2c5f7e7ea4c60956b1/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=YFE1om8du1XgaAsx8lxHLROZE9jg5qw8pIbd9mpI%2BMyyDBVr4g2gImmrApWA6NUQdU8VCC5qbU5sqerMaxV3duVv1OSlfPb79oVKUMVYg73K6fGV6B8A6qri88R%2FnT%2F8vSqidfLwNytXA8MfC6G%2B6GyaEs5NABThYWTRjjvsBz9v24877QFJiV%2BhymUebjILe9d4TkLs%2F7jlmUl1j3YTi9%2FI6c5VAgb6n4qdEI4NiKplmnxpnnooedSdtFJAuKfdM10QopgWfw94Uu%2BEwXzn7oWCwub%2FnRmJ2JDrw84C%2Fr0THuAKak%2FUoXSr8ZUGkny%2BEWbAzBIfXXsVztRnivA%2BUA%3D%3D",
            "price_config_hashkey": "v1-0212520c4cd1ce71f3927b7b0b690b7f1bafd5537b936f2c5f7e7ea4c60956b1",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-98c0957897368f278b2ad33fcc0970025d06ce1f28b78f8ed8b9f424d54bd964/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=oWeA6n6ECC5WpOl6qYTnD09U2aiRR%2BRCcj5y8g9FSU4EPLrLDDTtvMgxlYVDzdKxkI2dvYecyuS93r03VRZpbgUmOjVhN9BCnPb1S3Rw6LYUIJyFVOm39lGODXwzhI3ncS%2FFu71rDgrvvSywcS3domqyVKHTpgOQsx26tcVULEQH3cMPDJEyjnLX8vlK9rapWTOV3%2BJNjqzImonZtV8wDrogzKvTRhE%2F29kSQtyuPyejNYeYHoQnyp995XCcUhyKnqYIzH0M7zMHtwu8RHzB%2BWQk29w1RwNY%2FwkOQVycpXlRscSBLCb7Uz63nk1M8AtgBOe6KuBBpVeuJqIt1OHWWw%3D%3D",
            "price_config_hashkey": "v1-98c0957897368f278b2ad33fcc0970025d06ce1f28b78f8ed8b9f424d54bd964",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-03c1aefd27b2a963403b78d40d0a4eedcc1b9d24dcfca302e7a421b6488ba21f/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=OEt5vN%2BiwFrSnokulc5IOT9oOOatoj3AornvnB7r9UoY1IZ4gpxxJoBxXzW25YTxqBneRzpda5BEufiVyyU4xcSAGizzUIN1GWZd6fPQ2mQhKtr43HScb6CV3fzEi39k%2BKq6ZmffspMpKr5RNklSA9E6wTIQ6zYLAMPcD3LY42rv5qAY539Y49EMVhnCCa4FJraZQ5q5cKxVU3pS5k13PE%2BbP63ioOZV%2B1Yadlof41xlv3NAtkaYBxZmB7ZtAJ193ecsNf21QtzIIgMGZ4RJRVn4BqDEjzPlvOtKc3TmQnSz%2B0KeNTq05OfKrJ6Sg1fjpWPKJ3R7OhXFjQ1K7CnFBw%3D%3D",
            "price_config_hashkey": "v1-03c1aefd27b2a963403b78d40d0a4eedcc1b9d24dcfca302e7a421b6488ba21f",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-026bb9b53a55e73af9ef9e2880bc4467c7a0c98af9dc1fc15db09f1cd24deb6f/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=Mdcm38DWNnEUjYe4GpQ%2BsBLk7isrVUZxj5%2B22uCgjADCXzKrbdLP8TJb61pAcEDwU2SeNp%2F59PPNPMQO%2FNinwue%2BRhSqUlxv9dsVqtVBjIlXhndmLVs8CfJY407tYldJz4b2TTNWwkqwhwHxriIg%2BuYDjadvGsHAnpuvK4DhVpMc6SsqjFM9qNjeLoVZxHyRpBOM%2BHEc310AaytCjog5WHjyP9KG8I2YX%2FlPxL8xvFZxdjecz9X06s27NeXPqxJcZGqeK%2BHLGn0aeJQw9z4fG49vsVnv8Q1O1JL8PvrhMvOSx1tArRDYvjeTB%2FcE6adOD88ZaCw1DQaanSfzqJ7foQ%3D%3D",
            "price_config_hashkey": "v1-026bb9b53a55e73af9ef9e2880bc4467c7a0c98af9dc1fc15db09f1cd24deb6f",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-53d41dd717c9b578e96400f0861ddc5edf6d419ec5a2656367789e5e2e2dc5e9/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=Ijdkd6KeHsqmEwYCB8c29mgFNRaKgKhdOd%2FeKiaRNXa9sFzFEAhSksx6oOgL3D5sTi0s98ADlp4YS2iEXaflPD4HvC7sp4h4SYN1ZRwKvcUpcoqk%2Ba5mVYsxHrlCQ6QMjQtBvcBM1nknjFi7crCxsSJHpJp5hIvzVN4o1iD90AYIyyX7j0bh8piQjb%2FbmAqEGdHUTlSB24QEJkQ6kpHqc4gEzOfVjdccER5EzR5Gp7E991yn1R98AD3e7r75S4zw3iHU9o1DIFZFLvfWqJRfzPOa6cobGybXWifgp23%2BHicCkyD3nDQ%2FFqp3CJRIQtE0DEzSmxnHdGDXQr4qsbR1hg%3D%3D",
            "price_config_hashkey": "v1-53d41dd717c9b578e96400f0861ddc5edf6d419ec5a2656367789e5e2e2dc5e9",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-d3d4f801d4826c9b74db633c0d0a09a897896d4ebd713dc947058c609784ef3d/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=G6mf6e2Q12MeVlm6nWe0Da2yZ2YuZITPi1%2Fimu4V7NpA%2BaJZ5qkxahcZgk8YKbTPj2FDmYG8wSAkDsUxMsCRFlqTW7Sb%2F2fh9mV4I9Sll%2FiUMEREcoRWKJRuriGtqmEjwo%2B%2Bg8blUT7mdco5%2BTNzEWxJNEZkXp4GMrQratazhmM5lTZGG1Dx%2BUHn5EzdvNrt6GC4zym9MMGhvj%2BgXCzIiKm4gbYatI7HqSYzNLWtjvCdGOcYplBWeERuHrKg1w4iGeT3JS16qOt3fSlim5b4OwC15MI1jgZ9dk8CFbFRY9cur%2F2Fl151w0A3jo6gj54w9h9D0oIw1XlCcUmRrJ1ECw%3D%3D",
            "price_config_hashkey": "v1-d3d4f801d4826c9b74db633c0d0a09a897896d4ebd713dc947058c609784ef3d",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-d3b12ced8683aade1335f8a097036e4c3dee1290bce7076984d5a39c2121d38b/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=SuhjAIGUPQszN6H%2BTei2TDMxhx48tH4x6fHwnetZCelCdwViSS47bAf8oZU4fGtU5Pdoi8hDmoOsc8FdbRi0UgOj0D4J86DstDlYXmDmXsH6LMj%2FUFSwLeUfT5czd5lP7Gvs%2BdnleHAimGHsO6vA%2FCciU7pXjZF1dw1iOoyAf4jMPd6%2FAGPAhNhOflu16pP2v8iPka42kLKC7KV19K7%2F96LuK%2FGCtx4oHLmoojxZMBeawiZJMCNVzzE5U0j2%2F6RBTWfenwGRfcKOMkV%2BQVhMeFptiW1b5rNMmFLNsxvAwzQ94VdB0M69jYjVj0I8QJ8o6spsFGQMnQt7Q91lg9IKiQ%3D%3D",
            "price_config_hashkey": "v1-d3b12ced8683aade1335f8a097036e4c3dee1290bce7076984d5a39c2121d38b",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-023eda161aefb2888b6420816df84b19e2fc88538db4b1b5831a65aa835d41ae/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=fCNdsPKrv2vXdG69d%2FVcj99e59KlBG%2BIKTDCu6pdrdlEfrHmE2C0u3P9WdveTp46L8s4fxl9cncg9%2FPugvULVhjl7NlJRXurYQKECmbt4T%2BwarZJL%2B1Ce6x3XhI%2FxfKR%2FlepbhVVH94OtM%2FtprCgTTSw3UolrBV6uDjFsgBIGwa3FVHnF%2B%2B7QOsqzWSBzx2rE%2Bs6e8YnAt8q6wSgmxMlYtdrc%2F%2FjXfgaEqJBh6Zyv50dpYOpkPNVWUVxiv6lT8A2HVARTVZvGKYzIGThZGyLdQz4gJVISqQSThNUlOr3ZjaWyYVwMWi4hBrGgkEMQmKPogQE1awL8SIXCesNYVmtTQ%3D%3D",
            "price_config_hashkey": "v1-023eda161aefb2888b6420816df84b19e2fc88538db4b1b5831a65aa835d41ae",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-976ec3504ddc43c01f840ae9da1ec89ecad6b38fe53d976fa599eac85a369c0d/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=Z2j1cmg5xRLas2JYnm%2FzIa8lEZ2LcoGj9tBLVWJ2l7VcVD2FBwgdJymyfMNvjNY0IA2bPhPskv9tmGJrPFnpA%2FD6TrcpCHsXvloBSw3yv4DIDWgv%2Bj9lk7bI96It9FvZ%2F%2FrrEiJqH2YoGWOrqT545h3sgi8bxsu6ckI8OZs%2FOdNkSJu5h0HIw8rI%2BfYTJ7k0CWUbnLo5g2jDayfEfoA7%2Ble9%2FpILalLRXKFi9zyD2kJ%2Fahnev1KHitw7tI7W9pOGPn66PdHbn7rCglh7wmzcYdPssehsZ4yU0xOJG3l0ZrEcvJEoT%2B6k3v8O9qQr0ZpdSo4drPJrZiZwNDxWVk4vOA%3D%3D",
            "price_config_hashkey": "v1-976ec3504ddc43c01f840ae9da1ec89ecad6b38fe53d976fa599eac85a369c0d",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-3fb36225004df1f509be52e0f5f14780e3b12ed60b0f218033af99135917acbd/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=RbR8svLxEXcZB7VGppZ8SrkmIA3x08hH2gCFjr4YEF4RArC1tFIbTwVQXvudjTOwuzdpO5FwO%2FaBO5BAIDNIk3V8V%2FABQCJoQujtTK6vJ2RATNq6pP8XTqQrSFLluGgUt2mltV8VKClO1z%2FaC0TGwxoRmjGlM%2BvOj%2BMTuMzu0T4UEtx2NUDGyie1KasdA0ovd5xv%2FFvLrVTE%2F%2FtMF9Yh7BRAEsuvsbT44xE9ZTUa1qK0k8XYHtZ%2B8BS13HGNy9r4sSBRnQbUOhkhwTi6%2Bvdcj0Y2KYebHUPNEWmKbbTbfaRXlgIDcVZ1FLzkV%2BCv%2F42ObNNPiavV3h6ikGoxaG2RkQ%3D%3D",
            "price_config_hashkey": "v1-3fb36225004df1f509be52e0f5f14780e3b12ed60b0f218033af99135917acbd",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-30e01a18cac01b42129d4d29bad3d99953b380258a25374d3f2e341476f8e870/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=nXTFcMoqVpGjIYRhTZHMWpVokc4HgQ6BgceoFHlKM%2FOzUQNUymhVsjD5LOTxgeNAA%2B4h1%2BIi0GpMPMmilUdA9eTr3mKRbgpoL2RU%2BXHwnHAyEyR6wgQqCMv4kMK%2BU0XS4PyNix82Bwu7xrYkUTufxs%2FJ%2F5ixdfO3enHUERyMCsRIUeP1uezt4g11X4EycKB3hOPJ%2FfvPY2as84LBYB03k3eOJK3K%2FZs8js0MID6xvqnLzqCe89SH7X2dkwEKGN%2B%2FKuSNNWOnwIkaxGvKZU5cA%2B319lDKbIFbpLTD2oL%2BLbhVyja7kWJ0GxVCo4vrcVTk0eIvzrkrC32Joqrp6EKDyw%3D%3D",
            "price_config_hashkey": "v1-30e01a18cac01b42129d4d29bad3d99953b380258a25374d3f2e341476f8e870",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-6860e32f77b385dc904ac7c44494d77deb76692cf0a2e2693963fa650087ecde/result.json?GoogleAccessId=681889165978-compute%40developer.gserviceaccount.com&Expires=1814686484&Signature=QwSwvXwAuKRqPmLU9FRYNqskW%2FbVB8HuX%2Fd1IedW3dF2O%2Bnw3UJ4SZ0bnL24u9Yo0tFU4%2FCL0owbqbL7vxlvYPoYNNn2Sdt58%2Fkp2LYoJKpid0SQn731IARMMq0%2FBmkSnsU%2Fr2Uf4VXIFi1FIe3K9BhTG3gLl9HNSLnFKGg3SA4icB9YxFEa1bvVjGKvONf3Eh65gWS7XtVpbNosK6psMVN3cukE9LlQjzo5nCTY0NJnffTL5NxnoopI95nxJ%2FaisxcHkA2pasG2WTSyarprjz%2FztgRUEg1LGgObWE9BGVS8ZVG7zQ5byX2y%2Bo3EoHqklnNDDlTPWPai5cx0QOoswQ%3D%3D",
            "price_config_hashkey": "v1-6860e32f77b385dc904ac7c44494d77deb76692cf0a2e2693963fa650087ecde",
            "asset_options": [
                {
                    "options": {
                        "color": "#000000"
                    },
                    "symbol": [
                        "ETH",
                        "USDT"
                    ]
                }
            ]
        }
    ],
    "selectedMarketScenarios": [
        {
            "icon": {
                "key": null,
                "ref": null,
                "type": {
                    "type": {},
                    "compare": null
                },
                "props": {
                    "style": {
                        "fontSize": "16px"
                    }
                },
                "_owner": null,
                "_store": {}
            },
            "type": "rangebound",
            "color": "#000000",
            "shape": "sinusoidal",
            "title": "Rangebound",
            "selected": true,
            "maxVolatility": 50,
            "minVolatility": 0.5,
            "marketDriftMode": "ranging",
            "volatilityMultiplier": 10
        },
        {
            "icon": {
                "key": null,
                "ref": null,
                "type": {
                    "type": {},
                    "compare": null
                },
                "props": {
                    "style": {
                        "fontSize": "16px"
                    }
                },
                "_owner": null,
                "_store": {}
            },
            "type": "downtrend",
            "color": "#EF8F3B",
            "drift": [
                -1.8,
                -1.6
            ],
            "shape": "linear",
            "title": "Downtrend",
            "maxDrift": -1,
            "minDrift": -3,
            "selected": false,
            "maxVolatility": 1,
            "minVolatility": 0.01,
            "marketDriftMode": "linear",
            "volatilityMultiplier": 0.2
        },
        {
            "icon": {
                "key": null,
                "ref": null,
                "type": {
                    "type": {},
                    "compare": null
                },
                "props": {
                    "style": {
                        "fontSize": "16px"
                    }
                },
                "_owner": null,
                "_store": {}
            },
            "type": "uptrend",
            "color": "#4D5B9E",
            "drift": [
                1,
                1.1
            ],
            "shape": "linear",
            "title": "Uptrend",
            "maxDrift": 2,
            "minDrift": 0.5,
            "selected": false,
            "maxVolatility": 1,
            "minVolatility": 0.01,
            "marketDriftMode": "linear",
            "volatilityMultiplier": 0.2
        }
    ],
    "priceScenarioChartOptions": {
        "scales": {
            "x": {
                "grid": {
                    "display": false
                },
                "title": {
                    "display": true
                },
                "display": true
            },
            "y": {
                "grid": {
                    "display": false
                },
                "title": {
                    "font": {
                        "size": 14,
                        "weight": "bold"
                    },
                    "text": "Price",
                    "display": true
                },
                "display": true,
                "position": "right"
            }
        },
        "parsing": false,
        "plugins": {
            "legend": {
                "display": false
            },
            "tooltip": {
                "enabled": false
            },
            "annotation": {
                "annotations": [
                    {
                        "type": "line",
                        "value": 63,
                        "scaleID": "x",
                        "borderColor": "#000000",
                        "borderWidth": 1
                    }
                ]
            }
        },
        "elements": {
            "line": {
                "borderWidth": 1
            },
            "point": {
                "display": false,
                "borderWidth": 0,
                "backgroundColor": "transparent",
                "hoverBorderWidth": 0
            }
        },
        "animation": false,
        "interaction": {
            "intersect": false
        }
    },
    "selectedTradingStrategies": [
        "fixed"
    ]
}
export const arakisManagerTemplateFrontendState = {
    "block": 16949709,
    "steps": 312,
    "title": "Arrakis Template",
    "token0": "ETH",
    "token1": "USDT",
    "feeTier": 5000,
    "network": "Ethereum",
    "exchange": "Uniswap",
    "assetPair": [],
    "end_block": 17040889,
    "activeStep": 0,
    "granularity": 1,
    "start_block": 16949709,
    "agentConfigs": [{
        "id": "trader-univ3-trend-14j2oh",
        "type": "trader",
        "alias": "trader",
        "class": "trader-univ3-trend",
        "address": "0x662067f110C2C05C65427b359aCf066FdB7B1fcb",
        "holdings": [{"mint": true, "token": "ETH", "amount": "10000000000000000000"}, {
            "mint": true,
            "token": "WETH",
            "amount": "10000000000000000000"
        }, {"mint": true, "token": "USDT", "amount": "10000000000"}, {
            "mint": true,
            "token": "WBTC",
            "amount": "1000000000"
        }],
        "settings": {
            "cex_price_token0": "ETH",
            "cex_price_token1": "USDT",
            "min_eth_in_balance": 0.0010,
            "uniswapv3_pool_fee": 500,
            "max_fraction_to_trade": 0.9,
            "min_fraction_to_trade": 0.1,
            "uniswapv3_pool_token0": "WETH",
            "uniswapv3_pool_token1": "USDT",
            "total_deposit_token1": 1000000000
        }
    }, {
        "id": "arber-cex-dex-cyclic-zrt3ze",
        "type": "arb",
        "alias": "arber",
        "class": "arber-cex-dex-cyclic",
        "address": "0xFc1C34a9FEb232358E8F26289eE01253b059f304",
        "holdings": [{"mint": true, "token": "ETH", "amount": "10000000000000000000"}, {
            "mint": true,
            "token": "WETH",
            "amount": "10000000000000000000"
        }, {"mint": true, "token": "USDT", "amount": "10000000000"}, {
            "mint": true,
            "token": "WBTC",
            "amount": "1000000000"
        }],
        "settings": {
            "cex_trade_fee": 0.0010,
            "volume_buffer": 0.9,
            "volume_to_check": [0.3, 0.6, 0.9],
            "cex_price_token0": "ETH",
            "cex_price_token1": "USDT",
            "uniswapv3_pool_fee": 500,
            "uniswapv3_pool_token0": "WETH",
            "uniswapv3_pool_token1": "USDT"
        }
    }, {
        "id": "trader-univ3-trend-model-midpo8",
        "type": "trader",
        "alias": "trader-model",
        "class": "trader-univ3-trend-model",
        "address": "0x1B4126370F3820D4Ee4004371248A1c21173c889",
        "holdings": [{"mint": true, "token": "ETH", "amount": "10000000000000000000"}, {
            "mint": true,
            "token": "WETH",
            "amount": "10000000000000000000"
        }, {"mint": true, "token": "USDT", "amount": "10000000000"}, {
            "mint": true,
            "token": "WBTC",
            "amount": "1000000000"
        }],
        "settings": {
            "model_1_alias": "trader-trend-following",
            "cex_price_token0": "ETH",
            "cex_price_token1": "USDT",
            "min_eth_in_balance": 0.0010,
            "uniswapv3_pool_fee": 500,
            "max_fraction_to_trade": 0.9,
            "min_fraction_to_trade": 0.1,
            "uniswapv3_pool_token0": "WETH",
            "uniswapv3_pool_token1": "USDT",
            "total_deposit_token1": 1000000000
        }
    }, {
        "id": "lp-univ3-dynamic-xvb69g",
        "type": "arrakisManager",
        "alias": "arrakis-manager",
        "class": "manager-arrakisv2-dynamic",
        "address": "0x62A7FA91d283A3af35df9ADD9A901cC61b391E35",
        "holdings": [{"mint": true, "token": "ETH", "amount": "10000000000000000000"}, {
            "mint": true,
            "token": "WETH",
            "amount": "10000000000000000000"
        }, {"mint": true, "token": "USDT", "amount": "10000000000"}, {
            "mint": true,
            "token": "WBTC",
            "amount": "1000000000"
        }],
        "settings": {
            "dynamic_strat": true,
            "lookback_period": 3600,
            "vault_fee_tiers": "[500]",
            "always_rebalance": false,
            "cex_price_token0": "ETH",
            "cex_price_token1": "USDT",
            "volatility_scalar": 2,
            "fund_vault_on_init": true,
            "uniswapv3_pool_fee": 500,
            "vault_manager_fees": 0,
            "vault_ratio_token0": 0,
            "vault_ratio_token1": 0,
            "create_vault_on_init": true,
            "open_position_on_init": true,
            "uniswapv3_pool_token0": "WETH",
            "uniswapv3_pool_token1": "USDT",
            "total_deposit_token1": 5000000000
        }
    }],
    "priceConfigs": ["v1-64feed00403147599e57b0ebb0b0cc93bdca85aab7395f0a1fdafb44096065ab", "v1-32a64cea351e504471fa5596b3135ae22531b9bddbddcba40a6e49e05ac59231", "v1-b58f5d37a17f463a3568bf50acbc63fbf6c4855e7d2986285e082549282a2bb4", "v1-85f8dbd28f14b90fdd16343c8a7d80c5f5ce2a7041ea28fc0108ea2120efc6e2", "v1-ac635773568f3e659b8761db0b24ca448082681c86835b31b37fc46f0dc97070", "v1-9a81fd85a401a6617fef8613ed3fd220f5e9b6264bd2f5fa558b5cd26432b4ed", "v1-56bf2e7e2bb0786152d990d61d0d9773b3fd04e934c1d1683b2da24bd6c6de94", "v1-54f46ce2a962339c3e055c46a224e5451beaeee5570fce1c857359618282626d", "v1-200739e1700f81a5d5c5a6b7442caa5e24bd6c2d0e8904bc2394809c0513031c", "v1-f5f2372d9ded1a97d70c8acb16a12c57edaaea03ee9580f29c9576c011ae7eda", "v1-df829052fef7cfe76b1159787a9804628f9a3a1fb4556d910ef6b848353250fc", "v1-f1cc235792b69563ccb64cc204a12d3f1d5f353bf41307f2b1b7e819b225319a", "v1-8abb497153a91b0ff531e5a9aa64a96fc72a5f4c1cedb116015bf12d02b21feb", "v1-525ce611935e81eb914eae79bfdb681e8cf2f0f2857684890bfd76d7582e493d", "v1-c47ea0f1fe150cbdabad21a3512dfa5d21741646820ac2e9fae9baca200aae8d"],
    "depositAmount": 10000,
    "endPriceRange": 2000,
    "historical_end": "2023-03-31T21:00:00.000Z",
    "simulation_end": "2023-04-13T21:00:00.000Z",
    "granularitySize": "hour",
    "startPriceRange": 900,
    "historical_start": "2023-03-10T22:00:00.000Z",
    "simulation_start": "2023-03-31T21:00:00.000Z",
    "priceTrajectories": 5,
    "selectedAgentConfigs": [{
        "id": "trader-univ3-trend-14j2oh",
        "type": "trader",
        "alias": "trader",
        "class": "trader-univ3-trend",
        "address": "0x662067f110C2C05C65427b359aCf066FdB7B1fcb",
        "holdings": [{"mint": true, "token": "ETH", "amount": "10000000000000000000"}, {
            "mint": true,
            "token": "WETH",
            "amount": "10000000000000000000"
        }, {"mint": true, "token": "USDT", "amount": "10000000000"}, {
            "mint": true,
            "token": "WBTC",
            "amount": "1000000000"
        }],
        "settings": {
            "cex_price_token0": "ETH",
            "cex_price_token1": "USDT",
            "min_eth_in_balance": 0.0010,
            "uniswapv3_pool_fee": 500,
            "max_fraction_to_trade": 0.9,
            "min_fraction_to_trade": 0.1,
            "uniswapv3_pool_token0": "WETH",
            "uniswapv3_pool_token1": "USDT",
            "total_deposit_token1": 1000000000
        }
    }, {
        "id": "arber-cex-dex-cyclic-zrt3ze",
        "type": "arb",
        "alias": "arber",
        "class": "arber-cex-dex-cyclic",
        "address": "0xFc1C34a9FEb232358E8F26289eE01253b059f304",
        "holdings": [{"mint": true, "token": "ETH", "amount": "10000000000000000000"}, {
            "mint": true,
            "token": "WETH",
            "amount": "10000000000000000000"
        }, {"mint": true, "token": "USDT", "amount": "10000000000"}, {
            "mint": true,
            "token": "WBTC",
            "amount": "1000000000"
        }],
        "settings": {
            "cex_trade_fee": 0.0010,
            "volume_buffer": 0.9,
            "volume_to_check": [0.3, 0.6, 0.9],
            "cex_price_token0": "ETH",
            "cex_price_token1": "USDT",
            "uniswapv3_pool_fee": 500,
            "uniswapv3_pool_token0": "WETH",
            "uniswapv3_pool_token1": "USDT"
        }
    }, {
        "id": "trader-univ3-trend-model-midpo8",
        "type": "trader",
        "alias": "trader-model",
        "class": "trader-univ3-trend-model",
        "address": "0x1B4126370F3820D4Ee4004371248A1c21173c889",
        "holdings": [{"mint": true, "token": "ETH", "amount": "10000000000000000000"}, {
            "mint": true,
            "token": "WETH",
            "amount": "10000000000000000000"
        }, {"mint": true, "token": "USDT", "amount": "10000000000"}, {
            "mint": true,
            "token": "WBTC",
            "amount": "1000000000"
        }],
        "settings": {
            "model_1_alias": "trader-trend-following",
            "cex_price_token0": "ETH",
            "cex_price_token1": "USDT",
            "min_eth_in_balance": 0.0010,
            "uniswapv3_pool_fee": 500,
            "max_fraction_to_trade": 0.9,
            "min_fraction_to_trade": 0.1,
            "uniswapv3_pool_token0": "WETH",
            "uniswapv3_pool_token1": "USDT",
            "total_deposit_token1": 1000000000
        }
    }, {
        "id": "lp-univ3-dynamic-xvb69g",
        "type": "arrakisManager",
        "alias": "arrakis-manager",
        "class": "manager-arrakisv2-dynamic",
        "address": "0x62A7FA91d283A3af35df9ADD9A901cC61b391E35",
        "holdings": [{"mint": true, "token": "ETH", "amount": "10000000000000000000"}, {
            "mint": true,
            "token": "WETH",
            "amount": "10000000000000000000"
        }, {"mint": true, "token": "USDT", "amount": "10000000000"}, {
            "mint": true,
            "token": "WBTC",
            "amount": "1000000000"
        }],
        "settings": {
            "dynamic_strat": true,
            "lookback_period": 3600,
            "vault_fee_tiers": "[500]",
            "always_rebalance": false,
            "cex_price_token0": "ETH",
            "cex_price_token1": "USDT",
            "volatility_scalar": 2,
            "fund_vault_on_init": true,
            "uniswapv3_pool_fee": 500,
            "vault_manager_fees": 0,
            "vault_ratio_token0": 0,
            "vault_ratio_token1": 0,
            "create_vault_on_init": true,
            "open_position_on_init": true,
            "uniswapv3_pool_token0": "WETH",
            "uniswapv3_pool_token1": "USDT",
            "total_deposit_token1": 5000000000
        }
    }],
    "priceTrajectoryResults": [
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-64feed00403147599e57b0ebb0b0cc93bdca85aab7395f0a1fdafb44096065ab/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=X%2FYou1tp8FSExrBx8whgHTeN4uuMt0lLPGn8hrRypOh%2B4fz%2FLgsa6SbTgADvCLhdIWtaaVb5jeZDGMl9S4fEHryi5W0ILzkJYpYR4a%2FXyP%2B1ZV18d%2BT1mKIxS1tJHXmBhR7RWEV0on2NPfikGu60%2FXu87ITNeHtodU4mZoGzK95%2BKhxx3ltPYm8CujQ5jolNX7q%2FFXntr7vb%2FzSY2EsUnf69mh2YN%2FCcLIltsABOIjsQx22MZbNXDGy3gSrYv8hqFuM9gEYQWycsHjdZ9CPECaAVEwZMr4JqxQ2ciRggsj5i2VXe1Gck5Q9jhFDFHylc7q%2BGpuum7tizQLYUn20cDg%3D%3D",
            "options": {
                "color": "#000000"
            },
            "price_config_hashkey": "v1-64feed00403147599e57b0ebb0b0cc93bdca85aab7395f0a1fdafb44096065ab"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-32a64cea351e504471fa5596b3135ae22531b9bddbddcba40a6e49e05ac59231/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=jKglYm1qKK9%2B1gwOA80oQkZSt6GSsKR6IowGPRQRo%2BVC%2FNfthP1Q7Dhm6Oy0QbGW0yq5RvkQXbA7EXkAMQ2eWsLppWt0cdz0Cg6jzVcoBykyYqQs3NlhglWaS0%2BKOLSDxlTafH9yOsY6kVW38rT1z5o%2FOXdvf5TZTU7LikT1DEC3CFq%2F7AiO6jj0oo2p46oOOt5aUQoDUJMszprsKZmWdLJkitGJWw69AHf87I3g%2FkJeCsDXwiBqABGTyrFYlr1NwTsEiOsp%2FBjLyiD3tqrO%2BImSwLvgpQ7mvMfGOrWBwLgQ4wrlbbBqiYKSEfOj0QiEAf6lrEQslRKApNcmU7ECrQ%3D%3D",
            "options": {
                "color": "#000000"
            },
            "price_config_hashkey": "v1-32a64cea351e504471fa5596b3135ae22531b9bddbddcba40a6e49e05ac59231"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-b58f5d37a17f463a3568bf50acbc63fbf6c4855e7d2986285e082549282a2bb4/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=Qrb1Jwa7zVp2AESdzIEgfMZN68U%2BB3amEKlcRdYU0PTSCJ4ZhqTzaINYzq2OILvaW7fQcQgxYHyNT26Z5TA%2FgfpOiH6u82WTc5nbnyxI7aF%2FVCjGKsoNB%2FhbCEFUBgxVie38t%2FwVXTJJwIkO0kbwtkjfrNLVxuUH12p%2Buc257791y%2B%2BD34%2BuHoVqv3Et7F3HHjnf4QglTXDVVofIw5vf%2BzGW8ejcDI1BzGD%2F5QLOs67ALESAlL7LHBA%2B4oAKgDEhPSy3YcHGYyPGgeedlD%2BoX1v1meiSrRXva%2BCBqIkg8ii2iylAnrzpfg27PPxiQLwOymUeX5eJohV5%2FckXnzc6lw%3D%3D",
            "options": {
                "color": "#000000"
            },
            "price_config_hashkey": "v1-b58f5d37a17f463a3568bf50acbc63fbf6c4855e7d2986285e082549282a2bb4"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-85f8dbd28f14b90fdd16343c8a7d80c5f5ce2a7041ea28fc0108ea2120efc6e2/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=BZ7Q5Dt%2B3eBL3PCJAldGu2EkIY0D4lBghhPv%2FhmSrIXOVHLlQ1Gf821GksWpQ1WzkeeL1TqpCLfYCDUmaI%2BZTa4luZDK%2BNT1hIKyPqgfXDpdUeNkc8xkQt39zvtJO3S1fu7H7sqnfB%2FlPMc0rTuBOQbDllYxSA0qOQ5y%2FKy3GAgZcXkelGwxVVNFyMAabk7txdLSbetG%2BLs2jIyMcCsNxx1XViUNSDQ8bfg6v%2FvNTzlep8QEqOe3cEOZRoBEvJ%2FmJt5eR3ldV3WtyWTYcrAAf2bRYlL8WVdTmz7qN%2FoAIPFkqccMrdB9aHaby2hvhkH6umoX%2B0pnI5sMQ4TMpSwIxw%3D%3D",
            "options": {
                "color": "#000000"
            },
            "price_config_hashkey": "v1-85f8dbd28f14b90fdd16343c8a7d80c5f5ce2a7041ea28fc0108ea2120efc6e2"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-ac635773568f3e659b8761db0b24ca448082681c86835b31b37fc46f0dc97070/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=IBYIhJhAGKnq5YDgG5vtOfxAJiFaxYGZ2hUQ5IXZy%2Ff4jR7WmUUgvCHT6dcHwlaIZA4MY0nXkQ5LW7vZAYDZaLryDYjFtUXgH9jXgo%2FBZPzQ4Skhg0z%2B5XlcXMdR9RCBWnFSmCujBSn0tN80NzZvar3wt4WWUC80TjRNqp8gca1%2FD3X3eUtaZvBdgH8iS%2B4vQgLKYBw1e6JjE1IXzKveVPuHSTT7dOqvMi4QyvRsKDgcdcDV3iMYneQlyaWCpWoDvK4O1cyubfPbTiDUm8MxYbIM%2Bk4xG%2FU2WocSyFkdtvRE5HgyjjHi3KL7GUnOMnbArYRJxPrit9yYYfGBKE8PWg%3D%3D",
            "options": {
                "color": "#000000"
            },
            "price_config_hashkey": "v1-ac635773568f3e659b8761db0b24ca448082681c86835b31b37fc46f0dc97070"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-9a81fd85a401a6617fef8613ed3fd220f5e9b6264bd2f5fa558b5cd26432b4ed/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=HFrCzC89sWk536SPqaTzcX0v6tkQxP2uHzlTncsDZZLR1sg98sCgJKal4daUq2nZK1FwWcM4xbeel80yweEyEpNBrGSXeGGG99f4jKYpwSkT0b72LcM5JiaI%2FI1%2FhLNgAz47biQquJzH%2BW93NcMH8WL%2BQ7fhNzgKKDq77kJ%2FewGsU9hKhWl4wrvfR%2Bu9OAAEoedDlGs4DmTfx4rXuYWYjZ92KnH1B9OUeV2eg6eCs0cwrO%2FNl9Md2UnH9ZiFFRuGUh7fUMPkjjrKdyBTp7keu3GRbMb8Phvqc8G6kW%2BEFbgK5IKtR3vNJJIretAnGy5vfgJ%2B0AVtSVi%2FaRjFs7hz4w%3D%3D",
            "options": {
                "color": "#EF8F3B"
            },
            "price_config_hashkey": "v1-9a81fd85a401a6617fef8613ed3fd220f5e9b6264bd2f5fa558b5cd26432b4ed"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-56bf2e7e2bb0786152d990d61d0d9773b3fd04e934c1d1683b2da24bd6c6de94/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=MsDeF%2BJ3ZFSra183FS8x9BId6Rd7QjHpmqirKqUEpqX%2F3GYymHbMbaTPT6yKNlsPFw%2FkO6mYv0RoBzkvygESFGInOBIKQNQFVlJyCxlN3i5PeyYSqflqNUQjYqJDIB3dSeICFWuQ%2Fz7NI1QTPGVh9RddmPunJRihuELe3Rz2era8btHWMYUffkjkXIx%2FJRAK6%2FF%2Fj3hvopEUZfXgQb8tJKRnF%2F713q7Kd0jjGFLXg7GbtTv5L3KqwK2TBW%2BpV644Ocwh3ays8QmSvQHIDtCmnBdzEKL0DgaV2X8jC%2BOtnkNA9HlCqtg9uDFEQlvt1zLWpjqn%2FvYHlW0r22Hv6Ntjpw%3D%3D",
            "options": {
                "color": "#EF8F3B"
            },
            "price_config_hashkey": "v1-56bf2e7e2bb0786152d990d61d0d9773b3fd04e934c1d1683b2da24bd6c6de94"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-54f46ce2a962339c3e055c46a224e5451beaeee5570fce1c857359618282626d/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=nlwV%2FUPYRlfFQe6CwoNlaustv%2FR7X%2F57UzB9ZtKnqeaGu4k9ZT4LIqr7qWGDwoDaGa5joA%2F52jfrmpfSi0P8K1qZrmxdKsu4AVZ4JOhh4FGoiW9KhGRH5PYwcVPO%2Fq9ebsMiksbN%2B4N6exz8Pbqvq8h2AiZNDXP9byh8lPjWEY%2FMCpwms0zLOocQhk6srneYfGawOx6R%2FfFQ6PnDj%2BhBPUX4B3FePksCZdNAbaDCeAoOK2r9Z6Ymr3yxFkcsazI9NJYEgzj740ZZlRH57wnDegnZjoQ%2BANhDoNI4%2BCcvErtGdFXsOKys3vfwnC8K6TkDY0Gy2n0YyEuDlkwkzspEZg%3D%3D",
            "options": {
                "color": "#EF8F3B"
            },
            "price_config_hashkey": "v1-54f46ce2a962339c3e055c46a224e5451beaeee5570fce1c857359618282626d"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-200739e1700f81a5d5c5a6b7442caa5e24bd6c2d0e8904bc2394809c0513031c/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=RYvJToljgOwRnQVKEuOH%2Bxj7b5qxbQIwRZE5ifr2%2BKj0JVScS0zEOiMemxYJaVcGwYSbvNCX2BQN7ug8oXE0M95nj%2B1%2BNHlNdnWkXsz47A0IkvHRCaKsHSUOZordusRp4TUowTwn6h1Y7sly0nVvbwTXkrzngDMS4iRexYWM3Os%2BR%2Fn02F1gUK3dcztSHSJA5rm6stVyVNp0vM2yI7%2BwwAHHye30OqBCkOYJP%2BZvdhSA8A%2BTUMRRQ79IsbV0CG4C%2FrcOuAo128ehBJbfO%2FNY5Ffz9dyQGT2vcyzRbla%2BY%2F5OvNg2JsKYIqCxBVS3C3xTRbEyh0SvMAc9oC56HyVVWw%3D%3D",
            "options": {
                "color": "#EF8F3B"
            },
            "price_config_hashkey": "v1-200739e1700f81a5d5c5a6b7442caa5e24bd6c2d0e8904bc2394809c0513031c"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-f5f2372d9ded1a97d70c8acb16a12c57edaaea03ee9580f29c9576c011ae7eda/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=ijq%2F%2B5I8QkDzLxaSwFEs2kWXdgbxLk9xTtEIz5rVMXuJuFHVkooI4fw6Fn%2BninBE4fbYCiksrAbVgO0P5Ir9cjBdFeVrlwA7EGnth3KRPi9mnb9o37P3gmF0kI9z4C%2Bgpes1X6ZXoYulXiAOJy8r6rjpV3GFL%2BSMHNuYQbFQsnvzdxtkzxhJ%2Btgqw2tmfeDIkSvyXxp%2F7%2F15bGDQ8m43ltuo1lfSq8T797M1MMLryNVgrfvmN7h0rkpQ9cm%2FIIs4VPzCVfWYY2qTui3z%2Bfr3Jv%2F%2F06ZMfXuSjk8Y1Y6YIg4DNDD8MWNmnEEYMVPxwGo9ERGjtD75iAsATuPUM1tSfg%3D%3D",
            "options": {
                "color": "#EF8F3B"
            },
            "price_config_hashkey": "v1-f5f2372d9ded1a97d70c8acb16a12c57edaaea03ee9580f29c9576c011ae7eda"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-df829052fef7cfe76b1159787a9804628f9a3a1fb4556d910ef6b848353250fc/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=o8szmyE1FVGJ4TKofMDMaWaK5sw6NdHVX2fNDe%2B%2FjbU7inzeADDi0wTWD3jl9m4pzyfK0S%2F8AvQpO7Vh19CBdz6vrGYSLn6IZ2u3SyABRW0Sf7JfZwEbSBufDPQruav%2F2Fh4Fe3zfHGvo6d3wACIqBfeWmyhQh8EHRuSFihqI%2B5Ru0dGTsBeVJS0UAR1vZ8ORGCSCQZGTb%2FBMeQr3htfBW128B2TLyf1wMH1VgslrxTOsX5HNluFUFIPu7cA3EFDF8RH71BzACaVHzf46STVWg55Tz%2FyRKmAp%2FRyfMMw8HOaFST%2BNWNUWTIsv%2BdIi36PV8F4rVevp%2BR8rpyg0EYHiQ%3D%3D",
            "options": {
                "color": "#4D5B9E"
            },
            "price_config_hashkey": "v1-df829052fef7cfe76b1159787a9804628f9a3a1fb4556d910ef6b848353250fc"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-f1cc235792b69563ccb64cc204a12d3f1d5f353bf41307f2b1b7e819b225319a/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=FhnpcmJjtArNABHQOhCUhkbsH7igqWoEhAfrL7UhcIlciNfjNJdUWyYhNPLKDUo7rrBi5xrnjVyBMbpQhHctc8kgloa3ngKR%2FYERY9ygfqHSpU6NtL0GhyT4%2BxKFxIHMMHxq02NwSYulQP6chLE156Xy%2BfizqoFWPlW0IuTDrBwdeZylOzGoC3cXSmzEqPSFkhbU7gVH2PEaywQ9swCNNUKnvDsuAM57abodfPeCM0fIonT2gXjREZrYxYNW8Fk7ve7nty%2F6E%2FpWGq3p3uMcmlYTfy%2B3IHrKmDxKYyZl4Uh456sSCKO%2F%2FbOvpJmfolToq2O%2BLvf13fpNJvn5Y50AeA%3D%3D",
            "options": {
                "color": "#4D5B9E"
            },
            "price_config_hashkey": "v1-f1cc235792b69563ccb64cc204a12d3f1d5f353bf41307f2b1b7e819b225319a"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-8abb497153a91b0ff531e5a9aa64a96fc72a5f4c1cedb116015bf12d02b21feb/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=poEbJVngVoH10XQuEueDRCBPeae8YI%2BkrG8JQXnHX%2FKq6c0MNjpDnZfMUWbSbBoycRwiVmjUlf8%2F2L%2FK01tZTVe3bvHqNtRuqumsBBbr%2FKp7r863AVleDtMmUSSNAy38Mcww455sNeqQOtdLc2mJzPM69aaT4sn1K%2FoxF5rTE3WdQW3%2FBXmSqHucZJB8xbOTtlyJev%2BVv5NVCb16n7w6P3Kos7KnIT%2BR1K0ymHOGEDER3JGyKT4SduRaWfejGMDgVMYmRQ7tnJgryo1Hgexjl78NZwyLCol5q1ETSTpRDKNrA0RWyIiG2izpT0OGGmZwuG%2BzC2V3%2Bp6q2gk0tl0LJg%3D%3D",
            "options": {
                "color": "#4D5B9E"
            },
            "price_config_hashkey": "v1-8abb497153a91b0ff531e5a9aa64a96fc72a5f4c1cedb116015bf12d02b21feb"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-525ce611935e81eb914eae79bfdb681e8cf2f0f2857684890bfd76d7582e493d/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=MWMzDM%2B5ANWuN319GYjiZqao5XZoNX2TYmTHIhpgHv5MKGZ30PgzjAexkQqiHfmU0%2B%2BUklP8JQ3CEOgigq1iBED%2FktsSl2vZoY%2FURNUQUn2myE9iOTWM%2FAhMMUhsLi8iesMOGC8j5UXnKmJ0Wby19qt%2BdE13w87BEjV0eNGPUljm6Nf3U9AsmUa9M14FhVL0Ceyoo83gNW0bV8mTxzzcyyuFSGdL6ppqIh8RuDxfNmTgufW7fF9NSCk0X6j4wbp5EYbfUEmprmdXepAYt2Ji2KotgR5b4GskBB3KpPYDbg6Q6kT2Q2SZoVpkLFO%2BMlXwO179P%2FofRxHa2HYsb82aVA%3D%3D",
            "options": {
                "color": "#4D5B9E"
            },
            "price_config_hashkey": "v1-525ce611935e81eb914eae79bfdb681e8cf2f0f2857684890bfd76d7582e493d"
        },
        {
            "gcs_uri": "https://storage.googleapis.com/almanak-production-platform/v1-c47ea0f1fe150cbdabad21a3512dfa5d21741646820ac2e9fae9baca200aae8d/result.json?GoogleAccessId=278590626110-compute%40developer.gserviceaccount.com&Expires=1761484475&Signature=jSqs8w2w%2B5l9PzYVzOGNabICTUtChFinRC%2BavVpMYP9ACrSZazRHxPjwIPSAdrGF5VTNlZab9%2FuNCrjb6HzF%2Fja%2B5tKqenbSu6aElFUXK53XJN8uyPh1tyl8EYEcoHEtpF%2FX1pseUDKKF%2BUgaqxX6n5Mrzydr8z5k9OJSywPA3UYaBlUENurKiIwgCKpWeWZ%2Bfx2smkwPsEuKJfG5bZpVKienNKYvA0rZksu5fXYBJGnhz1z81gceFThanhVbp2BUm4B9D4cZ%2FIfde7JO%2FBm99wKGxe0pmMmXsiutETeNgGBUyjATFc%2B5nFyBqFt742pYtf7yPRynS2%2FbhSEYoN1Fg%3D%3D",
            "options": {
                "color": "#4D5B9E"
            },
            "price_config_hashkey": "v1-c47ea0f1fe150cbdabad21a3512dfa5d21741646820ac2e9fae9baca200aae8d"
        }
    ],
    "selectedMarketScenarios": [{
        "icon": {
            "key": null,
            "ref": null,
            "type": {"type": {}, "compare": null},
            "props": {"style": {"fontSize": "16px"}},
            "_owner": null,
            "_store": {}
        },
        "type": "rangebound",
        "color": "#000000",
        "shape": "sinusoidal",
        "title": "Rangebound",
        "selected": true,
        "maxVolatility": 50,
        "minVolatility": 0.5,
        "marketDriftMode": "ranging",
        "volatilityMultiplier": 10
    }, {
        "icon": {
            "key": null,
            "ref": null,
            "type": {"type": {}, "compare": null},
            "props": {"style": {"fontSize": "16px"}},
            "_owner": null,
            "_store": {}
        },
        "type": "downtrend",
        "color": "#EF8F3B",
        "drift": [-1.8, -1.6],
        "shape": "linear",
        "title": "Downtrend",
        "maxDrift": -1,
        "minDrift": -3,
        "selected": true,
        "maxVolatility": 1,
        "minVolatility": 0.010,
        "marketDriftMode": "linear",
        "volatilityMultiplier": 0.2
    }, {
        "icon": {
            "key": null,
            "ref": null,
            "type": {"type": {}, "compare": null},
            "props": {"style": {"fontSize": "16px"}},
            "_owner": null,
            "_store": {}
        },
        "type": "uptrend",
        "color": "#4D5B9E",
        "drift": [1, 1.1],
        "shape": "linear",
        "title": "Uptrend",
        "maxDrift": 2,
        "minDrift": 0.5,
        "selected": true,
        "maxVolatility": 1,
        "minVolatility": 0.010,
        "marketDriftMode": "linear",
        "volatilityMultiplier": 0.2
    }],
    "priceScenarioChartOptions": {
        "scales": {
            "x": {
                "grid": {"display": false},
                "title": {"display": true},
                "display": true
            },
            "y": {
                "grid": {"display": false},
                "title": {"font": {"size": 14, "weight": "bold"}, "text": "Price", "display": true},
                "display": true,
                "position": "right"
            }
        },
        "parsing": false,
        "plugins": {
            "legend": {"display": false},
            "tooltip": {"enabled": false},
            "annotation": {
                "annotations": [{
                    "type": "line",
                    "value": 63,
                    "scaleID": "x",
                    "borderColor": "#000000",
                    "borderWidth": 1
                }]
            }
        },
        "elements": {
            "line": {"borderWidth": 1},
            "point": {"display": false, "borderWidth": 0, "backgroundColor": "transparent", "hoverBorderWidth": 0}
        },
        "animation": false,
        "interaction": {"intersect": false}
    },
    "selectedTradingStrategies": ["fixed"]
};


export const feesChartMock = [
    {
        "date": 1681430400,
        "volume": "493649.411146348671804703",
        "open": "2012.66690071158488074229969269125",
        "close": "2105.416570799290010016397669760698",
        "untrackedVolumeUSD": "1035604271.369327877558683597683684",
        "priceUSD": "2105.416570799290010016397669760698"
    },
    {
        "date": 1681344000,
        "volume": "354018.482922659172957617",
        "open": "1923.630496306856976220295688841021",
        "close": "2012.66690071158488074229969269125",
        "untrackedVolumeUSD": "698274609.1476302240568242326083056",
        "priceUSD": "2012.66690071158488074229969269125"
    },
    {
        "date": 1681257600,
        "volume": "348718.710192034831913604",
        "open": "1891.80384652140225971547240814288",
        "close": "1923.630496306856976220295688841021",
        "untrackedVolumeUSD": "661225114.3722226875775242418148829",
        "priceUSD": "1923.630496306856976220295688841021"
    },
    {
        "date": 1681171200,
        "volume": "281907.759256582385026384",
        "open": "1912.513794073961978030063549401295",
        "close": "1891.80384652140225971547240814288",
        "untrackedVolumeUSD": "539234917.1690550951334650923959151",
        "priceUSD": "1891.80384652140225971547240814288"
    },
    {
        "date": 1681084800,
        "volume": "220091.174551583771026352",
        "open": "1863.160356657281537768066137359126",
        "close": "1912.513794073961978030063549401295",
        "untrackedVolumeUSD": "413497082.3747682770181475777194885",
        "priceUSD": "1912.513794073961978030063549401295"
    },
    {
        "date": 1680998400,
        "volume": "183377.010533141634178776",
        "open": "1853.838633116650036787389059651635",
        "close": "1863.160356657281537768066137359126",
        "untrackedVolumeUSD": "338844982.527842179017489089242307",
        "priceUSD": "1863.160356657281537768066137359126"
    },
    {
        "date": 1680912000,
        "volume": "108429.28407883106910033",
        "open": "1866.46005432968839853817765364473",
        "close": "1853.838633116650036787389059651635",
        "untrackedVolumeUSD": "202164376.2262135817113434175441218",
        "priceUSD": "1853.838633116650036787389059651635"
    },
    {
        "date": 1680825600,
        "volume": "166368.820769642741066585",
        "open": "1868.719690680695327851023151779256",
        "close": "1866.46005432968839853817765364473",
        "untrackedVolumeUSD": "309660268.0087563997994736590405859",
        "priceUSD": "1866.46005432968839853817765364473"
    },
    {
        "date": 1680739200,
        "volume": "273116.817077594514559876",
        "open": "1910.782285333076342877619662784159",
        "close": "1868.719690680695327851023151779256",
        "untrackedVolumeUSD": "512878896.3600543771802013202742195",
        "priceUSD": "1868.719690680695327851023151779256"
    },
    {
        "date": 1680652800,
        "volume": "355946.45853615472642261",
        "open": "1868.099819361958723342975611977774",
        "close": "1910.782285333076342877619662784159",
        "untrackedVolumeUSD": "678425087.0055135191734838510119929",
        "priceUSD": "1910.782285333076342877619662784159"
    },
    {
        "date": 1680566400,
        "volume": "359645.988066544627205763",
        "open": "1812.746274556003534881309556575416",
        "close": "1868.099819361958723342975611977774",
        "untrackedVolumeUSD": "666475246.0048482843062000436419235",
        "priceUSD": "1868.099819361958723342975611977774"
    },
    {
        "date": 1680480000,
        "volume": "417818.808379928638767192",
        "open": "1791.080164986100016896051437259299",
        "close": "1812.746274556003534881309556575416",
        "untrackedVolumeUSD": "751037336.8142011795782931707100776",
        "priceUSD": "1812.746274556003534881309556575416"
    },
    {
        "date": 1680393600,
        "volume": "205736.283659103990180867",
        "open": "1821.247793365411277690749943877697",
        "close": "1791.080164986100016896051437259299",
        "untrackedVolumeUSD": "370957956.3838924234105646833222452",
        "priceUSD": "1791.080164986100016896051437259299"
    },
    {
        "date": 1680307200,
        "volume": "224054.57144943986889587",
        "open": "1824.470041002610326971472785919467",
        "close": "1821.247793365411277690749943877697",
        "untrackedVolumeUSD": "408420188.9044501579511605845047154",
        "priceUSD": "1821.247793365411277690749943877697"
    },
    {
        "date": 1680220800,
        "volume": "322941.34439841840759938",
        "open": "1789.555052757875246987588278784574",
        "close": "1824.470041002610326971472785919467",
        "untrackedVolumeUSD": "587314005.2451613005719923169818948",
        "priceUSD": "1824.470041002610326971472785919467"
    },
    {
        "date": 1680134400,
        "volume": "349311.038387045038813337",
        "open": "1792.512497969295447432797145617816",
        "close": "1789.555052757875246987588278784574",
        "untrackedVolumeUSD": "626469545.1350107039337262861984219",
        "priceUSD": "1789.555052757875246987588278784574"
    },
    {
        "date": 1680048000,
        "volume": "338394.192156840623492227",
        "open": "1773.163015611095064803421415265483",
        "close": "1792.512497969295447432797145617816",
        "untrackedVolumeUSD": "609674271.0910881825888814774982099",
        "priceUSD": "1792.512497969295447432797145617816"
    },
    {
        "date": 1679961600,
        "volume": "338270.279201283704780007",
        "open": "1711.941342472404142034627618096492",
        "close": "1773.163015611095064803421415265483",
        "untrackedVolumeUSD": "589590399.3466237557338434256949582",
        "priceUSD": "1773.163015611095064803421415265483"
    },
    {
        "date": 1679875200,
        "volume": "377897.82508833461890524",
        "open": "1778.393744144888451196475123346453",
        "close": "1711.941342472404142034627618096492",
        "untrackedVolumeUSD": "654507474.7605151772011250328102705",
        "priceUSD": "1711.941342472404142034627618096492"
    },
    {
        "date": 1679788800,
        "volume": "245580.64508237161898125",
        "open": "1739.485982883241573999626117763643",
        "close": "1778.393744144888451196475123346453",
        "untrackedVolumeUSD": "434203625.6040159637847627453507394",
        "priceUSD": "1778.393744144888451196475123346453"
    },
    {
        "date": 1679702400,
        "volume": "229302.97054413956242464",
        "open": "1750.23746407740148828949498444068",
        "close": "1739.485982883241573999626117763643",
        "untrackedVolumeUSD": "400167901.8461006515455936111104089",
        "priceUSD": "1739.485982883241573999626117763643"
    },
    {
        "date": 1679616000,
        "volume": "485870.988130640076027029",
        "open": "1817.419796415281933385453478015453",
        "close": "1750.23746407740148828949498444068",
        "untrackedVolumeUSD": "859001278.1628242748136289920188268",
        "priceUSD": "1750.23746407740148828949498444068"
    },
    {
        "date": 1679529600,
        "volume": "554985.073531052023485438",
        "open": "1735.923082512533607122017385845713",
        "close": "1817.419796415281933385453478015453",
        "untrackedVolumeUSD": "996118819.835712613428954754634217",
        "priceUSD": "1817.419796415281933385453478015453"
    },
    {
        "date": 1679443200,
        "volume": "729665.28782733121981034",
        "open": "1804.723853981992252187692033569856",
        "close": "1735.923082512533607122017385845713",
        "untrackedVolumeUSD": "1294446551.421710631391203726137905",
        "priceUSD": "1735.923082512533607122017385845713"
    },
    {
        "date": 1679356800,
        "volume": "540940.723476513988831421",
        "open": "1735.817452357071134573121537149779",
        "close": "1804.723853981992252187692033569856",
        "untrackedVolumeUSD": "964276508.2294305202299336807992134",
        "priceUSD": "1804.723853981992252187692033569856"
    },
    {
        "date": 1679270400,
        "volume": "734249.520178947518044631",
        "open": "1786.95045079608864954254118745312",
        "close": "1735.817452357071134573121537149779",
        "untrackedVolumeUSD": "1299627095.382668172970869111188593",
        "priceUSD": "1735.817452357071134573121537149779"
    },
    {
        "date": 1679184000,
        "volume": "531746.142975399702106044",
        "open": "1761.586594493817396948896028432956",
        "close": "1786.95045079608864954254118745312",
        "untrackedVolumeUSD": "957164811.5374785291812236600304793",
        "priceUSD": "1786.95045079608864954254118745312"
    },
    {
        "date": 1679097600,
        "volume": "708494.853591585965508285",
        "open": "1790.309779237911409051144753792634",
        "close": "1761.586594493817396948896028432956",
        "untrackedVolumeUSD": "1278628857.278912661387459923408279",
        "priceUSD": "1761.586594493817396948896028432956"
    },
    {
        "date": 1679011200,
        "volume": "856658.750459411581511196",
        "open": "1677.339241766784749106969941976656",
        "close": "1790.309779237911409051144753792634",
        "untrackedVolumeUSD": "1483427964.349996637248868060062701",
        "priceUSD": "1790.309779237911409051144753792634"
    },
    {
        "date": 1678924800,
        "volume": "464697.54935774384222595",
        "open": "1656.09455611491906115282755915722",
        "close": "1677.339241766784749106969941976656",
        "untrackedVolumeUSD": "771597006.4584665262158502160413315",
        "priceUSD": "1677.339241766784749106969941976656"
    },
    {
        "date": 1678838400,
        "volume": "1101849.563210910785008262",
        "open": "1707.740886404113486800093654603105",
        "close": "1656.09455611491906115282755915722",
        "untrackedVolumeUSD": "1838371342.464931617716169512065822",
        "priceUSD": "1656.09455611491906115282755915722"
    },
    {
        "date": 1678752000,
        "volume": "1371453.463863990299105012",
        "open": "1677.377799987160801693211600586151",
        "close": "1707.740886404113486800093654603105",
        "untrackedVolumeUSD": "2350853657.86304279115790301544161",
        "priceUSD": "1707.740886404113486800093654603105"
    },
    {
        "date": 1678665600,
        "volume": "1362569.768885819553279694",
        "open": "1604.08619646809384946590408502447",
        "close": "1677.377799987160801693211600586151",
        "untrackedVolumeUSD": "2233472662.619509240853259304606019",
        "priceUSD": "1677.377799987160801693211600586151"
    },
    {
        "date": 1678579200,
        "volume": "1260108.567188438545355273",
        "open": "1537.00693630001603225985404482192",
        "close": "1604.08619646809384946590408502447",
        "untrackedVolumeUSD": "1963592404.337166577984799420103568",
        "priceUSD": "1604.08619646809384946590408502447"
    },
    {
        "date": 1678492800,
        "volume": "1428107.419429952860277154",
        "open": "1438.952436182730183894397269785581",
        "close": "1537.00693630001603225985404482192",
        "untrackedVolumeUSD": "7174435766.092070300579681983784158",
        "priceUSD": "1537.00693630001603225985404482192"
    },
    {
        "date": 1678406400,
        "volume": "1424540.921841127999653614",
        "open": "1436.884234887294186231070231003034",
        "close": "1438.952436182730183894397269785581",
        "untrackedVolumeUSD": "2014172922.764809853986985245796694",
        "priceUSD": "1438.952436182730183894397269785581"
    },
    {
        "date": 1678320000,
        "volume": "635423.887058245996280666",
        "open": "1533.249931993276973292997119784472",
        "close": "1436.884234887294186231070231003034",
        "untrackedVolumeUSD": "948706276.5518931534394563223212729",
        "priceUSD": "1436.884234887294186231070231003034"
    },
    {
        "date": 1678233600,
        "volume": "446006.945899508747769025",
        "open": "1558.497352944871129604039531099086",
        "close": "1533.249931993276973292997119784472",
        "untrackedVolumeUSD": "692719162.7697509200956257997679637",
        "priceUSD": "1533.249931993276973292997119784472"
    },
    {
        "date": 1678147200,
        "volume": "433362.600019596727513632",
        "open": "1566.459158150707460972445779650172",
        "close": "1558.497352944871129604039531099086",
        "untrackedVolumeUSD": "674956563.4848943714188776842273242",
        "priceUSD": "1558.497352944871129604039531099086"
    },
    {
        "date": 1678060800,
        "volume": "312929.766195739740220035",
        "open": "1561.720646879236992011355277111412",
        "close": "1566.459158150707460972445779650172",
        "untrackedVolumeUSD": "489951349.2466272826655531369303763",
        "priceUSD": "1566.459158150707460972445779650172"
    },
    {
        "date": 1677974400,
        "volume": "344439.818975639268744491",
        "open": "1563.53440524256932244110818994215",
        "close": "1561.720646879236992011355277111412",
        "untrackedVolumeUSD": "540941368.3814594927745182103735407",
        "priceUSD": "1561.720646879236992011355277111412"
    },
    {
        "date": 1677888000,
        "volume": "245522.205685600966930899",
        "open": "1566.107746162059272364494064436453",
        "close": "1563.53440524256932244110818994215",
        "untrackedVolumeUSD": "384115265.339763336819052617588374",
        "priceUSD": "1563.53440524256932244110818994215"
    },
    {
        "date": 1677801600,
        "volume": "599341.147152078660091241",
        "open": "1649.462350134186807957625714581529",
        "close": "1566.107746162059272364494064436453",
        "untrackedVolumeUSD": "942062005.1617418138921093571715374",
        "priceUSD": "1566.107746162059272364494064436453"
    },
    {
        "date": 1677715200,
        "volume": "473225.829761970279748734",
        "open": "1661.767350687257586095407503050614",
        "close": "1649.462350134186807957625714581529",
        "untrackedVolumeUSD": "776079912.3306224969847512888810786",
        "priceUSD": "1649.462350134186807957625714581529"
    },
    {
        "date": 1677628800,
        "volume": "574801.244816350756223964",
        "open": "1607.235979674547156264623252823493",
        "close": "1661.767350687257586095407503050614",
        "untrackedVolumeUSD": "945888885.7883435881757268803837575",
        "priceUSD": "1661.767350687257586095407503050614"
    },
    {
        "date": 1677542400,
        "volume": "413195.982141877932972195",
        "open": "1630.946779267032425619365401824015",
        "close": "1607.235979674547156264623252823493",
        "untrackedVolumeUSD": "671891441.2501762126115958708916175",
        "priceUSD": "1607.235979674547156264623252823493"
    },
    {
        "date": 1677456000,
        "volume": "442185.715008110096563967",
        "open": "1638.150433543007982527812914529953",
        "close": "1630.946779267032425619365401824015",
        "untrackedVolumeUSD": "724319757.9584918271435199441516095",
        "priceUSD": "1630.946779267032425619365401824015"
    },
    {
        "date": 1677369600,
        "volume": "319144.832054008939623225",
        "open": "1592.311043490723346529806607209349",
        "close": "1638.150433543007982527812914529953",
        "untrackedVolumeUSD": "515161313.5165548106064452882822296",
        "priceUSD": "1638.150433543007982527812914529953"
    },
    {
        "date": 1677283200,
        "volume": "355977.295445490192061115",
        "open": "1606.873321450348252407526281830601",
        "close": "1592.311043490723346529806607209349",
        "untrackedVolumeUSD": "566391996.7633579820371139764300502",
        "priceUSD": "1592.311043490723346529806607209349"
    },
    {
        "date": 1677196800,
        "volume": "609409.298979400659668244",
        "open": "1646.6383932590925869668155200157",
        "close": "1606.873321450348252407526281830601",
        "untrackedVolumeUSD": "987865138.7088387517080023697520135",
        "priceUSD": "1606.873321450348252407526281830601"
    },
    {
        "date": 1677110400,
        "volume": "552608.120797425493157903",
        "open": "1641.386265676911620544371396274814",
        "close": "1646.6383932590925869668155200157",
        "untrackedVolumeUSD": "914933785.4633014300572090097507694",
        "priceUSD": "1646.6383932590925869668155200157"
    },
    {
        "date": 1677024000,
        "volume": "644663.343434712973908326",
        "open": "1655.306119564281285899801165671834",
        "close": "1641.386265676911620544371396274814",
        "untrackedVolumeUSD": "1051460733.428374277168080822337933",
        "priceUSD": "1641.386265676911620544371396274814"
    },
    {
        "date": 1676937600,
        "volume": "684179.415130536851513005",
        "open": "1698.580178697303768342312029684105",
        "close": "1655.306119564281285899801165671834",
        "untrackedVolumeUSD": "1146747038.277908349597392506608047",
        "priceUSD": "1655.306119564281285899801165671834"
    },
    {
        "date": 1676851200,
        "volume": "471621.861140514429680955",
        "open": "1683.050054302945418826008385671219",
        "close": "1698.580178697303768342312029684105",
        "untrackedVolumeUSD": "799438431.0102707894828125903523308",
        "priceUSD": "1698.580178697303768342312029684105"
    },
    {
        "date": 1676764800,
        "volume": "520180.811997188193455244",
        "open": "1688.793712939118325495588311145666",
        "close": "1683.050054302945418826008385671219",
        "untrackedVolumeUSD": "882239377.4158385995615530421738079",
        "priceUSD": "1683.050054302945418826008385671219"
    },
    {
        "date": 1676678400,
        "volume": "356043.728310635515479271",
        "open": "1695.292837289291138763088616878336",
        "close": "1688.793712939118325495588311145666",
        "untrackedVolumeUSD": "603690555.8817507087701118395684516",
        "priceUSD": "1688.793712939118325495588311145666"
    },
    {
        "date": 1676592000,
        "volume": "749697.675971211502744467",
        "open": "1639.213012957787044107372846767645",
        "close": "1695.292837289291138763088616878336",
        "untrackedVolumeUSD": "1257278337.845766464960087896477811",
        "priceUSD": "1695.292837289291138763088616878336"
    },
    {
        "date": 1676505600,
        "volume": "938323.649221964709739068",
        "open": "1673.691633412489551900627887896524",
        "close": "1639.213012957787044107372846767645",
        "untrackedVolumeUSD": "1585816908.951330024786519589200747",
        "priceUSD": "1639.213012957787044107372846767645"
    },
    {
        "date": 1676419200,
        "volume": "674214.753471232561736034",
        "open": "1557.554464799155679567032341719053",
        "close": "1673.691633412489551900627887896524",
        "untrackedVolumeUSD": "1079327210.512886689571084195660958",
        "priceUSD": "1673.691633412489551900627887896524"
    },
    {
        "date": 1676332800,
        "volume": "711895.942865130713487126",
        "open": "1506.463778481760965222698856303737",
        "close": "1557.554464799155679567032341719053",
        "untrackedVolumeUSD": "1090452491.058251811482113789228399",
        "priceUSD": "1557.554464799155679567032341719053"
    },
    {
        "date": 1676246400,
        "volume": "672892.817774172408761859",
        "open": "1513.823860482546752960554383857241",
        "close": "1506.463778481760965222698856303737",
        "untrackedVolumeUSD": "1004038978.919278300996062750605887",
        "priceUSD": "1506.463778481760965222698856303737"
    },
    {
        "date": 1676160000,
        "volume": "376573.138460598159895353",
        "open": "1539.418294281043509211422465264336",
        "close": "1513.823860482546752960554383857241",
        "untrackedVolumeUSD": "575318085.6823571928421607351568563",
        "priceUSD": "1513.823860482546752960554383857241"
    },
    {
        "date": 1676073600,
        "volume": "350117.193660385290693892",
        "open": "1513.53061205410133968769848186013",
        "close": "1539.418294281043509211422465264336",
        "untrackedVolumeUSD": "532895383.7526039760598817519531659",
        "priceUSD": "1539.418294281043509211422465264336"
    },
    {
        "date": 1675987200,
        "volume": "649126.496710991141991459",
        "open": "1545.390217171965255361909180137758",
        "close": "1513.53061205410133968769848186013",
        "untrackedVolumeUSD": "995640711.9285436541655211979309904",
        "priceUSD": "1513.53061205410133968769848186013"
    },
    {
        "date": 1675900800,
        "volume": "815489.42344054583022826",
        "open": "1648.43494908851061013444077720964",
        "close": "1545.390217171965255361909180137758",
        "untrackedVolumeUSD": "1305091280.575327615134505043835702",
        "priceUSD": "1545.390217171965255361909180137758"
    },
    {
        "date": 1675814400,
        "volume": "489612.817764520018103572",
        "open": "1671.954848940821098098684989757763",
        "close": "1648.43494908851061013444077720964",
        "untrackedVolumeUSD": "814027450.8840014037215106254055076",
        "priceUSD": "1648.43494908851061013444077720964"
    },
    {
        "date": 1675728000,
        "volume": "561736.520284762353939785",
        "open": "1612.988655183062547714979247687033",
        "close": "1671.954848940821098098684989757763",
        "untrackedVolumeUSD": "923526003.9979919557216048202331035",
        "priceUSD": "1671.954848940821098098684989757763"
    },
    {
        "date": 1675641600,
        "volume": "414616.306028438492392854",
        "open": "1632.524848737303164810744881528308",
        "close": "1612.988655183062547714979247687033",
        "untrackedVolumeUSD": "677741338.0619189967996547494151249",
        "priceUSD": "1612.988655183062547714979247687033"
    },
    {
        "date": 1675555200,
        "volume": "454508.223688181944380879",
        "open": "1664.822319026630533271014972347018",
        "close": "1632.524848737303164810744881528308",
        "untrackedVolumeUSD": "747238678.5254211533875765567974978",
        "priceUSD": "1632.524848737303164810744881528308"
    },
    {
        "date": 1675468800,
        "volume": "347580.906149166758606251",
        "open": "1664.896135488040345051365277143936",
        "close": "1664.822319026630533271014972347018",
        "untrackedVolumeUSD": "580633860.3203272749438863517212546",
        "priceUSD": "1664.822319026630533271014972347018"
    },
    {
        "date": 1675382400,
        "volume": "511232.522854478172144554",
        "open": "1643.965171515638679473327030709019",
        "close": "1664.896135488040345051365277143936",
        "untrackedVolumeUSD": "845031603.7347159125990653499769701",
        "priceUSD": "1664.896135488040345051365277143936"
    },
    {
        "date": 1675296000,
        "volume": "722912.594069627693674129",
        "open": "1642.407213601986373827245003334429",
        "close": "1643.965171515638679473327030709019",
        "untrackedVolumeUSD": "1206994827.489289506227512573238459",
        "priceUSD": "1643.965171515638679473327030709019"
    },
    {
        "date": 1675209600,
        "volume": "578676.715641080416870869",
        "open": "1586.724267051153424121673354385305",
        "close": "1642.407213601986373827245003334429",
        "untrackedVolumeUSD": "923761588.5947634151100201182122459",
        "priceUSD": "1642.407213601986373827245003334429"
    },
    {
        "date": 1675123200,
        "volume": "359623.930478426240743033",
        "open": "1563.443639670593290693367032796737",
        "close": "1586.724267051153424121673354385305",
        "untrackedVolumeUSD": "568653920.3772214056511204132625401",
        "priceUSD": "1586.724267051153424121673354385305"
    },
    {
        "date": 1675036800,
        "volume": "510896.365951565886884107",
        "open": "1646.712400754135044140242750364377",
        "close": "1563.443639670593290693367032796737",
        "untrackedVolumeUSD": "810858283.5448314055909302784802995",
        "priceUSD": "1563.443639670593290693367032796737"
    },
    {
        "date": 1674950400,
        "volume": "424253.136858413961000456",
        "open": "1569.237813178635386404705927172591",
        "close": "1646.712400754135044140242750364377",
        "untrackedVolumeUSD": "684738782.6008384908688961761426886",
        "priceUSD": "1646.712400754135044140242750364377"
    },
    {
        "date": 1674864000,
        "volume": "313985.018322918149058307",
        "open": "1594.582633603022788688822689590044",
        "close": "1569.237813178635386404705927172591",
        "untrackedVolumeUSD": "498186936.9504608800366929341909575",
        "priceUSD": "1569.237813178635386404705927172591"
    },
    {
        "date": 1674777600,
        "volume": "521407.515701662286022341",
        "open": "1603.295436855563260888623674967254",
        "close": "1594.582633603022788688822689590044",
        "untrackedVolumeUSD": "826842971.6242348935203198827081134",
        "priceUSD": "1594.582633603022788688822689590044"
    },
    {
        "date": 1674691200,
        "volume": "488521.915229850472576642",
        "open": "1618.411084858304210716432345124273",
        "close": "1603.295436855563260888623674967254",
        "untrackedVolumeUSD": "785386936.9248685465339444010212378",
        "priceUSD": "1603.295436855563260888623674967254"
    },
    {
        "date": 1674604800,
        "volume": "765534.753890314656432474",
        "open": "1551.669659048602680206152597538615",
        "close": "1618.411084858304210716432345124273",
        "untrackedVolumeUSD": "1204173836.17867300822157293368868",
        "priceUSD": "1618.411084858304210716432345124273"
    },
    {
        "date": 1674518400,
        "volume": "510807.600536000963109782",
        "open": "1628.734426119914479538993717916591",
        "close": "1551.669659048602680206152597538615",
        "untrackedVolumeUSD": "818244754.0661087593410148721589337",
        "priceUSD": "1551.669659048602680206152597538615"
    },
    {
        "date": 1674432000,
        "volume": "463150.637426717363544979",
        "open": "1627.68834868115402521765054717578",
        "close": "1628.734426119914479538993717916591",
        "untrackedVolumeUSD": "755263234.468207087828373525698932",
        "priceUSD": "1628.734426119914479538993717916591"
    },
    {
        "date": 1674345600,
        "volume": "438768.087924857768761264",
        "open": "1627.517080493400330008667508833769",
        "close": "1627.68834868115402521765054717578",
        "untrackedVolumeUSD": "715539390.38841616279725375150757",
        "priceUSD": "1627.68834868115402521765054717578"
    },
    {
        "date": 1674259200,
        "volume": "568410.41021231833195901",
        "open": "1657.759752408764281943596656998239",
        "close": "1627.517080493400330008667508833769",
        "untrackedVolumeUSD": "940784287.5784802241106958097516969",
        "priceUSD": "1627.517080493400330008667508833769"
    },
    {
        "date": 1674172800,
        "volume": "428950.398865509188353713",
        "open": "1550.601054458855875816859672128303",
        "close": "1657.759752408764281943596656998239",
        "untrackedVolumeUSD": "684330635.6604965775395842468101419",
        "priceUSD": "1657.759752408764281943596656998239"
    },
    {
        "date": 1674086400,
        "volume": "382845.369294376754315437",
        "open": "1512.690339106959587761422695071655",
        "close": "1550.601054458855875816859672128303",
        "untrackedVolumeUSD": "587260349.3079875709455621084675136",
        "priceUSD": "1550.601054458855875816859672128303"
    },
    {
        "date": 1674000000,
        "volume": "890134.65651355136636721",
        "open": "1569.217580068324378546807338807343",
        "close": "1512.690339106959587761422695071655",
        "untrackedVolumeUSD": "1382339328.551470154900946439612526",
        "priceUSD": "1512.690339106959587761422695071655"
    },
    {
        "date": 1673913600,
        "volume": "453091.522791037378090213",
        "open": "1579.010741975927715859483572703891",
        "close": "1569.217580068324378546807338807343",
        "untrackedVolumeUSD": "714419499.975277650668295063658543",
        "priceUSD": "1569.217580068324378546807338807343"
    },
    {
        "date": 1673827200,
        "volume": "592666.166224720147475518",
        "open": "1553.894080849592949568154315393411",
        "close": "1579.010741975927715859483572703891",
        "untrackedVolumeUSD": "927272685.9872079161375689641743656",
        "priceUSD": "1579.010741975927715859483572703891"
    },
    {
        "date": 1673740800,
        "volume": "496991.892386675680935769",
        "open": "1552.063380157812484020327712143448",
        "close": "1553.894080849592949568154315393411",
        "untrackedVolumeUSD": "764836615.4563311404644138813676039",
        "priceUSD": "1553.894080849592949568154315393411"
    },
    {
        "date": 1673654400,
        "volume": "861465.431218706421830123",
        "open": "1447.897493887515578066647531893699",
        "close": "1552.063380157812484020327712143448",
        "untrackedVolumeUSD": "1324236547.394969829518451674667432",
        "priceUSD": "1552.063380157812484020327712143448"
    },
    {
        "date": 1673568000,
        "volume": "644171.703848883707047376",
        "open": "1419.835994890141270663811071322135",
        "close": "1447.897493887515578066647531893699",
        "untrackedVolumeUSD": "917577682.3193643037034125501962233",
        "priceUSD": "1447.897493887515578066647531893699"
    },
    {
        "date": 1673481600,
        "volume": "784866.263588645932249705",
        "open": "1387.996794952931729737247494643574",
        "close": "1419.835994890141270663811071322135",
        "untrackedVolumeUSD": "1105912681.059535801525426155978784",
        "priceUSD": "1419.835994890141270663811071322135"
    },
    {
        "date": 1673395200,
        "volume": "364098.198449840678516597",
        "open": "1337.925269331333081225774756554687",
        "close": "1387.996794952931729737247494643574",
        "untrackedVolumeUSD": "489191087.0767286485315016987739612",
        "priceUSD": "1387.996794952931729737247494643574"
    },
    {
        "date": 1673308800,
        "volume": "315026.214126236344985369",
        "open": "1319.346163232257037208274413560918",
        "close": "1337.925269331333081225774756554687",
        "untrackedVolumeUSD": "418825903.012890557677194298647409",
        "priceUSD": "1337.925269331333081225774756554687"
    },
    {
        "date": 1673222400,
        "volume": "573090.513464299566442683",
        "open": "1289.502261736667635864992613007314",
        "close": "1319.346163232257037208274413560918",
        "untrackedVolumeUSD": "755399577.8843916439906206159091464",
        "priceUSD": "1319.346163232257037208274413560918"
    },
    {
        "date": 1673136000,
        "volume": "283053.126441465979979598",
        "open": "1262.187471475887798868540925134317",
        "close": "1289.502261736667635864992613007314",
        "untrackedVolumeUSD": "359688603.9639344912720211034064806",
        "priceUSD": "1289.502261736667635864992613007314"
    },
    {
        "date": 1673049600,
        "volume": "120139.602087278646090169",
        "open": "1268.093822020362841021061864554936",
        "close": "1262.187471475887798868540925134317",
        "untrackedVolumeUSD": "151886111.7107974296125342190004081",
        "priceUSD": "1262.187471475887798868540925134317"
    },
    {
        "date": 1672963200,
        "volume": "304117.999535752557132341",
        "open": "1250.936338246894559333010842690685",
        "close": "1268.093822020362841021061864554936",
        "untrackedVolumeUSD": "381929272.0726761940409982375249409",
        "priceUSD": "1268.093822020362841021061864554936"
    },
    {
        "date": 1672876800,
        "volume": "197159.190806849609014654",
        "open": "1253.344908056070900194344118212132",
        "close": "1250.936338246894559333010842690685",
        "untrackedVolumeUSD": "246569505.5563618936292101588890734",
        "priceUSD": "1250.936338246894559333010842690685"
    },
    {
        "date": 1672790400,
        "volume": "452198.459647351585678526",
        "open": "1211.782279533189386095412797387077",
        "close": "1253.344908056070900194344118212132",
        "untrackedVolumeUSD": "564870195.4740888019972802412283148",
        "priceUSD": "1253.344908056070900194344118212132"
    },
    {
        "date": 1672704000,
        "volume": "178245.067696616792253103",
        "open": "1215.162167595281247322041548145568",
        "close": "1211.782279533189386095412797387077",
        "untrackedVolumeUSD": "216069804.0643228898722597819519487",
        "priceUSD": "1211.782279533189386095412797387077"
    },
    {
        "date": 1672617600,
        "volume": "234416.328144361489173755",
        "open": "1200.124464022518289435833876325203",
        "close": "1215.162167595281247322041548145568",
        "untrackedVolumeUSD": "284268605.020864155986342400950858",
        "priceUSD": "1215.162167595281247322041548145568"
    },
    {
        "date": 1672531200,
        "volume": "168936.704749793250996972",
        "open": "1196.13702549973826005002222432329",
        "close": "1200.124464022518289435833876325203",
        "untrackedVolumeUSD": "202072238.1815474870489432906479291",
        "priceUSD": "1200.124464022518289435833876325203"
    },
    {
        "date": 1672444800,
        "volume": "183721.532781218289988867",
        "open": "1197.600863912900849286639767522666",
        "close": "1196.13702549973826005002222432329",
        "untrackedVolumeUSD": "220022412.3032816142145888350799252",
        "priceUSD": "1196.13702549973826005002222432329"
    },
    {
        "date": 1672358400,
        "volume": "184509.667624164223034639",
        "open": "1202.308319308984349566811962084928",
        "close": "1197.600863912900849286639767522666",
        "untrackedVolumeUSD": "220454550.1257735381365501037652229",
        "priceUSD": "1197.600863912900849286639767522666"
    },
    {
        "date": 1672272000,
        "volume": "223944.820073053559858649",
        "open": "1186.971320083901401047228206242491",
        "close": "1202.308319308984349566811962084928",
        "untrackedVolumeUSD": "267950536.9253045487181350105091051",
        "priceUSD": "1202.308319308984349566811962084928"
    },
    {
        "date": 1672185600,
        "volume": "339490.056718607576118977",
        "open": "1210.237684135963313298666708731873",
        "close": "1186.971320083901401047228206242491",
        "untrackedVolumeUSD": "406159759.5173244426082448082088551",
        "priceUSD": "1186.971320083901401047228206242491"
    },
    {
        "date": 1672099200,
        "volume": "286271.904610628300029356",
        "open": "1226.354880330688424221165310582482",
        "close": "1210.237684135963313298666708731873",
        "untrackedVolumeUSD": "347619732.6150789752838279011842161",
        "priceUSD": "1210.237684135963313298666708731873"
    },
    {
        "date": 1672012800,
        "volume": "120931.67837884172925577",
        "open": "1218.702734723673967977930275008837",
        "close": "1226.354880330688424221165310582482",
        "untrackedVolumeUSD": "147356901.5298935594427280909657903",
        "priceUSD": "1226.354880330688424221165310582482"
    },
    {
        "date": 1671926400,
        "volume": "200700.228421249875919198",
        "open": "1221.385954100501076083659295708535",
        "close": "1218.702734723673967977930275008837",
        "untrackedVolumeUSD": "243319638.2990741504627924236545301",
        "priceUSD": "1218.702734723673967977930275008837"
    },
    {
        "date": 1671840000,
        "volume": "82725.633599695485984905",
        "open": "1219.737676311395358218611685447785",
        "close": "1221.385954100501076083659295708535",
        "untrackedVolumeUSD": "100804327.0373606842501865386602174",
        "priceUSD": "1221.385954100501076083659295708535"
    },
    {
        "date": 1671753600,
        "volume": "250428.11758174260419022",
        "open": "1216.805311074263419188395370754825",
        "close": "1219.737676311395358218611685447785",
        "untrackedVolumeUSD": "306585199.1243961324122940492998516",
        "priceUSD": "1219.737676311395358218611685447785"
    },
    {
        "date": 1671667200,
        "volume": "278625.457271838890580155",
        "open": "1211.225659755964477226500671219718",
        "close": "1216.805311074263419188395370754825",
        "untrackedVolumeUSD": "337256431.0471961916688162233431837",
        "priceUSD": "1216.805311074263419188395370754825"
    },
    {
        "date": 1671580800,
        "volume": "198738.989588982716864639",
        "open": "1216.84557529055680163574055753491",
        "close": "1211.225659755964477226500671219718",
        "untrackedVolumeUSD": "240889991.6435319883663599872248799",
        "priceUSD": "1211.225659755964477226500671219718"
    },
    {
        "date": 1671494400,
        "volume": "407883.066863741055176804",
        "open": "1165.956993647043207746106985182746",
        "close": "1216.84557529055680163574055753491",
        "untrackedVolumeUSD": "491974921.4378822566607276572867528",
        "priceUSD": "1216.84557529055680163574055753491"
    },
    {
        "date": 1671408000,
        "volume": "332429.017974445390521609",
        "open": "1185.084001428402245690482548960943",
        "close": "1165.956993647043207746106985182746",
        "untrackedVolumeUSD": "391245094.6768876216116610576578414",
        "priceUSD": "1165.956993647043207746106985182746"
    },
    {
        "date": 1671321600,
        "volume": "202390.283490794121839496",
        "open": "1186.011260000000074158134863482399",
        "close": "1185.084001428402245690482548960943",
        "untrackedVolumeUSD": "239687904.043297797673799475475185",
        "priceUSD": "1185.084001428402245690482548960943"
    },
    {
        "date": 1671235200,
        "volume": "405377.13826253653582579",
        "open": "1164.720229164287026834693027070717",
        "close": "1186.011260000000074158134863482399",
        "untrackedVolumeUSD": "477549599.2575103810665761376224503",
        "priceUSD": "1186.011260000000074158134863482399"
    },
    {
        "date": 1671148800,
        "volume": "799438.211860346927693262",
        "open": "1263.996886495760901486913812890323",
        "close": "1164.720229164287026834693027070717",
        "untrackedVolumeUSD": "962458839.6212495157519549637632965",
        "priceUSD": "1164.720229164287026834693027070717"
    },
    {
        "date": 1671062400,
        "volume": "368541.719559275854094681",
        "open": "1309.661539739560004805228581307379",
        "close": "1263.996886495760901486913812890323",
        "untrackedVolumeUSD": "471085846.3412696803685464835508461",
        "priceUSD": "1263.996886495760901486913812890323"
    },
    {
        "date": 1670976000,
        "volume": "476295.572587121593151742",
        "open": "1318.827021209748314065431491306726",
        "close": "1309.661539739560004805228581307379",
        "untrackedVolumeUSD": "629336421.3442201064029371659538218",
        "priceUSD": "1309.661539739560004805228581307379"
    },
    {
        "date": 1670889600,
        "volume": "627146.826881153201233809",
        "open": "1274.611143782710476702946338445493",
        "close": "1318.827021209748314065431491306726",
        "untrackedVolumeUSD": "820868624.5168641284047267814999167",
        "priceUSD": "1318.827021209748314065431491306726"
    },
    {
        "date": 1670803200,
        "volume": "282053.290177251334690637",
        "open": "1263.208703042992436708008258670564",
        "close": "1274.611143782710476702946338445493",
        "untrackedVolumeUSD": "353910393.8300306748418277613444596",
        "priceUSD": "1274.611143782710476702946338445493"
    },
    {
        "date": 1670716800,
        "volume": "183137.788988185247818686",
        "open": "1266.614660879179194024297647797546",
        "close": "1263.208703042992436708008258670564",
        "untrackedVolumeUSD": "232676478.9675602015410704502958712",
        "priceUSD": "1263.208703042992436708008258670564"
    },
    {
        "date": 1670630400,
        "volume": "159052.012407566794096639",
        "open": "1261.524110360818482177433006336446",
        "close": "1266.614660879179194024297647797546",
        "untrackedVolumeUSD": "201721336.105904147052414283199333",
        "priceUSD": "1266.614660879179194024297647797546"
    },
    {
        "date": 1670544000,
        "volume": "276098.327360518534814132",
        "open": "1282.529343404963032932552844415571",
        "close": "1261.524110360818482177433006336446",
        "untrackedVolumeUSD": "352411992.7943938487934353286945917",
        "priceUSD": "1261.524110360818482177433006336446"
    },
    {
        "date": 1670457600,
        "volume": "358825.930847963561116267",
        "open": "1231.217888166617760426839471923467",
        "close": "1282.529343404963032932552844415571",
        "untrackedVolumeUSD": "450239596.7987844236592726555231408",
        "priceUSD": "1282.529343404963032932552844415571"
    },
    {
        "date": 1670371200,
        "volume": "312656.643365910069756697",
        "open": "1270.230947964217587710950632308095",
        "close": "1231.217888166617760426839471923467",
        "untrackedVolumeUSD": "386906955.4821568958177004783366664",
        "priceUSD": "1231.217888166617760426839471923467"
    },
    {
        "date": 1670284800,
        "volume": "362332.427500262946914614",
        "open": "1257.006124266697566822753996038765",
        "close": "1270.230947964217587710950632308095",
        "untrackedVolumeUSD": "454991822.1542377596231452346036883",
        "priceUSD": "1270.230947964217587710950632308095"
    },
    {
        "date": 1670198400,
        "volume": "351372.880014004271150922",
        "open": "1280.471619301194229576954218399296",
        "close": "1257.006124266697566822753996038765",
        "untrackedVolumeUSD": "449892463.2362307319341493872375943",
        "priceUSD": "1257.006124266697566822753996038765"
    },
    {
        "date": 1670112000,
        "volume": "248540.358292987418729859",
        "open": "1240.603949121483454432341668415836",
        "close": "1280.471619301194229576954218399296",
        "untrackedVolumeUSD": "313612731.8213755169724569792948251",
        "priceUSD": "1280.471619301194229576954218399296"
    },
    {
        "date": 1670025600,
        "volume": "250253.571448803346456336",
        "open": "1292.380097228987163620512707423318",
        "close": "1240.603949121483454432341668415836",
        "untrackedVolumeUSD": "317901475.2188260941394706690696342",
        "priceUSD": "1240.603949121483454432341668415836"
    },
    {
        "date": 1669939200,
        "volume": "331917.27887444308973691",
        "open": "1276.834206185891749200405636407027",
        "close": "1292.380097228987163620512707423318",
        "untrackedVolumeUSD": "424903831.9285118083138192130415193",
        "priceUSD": "1292.380097228987163620512707423318"
    },
    {
        "date": 1669852800,
        "volume": "352865.493882734447212384",
        "open": "1297.411640099043800638071902915701",
        "close": "1276.834206185891749200405636407027",
        "untrackedVolumeUSD": "451886511.7035117738546446684537566",
        "priceUSD": "1276.834206185891749200405636407027"
    },
    {
        "date": 1669766400,
        "volume": "670773.326594543608254786",
        "open": "1218.671024137370690558473170521782",
        "close": "1297.411640099043800638071902915701",
        "untrackedVolumeUSD": "854586575.9190095083953389852745382",
        "priceUSD": "1297.411640099043800638071902915701"
    },
    {
        "date": 1669680000,
        "volume": "363748.911203124680281523",
        "open": "1170.22934897191401673489758142137",
        "close": "1218.671024137370690558473170521782",
        "untrackedVolumeUSD": "438147318.6123067987916335230031211",
        "priceUSD": "1218.671024137370690558473170521782"
    },
    {
        "date": 1669593600,
        "volume": "465080.396908304471708602",
        "open": "1192.919049239307377880288636862315",
        "close": "1170.22934897191401673489758142137",
        "untrackedVolumeUSD": "544593399.4631171394244348592967882",
        "priceUSD": "1170.22934897191401673489758142137"
    },
    {
        "date": 1669507200,
        "volume": "269287.51844949834388629",
        "open": "1202.827865308465447924318856775841",
        "close": "1192.919049239307377880288636862315",
        "untrackedVolumeUSD": "326531692.9678727606632874299058959",
        "priceUSD": "1192.919049239307377880288636862315"
    },
    {
        "date": 1669420800,
        "volume": "358214.817781381485222083",
        "open": "1198.529049536224644802418017517451",
        "close": "1202.827865308465447924318856775841",
        "untrackedVolumeUSD": "435080352.2293160417354447144040618",
        "priceUSD": "1202.827865308465447924318856775841"
    },
    {
        "date": 1669334400,
        "volume": "451483.909334100317143893",
        "open": "1201.33158887383610389697403694381",
        "close": "1198.529049536224644802418017517451",
        "untrackedVolumeUSD": "536898391.0898417022824845533135026",
        "priceUSD": "1198.529049536224644802418017517451"
    },
    {
        "date": 1669248000,
        "volume": "447101.736558446371527914",
        "open": "1184.309758418303094257344892597572",
        "close": "1201.33158887383610389697403694381",
        "untrackedVolumeUSD": "536516261.9207973541946905374524914",
        "priceUSD": "1201.33158887383610389697403694381"
    },
    {
        "date": 1669161600,
        "volume": "593732.160193857179337346",
        "open": "1135.595753430623814518612786069873",
        "close": "1184.309758418303094257344892597572",
        "untrackedVolumeUSD": "691538080.8391077788985977165239986",
        "priceUSD": "1184.309758418303094257344892597572"
    },
    {
        "date": 1669075200,
        "volume": "780572.133277228328335463",
        "open": "1106.887346426861665775805851137555",
        "close": "1135.595753430623814518612786069873",
        "untrackedVolumeUSD": "868576582.8010762879291591105066673",
        "priceUSD": "1135.595753430623814518612786069873"
    },
    {
        "date": 1668988800,
        "volume": "979134.684364860353577733",
        "open": "1138.657100479870702900872203630991",
        "close": "1106.887346426861665775805851137555",
        "untrackedVolumeUSD": "1091432471.283946265259169243022453",
        "priceUSD": "1106.887346426861665775805851137555"
    },
    {
        "date": 1668902400,
        "volume": "542879.602895793485396525",
        "open": "1219.055477524759673689796772666279",
        "close": "1138.657100479870702900872203630991",
        "untrackedVolumeUSD": "638939332.639525595440233739050224",
        "priceUSD": "1138.657100479870702900872203630991"
    },
    {
        "date": 1668816000,
        "volume": "224872.591365744488043169",
        "open": "1209.30318128171719699890089992734",
        "close": "1219.055477524759673689796772666279",
        "untrackedVolumeUSD": "272385279.8155813581387063911513476",
        "priceUSD": "1219.055477524759673689796772666279"
    },
    {
        "date": 1668729600,
        "volume": "318654.061509396878585416",
        "open": "1199.967238985312205085188531414667",
        "close": "1209.30318128171719699890089992734",
        "untrackedVolumeUSD": "386568788.3941427309687804585973982",
        "priceUSD": "1209.30318128171719699890089992734"
    },
    {
        "date": 1668643200,
        "volume": "505920.60648120616879715",
        "open": "1213.948508627196817096424488299763",
        "close": "1199.967238985312205085188531414667",
        "untrackedVolumeUSD": "608528864.1157390373218940134381598",
        "priceUSD": "1199.967238985312205085188531414667"
    },
    {
        "date": 1668556800,
        "volume": "675980.19143092207789841",
        "open": "1248.109449922940806658879608437984",
        "close": "1213.948508627196817096424488299763",
        "untrackedVolumeUSD": "825163423.3260184098696322877627321",
        "priceUSD": "1213.948508627196817096424488299763"
    },
    {
        "date": 1668470400,
        "volume": "694410.697122427568782695",
        "open": "1242.937862533742126125897241118443",
        "close": "1248.109449922940806658879608437984",
        "untrackedVolumeUSD": "876586716.3896212100937140433915372",
        "priceUSD": "1248.109449922940806658879608437984"
    },
    {
        "date": 1668384000,
        "volume": "1021468.180829137117467158",
        "open": "1219.855750564753304427638075757563",
        "close": "1242.937862533742126125897241118443",
        "untrackedVolumeUSD": "1257768484.679000007544666944530184",
        "priceUSD": "1242.937862533742126125897241118443"
    },
    {
        "date": 1668297600,
        "volume": "581444.183603475565732509",
        "open": "1251.317193778265292495737816982528",
        "close": "1219.855750564753304427638075757563",
        "untrackedVolumeUSD": "717430092.7694216392936973273655578",
        "priceUSD": "1219.855750564753304427638075757563"
    },
    {
        "date": 1668211200,
        "volume": "850490.456535342734616422",
        "open": "1287.585338190992576439048928961108",
        "close": "1251.317193778265292495737816982528",
        "untrackedVolumeUSD": "1073804612.372964074391589622587441",
        "priceUSD": "1251.317193778265292495737816982528"
    },
    {
        "date": 1668124800,
        "volume": "1424251.082104780615437285",
        "open": "1296.428680635827479638427919586898",
        "close": "1287.585338190992576439048928961108",
        "untrackedVolumeUSD": "1794343001.874641037102657310394128",
        "priceUSD": "1287.585338190992576439048928961108"
    },
    {
        "date": 1668038400,
        "volume": "593361.561743576361688708",
        "open": "1098.458691163302253852349430776776",
        "close": "1296.428680635827479638427919586898",
        "untrackedVolumeUSD": "2996668125.140269317599919569166917",
        "priceUSD": "1296.428680635827479638427919586898"
    },
    {
        "date": 1667952000,
        "volume": "593368.796059922274027923",
        "open": "1333.692427597191420931045478905821",
        "close": "1098.458691163302253852349430776776",
        "untrackedVolumeUSD": "3845091524.915737298318729123072397",
        "priceUSD": "1098.458691163302253852349430776776"
    },
    {
        "date": 1667865600,
        "volume": "593362.267049402839842613",
        "open": "1565.069802157205287792469954554942",
        "close": "1333.692427597191420931045478905821",
        "untrackedVolumeUSD": "3314411318.628327576261895585634947",
        "priceUSD": "1333.692427597191420931045478905821"
    },
    {
        "date": 1667779200,
        "volume": "593364.279756587584194063",
        "open": "1571.970725121304001334410675311333",
        "close": "1565.069802157205287792469954554942",
        "untrackedVolumeUSD": "935624692.0608885735031061378119865",
        "priceUSD": "1565.069802157205287792469954554942"
    },
    {
        "date": 1667692800,
        "volume": "359703.451498550562832972",
        "open": "1626.77622379102411073693248912975",
        "close": "1571.970725121304001334410675311333",
        "untrackedVolumeUSD": "579492374.2890954659280261442681825",
        "priceUSD": "1571.970725121304001334410675311333"
    },
    {
        "date": 1667606400,
        "volume": "293398.676450151432825816",
        "open": "1646.647026324186176225650244011932",
        "close": "1626.77622379102411073693248912975",
        "untrackedVolumeUSD": "481465200.0098325591812841326922263",
        "priceUSD": "1626.77622379102411073693248912975"
    },
    {
        "date": 1667520000,
        "volume": "756770.222879785582718772",
        "open": "1529.304096263005912959856349062099",
        "close": "1646.647026324186176225650244011932",
        "untrackedVolumeUSD": "1220837457.348773319681050451750209",
        "priceUSD": "1646.647026324186176225650244011932"
    },
    {
        "date": 1667433600,
        "volume": "356855.52992583633004212",
        "open": "1517.620713078554006032973981191753",
        "close": "1529.304096263005912959856349062099",
        "untrackedVolumeUSD": "549854167.2628869068409434285096314",
        "priceUSD": "1529.304096263005912959856349062099"
    },
    {
        "date": 1667347200,
        "volume": "843278.603188416080691374",
        "open": "1577.83224877893399354683576796395",
        "close": "1517.620713078554006032973981191753",
        "untrackedVolumeUSD": "1315634225.355644671889951608410095",
        "priceUSD": "1517.620713078554006032973981191753"
    },
    {
        "date": 1667260800,
        "volume": "378516.98759030438730957",
        "open": "1572.720229730455351033692119083047",
        "close": "1577.83224877893399354683576796395",
        "untrackedVolumeUSD": "599801915.6105740932296099856947582",
        "priceUSD": "1577.83224877893399354683576796395"
    },
    {
        "date": 1667174400,
        "volume": "624345.200464251711146333",
        "open": "1588.46706029912075337252864176776",
        "close": "1572.720229730455351033692119083047",
        "untrackedVolumeUSD": "988059544.5670922374250938359726671",
        "priceUSD": "1572.720229730455351033692119083047"
    },
    {
        "date": 1667088000,
        "volume": "434980.406298277465937287",
        "open": "1617.143006103515410829386830025178",
        "close": "1588.46706029912075337252864176776",
        "untrackedVolumeUSD": "696800907.3746210897489398770070763",
        "priceUSD": "1588.46706029912075337252864176776"
    },
    {
        "date": 1667001600,
        "volume": "666137.138735414071651749",
        "open": "1557.247718963406947597861476697103",
        "close": "1617.143006103515410829386830025178",
        "untrackedVolumeUSD": "1073513392.808275917807826551905209",
        "priceUSD": "1617.143006103515410829386830025178"
    },
    {
        "date": 1666915200,
        "volume": "648480.57027760657922292",
        "open": "1510.390353691249282308647600713915",
        "close": "1557.247718963406947597861476697103",
        "untrackedVolumeUSD": "990551610.1605330844719361384926022",
        "priceUSD": "1557.247718963406947597861476697103"
    },
    {
        "date": 1666828800,
        "volume": "642630.808489295382153404",
        "open": "1567.861807094520288869788932106172",
        "close": "1510.390353691249282308647600713915",
        "untrackedVolumeUSD": "994344483.5070843720871637249066214",
        "priceUSD": "1510.390353691249282308647600713915"
    },
    {
        "date": 1666742400,
        "volume": "819608.678754846690381111",
        "open": "1460.197283081249814687522034733243",
        "close": "1567.861807094520288869788932106172",
        "untrackedVolumeUSD": "1259412596.276358465813366541675462",
        "priceUSD": "1567.861807094520288869788932106172"
    },
    {
        "date": 1666656000,
        "volume": "918676.129168091250724968",
        "open": "1345.616292745401844642987367110919",
        "close": "1460.197283081249814687522034733243",
        "untrackedVolumeUSD": "1314606119.331162714096053646821447",
        "priceUSD": "1460.197283081249814687522034733243"
    },
    {
        "date": 1666569600,
        "volume": "414465.876933647532779289",
        "open": "1364.37086670639339466488104862123",
        "close": "1345.616292745401844642987367110919",
        "untrackedVolumeUSD": "557165282.4629339918269273141837884",
        "priceUSD": "1345.616292745401844642987367110919"
    },
    {
        "date": 1666483200,
        "volume": "292781.06005630040926013",
        "open": "1314.313003912999666914995456979141",
        "close": "1364.37086670639339466488104862123",
        "untrackedVolumeUSD": "389106271.9759111380390524965241304",
        "priceUSD": "1364.37086670639339466488104862123"
    },
    {
        "date": 1666396800,
        "volume": "189515.649480294073222858",
        "open": "1301.216954127304884640747819530381",
        "close": "1314.313003912999666914995456979141",
        "untrackedVolumeUSD": "247719032.672096529375299185973363",
        "priceUSD": "1314.313003912999666914995456979141"
    },
    {
        "date": 1666310400,
        "volume": "424313.120058724363074828",
        "open": "1281.895918307704080994075924540092",
        "close": "1301.216954127304884640747819530381",
        "untrackedVolumeUSD": "545355137.9884045816267396592060647",
        "priceUSD": "1301.216954127304884640747819530381"
    },
    {
        "date": 1666224000,
        "volume": "290590.754238703768797194",
        "open": "1284.200515474003751060653141186454",
        "close": "1281.895918307704080994075924540092",
        "untrackedVolumeUSD": "374654331.144413866610598277020415",
        "priceUSD": "1281.895918307704080994075924540092"
    },
    {
        "date": 1666137600,
        "volume": "313382.113796034699076441",
        "open": "1311.38802130763767426012938306964",
        "close": "1284.200515474003751060653141186454",
        "untrackedVolumeUSD": "406798014.1180710967881741003806285",
        "priceUSD": "1284.200515474003751060653141186454"
    },
    {
        "date": 1666051200,
        "volume": "431143.678982169876382037",
        "open": "1330.502956392938160886505804550747",
        "close": "1311.38802130763767426012938306964",
        "untrackedVolumeUSD": "568963402.8075446983453101879255059",
        "priceUSD": "1311.38802130763767426012938306964"
    },
    {
        "date": 1665964800,
        "volume": "461044.791179779010906015",
        "open": "1306.526486006612380881812742961407",
        "close": "1330.502956392938160886505804550747",
        "untrackedVolumeUSD": "609278589.6686231029124796756749459",
        "priceUSD": "1330.502956392938160886505804550747"
    },
    {
        "date": 1665878400,
        "volume": "215329.81647463113082966",
        "open": "1275.91346250637987999081534405624",
        "close": "1306.526486006612380881812742961407",
        "untrackedVolumeUSD": "278113279.1844527778555205199596449",
        "priceUSD": "1306.526486006612380881812742961407"
    },
    {
        "date": 1665792000,
        "volume": "223925.978698933707895116",
        "open": "1295.149732717195418842108011590024",
        "close": "1275.91346250637987999081534405624",
        "untrackedVolumeUSD": "287035290.7238266694773881669364184",
        "priceUSD": "1275.91346250637987999081534405624"
    },
    {
        "date": 1665705600,
        "volume": "532071.24645461246053439",
        "open": "1289.403401601435701338841214508262",
        "close": "1295.149732717195418842108011590024",
        "untrackedVolumeUSD": "701011851.5485878369435136587995253",
        "priceUSD": "1295.149732717195418842108011590024"
    },
    {
        "date": 1665619200,
        "volume": "582610.861385355926008567",
        "open": "1296.630150365266287831481041099231",
        "close": "1289.403401601435701338841214508262",
        "untrackedVolumeUSD": "727950659.5565197061237805299276271",
        "priceUSD": "1289.403401601435701338841214508262"
    },
    {
        "date": 1665532800,
        "volume": "175727.87981764044801344",
        "open": "1278.003681723359277660886379932932",
        "close": "1296.630150365266287831481041099231",
        "untrackedVolumeUSD": "227367261.3554244757665141028373159",
        "priceUSD": "1296.630150365266287831481041099231"
    },
    {
        "date": 1665446400,
        "volume": "296293.458075942738382006",
        "open": "1292.464713868673530832747429549476",
        "close": "1278.003681723359277660886379932932",
        "untrackedVolumeUSD": "379533339.8939944246264153250175125",
        "priceUSD": "1278.003681723359277660886379932932"
    },
    {
        "date": 1665360000,
        "volume": "273859.165152256152322774",
        "open": "1319.417407119561749546497784717757",
        "close": "1292.464713868673530832747429549476",
        "untrackedVolumeUSD": "359059711.4862819553131349525391881",
        "priceUSD": "1292.464713868673530832747429549476"
    },
    {
        "date": 1665273600,
        "volume": "154431.697598918323333186",
        "open": "1313.481352943806512332934276010947",
        "close": "1319.417407119561749546497784717757",
        "untrackedVolumeUSD": "204527566.1010020821276617570034608",
        "priceUSD": "1319.417407119561749546497784717757"
    },
    {
        "date": 1665187200,
        "volume": "134787.200464462724110317",
        "open": "1332.713142017844558632982489237189",
        "close": "1313.481352943806512332934276010947",
        "untrackedVolumeUSD": "178699447.8454172094984141783042153",
        "priceUSD": "1313.481352943806512332934276010947"
    },
    {
        "date": 1665100800,
        "volume": "308855.645671571898905756",
        "open": "1350.781122709413048706767254853051",
        "close": "1332.713142017844558632982489237189",
        "untrackedVolumeUSD": "413703072.4891781164111286690241228",
        "priceUSD": "1332.713142017844558632982489237189"
    },
    {
        "date": 1665014400,
        "volume": "519390.98297162331650102",
        "open": "1353.208490815833622269870106819884",
        "close": "1350.781122709413048706767254853051",
        "untrackedVolumeUSD": "708854312.929461689225279608060202",
        "priceUSD": "1350.781122709413048706767254853051"
    },
    {
        "date": 1664928000,
        "volume": "332917.311161491079569818",
        "open": "1362.079265260310293755356052278897",
        "close": "1353.208490815833622269870106819884",
        "untrackedVolumeUSD": "447660135.9900297587465513349752714",
        "priceUSD": "1353.208490815833622269870106819884"
    },
    {
        "date": 1664841600,
        "volume": "363485.408618709447416007",
        "open": "1323.86772774433694607452889310959",
        "close": "1362.079265260310293755356052278897",
        "untrackedVolumeUSD": "489904029.8125588935175281596147279",
        "priceUSD": "1362.079265260310293755356052278897"
    },
    {
        "date": 1664755200,
        "volume": "324346.640435112812175671",
        "open": "1276.988933068001344657406441014578",
        "close": "1323.86772774433694607452889310959",
        "untrackedVolumeUSD": "423921302.8495241396442682712821614",
        "priceUSD": "1323.86772774433694607452889310959"
    },
    {
        "date": 1664668800,
        "volume": "265861.652526971890928967",
        "open": "1312.461687591151521909614359353653",
        "close": "1276.988933068001344657406441014578",
        "untrackedVolumeUSD": "344930929.9568437455287344843232103",
        "priceUSD": "1276.988933068001344657406441014578"
    },
    {
        "date": 1664582400,
        "volume": "180786.561495442741313886",
        "open": "1325.434269220712381182719262898217",
        "close": "1312.461687591151521909614359353653",
        "untrackedVolumeUSD": "238581117.4667043910774431036330384",
        "priceUSD": "1312.461687591151521909614359353653"
    },
    {
        "date": 1664496000,
        "volume": "526017.266125212029381477",
        "open": "1337.045456855046794234655968633555",
        "close": "1325.434269220712381182719262898217",
        "untrackedVolumeUSD": "704961173.4076058861980659395666692",
        "priceUSD": "1325.434269220712381182719262898217"
    },
    {
        "date": 1664409600,
        "volume": "508213.377499555534650519",
        "open": "1338.554927912188001871128752689706",
        "close": "1337.045456855046794234655968633555",
        "untrackedVolumeUSD": "675233765.5152557586824383728941876",
        "priceUSD": "1337.045456855046794234655968633555"
    },
    {
        "date": 1664323200,
        "volume": "655314.750542713258115103",
        "open": "1330.469929804349017029121530495201",
        "close": "1338.554927912188001871128752689706",
        "untrackedVolumeUSD": "859814639.8024683676715599679531566",
        "priceUSD": "1338.554927912188001871128752689706"
    },
    {
        "date": 1664236800,
        "volume": "552439.207725413478470133",
        "open": "1333.572011523799041551279788445599",
        "close": "1330.469929804349017029121530495201",
        "untrackedVolumeUSD": "751462313.2548562109551723138143205",
        "priceUSD": "1330.469929804349017029121530495201"
    },
    {
        "date": 1664150400,
        "volume": "452478.890428999895953565",
        "open": "1294.037241128421501005053372339017",
        "close": "1333.572011523799041551279788445599",
        "untrackedVolumeUSD": "594155184.2993031782584851493100897",
        "priceUSD": "1333.572011523799041551279788445599"
    },
    {
        "date": 1664064000,
        "volume": "280146.615723264394645454",
        "open": "1316.871391663669728307175531847913",
        "close": "1294.037241128421501005053372339017",
        "untrackedVolumeUSD": "365588203.6870317292243800250558712",
        "priceUSD": "1294.037241128421501005053372339017"
    },
    {
        "date": 1663977600,
        "volume": "331706.773406121214268068",
        "open": "1327.573477266407111378861324015809",
        "close": "1316.871391663669728307175531847913",
        "untrackedVolumeUSD": "440566233.472283538476574508183892",
        "priceUSD": "1316.871391663669728307175531847913"
    },
    {
        "date": 1663891200,
        "volume": "587656.540713920343854335",
        "open": "1329.833632464102590133518854806843",
        "close": "1327.573477266407111378861324015809",
        "untrackedVolumeUSD": "768156159.1156094208092709871743426",
        "priceUSD": "1327.573477266407111378861324015809"
    },
    {
        "date": 1663804800,
        "volume": "590002.166941637652407363",
        "open": "1249.463876065697257155810361237672",
        "close": "1329.833632464102590133518854806843",
        "untrackedVolumeUSD": "756116222.2650067636146379669458748",
        "priceUSD": "1329.833632464102590133518854806843"
    },
    {
        "date": 1663718400,
        "volume": "724199.030193429117796342",
        "open": "1322.948710145728979004257042306221",
        "close": "1249.463876065697257155810361237672",
        "untrackedVolumeUSD": "954602277.801162923080168351493539",
        "priceUSD": "1249.463876065697257155810361237672"
    },
    {
        "date": 1663632000,
        "volume": "466774.271565378193970544",
        "open": "1378.714262011493411111542222189444",
        "close": "1322.948710145728979004257042306221",
        "untrackedVolumeUSD": "629622600.2261023428569154535198515",
        "priceUSD": "1322.948710145728979004257042306221"
    },
    {
        "date": 1663545600,
        "volume": "551493.208005550502804152",
        "open": "1334.179907332456638158668313398553",
        "close": "1378.714262011493411111542222189444",
        "untrackedVolumeUSD": "734130560.0409119416288392826111282",
        "priceUSD": "1378.714262011493411111542222189444"
    },
    {
        "date": 1663459200,
        "volume": "343031.853141644888134451",
        "open": "1469.283080224565691990624513935878",
        "close": "1334.179907332456638158668313398553",
        "untrackedVolumeUSD": "478913060.3945796477120307070861169",
        "priceUSD": "1334.179907332456638158668313398553"
    },
    {
        "date": 1663372800,
        "volume": "248444.986296165785747801",
        "open": "1429.912959911105386227540486172153",
        "close": "1469.283080224565691990624513935878",
        "untrackedVolumeUSD": "359513220.4076408260649442804792035",
        "priceUSD": "1469.283080224565691990624513935878"
    },
    {
        "date": 1663286400,
        "volume": "500605.93975963906905471",
        "open": "1471.100610402105934398390537846396",
        "close": "1429.912959911105386227540486172153",
        "untrackedVolumeUSD": "722938617.9249173886934848635923381",
        "priceUSD": "1429.912959911105386227540486172153"
    },
    {
        "date": 1663200000,
        "volume": "750141.377572492722014883",
        "open": "1634.533180271369250696695130720991",
        "close": "1471.100610402105934398390537846396",
        "untrackedVolumeUSD": "1169327380.471418586634228766024623",
        "priceUSD": "1471.100610402105934398390537846396"
    },
    {
        "date": 1663113600,
        "volume": "534886.672924778967991789",
        "open": "1573.490054981503794640315242191117",
        "close": "1634.533180271369250696695130720991",
        "untrackedVolumeUSD": "855924951.3496947464092799279284316",
        "priceUSD": "1634.533180271369250696695130720991"
    },
    {
        "date": 1663027200,
        "volume": "808455.630875662074148069",
        "open": "1712.135878751709871091042865136183",
        "close": "1573.490054981503794640315242191117",
        "untrackedVolumeUSD": "1333061545.46577062464057363843106",
        "priceUSD": "1573.490054981503794640315242191117"
    },
    {
        "date": 1662940800,
        "volume": "487776.532098742394840601",
        "open": "1762.624333986545986168562685822082",
        "close": "1712.135878751709871091042865136183",
        "untrackedVolumeUSD": "846855407.930334093532696218602054",
        "priceUSD": "1712.135878751709871091042865136183"
    },
    {
        "date": 1662854400,
        "volume": "303443.273915451959613054",
        "open": "1778.932461217125432999153717992146",
        "close": "1762.624333986545986168562685822082",
        "untrackedVolumeUSD": "535156266.661176542973608051037797",
        "priceUSD": "1762.624333986545986168562685822082"
    },
    {
        "date": 1662768000,
        "volume": "363908.449335267820563823",
        "open": "1716.743640690177498377577460016662",
        "close": "1778.932461217125432999153717992146",
        "untrackedVolumeUSD": "631462614.9493466711284779513743675",
        "priceUSD": "1778.932461217125432999153717992146"
    },
    {
        "date": 1662681600,
        "volume": "519772.071834639125788562",
        "open": "1632.644622602373034780650799451813",
        "close": "1716.743640690177498377577460016662",
        "untrackedVolumeUSD": "884517251.0164737312266601741107382",
        "priceUSD": "1716.743640690177498377577460016662"
    },
    {
        "date": 1662595200,
        "volume": "467917.834616349088052577",
        "open": "1628.188911106200898615393923121325",
        "close": "1632.644622602373034780650799451813",
        "untrackedVolumeUSD": "762772807.2964006058549478367551188",
        "priceUSD": "1632.644622602373034780650799451813"
    },
    {
        "date": 1662508800,
        "volume": "660049.147225092617674636",
        "open": "1561.77589014009460393673652614368",
        "close": "1628.188911106200898615393923121325",
        "untrackedVolumeUSD": "1024462731.914568097492906101746701",
        "priceUSD": "1628.188911106200898615393923121325"
    },
    {
        "date": 1662422400,
        "volume": "627139.232476520217641573",
        "open": "1619.45588317949927066736839841291",
        "close": "1561.77589014009460393673652614368",
        "untrackedVolumeUSD": "1021546071.016418115558574267234316",
        "priceUSD": "1561.77589014009460393673652614368"
    },
    {
        "date": 1662336000,
        "volume": "407358.802988060098655253",
        "open": "1575.640160321265981070517410390242",
        "close": "1619.45588317949927066736839841291",
        "untrackedVolumeUSD": "648084181.7043940431469167720129828",
        "priceUSD": "1619.45588317949927066736839841291"
    },
    {
        "date": 1662249600,
        "volume": "246692.353492167869171739",
        "open": "1554.901987725990672667878130431881",
        "close": "1575.640160321265981070517410390242",
        "untrackedVolumeUSD": "384992048.4808344867118436726677843",
        "priceUSD": "1575.640160321265981070517410390242"
    },
    {
        "date": 1662163200,
        "volume": "286204.341285746745889017",
        "open": "1576.447484946132838834651498254034",
        "close": "1554.901987725990672667878130431881",
        "untrackedVolumeUSD": "445340735.6827328402585963844871129",
        "priceUSD": "1554.901987725990672667878130431881"
    },
    {
        "date": 1662076800,
        "volume": "604239.451581477136018997",
        "open": "1586.487967631418057352224894839498",
        "close": "1576.447484946132838834651498254034",
        "untrackedVolumeUSD": "965354892.8572873326997072001403962",
        "priceUSD": "1576.447484946132838834651498254034"
    },
    {
        "date": 1661990400,
        "volume": "616861.443781658767488052",
        "open": "1550.595890397765355908907424799271",
        "close": "1586.487967631418057352224894839498",
        "untrackedVolumeUSD": "960433094.4149290954393627888112607",
        "priceUSD": "1586.487967631418057352224894839498"
    },
    {
        "date": 1661904000,
        "volume": "793800.471121499316140034",
        "open": "1523.457389399116194191299029232413",
        "close": "1550.595890397765355908907424799271",
        "untrackedVolumeUSD": "1252402762.225415357889885690853424",
        "priceUSD": "1550.595890397765355908907424799271"
    },
    {
        "date": 1661817600,
        "volume": "673104.462744932043646213",
        "open": "1555.302537299398084400094493403356",
        "close": "1523.457389399116194191299029232413",
        "untrackedVolumeUSD": "1041751390.970417542149572647545684",
        "priceUSD": "1523.457389399116194191299029232413"
    },
    {
        "date": 1661731200,
        "volume": "524401.333393066892606607",
        "open": "1430.395834486597168037399717625599",
        "close": "1555.302537299398084400094493403356",
        "untrackedVolumeUSD": "782933408.2586430484951240859808072",
        "priceUSD": "1555.302537299398084400094493403356"
    },
    {
        "date": 1661644800,
        "volume": "469629.029751086542678758",
        "open": "1493.811455704440314099383689294187",
        "close": "1430.395834486597168037399717625599",
        "untrackedVolumeUSD": "695365287.0155864800179297673450342",
        "priceUSD": "1430.395834486597168037399717625599"
    },
    {
        "date": 1661558400,
        "volume": "521386.231634780360401633",
        "open": "1503.562628149576346550481971128205",
        "close": "1493.811455704440314099383689294187",
        "untrackedVolumeUSD": "774551879.1526187877551744729435575",
        "priceUSD": "1493.811455704440314099383689294187"
    },
    {
        "date": 1661472000,
        "volume": "727301.872096685822322094",
        "open": "1693.662645594171847391634498816532",
        "close": "1503.562628149576346550481971128205",
        "untrackedVolumeUSD": "1176373148.839745654225737709987713",
        "priceUSD": "1503.562628149576346550481971128205"
    },
    {
        "date": 1661385600,
        "volume": "386223.383702985826088766",
        "open": "1657.437615423424121115618750135402",
        "close": "1693.662645594171847391634498816532",
        "untrackedVolumeUSD": "655393030.7818738418591610747448729",
        "priceUSD": "1693.662645594171847391634498816532"
    },
    {
        "date": 1661299200,
        "volume": "521224.390789876428434276",
        "open": "1666.259443906178880876866356976088",
        "close": "1657.437615423424121115618750135402",
        "untrackedVolumeUSD": "861228241.2451205489721295274085367",
        "priceUSD": "1657.437615423424121115618750135402"
    },
    {
        "date": 1661212800,
        "volume": "808778.543927852746501256",
        "open": "1624.369303380885570343876433283331",
        "close": "1666.259443906178880876866356976088",
        "untrackedVolumeUSD": "1316888954.64799598036514791115371",
        "priceUSD": "1666.259443906178880876866356976088"
    },
    {
        "date": 1661126400,
        "volume": "615694.885225770093442032",
        "open": "1620.913895017338038389824238888478",
        "close": "1624.369303380885570343876433283331",
        "untrackedVolumeUSD": "971253645.8598564619946279252204581",
        "priceUSD": "1624.369303380885570343876433283331"
    },
    {
        "date": 1661040000,
        "volume": "573039.712599476594116688",
        "open": "1575.049406846600679847962742813391",
        "close": "1620.913895017338038389824238888478",
        "untrackedVolumeUSD": "922076428.5254933981802581217751818",
        "priceUSD": "1620.913895017338038389824238888478"
    },
    {
        "date": 1660953600,
        "volume": "630283.817682988487283196",
        "open": "1609.58555089641965123773976293502",
        "close": "1575.049406846600679847962742813391",
        "untrackedVolumeUSD": "1008920316.950125915637561906466526",
        "priceUSD": "1575.049406846600679847962742813391"
    },
    {
        "date": 1660867200,
        "volume": "775465.498441844897124058",
        "open": "1849.811322777741750051422864416683",
        "close": "1609.58555089641965123773976293502",
        "untrackedVolumeUSD": "1332983787.354574610829580218110326",
        "priceUSD": "1609.58555089641965123773976293502"
    },
    {
        "date": 1660780800,
        "volume": "379503.002819984469509127",
        "open": "1831.471256040449136008292147918772",
        "close": "1849.811322777741750051422864416683",
        "untrackedVolumeUSD": "705178886.3457854011734160890654658",
        "priceUSD": "1849.811322777741750051422864416683"
    },
    {
        "date": 1660694400,
        "volume": "598770.732675380565782433",
        "open": "1875.262789081693138206212642719346",
        "close": "1831.471256040449136008292147918772",
        "untrackedVolumeUSD": "1119791435.627876703601471638539511",
        "priceUSD": "1831.471256040449136008292147918772"
    },
    {
        "date": 1660608000,
        "volume": "418195.703129983276292195",
        "open": "1896.122432836570175434032241089274",
        "close": "1875.262789081693138206212642719346",
        "untrackedVolumeUSD": "788785776.7603552136249314336906248",
        "priceUSD": "1875.262789081693138206212642719346"
    },
    {
        "date": 1660521600,
        "volume": "585679.36863457449445986",
        "open": "1934.728712693098111129560973420641",
        "close": "1896.122432836570175434032241089274",
        "untrackedVolumeUSD": "1124314379.457861808431493508217095",
        "priceUSD": "1896.122432836570175434032241089274"
    },
    {
        "date": 1660435200,
        "volume": "348880.504682362726155632",
        "open": "1981.31436952246994775102060590944",
        "close": "1934.728712693098111129560973420641",
        "untrackedVolumeUSD": "687007390.6005456079174697974293208",
        "priceUSD": "1934.728712693098111129560973420641"
    },
    {
        "date": 1660348800,
        "volume": "352273.980102092722257587",
        "open": "1958.903416892567293717137760166185",
        "close": "1981.31436952246994775102060590944",
        "untrackedVolumeUSD": "700380228.109605590795181018172227",
        "priceUSD": "1981.31436952246994775102060590944"
    },
    {
        "date": 1660262400,
        "volume": "409023.151492292225872701",
        "open": "1878.208611090695029080890640634949",
        "close": "1958.903416892567293717137760166185",
        "untrackedVolumeUSD": "776495852.6609981329665650480135447",
        "priceUSD": "1958.903416892567293717137760166185"
    },
    {
        "date": 1660176000,
        "volume": "646544.661711948261132137",
        "open": "1851.24998779754541601985893106852",
        "close": "1878.208611090695029080890640634949",
        "untrackedVolumeUSD": "1224774132.780348404338200809299612",
        "priceUSD": "1878.208611090695029080890640634949"
    },
    {
        "date": 1660089600,
        "volume": "720283.801496020960014144",
        "open": "1705.613517081326912383379132403644",
        "close": "1851.24998779754541601985893106852",
        "untrackedVolumeUSD": "1282902352.937770707650324030214908",
        "priceUSD": "1851.24998779754541601985893106852"
    },
    {
        "date": 1660003200,
        "volume": "514567.552928527682883777",
        "open": "1774.773586490356444628071019632437",
        "close": "1705.613517081326912383379132403644",
        "untrackedVolumeUSD": "883683495.2703878203594988671587821",
        "priceUSD": "1705.613517081326912383379132403644"
    },
    {
        "date": 1659916800,
        "volume": "521587.232203414390251623",
        "open": "1696.063231117178994972350557155397",
        "close": "1774.773586490356444628071019632437",
        "untrackedVolumeUSD": "921396554.724048692224195622124645",
        "priceUSD": "1774.773586490356444628071019632437"
    },
    {
        "date": 1659830400,
        "volume": "278886.910190630685473684",
        "open": "1692.807306781228600344315229391612",
        "close": "1696.063231117178994972350557155397",
        "untrackedVolumeUSD": "473465205.7476762980203209164269283",
        "priceUSD": "1696.063231117178994972350557155397"
    },
    {
        "date": 1659744000,
        "volume": "279269.102222841425333765",
        "open": "1735.9265861350052274671065268099",
        "close": "1692.807306781228600344315229391612",
        "untrackedVolumeUSD": "481319771.7981539986471123781077136",
        "priceUSD": "1692.807306781228600344315229391612"
    },
    {
        "date": 1659657600,
        "volume": "563748.006744934844670809",
        "open": "1608.139939533599819521681854066809",
        "close": "1735.9265861350052274671065268099",
        "untrackedVolumeUSD": "947437839.3865542173451623610319636",
        "priceUSD": "1735.9265861350052274671065268099"
    },
    {
        "date": 1659571200,
        "volume": "499536.516494417041314361",
        "open": "1617.867917843883575075929470236413",
        "close": "1608.139939533599819521681854066809",
        "untrackedVolumeUSD": "809933062.7324197740758681864592077",
        "priceUSD": "1608.139939533599819521681854066809"
    },
    {
        "date": 1659484800,
        "volume": "505498.365571263621350596",
        "open": "1633.757273895149195655817788590644",
        "close": "1617.867917843883575075929470236413",
        "untrackedVolumeUSD": "827665249.4276988917527159247462304",
        "priceUSD": "1617.867917843883575075929470236413"
    },
    {
        "date": 1659398400,
        "volume": "772986.463408182498996585",
        "open": "1631.527674887434704381914183592022",
        "close": "1633.757273895149195655817788590644",
        "untrackedVolumeUSD": "1250482981.698179315067915333346016",
        "priceUSD": "1633.757273895149195655817788590644"
    },
    {
        "date": 1659312000,
        "volume": "526717.829506764550192309",
        "open": "1683.593063440322964263452519256328",
        "close": "1631.527674887434704381914183592022",
        "untrackedVolumeUSD": "872110237.8424287380704466236414221",
        "priceUSD": "1631.527674887434704381914183592022"
    },
    {
        "date": 1659225600,
        "volume": "402145.157427237022562222",
        "open": "1693.719593594312792254598930213691",
        "close": "1683.593063440322964263452519256328",
        "untrackedVolumeUSD": "686039587.3245856559020133554555011",
        "priceUSD": "1683.593063440322964263452519256328"
    },
    {
        "date": 1659139200,
        "volume": "449862.285703831072972006",
        "open": "1723.975290158357494736694333478117",
        "close": "1693.719593594312792254598930213691",
        "untrackedVolumeUSD": "769403175.9280738109577535202513894",
        "priceUSD": "1693.719593594312792254598930213691"
    },
    {
        "date": 1659052800,
        "volume": "711883.427652514096541066",
        "open": "1723.650966252407381146032253864081",
        "close": "1723.975290158357494736694333478117",
        "untrackedVolumeUSD": "1218933131.023635239545031782572743",
        "priceUSD": "1723.975290158357494736694333478117"
    },
    {
        "date": 1658966400,
        "volume": "851801.047859839743635302",
        "open": "1639.294733355766835685378261273548",
        "close": "1723.650966252407381146032253864081",
        "untrackedVolumeUSD": "1426358581.466744690728965547390273",
        "priceUSD": "1723.650966252407381146032253864081"
    },
    {
        "date": 1658880000,
        "volume": "825096.615057288523409014",
        "open": "1449.449059230600202585424552237458",
        "close": "1639.294733355766835685378261273548",
        "untrackedVolumeUSD": "1251847780.992790292531787971390109",
        "priceUSD": "1639.294733355766835685378261273548"
    },
    {
        "date": 1658793600,
        "volume": "679409.929122825768238315",
        "open": "1442.684598510619086794433238970584",
        "close": "1449.449059230600202585424552237458",
        "untrackedVolumeUSD": "952014969.6477387675827407377317976",
        "priceUSD": "1449.449059230600202585424552237458"
    },
    {
        "date": 1658707200,
        "volume": "724626.581519648227360946",
        "open": "1597.868836250534988124477277782571",
        "close": "1442.684598510619086794433238970584",
        "untrackedVolumeUSD": "1099439633.225169585438051288298382",
        "priceUSD": "1442.684598510619086794433238970584"
    },
    {
        "date": 1658620800,
        "volume": "521240.219600245227981608",
        "open": "1550.024241942749988068276969228899",
        "close": "1597.868836250534988124477277782571",
        "untrackedVolumeUSD": "834104225.8251862488460920367264805",
        "priceUSD": "1597.868836250534988124477277782571"
    },
    {
        "date": 1658534400,
        "volume": "517061.619839833425462787",
        "open": "1536.454970854210018146430163865269",
        "close": "1550.024241942749988068276969228899",
        "untrackedVolumeUSD": "795802058.3494766612098288454035475",
        "priceUSD": "1550.024241942749988068276969228899"
    },
    {
        "date": 1658448000,
        "volume": "583170.7051821909804807",
        "open": "1575.08339896225830509542896426358",
        "close": "1536.454970854210018146430163865269",
        "untrackedVolumeUSD": "922221480.6458640620480425873898222",
        "priceUSD": "1536.454970854210018146430163865269"
    },
    {
        "date": 1658361600,
        "volume": "714293.82088077168817054",
        "open": "1521.589911235647660839081965692546",
        "close": "1575.08339896225830509542896426358",
        "untrackedVolumeUSD": "1085909114.668269340010154205337086",
        "priceUSD": "1575.08339896225830509542896426358"
    },
    {
        "date": 1658275200,
        "volume": "847040.675861248027737063",
        "open": "1540.632369304936296655131735739593",
        "close": "1521.589911235647660839081965692546",
        "untrackedVolumeUSD": "1318848915.109896403805036576833488",
        "priceUSD": "1521.589911235647660839081965692546"
    },
    {
        "date": 1658188800,
        "volume": "1034273.203707494723135417",
        "open": "1585.994518790602764875322542893376",
        "close": "1540.632369304936296655131735739593",
        "untrackedVolumeUSD": "1598362002.136962273296656788794603",
        "priceUSD": "1540.632369304936296655131735739593"
    },
    {
        "date": 1658102400,
        "volume": "994751.801544041985069681",
        "open": "1338.347071773165205444115355282672",
        "close": "1585.994518790602764875322542893376",
        "untrackedVolumeUSD": "1455905866.822888995270647069334951",
        "priceUSD": "1585.994518790602764875322542893376"
    },
    {
        "date": 1658016000,
        "volume": "750321.866737564381406673",
        "open": "1355.395081970511552313081917735865",
        "close": "1338.347071773165205444115355282672",
        "untrackedVolumeUSD": "1015977289.385277331385607416927692",
        "priceUSD": "1338.347071773165205444115355282672"
    },
    {
        "date": 1657929600,
        "volume": "766885.993129034588286393",
        "open": "1233.418087599487384596217508708708",
        "close": "1355.395081970511552313081917735865",
        "untrackedVolumeUSD": "988132430.5918350623540503791663754",
        "priceUSD": "1355.395081970511552313081917735865"
    },
    {
        "date": 1657843200,
        "volume": "889999.305232611808779178",
        "open": "1191.857820826720641744198899046141",
        "close": "1233.418087599487384596217508708708",
        "untrackedVolumeUSD": "1090229645.26083491849146832706149",
        "priceUSD": "1233.418087599487384596217508708708"
    },
    {
        "date": 1657756800,
        "volume": "692777.842814664673337615",
        "open": "1113.964832820670698962670819495847",
        "close": "1191.857820826720641744198899046141",
        "untrackedVolumeUSD": "783954288.9655775288750723172238686",
        "priceUSD": "1191.857820826720641744198899046141"
    },
    {
        "date": 1657670400,
        "volume": "887532.263932624440341478",
        "open": "1037.681843269342561000071311583608",
        "close": "1113.964832820670698962670819495847",
        "untrackedVolumeUSD": "943862111.4884471432730567419662797",
        "priceUSD": "1113.964832820670698962670819495847"
    },
    {
        "date": 1657584000,
        "volume": "606874.936112304350931621",
        "open": "1098.000280161693500051386583107309",
        "close": "1037.681843269342561000071311583608",
        "untrackedVolumeUSD": "648691857.7768807077815834135792875",
        "priceUSD": "1037.681843269342561000071311583608"
    },
    {
        "date": 1657497600,
        "volume": "606057.634997944868426832",
        "open": "1166.216693586216812641880005395793",
        "close": "1098.000280161693500051386583107309",
        "untrackedVolumeUSD": "688856378.054759771978340319190036",
        "priceUSD": "1098.000280161693500051386583107309"
    },
    {
        "date": 1657411200,
        "volume": "403097.251042439167198809",
        "open": "1216.379790588969880234079620466635",
        "close": "1166.216693586216812641880005395793",
        "untrackedVolumeUSD": "476069933.6835907637805662104865245",
        "priceUSD": "1166.216693586216812641880005395793"
    },
    {
        "date": 1657324800,
        "volume": "386935.692390027382526993",
        "open": "1214.138430886530600752683895906423",
        "close": "1216.379790588969880234079620466635",
        "untrackedVolumeUSD": "470787142.6935105203029767557611198",
        "priceUSD": "1216.379790588969880234079620466635"
    },
    {
        "date": 1657238400,
        "volume": "826591.35619082795056437",
        "open": "1238.433504976075716769629326855619",
        "close": "1214.138430886530600752683895906423",
        "untrackedVolumeUSD": "1017326592.943850411807878434296814",
        "priceUSD": "1214.138430886530600752683895906423"
    },
];
export const volumeChartMock = [
    {
        "date": 1687219200,
        "volume": "51030.257288870991925463",
        "open": "1732.108043992725498483052170499316",
        "close": "1729.431823935762829166448565144746",
        "untrackedVolumeUSD": "88583142.62001749455266703270935793",
        "priceUSD": "1729.431823935762829166448565144746"
    },
    {
        "date": 1687132800,
        "volume": "227110.067360235563758344",
        "open": "1719.250207245601655754924648615498",
        "close": "1732.108043992725498483052170499316",
        "untrackedVolumeUSD": "391616165.1909110798775342271406656",
        "priceUSD": "1732.108043992725498483052170499316"
    },
    {
        "date": 1687046400,
        "volume": "101612.318175105001723287",
        "open": "1726.654472088578643132369155078428",
        "close": "1719.250207245601655754924648615498",
        "untrackedVolumeUSD": "175838153.2516402061316486488231358",
        "priceUSD": "1719.250207245601655754924648615498"
    },
    {
        "date": 1686960000,
        "volume": "157504.738086680155060852",
        "open": "1718.897323853610986380351179101462",
        "close": "1726.654472088578643132369155078428",
        "untrackedVolumeUSD": "273449537.2573042994524256764931803",
        "priceUSD": "1726.654472088578643132369155078428"
    },
    {
        "date": 1686873600,
        "volume": "223054.505846905492434627",
        "open": "1665.740683755112148996643835711637",
        "close": "1718.897323853610986380351179101462",
        "untrackedVolumeUSD": "375877672.7763602761297987933098983",
        "priceUSD": "1718.897323853610986380351179101462"
    },
    {
        "date": 1686787200,
        "volume": "298213.134520852034082293",
        "open": "1648.238102184999955510678047599472",
        "close": "1665.740683755112148996643835711637",
        "untrackedVolumeUSD": "491597831.9290366001816424539777539",
        "priceUSD": "1665.740683755112148996643835711637"
    },
    {
        "date": 1686700800,
        "volume": "305199.145641342505717",
        "open": "1737.912837359881221529088408357851",
        "close": "1648.238102184999955510678047599472",
        "untrackedVolumeUSD": "518433853.3536743625130377028539653",
        "priceUSD": "1648.238102184999955510678047599472"
    },
    {
        "date": 1686614400,
        "volume": "244287.379594879741979207",
        "open": "1740.637242552813373914251861471266",
        "close": "1737.912837359881221529088408357851",
        "untrackedVolumeUSD": "425737631.0594242128099240181677842",
        "priceUSD": "1737.912837359881221529088408357851"
    },
    {
        "date": 1686528000,
        "volume": "241980.778187789351838887",
        "open": "1750.967781799137662029080128096872",
        "close": "1740.637242552813373914251861471266",
        "untrackedVolumeUSD": "421037202.2100560142872095766549311",
        "priceUSD": "1740.637242552813373914251861471266"
    },
    {
        "date": 1686441600,
        "volume": "149659.181997448187268003",
        "open": "1753.010893613468529432615676290446",
        "close": "1750.967781799137662029080128096872",
        "untrackedVolumeUSD": "262463330.2611453621356223427612662",
        "priceUSD": "1750.967781799137662029080128096872"
    },
    {
        "date": 1686355200,
        "volume": "546884.627906851127059375",
        "open": "1835.746393679954948445902543774585",
        "close": "1753.010893613468529432615676290446",
        "untrackedVolumeUSD": "963513774.0608159653052674017334366",
        "priceUSD": "1753.010893613468529432615676290446"
    },
    {
        "date": 1686268800,
        "volume": "150243.133784415047825655",
        "open": "1848.513564311523568010909040091503",
        "close": "1835.746393679954948445902543774585",
        "untrackedVolumeUSD": "276539183.8399801820996701304369082",
        "priceUSD": "1835.746393679954948445902543774585"
    },
    {
        "date": 1686182400,
        "volume": "159333.26903912744769854",
        "open": "1828.057631581553099059935870899797",
        "close": "1848.513564311523568010909040091503",
        "untrackedVolumeUSD": "294012203.9793685119439770011445063",
        "priceUSD": "1848.513564311523568010909040091503"
    },
    {
        "date": 1686096000,
        "volume": "320322.341189436652993908",
        "open": "1883.614053633318434567760643859046",
        "close": "1828.057631581553099059935870899797",
        "untrackedVolumeUSD": "594555189.3661260118328975925153347",
        "priceUSD": "1828.057631581553099059935870899797"
    },
    {
        "date": 1686009600,
        "volume": "347862.269813406432914281",
        "open": "1810.709015382846467878892175267192",
        "close": "1883.614053633318434567760643859046",
        "untrackedVolumeUSD": "642582586.8096102310902901069391897",
        "priceUSD": "1883.614053633318434567760643859046"
    },
    {
        "date": 1685923200,
        "volume": "378069.991928365368973864",
        "open": "1892.035532722325804876034028886942",
        "close": "1810.709015382846467878892175267192",
        "untrackedVolumeUSD": "695142141.682337098027191056273147",
        "priceUSD": "1810.709015382846467878892175267192"
    },
    {
        "date": 1685836800,
        "volume": "113140.382801445697390023",
        "open": "1888.436289012211512298350469011066",
        "close": "1892.035532722325804876034028886942",
        "untrackedVolumeUSD": "214977217.8755439657043074615802382",
        "priceUSD": "1892.035532722325804876034028886942"
    },
    {
        "date": 1685750400,
        "volume": "110101.622608534966441626",
        "open": "1905.767940155642478922507094622564",
        "close": "1888.436289012211512298350469011066",
        "untrackedVolumeUSD": "209176320.6458670748023621871323521",
        "priceUSD": "1888.436289012211512298350469011066"
    },
    {
        "date": 1685664000,
        "volume": "203163.287683258920177147",
        "open": "1859.731026025086573648897784328045",
        "close": "1905.767940155642478922507094622564",
        "untrackedVolumeUSD": "383567255.0092547153989685793373178",
        "priceUSD": "1905.767940155642478922507094622564"
    },
    {
        "date": 1685577600,
        "volume": "207483.719456317027403979",
        "open": "1871.656181054300940052285287488563",
        "close": "1859.731026025086573648897784328045",
        "untrackedVolumeUSD": "386874715.5618630968575653457322269",
        "priceUSD": "1859.731026025086573648897784328045"
    },
    {
        "date": 1685491200,
        "volume": "270170.597739438019557111",
        "open": "1904.251513636283646031042106203924",
        "close": "1871.656181054300940052285287488563",
        "untrackedVolumeUSD": "505493416.641637497542248872617284",
        "priceUSD": "1871.656181054300940052285287488563"
    },
    {
        "date": 1685404800,
        "volume": "248870.58379584272211064",
        "open": "1897.060458156860544568128152550481",
        "close": "1904.251513636283646031042106203924",
        "untrackedVolumeUSD": "473960480.3504551154565291239206679",
        "priceUSD": "1904.251513636283646031042106203924"
    },
    {
        "date": 1685318400,
        "volume": "231891.986600058865141566",
        "open": "1911.463442626953148841666411304792",
        "close": "1897.060458156860544568128152550481",
        "untrackedVolumeUSD": "440567034.3680626798960622883215784",
        "priceUSD": "1897.060458156860544568128152550481"
    },
    {
        "date": 1685232000,
        "volume": "266150.071517540206252234",
        "open": "1828.063377059410750066117061297334",
        "close": "1911.463442626953148841666411304792",
        "untrackedVolumeUSD": "496045326.4606710805639208287825758",
        "priceUSD": "1911.463442626953148841666411304792"
    },
    {
        "date": 1685145600,
        "volume": "133538.127205566443764281",
        "open": "1832.141058075041976045500175529453",
        "close": "1828.063377059410750066117061297334",
        "untrackedVolumeUSD": "244076085.5749959698204059943378442",
        "priceUSD": "1828.063377059410750066117061297334"
    },
    {
        "date": 1685059200,
        "volume": "218908.713787882550192493",
        "open": "1810.911320048426174437240580918241",
        "close": "1832.141058075041976045500175529453",
        "untrackedVolumeUSD": "398323194.209429141946983881652811",
        "priceUSD": "1832.141058075041976045500175529453"
    },
    {
        "date": 1684972800,
        "volume": "256637.349274094062564294",
        "open": "1799.681265998766494733290412467148",
        "close": "1810.911320048426174437240580918241",
        "untrackedVolumeUSD": "460139494.682714015143495458791423",
        "priceUSD": "1810.911320048426174437240580918241"
    },
    {
        "date": 1684886400,
        "volume": "343691.591507924417194447",
        "open": "1851.681685682754083484461145814277",
        "close": "1799.681265998766494733290412467148",
        "untrackedVolumeUSD": "621738348.6556085301010870646020613",
        "priceUSD": "1799.681265998766494733290412467148"
    },
    {
        "date": 1684800000,
        "volume": "341059.210664484652088351",
        "open": "1820.892727779596075908148445336096",
        "close": "1851.681685682754083484461145814277",
        "untrackedVolumeUSD": "631569275.148500127732870237136473",
        "priceUSD": "1851.681685682754083484461145814277"
    },
    {
        "date": 1684713600,
        "volume": "237850.208892337971600321",
        "open": "1804.653479931751512760888816153473",
        "close": "1820.892727779596075908148445336096",
        "untrackedVolumeUSD": "431051349.9228763824521680155623798",
        "priceUSD": "1820.892727779596075908148445336096"
    },
    {
        "date": 1684627200,
        "volume": "187147.032163892694953034",
        "open": "1819.468155088755646652327979405198",
        "close": "1804.653479931751512760888816153473",
        "untrackedVolumeUSD": "339322272.8211748863228300078250224",
        "priceUSD": "1804.653479931751512760888816153473"
    },
    {
        "date": 1684540800,
        "volume": "137191.198500214463132355",
        "open": "1813.876326419036457114962680615717",
        "close": "1819.468155088755646652327979405198",
        "untrackedVolumeUSD": "249128123.4968979040584979283948938",
        "priceUSD": "1819.468155088755646652327979405198"
    },
    {
        "date": 1684454400,
        "volume": "182534.891051096299390869",
        "open": "1805.714679549776346304490693384817",
        "close": "1813.876326419036457114962680615717",
        "untrackedVolumeUSD": "330402299.8285589994800718207673685",
        "priceUSD": "1813.876326419036457114962680615717"
    },
    {
        "date": 1684368000,
        "volume": "218671.929413698229184163",
        "open": "1825.272905674184096690352938677376",
        "close": "1805.714679549776346304490693384817",
        "untrackedVolumeUSD": "396151172.5988308305739976272807342",
        "priceUSD": "1805.714679549776346304490693384817"
    },
    {
        "date": 1684281600,
        "volume": "234054.058794285313382598",
        "open": "1823.419794215579469078750515524987",
        "close": "1825.272905674184096690352938677376",
        "untrackedVolumeUSD": "424512106.5175696443979759374072882",
        "priceUSD": "1825.272905674184096690352938677376"
    },
    {
        "date": 1684195200,
        "volume": "210947.826909755616454376",
        "open": "1820.557266172119590393704962680451",
        "close": "1823.419794215579469078750515524987",
        "untrackedVolumeUSD": "383293594.1846998133784913827420356",
        "priceUSD": "1823.419794215579469078750515524987"
    },
    {
        "date": 1684108800,
        "volume": "244112.279806136978683203",
        "open": "1798.049900449482843870015141037912",
        "close": "1820.557266172119590393704962680451",
        "untrackedVolumeUSD": "445490728.9986262938406242818889503",
        "priceUSD": "1820.557266172119590393704962680451"
    },
    {
        "date": 1684022400,
        "volume": "164004.831402423808300484",
        "open": "1800.072949881754193305699049997668",
        "close": "1798.049900449482843870015141037912",
        "untrackedVolumeUSD": "295975714.2188040422711513845063757",
        "priceUSD": "1798.049900449482843870015141037912"
    },
    {
        "date": 1683936000,
        "volume": "229441.505295495161448125",
        "open": "1806.129934653484250064584386040876",
        "close": "1800.072949881754193305699049997668",
        "untrackedVolumeUSD": "413463146.4295394600829262031915096",
        "priceUSD": "1800.072949881754193305699049997668"
    },
    {
        "date": 1683849600,
        "volume": "447426.817929234694030075",
        "open": "1795.727059792628777118447434595319",
        "close": "1806.129934653484250064584386040876",
        "untrackedVolumeUSD": "791513584.9159126036572841265939486",
        "priceUSD": "1806.129934653484250064584386040876"
    },
    {
        "date": 1683763200,
        "volume": "463066.572824028687201212",
        "open": "1842.732027672917181305851185823911",
        "close": "1795.727059792628777118447434595319",
        "untrackedVolumeUSD": "838147359.3190693175291970697062029",
        "priceUSD": "1795.727059792628777118447434595319"
    },
    {
        "date": 1683676800,
        "volume": "412070.993377955608071985",
        "open": "1847.105102602251991178483435461088",
        "close": "1842.732027672917181305851185823911",
        "untrackedVolumeUSD": "761372612.9529490507832536681592895",
        "priceUSD": "1842.732027672917181305851185823911"
    },
    {
        "date": 1683590400,
        "volume": "294995.680084842054649558",
        "open": "1845.672287244064538950572013535085",
        "close": "1847.105102602251991178483435461088",
        "untrackedVolumeUSD": "544535784.368895046209046654867692",
        "priceUSD": "1847.105102602251991178483435461088"
    },
    {
        "date": 1683504000,
        "volume": "538829.18265591528173355",
        "open": "1878.207712408912982783715849682082",
        "close": "1845.672287244064538950572013535085",
        "untrackedVolumeUSD": "999164863.7099687712793412610928977",
        "priceUSD": "1845.672287244064538950572013535085"
    },
    {
        "date": 1683417600,
        "volume": "301258.681625869787076676",
        "open": "1895.977949520741882357985595192759",
        "close": "1878.207712408912982783715849682082",
        "untrackedVolumeUSD": "575249740.0665936757799814915856491",
        "priceUSD": "1878.207712408912982783715849682082"
    },
    {
        "date": 1683331200,
        "volume": "552044.004483530381902989",
        "open": "1992.458889215561967371998718369136",
        "close": "1895.977949520741882357985595192759",
        "untrackedVolumeUSD": "1061832096.974359639926244408092951",
        "priceUSD": "1895.977949520741882357985595192759"
    },
    {
        "date": 1683244800,
        "volume": "538901.33366699348310654",
        "open": "1875.108934649659371488059312145592",
        "close": "1992.458889215561967371998718369136",
        "untrackedVolumeUSD": "1045926781.788431312306308338166745",
        "priceUSD": "1992.458889215561967371998718369136"
    },
    {
        "date": 1683158400,
        "volume": "267636.953268390090768351",
        "open": "1906.534585058419761788325976830579",
        "close": "1875.108934649659371488059312145592",
        "untrackedVolumeUSD": "506331793.4593545616444789527445796",
        "priceUSD": "1875.108934649659371488059312145592"
    },
    {
        "date": 1683072000,
        "volume": "314958.567117755415731437",
        "open": "1875.148768044126340781767009970716",
        "close": "1906.534585058419761788325976830579",
        "untrackedVolumeUSD": "589528006.5161327848925914119717369",
        "priceUSD": "1906.534585058419761788325976830579"
    },
    {
        "date": 1682985600,
        "volume": "261335.753298761996957063",
        "open": "1828.041084215229655695333797558664",
        "close": "1875.148768044126340781767009970716",
        "untrackedVolumeUSD": "482839210.3315744517528926361609299",
        "priceUSD": "1875.148768044126340781767009970716"
    },
    {
        "date": 1682899200,
        "volume": "305337.148205721757166287",
        "open": "1877.156649414033945917909151222883",
        "close": "1828.041084215229655695333797558664",
        "untrackedVolumeUSD": "561524036.5923842466164777252248426",
        "priceUSD": "1828.041084215229655695333797558664"
    },
    {
        "date": 1682812800,
        "volume": "221200.793407931443579884",
        "open": "1904.117983750064465192310946688711",
        "close": "1877.156649414033945917909151222883",
        "untrackedVolumeUSD": "421651557.9635875161235326862348058",
        "priceUSD": "1877.156649414033945917909151222883"
    },
    {
        "date": 1682726400,
        "volume": "106597.083623594471906328",
        "open": "1895.367925835261829731835083838459",
        "close": "1904.117983750064465192310946688711",
        "untrackedVolumeUSD": "202703329.1470036830806408354113617",
        "priceUSD": "1904.117983750064465192310946688711"
    },
    {
        "date": 1682640000,
        "volume": "190469.088992659331083427",
        "open": "1905.483099404547118951828126486234",
        "close": "1895.367925835261829731835083838459",
        "untrackedVolumeUSD": "362092032.8586696161848076950761141",
        "priceUSD": "1895.367925835261829731835083838459"
    },
    {
        "date": 1682553600,
        "volume": "446049.396144160613822504",
        "open": "1866.336790043031022928070184684033",
        "close": "1905.483099404547118951828126486234",
        "untrackedVolumeUSD": "847752972.355370453856672616027857",
        "priceUSD": "1905.483099404547118951828126486234"
    },
    {
        "date": 1682467200,
        "volume": "460913.753693402863660679",
        "open": "1870.036217642510217542632166621046",
        "close": "1866.336790043031022928070184684033",
        "untrackedVolumeUSD": "867732174.7600775333831829578258683",
        "priceUSD": "1866.336790043031022928070184684033"
    },
    {
        "date": 1682380800,
        "volume": "245527.580510923055639584",
        "open": "1840.36991747234134761789387162059",
        "close": "1870.036217642510217542632166621046",
        "untrackedVolumeUSD": "450677873.4937383750897765701495278",
        "priceUSD": "1870.036217642510217542632166621046"
    },
    {
        "date": 1682294400,
        "volume": "266232.388323756914665809",
        "open": "1861.234909153041981863272850368677",
        "close": "1840.36991747234134761789387162059",
        "untrackedVolumeUSD": "491673755.1273492705873930502521121",
        "priceUSD": "1840.36991747234134761789387162059"
    },
    {
        "date": 1682208000,
        "volume": "173948.996636540481643865",
        "open": "1873.240896026831002010825569012719",
        "close": "1861.234909153041981863272850368677",
        "untrackedVolumeUSD": "323823175.9587835818641719139868079",
        "priceUSD": "1861.234909153041981863272850368677"
    },
    {
        "date": 1682121600,
        "volume": "145375.929340699043879773",
        "open": "1848.0318638316137913424585542316",
        "close": "1873.240896026831002010825569012719",
        "untrackedVolumeUSD": "270733100.7397369728951345727772883",
        "priceUSD": "1873.240896026831002010825569012719"
    },
    {
        "date": 1682035200,
        "volume": "435200.22945731460577187",
        "open": "1944.334686937760983295284000717671",
        "close": "1848.0318638316137913424585542316",
        "untrackedVolumeUSD": "824900385.6987992838509642176284442",
        "priceUSD": "1848.0318638316137913424585542316"
    },
    {
        "date": 1681948800,
        "volume": "365406.067515109056215509",
        "open": "1933.28227257040899962430664886029",
        "close": "1944.334686937760983295284000717671",
        "untrackedVolumeUSD": "711624311.3919459084557839843109403",
        "priceUSD": "1944.334686937760983295284000717671"
    },
    {
        "date": 1681862400,
        "volume": "405314.325427943989874682",
        "open": "2101.173760255638965619679857430764",
        "close": "1933.28227257040899962430664886029",
        "untrackedVolumeUSD": "810876111.2690389799153301947730868",
        "priceUSD": "1933.28227257040899962430664886029"
    },
    {
        "date": 1681776000,
        "volume": "247913.623347431184637075",
        "open": "2078.05600135112000576089386774471",
        "close": "2101.173760255638965619679857430764",
        "untrackedVolumeUSD": "519211112.3721607910687125010743864",
        "priceUSD": "2101.173760255638965619679857430764"
    },
    {
        "date": 1681689600,
        "volume": "261513.143666069345278772",
        "open": "2119.805385499220627696304507624473",
        "close": "2078.05600135112000576089386774471",
        "untrackedVolumeUSD": "546008742.3590165810933852101048444",
        "priceUSD": "2078.05600135112000576089386774471"
    },
    {
        "date": 1681603200,
        "volume": "233759.187376144765149278",
        "open": "2094.155863753542299673890769693077",
        "close": "2119.805385499220627696304507624473",
        "untrackedVolumeUSD": "492115949.9322780549193637824050139",
        "priceUSD": "2119.805385499220627696304507624473"
    },
    {
        "date": 1681516800,
        "volume": "213902.552911719484535983",
        "open": "2105.416570799290010016397669760698",
        "close": "2094.155863753542299673890769693077",
        "untrackedVolumeUSD": "448488339.1574399405982085628950387",
        "priceUSD": "2094.155863753542299673890769693077"
    },
    {
        "date": 1681430400,
        "volume": "493649.411146348671804703",
        "open": "2012.66690071158488074229969269125",
        "close": "2105.416570799290010016397669760698",
        "untrackedVolumeUSD": "1035604271.369327877558683597683684",
        "priceUSD": "2105.416570799290010016397669760698"
    },
    {
        "date": 1681344000,
        "volume": "354018.482922659172957617",
        "open": "1923.630496306856976220295688841021",
        "close": "2012.66690071158488074229969269125",
        "untrackedVolumeUSD": "698274609.1476302240568242326083056",
        "priceUSD": "2012.66690071158488074229969269125"
    },
    {
        "date": 1681257600,
        "volume": "348718.710192034831913604",
        "open": "1891.80384652140225971547240814288",
        "close": "1923.630496306856976220295688841021",
        "untrackedVolumeUSD": "661225114.3722226875775242418148829",
        "priceUSD": "1923.630496306856976220295688841021"
    },
    {
        "date": 1681171200,
        "volume": "281907.759256582385026384",
        "open": "1912.513794073961978030063549401295",
        "close": "1891.80384652140225971547240814288",
        "untrackedVolumeUSD": "539234917.1690550951334650923959151",
        "priceUSD": "1891.80384652140225971547240814288"
    },
    {
        "date": 1681084800,
        "volume": "220091.174551583771026352",
        "open": "1863.160356657281537768066137359126",
        "close": "1912.513794073961978030063549401295",
        "untrackedVolumeUSD": "413497082.3747682770181475777194885",
        "priceUSD": "1912.513794073961978030063549401295"
    },
    {
        "date": 1680998400,
        "volume": "183377.010533141634178776",
        "open": "1853.838633116650036787389059651635",
        "close": "1863.160356657281537768066137359126",
        "untrackedVolumeUSD": "338844982.527842179017489089242307",
        "priceUSD": "1863.160356657281537768066137359126"
    },
    {
        "date": 1680912000,
        "volume": "108429.28407883106910033",
        "open": "1866.46005432968839853817765364473",
        "close": "1853.838633116650036787389059651635",
        "untrackedVolumeUSD": "202164376.2262135817113434175441218",
        "priceUSD": "1853.838633116650036787389059651635"
    },
    {
        "date": 1680825600,
        "volume": "166368.820769642741066585",
        "open": "1868.719690680695327851023151779256",
        "close": "1866.46005432968839853817765364473",
        "untrackedVolumeUSD": "309660268.0087563997994736590405859",
        "priceUSD": "1866.46005432968839853817765364473"
    },
    {
        "date": 1680739200,
        "volume": "273116.817077594514559876",
        "open": "1910.782285333076342877619662784159",
        "close": "1868.719690680695327851023151779256",
        "untrackedVolumeUSD": "512878896.3600543771802013202742195",
        "priceUSD": "1868.719690680695327851023151779256"
    },
    {
        "date": 1680652800,
        "volume": "355946.45853615472642261",
        "open": "1868.099819361958723342975611977774",
        "close": "1910.782285333076342877619662784159",
        "untrackedVolumeUSD": "678425087.0055135191734838510119929",
        "priceUSD": "1910.782285333076342877619662784159"
    },
    {
        "date": 1680566400,
        "volume": "359645.988066544627205763",
        "open": "1812.746274556003534881309556575416",
        "close": "1868.099819361958723342975611977774",
        "untrackedVolumeUSD": "666475246.0048482843062000436419235",
        "priceUSD": "1868.099819361958723342975611977774"
    },
    {
        "date": 1680480000,
        "volume": "417818.808379928638767192",
        "open": "1791.080164986100016896051437259299",
        "close": "1812.746274556003534881309556575416",
        "untrackedVolumeUSD": "751037336.8142011795782931707100776",
        "priceUSD": "1812.746274556003534881309556575416"
    },
    {
        "date": 1680393600,
        "volume": "205736.283659103990180867",
        "open": "1821.247793365411277690749943877697",
        "close": "1791.080164986100016896051437259299",
        "untrackedVolumeUSD": "370957956.3838924234105646833222452",
        "priceUSD": "1791.080164986100016896051437259299"
    },
    {
        "date": 1680307200,
        "volume": "224054.57144943986889587",
        "open": "1824.470041002610326971472785919467",
        "close": "1821.247793365411277690749943877697",
        "untrackedVolumeUSD": "408420188.9044501579511605845047154",
        "priceUSD": "1821.247793365411277690749943877697"
    },
    {
        "date": 1680220800,
        "volume": "322941.34439841840759938",
        "open": "1789.555052757875246987588278784574",
        "close": "1824.470041002610326971472785919467",
        "untrackedVolumeUSD": "587314005.2451613005719923169818948",
        "priceUSD": "1824.470041002610326971472785919467"
    },
    {
        "date": 1680134400,
        "volume": "349311.038387045038813337",
        "open": "1792.512497969295447432797145617816",
        "close": "1789.555052757875246987588278784574",
        "untrackedVolumeUSD": "626469545.1350107039337262861984219",
        "priceUSD": "1789.555052757875246987588278784574"
    },
    {
        "date": 1680048000,
        "volume": "338394.192156840623492227",
        "open": "1773.163015611095064803421415265483",
        "close": "1792.512497969295447432797145617816",
        "untrackedVolumeUSD": "609674271.0910881825888814774982099",
        "priceUSD": "1792.512497969295447432797145617816"
    },
    {
        "date": 1679961600,
        "volume": "338270.279201283704780007",
        "open": "1711.941342472404142034627618096492",
        "close": "1773.163015611095064803421415265483",
        "untrackedVolumeUSD": "589590399.3466237557338434256949582",
        "priceUSD": "1773.163015611095064803421415265483"
    },
    {
        "date": 1679875200,
        "volume": "377897.82508833461890524",
        "open": "1778.393744144888451196475123346453",
        "close": "1711.941342472404142034627618096492",
        "untrackedVolumeUSD": "654507474.7605151772011250328102705",
        "priceUSD": "1711.941342472404142034627618096492"
    },
    {
        "date": 1679788800,
        "volume": "245580.64508237161898125",
        "open": "1739.485982883241573999626117763643",
        "close": "1778.393744144888451196475123346453",
        "untrackedVolumeUSD": "434203625.6040159637847627453507394",
        "priceUSD": "1778.393744144888451196475123346453"
    },
    {
        "date": 1679702400,
        "volume": "229302.97054413956242464",
        "open": "1750.23746407740148828949498444068",
        "close": "1739.485982883241573999626117763643",
        "untrackedVolumeUSD": "400167901.8461006515455936111104089",
        "priceUSD": "1739.485982883241573999626117763643"
    },
    {
        "date": 1679616000,
        "volume": "485870.988130640076027029",
        "open": "1817.419796415281933385453478015453",
        "close": "1750.23746407740148828949498444068",
        "untrackedVolumeUSD": "859001278.1628242748136289920188268",
        "priceUSD": "1750.23746407740148828949498444068"
    },
    {
        "date": 1679529600,
        "volume": "554985.073531052023485438",
        "open": "1735.923082512533607122017385845713",
        "close": "1817.419796415281933385453478015453",
        "untrackedVolumeUSD": "996118819.835712613428954754634217",
        "priceUSD": "1817.419796415281933385453478015453"
    },
    {
        "date": 1679443200,
        "volume": "729665.28782733121981034",
        "open": "1804.723853981992252187692033569856",
        "close": "1735.923082512533607122017385845713",
        "untrackedVolumeUSD": "1294446551.421710631391203726137905",
        "priceUSD": "1735.923082512533607122017385845713"
    },
    {
        "date": 1679356800,
        "volume": "540940.723476513988831421",
        "open": "1735.817452357071134573121537149779",
        "close": "1804.723853981992252187692033569856",
        "untrackedVolumeUSD": "964276508.2294305202299336807992134",
        "priceUSD": "1804.723853981992252187692033569856"
    },
    {
        "date": 1679270400,
        "volume": "734249.520178947518044631",
        "open": "1786.95045079608864954254118745312",
        "close": "1735.817452357071134573121537149779",
        "untrackedVolumeUSD": "1299627095.382668172970869111188593",
        "priceUSD": "1735.817452357071134573121537149779"
    },
    {
        "date": 1679184000,
        "volume": "531746.142975399702106044",
        "open": "1761.586594493817396948896028432956",
        "close": "1786.95045079608864954254118745312",
        "untrackedVolumeUSD": "957164811.5374785291812236600304793",
        "priceUSD": "1786.95045079608864954254118745312"
    },
    {
        "date": 1679097600,
        "volume": "708494.853591585965508285",
        "open": "1790.309779237911409051144753792634",
        "close": "1761.586594493817396948896028432956",
        "untrackedVolumeUSD": "1278628857.278912661387459923408279",
        "priceUSD": "1761.586594493817396948896028432956"
    },
    {
        "date": 1679011200,
        "volume": "856658.750459411581511196",
        "open": "1677.339241766784749106969941976656",
        "close": "1790.309779237911409051144753792634",
        "untrackedVolumeUSD": "1483427964.349996637248868060062701",
        "priceUSD": "1790.309779237911409051144753792634"
    },
    {
        "date": 1678924800,
        "volume": "464697.54935774384222595",
        "open": "1656.09455611491906115282755915722",
        "close": "1677.339241766784749106969941976656",
        "untrackedVolumeUSD": "771597006.4584665262158502160413315",
        "priceUSD": "1677.339241766784749106969941976656"
    },
    {
        "date": 1678838400,
        "volume": "1101849.563210910785008262",
        "open": "1707.740886404113486800093654603105",
        "close": "1656.09455611491906115282755915722",
        "untrackedVolumeUSD": "1838371342.464931617716169512065822",
        "priceUSD": "1656.09455611491906115282755915722"
    },
    {
        "date": 1678752000,
        "volume": "1371453.463863990299105012",
        "open": "1677.377799987160801693211600586151",
        "close": "1707.740886404113486800093654603105",
        "untrackedVolumeUSD": "2350853657.86304279115790301544161",
        "priceUSD": "1707.740886404113486800093654603105"
    },
    {
        "date": 1678665600,
        "volume": "1362569.768885819553279694",
        "open": "1604.08619646809384946590408502447",
        "close": "1677.377799987160801693211600586151",
        "untrackedVolumeUSD": "2233472662.619509240853259304606019",
        "priceUSD": "1677.377799987160801693211600586151"
    },
    {
        "date": 1678579200,
        "volume": "1260108.567188438545355273",
        "open": "1537.00693630001603225985404482192",
        "close": "1604.08619646809384946590408502447",
        "untrackedVolumeUSD": "1963592404.337166577984799420103568",
        "priceUSD": "1604.08619646809384946590408502447"
    },
    {
        "date": 1678492800,
        "volume": "1328107.419429952860277154",
        "open": "1438.952436182730183894397269785581",
        "close": "1537.00693630001603225985404482192",
        "untrackedVolumeUSD": "7174435766.092070300579681983784158",
        "priceUSD": "1537.00693630001603225985404482192"
    },
    {
        "date": 1678406400,
        "volume": "1424540.921841127999653614",
        "open": "1436.884234887294186231070231003034",
        "close": "1438.952436182730183894397269785581",
        "untrackedVolumeUSD": "2014172922.764809853986985245796694",
        "priceUSD": "1438.952436182730183894397269785581"
    },
    {
        "date": 1678320000,
        "volume": "635423.887058245996280666",
        "open": "1533.249931993276973292997119784472",
        "close": "1436.884234887294186231070231003034",
        "untrackedVolumeUSD": "948706276.5518931534394563223212729",
        "priceUSD": "1436.884234887294186231070231003034"
    },
    {
        "date": 1678233600,
        "volume": "446006.945899508747769025",
        "open": "1558.497352944871129604039531099086",
        "close": "1533.249931993276973292997119784472",
        "untrackedVolumeUSD": "692719162.7697509200956257997679637",
        "priceUSD": "1533.249931993276973292997119784472"
    },
    {
        "date": 1678147200,
        "volume": "433362.600019596727513632",
        "open": "1566.459158150707460972445779650172",
        "close": "1558.497352944871129604039531099086",
        "untrackedVolumeUSD": "674956563.4848943714188776842273242",
        "priceUSD": "1558.497352944871129604039531099086"
    },
    {
        "date": 1678060800,
        "volume": "312929.766195739740220035",
        "open": "1561.720646879236992011355277111412",
        "close": "1566.459158150707460972445779650172",
        "untrackedVolumeUSD": "489951349.2466272826655531369303763",
        "priceUSD": "1566.459158150707460972445779650172"
    },
    {
        "date": 1677974400,
        "volume": "344439.818975639268744491",
        "open": "1563.53440524256932244110818994215",
        "close": "1561.720646879236992011355277111412",
        "untrackedVolumeUSD": "540941368.3814594927745182103735407",
        "priceUSD": "1561.720646879236992011355277111412"
    },
    {
        "date": 1677888000,
        "volume": "245522.205685600966930899",
        "open": "1566.107746162059272364494064436453",
        "close": "1563.53440524256932244110818994215",
        "untrackedVolumeUSD": "384115265.339763336819052617588374",
        "priceUSD": "1563.53440524256932244110818994215"
    },
    {
        "date": 1677801600,
        "volume": "599341.147152078660091241",
        "open": "1649.462350134186807957625714581529",
        "close": "1566.107746162059272364494064436453",
        "untrackedVolumeUSD": "942062005.1617418138921093571715374",
        "priceUSD": "1566.107746162059272364494064436453"
    },
    {
        "date": 1677715200,
        "volume": "473225.829761970279748734",
        "open": "1661.767350687257586095407503050614",
        "close": "1649.462350134186807957625714581529",
        "untrackedVolumeUSD": "776079912.3306224969847512888810786",
        "priceUSD": "1649.462350134186807957625714581529"
    },
    {
        "date": 1677628800,
        "volume": "574801.244816350756223964",
        "open": "1607.235979674547156264623252823493",
        "close": "1661.767350687257586095407503050614",
        "untrackedVolumeUSD": "945888885.7883435881757268803837575",
        "priceUSD": "1661.767350687257586095407503050614"
    },
    {
        "date": 1677542400,
        "volume": "413195.982141877932972195",
        "open": "1630.946779267032425619365401824015",
        "close": "1607.235979674547156264623252823493",
        "untrackedVolumeUSD": "671891441.2501762126115958708916175",
        "priceUSD": "1607.235979674547156264623252823493"
    },
    {
        "date": 1677456000,
        "volume": "442185.715008110096563967",
        "open": "1638.150433543007982527812914529953",
        "close": "1630.946779267032425619365401824015",
        "untrackedVolumeUSD": "724319757.9584918271435199441516095",
        "priceUSD": "1630.946779267032425619365401824015"
    },
    {
        "date": 1677369600,
        "volume": "319144.832054008939623225",
        "open": "1592.311043490723346529806607209349",
        "close": "1638.150433543007982527812914529953",
        "untrackedVolumeUSD": "515161313.5165548106064452882822296",
        "priceUSD": "1638.150433543007982527812914529953"
    },
    {
        "date": 1677283200,
        "volume": "355977.295445490192061115",
        "open": "1606.873321450348252407526281830601",
        "close": "1592.311043490723346529806607209349",
        "untrackedVolumeUSD": "566391996.7633579820371139764300502",
        "priceUSD": "1592.311043490723346529806607209349"
    },
    {
        "date": 1677196800,
        "volume": "609409.298979400659668244",
        "open": "1646.6383932590925869668155200157",
        "close": "1606.873321450348252407526281830601",
        "untrackedVolumeUSD": "987865138.7088387517080023697520135",
        "priceUSD": "1606.873321450348252407526281830601"
    },
    {
        "date": 1677110400,
        "volume": "552608.120797425493157903",
        "open": "1641.386265676911620544371396274814",
        "close": "1646.6383932590925869668155200157",
        "untrackedVolumeUSD": "914933785.4633014300572090097507694",
        "priceUSD": "1646.6383932590925869668155200157"
    },
    {
        "date": 1677024000,
        "volume": "644663.343434712973908326",
        "open": "1655.306119564281285899801165671834",
        "close": "1641.386265676911620544371396274814",
        "untrackedVolumeUSD": "1051460733.428374277168080822337933",
        "priceUSD": "1641.386265676911620544371396274814"
    },
    {
        "date": 1676937600,
        "volume": "684179.415130536851513005",
        "open": "1698.580178697303768342312029684105",
        "close": "1655.306119564281285899801165671834",
        "untrackedVolumeUSD": "1146747038.277908349597392506608047",
        "priceUSD": "1655.306119564281285899801165671834"
    },
    {
        "date": 1676851200,
        "volume": "471621.861140514429680955",
        "open": "1683.050054302945418826008385671219",
        "close": "1698.580178697303768342312029684105",
        "untrackedVolumeUSD": "799438431.0102707894828125903523308",
        "priceUSD": "1698.580178697303768342312029684105"
    },
    {
        "date": 1676764800,
        "volume": "520180.811997188193455244",
        "open": "1688.793712939118325495588311145666",
        "close": "1683.050054302945418826008385671219",
        "untrackedVolumeUSD": "882239377.4158385995615530421738079",
        "priceUSD": "1683.050054302945418826008385671219"
    },
    {
        "date": 1676678400,
        "volume": "356043.728310635515479271",
        "open": "1695.292837289291138763088616878336",
        "close": "1688.793712939118325495588311145666",
        "untrackedVolumeUSD": "603690555.8817507087701118395684516",
        "priceUSD": "1688.793712939118325495588311145666"
    },
    {
        "date": 1676592000,
        "volume": "749697.675971211502744467",
        "open": "1639.213012957787044107372846767645",
        "close": "1695.292837289291138763088616878336",
        "untrackedVolumeUSD": "1257278337.845766464960087896477811",
        "priceUSD": "1695.292837289291138763088616878336"
    },
    {
        "date": 1676505600,
        "volume": "938323.649221964709739068",
        "open": "1673.691633412489551900627887896524",
        "close": "1639.213012957787044107372846767645",
        "untrackedVolumeUSD": "1585816908.951330024786519589200747",
        "priceUSD": "1639.213012957787044107372846767645"
    },
    {
        "date": 1676419200,
        "volume": "674214.753471232561736034",
        "open": "1557.554464799155679567032341719053",
        "close": "1673.691633412489551900627887896524",
        "untrackedVolumeUSD": "1079327210.512886689571084195660958",
        "priceUSD": "1673.691633412489551900627887896524"
    },
    {
        "date": 1676332800,
        "volume": "711895.942865130713487126",
        "open": "1506.463778481760965222698856303737",
        "close": "1557.554464799155679567032341719053",
        "untrackedVolumeUSD": "1090452491.058251811482113789228399",
        "priceUSD": "1557.554464799155679567032341719053"
    },
    {
        "date": 1676246400,
        "volume": "672892.817774172408761859",
        "open": "1513.823860482546752960554383857241",
        "close": "1506.463778481760965222698856303737",
        "untrackedVolumeUSD": "1004038978.919278300996062750605887",
        "priceUSD": "1506.463778481760965222698856303737"
    },
    {
        "date": 1676160000,
        "volume": "376573.138460598159895353",
        "open": "1539.418294281043509211422465264336",
        "close": "1513.823860482546752960554383857241",
        "untrackedVolumeUSD": "575318085.6823571928421607351568563",
        "priceUSD": "1513.823860482546752960554383857241"
    },
    {
        "date": 1676073600,
        "volume": "350117.193660385290693892",
        "open": "1513.53061205410133968769848186013",
        "close": "1539.418294281043509211422465264336",
        "untrackedVolumeUSD": "532895383.7526039760598817519531659",
        "priceUSD": "1539.418294281043509211422465264336"
    },
    {
        "date": 1675987200,
        "volume": "649126.496710991141991459",
        "open": "1545.390217171965255361909180137758",
        "close": "1513.53061205410133968769848186013",
        "untrackedVolumeUSD": "995640711.9285436541655211979309904",
        "priceUSD": "1513.53061205410133968769848186013"
    },
    {
        "date": 1675900800,
        "volume": "815489.42344054583022826",
        "open": "1648.43494908851061013444077720964",
        "close": "1545.390217171965255361909180137758",
        "untrackedVolumeUSD": "1305091280.575327615134505043835702",
        "priceUSD": "1545.390217171965255361909180137758"
    },
    {
        "date": 1675814400,
        "volume": "489612.817764520018103572",
        "open": "1671.954848940821098098684989757763",
        "close": "1648.43494908851061013444077720964",
        "untrackedVolumeUSD": "814027450.8840014037215106254055076",
        "priceUSD": "1648.43494908851061013444077720964"
    },
    {
        "date": 1675728000,
        "volume": "561736.520284762353939785",
        "open": "1612.988655183062547714979247687033",
        "close": "1671.954848940821098098684989757763",
        "untrackedVolumeUSD": "923526003.9979919557216048202331035",
        "priceUSD": "1671.954848940821098098684989757763"
    },
    {
        "date": 1675641600,
        "volume": "414616.306028438492392854",
        "open": "1632.524848737303164810744881528308",
        "close": "1612.988655183062547714979247687033",
        "untrackedVolumeUSD": "677741338.0619189967996547494151249",
        "priceUSD": "1612.988655183062547714979247687033"
    },
    {
        "date": 1675555200,
        "volume": "454508.223688181944380879",
        "open": "1664.822319026630533271014972347018",
        "close": "1632.524848737303164810744881528308",
        "untrackedVolumeUSD": "747238678.5254211533875765567974978",
        "priceUSD": "1632.524848737303164810744881528308"
    },
    {
        "date": 1675468800,
        "volume": "347580.906149166758606251",
        "open": "1664.896135488040345051365277143936",
        "close": "1664.822319026630533271014972347018",
        "untrackedVolumeUSD": "580633860.3203272749438863517212546",
        "priceUSD": "1664.822319026630533271014972347018"
    },
    {
        "date": 1675382400,
        "volume": "511232.522854478172144554",
        "open": "1643.965171515638679473327030709019",
        "close": "1664.896135488040345051365277143936",
        "untrackedVolumeUSD": "845031603.7347159125990653499769701",
        "priceUSD": "1664.896135488040345051365277143936"
    },
    {
        "date": 1675296000,
        "volume": "722912.594069627693674129",
        "open": "1642.407213601986373827245003334429",
        "close": "1643.965171515638679473327030709019",
        "untrackedVolumeUSD": "1206994827.489289506227512573238459",
        "priceUSD": "1643.965171515638679473327030709019"
    },
    {
        "date": 1675209600,
        "volume": "578676.715641080416870869",
        "open": "1586.724267051153424121673354385305",
        "close": "1642.407213601986373827245003334429",
        "untrackedVolumeUSD": "923761588.5947634151100201182122459",
        "priceUSD": "1642.407213601986373827245003334429"
    },
    {
        "date": 1675123200,
        "volume": "359623.930478426240743033",
        "open": "1563.443639670593290693367032796737",
        "close": "1586.724267051153424121673354385305",
        "untrackedVolumeUSD": "568653920.3772214056511204132625401",
        "priceUSD": "1586.724267051153424121673354385305"
    },
    {
        "date": 1675036800,
        "volume": "510896.365951565886884107",
        "open": "1646.712400754135044140242750364377",
        "close": "1563.443639670593290693367032796737",
        "untrackedVolumeUSD": "810858283.5448314055909302784802995",
        "priceUSD": "1563.443639670593290693367032796737"
    },
    {
        "date": 1674950400,
        "volume": "424253.136858413961000456",
        "open": "1569.237813178635386404705927172591",
        "close": "1646.712400754135044140242750364377",
        "untrackedVolumeUSD": "684738782.6008384908688961761426886",
        "priceUSD": "1646.712400754135044140242750364377"
    },
    {
        "date": 1674864000,
        "volume": "313985.018322918149058307",
        "open": "1594.582633603022788688822689590044",
        "close": "1569.237813178635386404705927172591",
        "untrackedVolumeUSD": "498186936.9504608800366929341909575",
        "priceUSD": "1569.237813178635386404705927172591"
    },
    {
        "date": 1674777600,
        "volume": "521407.515701662286022341",
        "open": "1603.295436855563260888623674967254",
        "close": "1594.582633603022788688822689590044",
        "untrackedVolumeUSD": "826842971.6242348935203198827081134",
        "priceUSD": "1594.582633603022788688822689590044"
    },
    {
        "date": 1674691200,
        "volume": "488521.915229850472576642",
        "open": "1618.411084858304210716432345124273",
        "close": "1603.295436855563260888623674967254",
        "untrackedVolumeUSD": "785386936.9248685465339444010212378",
        "priceUSD": "1603.295436855563260888623674967254"
    },
    {
        "date": 1674604800,
        "volume": "765534.753890314656432474",
        "open": "1551.669659048602680206152597538615",
        "close": "1618.411084858304210716432345124273",
        "untrackedVolumeUSD": "1204173836.17867300822157293368868",
        "priceUSD": "1618.411084858304210716432345124273"
    },
    {
        "date": 1674518400,
        "volume": "510807.600536000963109782",
        "open": "1628.734426119914479538993717916591",
        "close": "1551.669659048602680206152597538615",
        "untrackedVolumeUSD": "818244754.0661087593410148721589337",
        "priceUSD": "1551.669659048602680206152597538615"
    },
    {
        "date": 1674432000,
        "volume": "463150.637426717363544979",
        "open": "1627.68834868115402521765054717578",
        "close": "1628.734426119914479538993717916591",
        "untrackedVolumeUSD": "755263234.468207087828373525698932",
        "priceUSD": "1628.734426119914479538993717916591"
    },
    {
        "date": 1674345600,
        "volume": "438768.087924857768761264",
        "open": "1627.517080493400330008667508833769",
        "close": "1627.68834868115402521765054717578",
        "untrackedVolumeUSD": "715539390.38841616279725375150757",
        "priceUSD": "1627.68834868115402521765054717578"
    },
    {
        "date": 1674259200,
        "volume": "568410.41021231833195901",
        "open": "1657.759752408764281943596656998239",
        "close": "1627.517080493400330008667508833769",
        "untrackedVolumeUSD": "940784287.5784802241106958097516969",
        "priceUSD": "1627.517080493400330008667508833769"
    },
    {
        "date": 1674172800,
        "volume": "428950.398865509188353713",
        "open": "1550.601054458855875816859672128303",
        "close": "1657.759752408764281943596656998239",
        "untrackedVolumeUSD": "684330635.6604965775395842468101419",
        "priceUSD": "1657.759752408764281943596656998239"
    },
    {
        "date": 1674086400,
        "volume": "382845.369294376754315437",
        "open": "1512.690339106959587761422695071655",
        "close": "1550.601054458855875816859672128303",
        "untrackedVolumeUSD": "587260349.3079875709455621084675136",
        "priceUSD": "1550.601054458855875816859672128303"
    },
    {
        "date": 1674000000,
        "volume": "890134.65651355136636721",
        "open": "1569.217580068324378546807338807343",
        "close": "1512.690339106959587761422695071655",
        "untrackedVolumeUSD": "1382339328.551470154900946439612526",
        "priceUSD": "1512.690339106959587761422695071655"
    },
    {
        "date": 1673913600,
        "volume": "453091.522791037378090213",
        "open": "1579.010741975927715859483572703891",
        "close": "1569.217580068324378546807338807343",
        "untrackedVolumeUSD": "714419499.975277650668295063658543",
        "priceUSD": "1569.217580068324378546807338807343"
    },
    {
        "date": 1673827200,
        "volume": "592666.166224720147475518",
        "open": "1553.894080849592949568154315393411",
        "close": "1579.010741975927715859483572703891",
        "untrackedVolumeUSD": "927272685.9872079161375689641743656",
        "priceUSD": "1579.010741975927715859483572703891"
    },
    {
        "date": 1673740800,
        "volume": "496991.892386675680935769",
        "open": "1552.063380157812484020327712143448",
        "close": "1553.894080849592949568154315393411",
        "untrackedVolumeUSD": "764836615.4563311404644138813676039",
        "priceUSD": "1553.894080849592949568154315393411"
    },
    {
        "date": 1673654400,
        "volume": "861465.431218706421830123",
        "open": "1447.897493887515578066647531893699",
        "close": "1552.063380157812484020327712143448",
        "untrackedVolumeUSD": "1324236547.394969829518451674667432",
        "priceUSD": "1552.063380157812484020327712143448"
    },
    {
        "date": 1673568000,
        "volume": "644171.703848883707047376",
        "open": "1419.835994890141270663811071322135",
        "close": "1447.897493887515578066647531893699",
        "untrackedVolumeUSD": "917577682.3193643037034125501962233",
        "priceUSD": "1447.897493887515578066647531893699"
    },
    {
        "date": 1673481600,
        "volume": "784866.263588645932249705",
        "open": "1387.996794952931729737247494643574",
        "close": "1419.835994890141270663811071322135",
        "untrackedVolumeUSD": "1105912681.059535801525426155978784",
        "priceUSD": "1419.835994890141270663811071322135"
    },
    {
        "date": 1673395200,
        "volume": "364098.198449840678516597",
        "open": "1337.925269331333081225774756554687",
        "close": "1387.996794952931729737247494643574",
        "untrackedVolumeUSD": "489191087.0767286485315016987739612",
        "priceUSD": "1387.996794952931729737247494643574"
    },
    {
        "date": 1673308800,
        "volume": "315026.214126236344985369",
        "open": "1319.346163232257037208274413560918",
        "close": "1337.925269331333081225774756554687",
        "untrackedVolumeUSD": "418825903.012890557677194298647409",
        "priceUSD": "1337.925269331333081225774756554687"
    },
    {
        "date": 1673222400,
        "volume": "573090.513464299566442683",
        "open": "1289.502261736667635864992613007314",
        "close": "1319.346163232257037208274413560918",
        "untrackedVolumeUSD": "755399577.8843916439906206159091464",
        "priceUSD": "1319.346163232257037208274413560918"
    },
    {
        "date": 1673136000,
        "volume": "283053.126441465979979598",
        "open": "1262.187471475887798868540925134317",
        "close": "1289.502261736667635864992613007314",
        "untrackedVolumeUSD": "359688603.9639344912720211034064806",
        "priceUSD": "1289.502261736667635864992613007314"
    },
    {
        "date": 1673049600,
        "volume": "120139.602087278646090169",
        "open": "1268.093822020362841021061864554936",
        "close": "1262.187471475887798868540925134317",
        "untrackedVolumeUSD": "151886111.7107974296125342190004081",
        "priceUSD": "1262.187471475887798868540925134317"
    },
    {
        "date": 1672963200,
        "volume": "304117.999535752557132341",
        "open": "1250.936338246894559333010842690685",
        "close": "1268.093822020362841021061864554936",
        "untrackedVolumeUSD": "381929272.0726761940409982375249409",
        "priceUSD": "1268.093822020362841021061864554936"
    },
    {
        "date": 1672876800,
        "volume": "197159.190806849609014654",
        "open": "1253.344908056070900194344118212132",
        "close": "1250.936338246894559333010842690685",
        "untrackedVolumeUSD": "246569505.5563618936292101588890734",
        "priceUSD": "1250.936338246894559333010842690685"
    },
    {
        "date": 1672790400,
        "volume": "452198.459647351585678526",
        "open": "1211.782279533189386095412797387077",
        "close": "1253.344908056070900194344118212132",
        "untrackedVolumeUSD": "564870195.4740888019972802412283148",
        "priceUSD": "1253.344908056070900194344118212132"
    },
    {
        "date": 1672704000,
        "volume": "178245.067696616792253103",
        "open": "1215.162167595281247322041548145568",
        "close": "1211.782279533189386095412797387077",
        "untrackedVolumeUSD": "216069804.0643228898722597819519487",
        "priceUSD": "1211.782279533189386095412797387077"
    },
    {
        "date": 1672617600,
        "volume": "234416.328144361489173755",
        "open": "1200.124464022518289435833876325203",
        "close": "1215.162167595281247322041548145568",
        "untrackedVolumeUSD": "284268605.020864155986342400950858",
        "priceUSD": "1215.162167595281247322041548145568"
    },
    {
        "date": 1672531200,
        "volume": "168936.704749793250996972",
        "open": "1196.13702549973826005002222432329",
        "close": "1200.124464022518289435833876325203",
        "untrackedVolumeUSD": "202072238.1815474870489432906479291",
        "priceUSD": "1200.124464022518289435833876325203"
    },
    {
        "date": 1672444800,
        "volume": "183721.532781218289988867",
        "open": "1197.600863912900849286639767522666",
        "close": "1196.13702549973826005002222432329",
        "untrackedVolumeUSD": "220022412.3032816142145888350799252",
        "priceUSD": "1196.13702549973826005002222432329"
    },
    {
        "date": 1672358400,
        "volume": "184509.667624164223034639",
        "open": "1202.308319308984349566811962084928",
        "close": "1197.600863912900849286639767522666",
        "untrackedVolumeUSD": "220454550.1257735381365501037652229",
        "priceUSD": "1197.600863912900849286639767522666"
    },
    {
        "date": 1672272000,
        "volume": "223944.820073053559858649",
        "open": "1186.971320083901401047228206242491",
        "close": "1202.308319308984349566811962084928",
        "untrackedVolumeUSD": "267950536.9253045487181350105091051",
        "priceUSD": "1202.308319308984349566811962084928"
    },
    {
        "date": 1672185600,
        "volume": "339490.056718607576118977",
        "open": "1210.237684135963313298666708731873",
        "close": "1186.971320083901401047228206242491",
        "untrackedVolumeUSD": "406159759.5173244426082448082088551",
        "priceUSD": "1186.971320083901401047228206242491"
    },
    {
        "date": 1672099200,
        "volume": "286271.904610628300029356",
        "open": "1226.354880330688424221165310582482",
        "close": "1210.237684135963313298666708731873",
        "untrackedVolumeUSD": "347619732.6150789752838279011842161",
        "priceUSD": "1210.237684135963313298666708731873"
    },
    {
        "date": 1672012800,
        "volume": "120931.67837884172925577",
        "open": "1218.702734723673967977930275008837",
        "close": "1226.354880330688424221165310582482",
        "untrackedVolumeUSD": "147356901.5298935594427280909657903",
        "priceUSD": "1226.354880330688424221165310582482"
    },
    {
        "date": 1671926400,
        "volume": "200700.228421249875919198",
        "open": "1221.385954100501076083659295708535",
        "close": "1218.702734723673967977930275008837",
        "untrackedVolumeUSD": "243319638.2990741504627924236545301",
        "priceUSD": "1218.702734723673967977930275008837"
    },
    {
        "date": 1671840000,
        "volume": "82725.633599695485984905",
        "open": "1219.737676311395358218611685447785",
        "close": "1221.385954100501076083659295708535",
        "untrackedVolumeUSD": "100804327.0373606842501865386602174",
        "priceUSD": "1221.385954100501076083659295708535"
    },
    {
        "date": 1671753600,
        "volume": "250428.11758174260419022",
        "open": "1216.805311074263419188395370754825",
        "close": "1219.737676311395358218611685447785",
        "untrackedVolumeUSD": "306585199.1243961324122940492998516",
        "priceUSD": "1219.737676311395358218611685447785"
    },
    {
        "date": 1671667200,
        "volume": "278625.457271838890580155",
        "open": "1211.225659755964477226500671219718",
        "close": "1216.805311074263419188395370754825",
        "untrackedVolumeUSD": "337256431.0471961916688162233431837",
        "priceUSD": "1216.805311074263419188395370754825"
    },
    {
        "date": 1671580800,
        "volume": "198738.989588982716864639",
        "open": "1216.84557529055680163574055753491",
        "close": "1211.225659755964477226500671219718",
        "untrackedVolumeUSD": "240889991.6435319883663599872248799",
        "priceUSD": "1211.225659755964477226500671219718"
    },
    {
        "date": 1671494400,
        "volume": "407883.066863741055176804",
        "open": "1165.956993647043207746106985182746",
        "close": "1216.84557529055680163574055753491",
        "untrackedVolumeUSD": "491974921.4378822566607276572867528",
        "priceUSD": "1216.84557529055680163574055753491"
    },
    {
        "date": 1671408000,
        "volume": "332429.017974445390521609",
        "open": "1185.084001428402245690482548960943",
        "close": "1165.956993647043207746106985182746",
        "untrackedVolumeUSD": "391245094.6768876216116610576578414",
        "priceUSD": "1165.956993647043207746106985182746"
    },
    {
        "date": 1671321600,
        "volume": "202390.283490794121839496",
        "open": "1186.011260000000074158134863482399",
        "close": "1185.084001428402245690482548960943",
        "untrackedVolumeUSD": "239687904.043297797673799475475185",
        "priceUSD": "1185.084001428402245690482548960943"
    },
    {
        "date": 1671235200,
        "volume": "405377.13826253653582579",
        "open": "1164.720229164287026834693027070717",
        "close": "1186.011260000000074158134863482399",
        "untrackedVolumeUSD": "477549599.2575103810665761376224503",
        "priceUSD": "1186.011260000000074158134863482399"
    },
    {
        "date": 1671148800,
        "volume": "799438.211860346927693262",
        "open": "1263.996886495760901486913812890323",
        "close": "1164.720229164287026834693027070717",
        "untrackedVolumeUSD": "962458839.6212495157519549637632965",
        "priceUSD": "1164.720229164287026834693027070717"
    },
    {
        "date": 1671062400,
        "volume": "368541.719559275854094681",
        "open": "1309.661539739560004805228581307379",
        "close": "1263.996886495760901486913812890323",
        "untrackedVolumeUSD": "471085846.3412696803685464835508461",
        "priceUSD": "1263.996886495760901486913812890323"
    },
    {
        "date": 1670976000,
        "volume": "476295.572587121593151742",
        "open": "1318.827021209748314065431491306726",
        "close": "1309.661539739560004805228581307379",
        "untrackedVolumeUSD": "629336421.3442201064029371659538218",
        "priceUSD": "1309.661539739560004805228581307379"
    },
    {
        "date": 1670889600,
        "volume": "627146.826881153201233809",
        "open": "1274.611143782710476702946338445493",
        "close": "1318.827021209748314065431491306726",
        "untrackedVolumeUSD": "820868624.5168641284047267814999167",
        "priceUSD": "1318.827021209748314065431491306726"
    },
    {
        "date": 1670803200,
        "volume": "282053.290177251334690637",
        "open": "1263.208703042992436708008258670564",
        "close": "1274.611143782710476702946338445493",
        "untrackedVolumeUSD": "353910393.8300306748418277613444596",
        "priceUSD": "1274.611143782710476702946338445493"
    },
    {
        "date": 1670716800,
        "volume": "183137.788988185247818686",
        "open": "1266.614660879179194024297647797546",
        "close": "1263.208703042992436708008258670564",
        "untrackedVolumeUSD": "232676478.9675602015410704502958712",
        "priceUSD": "1263.208703042992436708008258670564"
    },
    {
        "date": 1670630400,
        "volume": "159052.012407566794096639",
        "open": "1261.524110360818482177433006336446",
        "close": "1266.614660879179194024297647797546",
        "untrackedVolumeUSD": "201721336.105904147052414283199333",
        "priceUSD": "1266.614660879179194024297647797546"
    },
    {
        "date": 1670544000,
        "volume": "276098.327360518534814132",
        "open": "1282.529343404963032932552844415571",
        "close": "1261.524110360818482177433006336446",
        "untrackedVolumeUSD": "352411992.7943938487934353286945917",
        "priceUSD": "1261.524110360818482177433006336446"
    },
    {
        "date": 1670457600,
        "volume": "358825.930847963561116267",
        "open": "1231.217888166617760426839471923467",
        "close": "1282.529343404963032932552844415571",
        "untrackedVolumeUSD": "450239596.7987844236592726555231408",
        "priceUSD": "1282.529343404963032932552844415571"
    },
    {
        "date": 1670371200,
        "volume": "312656.643365910069756697",
        "open": "1270.230947964217587710950632308095",
        "close": "1231.217888166617760426839471923467",
        "untrackedVolumeUSD": "386906955.4821568958177004783366664",
        "priceUSD": "1231.217888166617760426839471923467"
    },
    {
        "date": 1670284800,
        "volume": "362332.427500262946914614",
        "open": "1257.006124266697566822753996038765",
        "close": "1270.230947964217587710950632308095",
        "untrackedVolumeUSD": "454991822.1542377596231452346036883",
        "priceUSD": "1270.230947964217587710950632308095"
    },
    {
        "date": 1670198400,
        "volume": "351372.880014004271150922",
        "open": "1280.471619301194229576954218399296",
        "close": "1257.006124266697566822753996038765",
        "untrackedVolumeUSD": "449892463.2362307319341493872375943",
        "priceUSD": "1257.006124266697566822753996038765"
    },
    {
        "date": 1670112000,
        "volume": "248540.358292987418729859",
        "open": "1240.603949121483454432341668415836",
        "close": "1280.471619301194229576954218399296",
        "untrackedVolumeUSD": "313612731.8213755169724569792948251",
        "priceUSD": "1280.471619301194229576954218399296"
    },
    {
        "date": 1670025600,
        "volume": "250253.571448803346456336",
        "open": "1292.380097228987163620512707423318",
        "close": "1240.603949121483454432341668415836",
        "untrackedVolumeUSD": "317901475.2188260941394706690696342",
        "priceUSD": "1240.603949121483454432341668415836"
    },
    {
        "date": 1669939200,
        "volume": "331917.27887444308973691",
        "open": "1276.834206185891749200405636407027",
        "close": "1292.380097228987163620512707423318",
        "untrackedVolumeUSD": "424903831.9285118083138192130415193",
        "priceUSD": "1292.380097228987163620512707423318"
    },
    {
        "date": 1669852800,
        "volume": "352865.493882734447212384",
        "open": "1297.411640099043800638071902915701",
        "close": "1276.834206185891749200405636407027",
        "untrackedVolumeUSD": "451886511.7035117738546446684537566",
        "priceUSD": "1276.834206185891749200405636407027"
    },
    {
        "date": 1669766400,
        "volume": "670773.326594543608254786",
        "open": "1218.671024137370690558473170521782",
        "close": "1297.411640099043800638071902915701",
        "untrackedVolumeUSD": "854586575.9190095083953389852745382",
        "priceUSD": "1297.411640099043800638071902915701"
    },
    {
        "date": 1669680000,
        "volume": "363748.911203124680281523",
        "open": "1170.22934897191401673489758142137",
        "close": "1218.671024137370690558473170521782",
        "untrackedVolumeUSD": "438147318.6123067987916335230031211",
        "priceUSD": "1218.671024137370690558473170521782"
    },
    {
        "date": 1669593600,
        "volume": "465080.396908304471708602",
        "open": "1192.919049239307377880288636862315",
        "close": "1170.22934897191401673489758142137",
        "untrackedVolumeUSD": "544593399.4631171394244348592967882",
        "priceUSD": "1170.22934897191401673489758142137"
    },
    {
        "date": 1669507200,
        "volume": "269287.51844949834388629",
        "open": "1202.827865308465447924318856775841",
        "close": "1192.919049239307377880288636862315",
        "untrackedVolumeUSD": "326531692.9678727606632874299058959",
        "priceUSD": "1192.919049239307377880288636862315"
    },
    {
        "date": 1669420800,
        "volume": "358214.817781381485222083",
        "open": "1198.529049536224644802418017517451",
        "close": "1202.827865308465447924318856775841",
        "untrackedVolumeUSD": "435080352.2293160417354447144040618",
        "priceUSD": "1202.827865308465447924318856775841"
    },
    {
        "date": 1669334400,
        "volume": "451483.909334100317143893",
        "open": "1201.33158887383610389697403694381",
        "close": "1198.529049536224644802418017517451",
        "untrackedVolumeUSD": "536898391.0898417022824845533135026",
        "priceUSD": "1198.529049536224644802418017517451"
    },
    {
        "date": 1669248000,
        "volume": "447101.736558446371527914",
        "open": "1184.309758418303094257344892597572",
        "close": "1201.33158887383610389697403694381",
        "untrackedVolumeUSD": "536516261.9207973541946905374524914",
        "priceUSD": "1201.33158887383610389697403694381"
    },
    {
        "date": 1669161600,
        "volume": "593732.160193857179337346",
        "open": "1135.595753430623814518612786069873",
        "close": "1184.309758418303094257344892597572",
        "untrackedVolumeUSD": "691538080.8391077788985977165239986",
        "priceUSD": "1184.309758418303094257344892597572"
    },
    {
        "date": 1669075200,
        "volume": "780572.133277228328335463",
        "open": "1106.887346426861665775805851137555",
        "close": "1135.595753430623814518612786069873",
        "untrackedVolumeUSD": "868576582.8010762879291591105066673",
        "priceUSD": "1135.595753430623814518612786069873"
    },
    {
        "date": 1668988800,
        "volume": "979134.684364860353577733",
        "open": "1138.657100479870702900872203630991",
        "close": "1106.887346426861665775805851137555",
        "untrackedVolumeUSD": "1091432471.283946265259169243022453",
        "priceUSD": "1106.887346426861665775805851137555"
    },
    {
        "date": 1668902400,
        "volume": "542879.602895793485396525",
        "open": "1219.055477524759673689796772666279",
        "close": "1138.657100479870702900872203630991",
        "untrackedVolumeUSD": "638939332.639525595440233739050224",
        "priceUSD": "1138.657100479870702900872203630991"
    },
    {
        "date": 1668816000,
        "volume": "224872.591365744488043169",
        "open": "1209.30318128171719699890089992734",
        "close": "1219.055477524759673689796772666279",
        "untrackedVolumeUSD": "272385279.8155813581387063911513476",
        "priceUSD": "1219.055477524759673689796772666279"
    },
    {
        "date": 1668729600,
        "volume": "318654.061509396878585416",
        "open": "1199.967238985312205085188531414667",
        "close": "1209.30318128171719699890089992734",
        "untrackedVolumeUSD": "386568788.3941427309687804585973982",
        "priceUSD": "1209.30318128171719699890089992734"
    },
    {
        "date": 1668643200,
        "volume": "505920.60648120616879715",
        "open": "1213.948508627196817096424488299763",
        "close": "1199.967238985312205085188531414667",
        "untrackedVolumeUSD": "608528864.1157390373218940134381598",
        "priceUSD": "1199.967238985312205085188531414667"
    },
    {
        "date": 1668556800,
        "volume": "675980.19143092207789841",
        "open": "1248.109449922940806658879608437984",
        "close": "1213.948508627196817096424488299763",
        "untrackedVolumeUSD": "825163423.3260184098696322877627321",
        "priceUSD": "1213.948508627196817096424488299763"
    },
    {
        "date": 1668470400,
        "volume": "694410.697122427568782695",
        "open": "1242.937862533742126125897241118443",
        "close": "1248.109449922940806658879608437984",
        "untrackedVolumeUSD": "876586716.3896212100937140433915372",
        "priceUSD": "1248.109449922940806658879608437984"
    },
    {
        "date": 1668384000,
        "volume": "1021468.180829137117467158",
        "open": "1219.855750564753304427638075757563",
        "close": "1242.937862533742126125897241118443",
        "untrackedVolumeUSD": "1257768484.679000007544666944530184",
        "priceUSD": "1242.937862533742126125897241118443"
    },
    {
        "date": 1668297600,
        "volume": "581444.183603475565732509",
        "open": "1251.317193778265292495737816982528",
        "close": "1219.855750564753304427638075757563",
        "untrackedVolumeUSD": "717430092.7694216392936973273655578",
        "priceUSD": "1219.855750564753304427638075757563"
    },
    {
        "date": 1668211200,
        "volume": "850490.456535342734616422",
        "open": "1287.585338190992576439048928961108",
        "close": "1251.317193778265292495737816982528",
        "untrackedVolumeUSD": "1073804612.372964074391589622587441",
        "priceUSD": "1251.317193778265292495737816982528"
    },
    {
        "date": 1668124800,
        "volume": "1424251.082104780615437285",
        "open": "1296.428680635827479638427919586898",
        "close": "1287.585338190992576439048928961108",
        "untrackedVolumeUSD": "1794343001.874641037102657310394128",
        "priceUSD": "1287.585338190992576439048928961108"
    },
    {
        "date": 1668038400,
        "volume": "2438472.561743576361688708",
        "open": "1098.458691163302253852349430776776",
        "close": "1296.428680635827479638427919586898",
        "untrackedVolumeUSD": "2996668125.140269317599919569166917",
        "priceUSD": "1296.428680635827479638427919586898"
    },
    {
        "date": 1667952000,
        "volume": "3219300.796059922274027923",
        "open": "1333.692427597191420931045478905821",
        "close": "1098.458691163302253852349430776776",
        "untrackedVolumeUSD": "3845091524.915737298318729123072397",
        "priceUSD": "1098.458691163302253852349430776776"
    },
    {
        "date": 1667865600,
        "volume": "2338568.267049402839842613",
        "open": "1565.069802157205287792469954554942",
        "close": "1333.692427597191420931045478905821",
        "untrackedVolumeUSD": "3314411318.628327576261895585634947",
        "priceUSD": "1333.692427597191420931045478905821"
    },
    {
        "date": 1667779200,
        "volume": "593364.279756587584194063",
        "open": "1571.970725121304001334410675311333",
        "close": "1565.069802157205287792469954554942",
        "untrackedVolumeUSD": "935624692.0608885735031061378119865",
        "priceUSD": "1565.069802157205287792469954554942"
    },
    {
        "date": 1667692800,
        "volume": "359703.451498550562832972",
        "open": "1626.77622379102411073693248912975",
        "close": "1571.970725121304001334410675311333",
        "untrackedVolumeUSD": "579492374.2890954659280261442681825",
        "priceUSD": "1571.970725121304001334410675311333"
    },
    {
        "date": 1667606400,
        "volume": "293398.676450151432825816",
        "open": "1646.647026324186176225650244011932",
        "close": "1626.77622379102411073693248912975",
        "untrackedVolumeUSD": "481465200.0098325591812841326922263",
        "priceUSD": "1626.77622379102411073693248912975"
    },
    {
        "date": 1667520000,
        "volume": "756770.222879785582718772",
        "open": "1529.304096263005912959856349062099",
        "close": "1646.647026324186176225650244011932",
        "untrackedVolumeUSD": "1220837457.348773319681050451750209",
        "priceUSD": "1646.647026324186176225650244011932"
    },
    {
        "date": 1667433600,
        "volume": "356855.52992583633004212",
        "open": "1517.620713078554006032973981191753",
        "close": "1529.304096263005912959856349062099",
        "untrackedVolumeUSD": "549854167.2628869068409434285096314",
        "priceUSD": "1529.304096263005912959856349062099"
    },
    {
        "date": 1667347200,
        "volume": "843278.603188416080691374",
        "open": "1577.83224877893399354683576796395",
        "close": "1517.620713078554006032973981191753",
        "untrackedVolumeUSD": "1315634225.355644671889951608410095",
        "priceUSD": "1517.620713078554006032973981191753"
    },
    {
        "date": 1667260800,
        "volume": "378516.98759030438730957",
        "open": "1572.720229730455351033692119083047",
        "close": "1577.83224877893399354683576796395",
        "untrackedVolumeUSD": "599801915.6105740932296099856947582",
        "priceUSD": "1577.83224877893399354683576796395"
    },
    {
        "date": 1667174400,
        "volume": "624345.200464251711146333",
        "open": "1588.46706029912075337252864176776",
        "close": "1572.720229730455351033692119083047",
        "untrackedVolumeUSD": "988059544.5670922374250938359726671",
        "priceUSD": "1572.720229730455351033692119083047"
    },
    {
        "date": 1667088000,
        "volume": "434980.406298277465937287",
        "open": "1617.143006103515410829386830025178",
        "close": "1588.46706029912075337252864176776",
        "untrackedVolumeUSD": "696800907.3746210897489398770070763",
        "priceUSD": "1588.46706029912075337252864176776"
    },
    {
        "date": 1667001600,
        "volume": "666137.138735414071651749",
        "open": "1557.247718963406947597861476697103",
        "close": "1617.143006103515410829386830025178",
        "untrackedVolumeUSD": "1073513392.808275917807826551905209",
        "priceUSD": "1617.143006103515410829386830025178"
    },
    {
        "date": 1666915200,
        "volume": "648480.57027760657922292",
        "open": "1510.390353691249282308647600713915",
        "close": "1557.247718963406947597861476697103",
        "untrackedVolumeUSD": "990551610.1605330844719361384926022",
        "priceUSD": "1557.247718963406947597861476697103"
    },
    {
        "date": 1666828800,
        "volume": "642630.808489295382153404",
        "open": "1567.861807094520288869788932106172",
        "close": "1510.390353691249282308647600713915",
        "untrackedVolumeUSD": "994344483.5070843720871637249066214",
        "priceUSD": "1510.390353691249282308647600713915"
    },
    {
        "date": 1666742400,
        "volume": "819608.678754846690381111",
        "open": "1460.197283081249814687522034733243",
        "close": "1567.861807094520288869788932106172",
        "untrackedVolumeUSD": "1259412596.276358465813366541675462",
        "priceUSD": "1567.861807094520288869788932106172"
    },
    {
        "date": 1666656000,
        "volume": "918676.129168091250724968",
        "open": "1345.616292745401844642987367110919",
        "close": "1460.197283081249814687522034733243",
        "untrackedVolumeUSD": "1314606119.331162714096053646821447",
        "priceUSD": "1460.197283081249814687522034733243"
    },
    {
        "date": 1666569600,
        "volume": "414465.876933647532779289",
        "open": "1364.37086670639339466488104862123",
        "close": "1345.616292745401844642987367110919",
        "untrackedVolumeUSD": "557165282.4629339918269273141837884",
        "priceUSD": "1345.616292745401844642987367110919"
    },
    {
        "date": 1666483200,
        "volume": "292781.06005630040926013",
        "open": "1314.313003912999666914995456979141",
        "close": "1364.37086670639339466488104862123",
        "untrackedVolumeUSD": "389106271.9759111380390524965241304",
        "priceUSD": "1364.37086670639339466488104862123"
    },
    {
        "date": 1666396800,
        "volume": "189515.649480294073222858",
        "open": "1301.216954127304884640747819530381",
        "close": "1314.313003912999666914995456979141",
        "untrackedVolumeUSD": "247719032.672096529375299185973363",
        "priceUSD": "1314.313003912999666914995456979141"
    },
    {
        "date": 1666310400,
        "volume": "424313.120058724363074828",
        "open": "1281.895918307704080994075924540092",
        "close": "1301.216954127304884640747819530381",
        "untrackedVolumeUSD": "545355137.9884045816267396592060647",
        "priceUSD": "1301.216954127304884640747819530381"
    },
    {
        "date": 1666224000,
        "volume": "290590.754238703768797194",
        "open": "1284.200515474003751060653141186454",
        "close": "1281.895918307704080994075924540092",
        "untrackedVolumeUSD": "374654331.144413866610598277020415",
        "priceUSD": "1281.895918307704080994075924540092"
    },
    {
        "date": 1666137600,
        "volume": "313382.113796034699076441",
        "open": "1311.38802130763767426012938306964",
        "close": "1284.200515474003751060653141186454",
        "untrackedVolumeUSD": "406798014.1180710967881741003806285",
        "priceUSD": "1284.200515474003751060653141186454"
    },
    {
        "date": 1666051200,
        "volume": "431143.678982169876382037",
        "open": "1330.502956392938160886505804550747",
        "close": "1311.38802130763767426012938306964",
        "untrackedVolumeUSD": "568963402.8075446983453101879255059",
        "priceUSD": "1311.38802130763767426012938306964"
    },
    {
        "date": 1665964800,
        "volume": "461044.791179779010906015",
        "open": "1306.526486006612380881812742961407",
        "close": "1330.502956392938160886505804550747",
        "untrackedVolumeUSD": "609278589.6686231029124796756749459",
        "priceUSD": "1330.502956392938160886505804550747"
    },
    {
        "date": 1665878400,
        "volume": "215329.81647463113082966",
        "open": "1275.91346250637987999081534405624",
        "close": "1306.526486006612380881812742961407",
        "untrackedVolumeUSD": "278113279.1844527778555205199596449",
        "priceUSD": "1306.526486006612380881812742961407"
    },
    {
        "date": 1665792000,
        "volume": "223925.978698933707895116",
        "open": "1295.149732717195418842108011590024",
        "close": "1275.91346250637987999081534405624",
        "untrackedVolumeUSD": "287035290.7238266694773881669364184",
        "priceUSD": "1275.91346250637987999081534405624"
    },
    {
        "date": 1665705600,
        "volume": "532071.24645461246053439",
        "open": "1289.403401601435701338841214508262",
        "close": "1295.149732717195418842108011590024",
        "untrackedVolumeUSD": "701011851.5485878369435136587995253",
        "priceUSD": "1295.149732717195418842108011590024"
    },
    {
        "date": 1665619200,
        "volume": "582610.861385355926008567",
        "open": "1296.630150365266287831481041099231",
        "close": "1289.403401601435701338841214508262",
        "untrackedVolumeUSD": "727950659.5565197061237805299276271",
        "priceUSD": "1289.403401601435701338841214508262"
    },
    {
        "date": 1665532800,
        "volume": "175727.87981764044801344",
        "open": "1278.003681723359277660886379932932",
        "close": "1296.630150365266287831481041099231",
        "untrackedVolumeUSD": "227367261.3554244757665141028373159",
        "priceUSD": "1296.630150365266287831481041099231"
    },
    {
        "date": 1665446400,
        "volume": "296293.458075942738382006",
        "open": "1292.464713868673530832747429549476",
        "close": "1278.003681723359277660886379932932",
        "untrackedVolumeUSD": "379533339.8939944246264153250175125",
        "priceUSD": "1278.003681723359277660886379932932"
    },
    {
        "date": 1665360000,
        "volume": "273859.165152256152322774",
        "open": "1319.417407119561749546497784717757",
        "close": "1292.464713868673530832747429549476",
        "untrackedVolumeUSD": "359059711.4862819553131349525391881",
        "priceUSD": "1292.464713868673530832747429549476"
    },
    {
        "date": 1665273600,
        "volume": "154431.697598918323333186",
        "open": "1313.481352943806512332934276010947",
        "close": "1319.417407119561749546497784717757",
        "untrackedVolumeUSD": "204527566.1010020821276617570034608",
        "priceUSD": "1319.417407119561749546497784717757"
    },
    {
        "date": 1665187200,
        "volume": "134787.200464462724110317",
        "open": "1332.713142017844558632982489237189",
        "close": "1313.481352943806512332934276010947",
        "untrackedVolumeUSD": "178699447.8454172094984141783042153",
        "priceUSD": "1313.481352943806512332934276010947"
    },
    {
        "date": 1665100800,
        "volume": "308855.645671571898905756",
        "open": "1350.781122709413048706767254853051",
        "close": "1332.713142017844558632982489237189",
        "untrackedVolumeUSD": "413703072.4891781164111286690241228",
        "priceUSD": "1332.713142017844558632982489237189"
    },
    {
        "date": 1665014400,
        "volume": "519390.98297162331650102",
        "open": "1353.208490815833622269870106819884",
        "close": "1350.781122709413048706767254853051",
        "untrackedVolumeUSD": "708854312.929461689225279608060202",
        "priceUSD": "1350.781122709413048706767254853051"
    },
    {
        "date": 1664928000,
        "volume": "332917.311161491079569818",
        "open": "1362.079265260310293755356052278897",
        "close": "1353.208490815833622269870106819884",
        "untrackedVolumeUSD": "447660135.9900297587465513349752714",
        "priceUSD": "1353.208490815833622269870106819884"
    },
    {
        "date": 1664841600,
        "volume": "363485.408618709447416007",
        "open": "1323.86772774433694607452889310959",
        "close": "1362.079265260310293755356052278897",
        "untrackedVolumeUSD": "489904029.8125588935175281596147279",
        "priceUSD": "1362.079265260310293755356052278897"
    },
    {
        "date": 1664755200,
        "volume": "324346.640435112812175671",
        "open": "1276.988933068001344657406441014578",
        "close": "1323.86772774433694607452889310959",
        "untrackedVolumeUSD": "423921302.8495241396442682712821614",
        "priceUSD": "1323.86772774433694607452889310959"
    },
    {
        "date": 1664668800,
        "volume": "265861.652526971890928967",
        "open": "1312.461687591151521909614359353653",
        "close": "1276.988933068001344657406441014578",
        "untrackedVolumeUSD": "344930929.9568437455287344843232103",
        "priceUSD": "1276.988933068001344657406441014578"
    },
    {
        "date": 1664582400,
        "volume": "180786.561495442741313886",
        "open": "1325.434269220712381182719262898217",
        "close": "1312.461687591151521909614359353653",
        "untrackedVolumeUSD": "238581117.4667043910774431036330384",
        "priceUSD": "1312.461687591151521909614359353653"
    },
    {
        "date": 1664496000,
        "volume": "526017.266125212029381477",
        "open": "1337.045456855046794234655968633555",
        "close": "1325.434269220712381182719262898217",
        "untrackedVolumeUSD": "704961173.4076058861980659395666692",
        "priceUSD": "1325.434269220712381182719262898217"
    },
    {
        "date": 1664409600,
        "volume": "508213.377499555534650519",
        "open": "1338.554927912188001871128752689706",
        "close": "1337.045456855046794234655968633555",
        "untrackedVolumeUSD": "675233765.5152557586824383728941876",
        "priceUSD": "1337.045456855046794234655968633555"
    },
    {
        "date": 1664323200,
        "volume": "655314.750542713258115103",
        "open": "1330.469929804349017029121530495201",
        "close": "1338.554927912188001871128752689706",
        "untrackedVolumeUSD": "859814639.8024683676715599679531566",
        "priceUSD": "1338.554927912188001871128752689706"
    },
    {
        "date": 1664236800,
        "volume": "552439.207725413478470133",
        "open": "1333.572011523799041551279788445599",
        "close": "1330.469929804349017029121530495201",
        "untrackedVolumeUSD": "751462313.2548562109551723138143205",
        "priceUSD": "1330.469929804349017029121530495201"
    },
    {
        "date": 1664150400,
        "volume": "452478.890428999895953565",
        "open": "1294.037241128421501005053372339017",
        "close": "1333.572011523799041551279788445599",
        "untrackedVolumeUSD": "594155184.2993031782584851493100897",
        "priceUSD": "1333.572011523799041551279788445599"
    },
    {
        "date": 1664064000,
        "volume": "280146.615723264394645454",
        "open": "1316.871391663669728307175531847913",
        "close": "1294.037241128421501005053372339017",
        "untrackedVolumeUSD": "365588203.6870317292243800250558712",
        "priceUSD": "1294.037241128421501005053372339017"
    },
    {
        "date": 1663977600,
        "volume": "331706.773406121214268068",
        "open": "1327.573477266407111378861324015809",
        "close": "1316.871391663669728307175531847913",
        "untrackedVolumeUSD": "440566233.472283538476574508183892",
        "priceUSD": "1316.871391663669728307175531847913"
    },
    {
        "date": 1663891200,
        "volume": "587656.540713920343854335",
        "open": "1329.833632464102590133518854806843",
        "close": "1327.573477266407111378861324015809",
        "untrackedVolumeUSD": "768156159.1156094208092709871743426",
        "priceUSD": "1327.573477266407111378861324015809"
    },
    {
        "date": 1663804800,
        "volume": "590002.166941637652407363",
        "open": "1249.463876065697257155810361237672",
        "close": "1329.833632464102590133518854806843",
        "untrackedVolumeUSD": "756116222.2650067636146379669458748",
        "priceUSD": "1329.833632464102590133518854806843"
    },
    {
        "date": 1663718400,
        "volume": "724199.030193429117796342",
        "open": "1322.948710145728979004257042306221",
        "close": "1249.463876065697257155810361237672",
        "untrackedVolumeUSD": "954602277.801162923080168351493539",
        "priceUSD": "1249.463876065697257155810361237672"
    },
    {
        "date": 1663632000,
        "volume": "466774.271565378193970544",
        "open": "1378.714262011493411111542222189444",
        "close": "1322.948710145728979004257042306221",
        "untrackedVolumeUSD": "629622600.2261023428569154535198515",
        "priceUSD": "1322.948710145728979004257042306221"
    },
    {
        "date": 1663545600,
        "volume": "551493.208005550502804152",
        "open": "1334.179907332456638158668313398553",
        "close": "1378.714262011493411111542222189444",
        "untrackedVolumeUSD": "734130560.0409119416288392826111282",
        "priceUSD": "1378.714262011493411111542222189444"
    },
    {
        "date": 1663459200,
        "volume": "343031.853141644888134451",
        "open": "1469.283080224565691990624513935878",
        "close": "1334.179907332456638158668313398553",
        "untrackedVolumeUSD": "478913060.3945796477120307070861169",
        "priceUSD": "1334.179907332456638158668313398553"
    },
    {
        "date": 1663372800,
        "volume": "248444.986296165785747801",
        "open": "1429.912959911105386227540486172153",
        "close": "1469.283080224565691990624513935878",
        "untrackedVolumeUSD": "359513220.4076408260649442804792035",
        "priceUSD": "1469.283080224565691990624513935878"
    },
    {
        "date": 1663286400,
        "volume": "500605.93975963906905471",
        "open": "1471.100610402105934398390537846396",
        "close": "1429.912959911105386227540486172153",
        "untrackedVolumeUSD": "722938617.9249173886934848635923381",
        "priceUSD": "1429.912959911105386227540486172153"
    },
    {
        "date": 1663200000,
        "volume": "750141.377572492722014883",
        "open": "1634.533180271369250696695130720991",
        "close": "1471.100610402105934398390537846396",
        "untrackedVolumeUSD": "1169327380.471418586634228766024623",
        "priceUSD": "1471.100610402105934398390537846396"
    },
    {
        "date": 1663113600,
        "volume": "534886.672924778967991789",
        "open": "1573.490054981503794640315242191117",
        "close": "1634.533180271369250696695130720991",
        "untrackedVolumeUSD": "855924951.3496947464092799279284316",
        "priceUSD": "1634.533180271369250696695130720991"
    },
    {
        "date": 1663027200,
        "volume": "808455.630875662074148069",
        "open": "1712.135878751709871091042865136183",
        "close": "1573.490054981503794640315242191117",
        "untrackedVolumeUSD": "1333061545.46577062464057363843106",
        "priceUSD": "1573.490054981503794640315242191117"
    },
    {
        "date": 1662940800,
        "volume": "487776.532098742394840601",
        "open": "1762.624333986545986168562685822082",
        "close": "1712.135878751709871091042865136183",
        "untrackedVolumeUSD": "846855407.930334093532696218602054",
        "priceUSD": "1712.135878751709871091042865136183"
    },
    {
        "date": 1662854400,
        "volume": "303443.273915451959613054",
        "open": "1778.932461217125432999153717992146",
        "close": "1762.624333986545986168562685822082",
        "untrackedVolumeUSD": "535156266.661176542973608051037797",
        "priceUSD": "1762.624333986545986168562685822082"
    },
    {
        "date": 1662768000,
        "volume": "363908.449335267820563823",
        "open": "1716.743640690177498377577460016662",
        "close": "1778.932461217125432999153717992146",
        "untrackedVolumeUSD": "631462614.9493466711284779513743675",
        "priceUSD": "1778.932461217125432999153717992146"
    },
    {
        "date": 1662681600,
        "volume": "519772.071834639125788562",
        "open": "1632.644622602373034780650799451813",
        "close": "1716.743640690177498377577460016662",
        "untrackedVolumeUSD": "884517251.0164737312266601741107382",
        "priceUSD": "1716.743640690177498377577460016662"
    },
    {
        "date": 1662595200,
        "volume": "467917.834616349088052577",
        "open": "1628.188911106200898615393923121325",
        "close": "1632.644622602373034780650799451813",
        "untrackedVolumeUSD": "762772807.2964006058549478367551188",
        "priceUSD": "1632.644622602373034780650799451813"
    },
    {
        "date": 1662508800,
        "volume": "660049.147225092617674636",
        "open": "1561.77589014009460393673652614368",
        "close": "1628.188911106200898615393923121325",
        "untrackedVolumeUSD": "1024462731.914568097492906101746701",
        "priceUSD": "1628.188911106200898615393923121325"
    },
    {
        "date": 1662422400,
        "volume": "627139.232476520217641573",
        "open": "1619.45588317949927066736839841291",
        "close": "1561.77589014009460393673652614368",
        "untrackedVolumeUSD": "1021546071.016418115558574267234316",
        "priceUSD": "1561.77589014009460393673652614368"
    },
    {
        "date": 1662336000,
        "volume": "407358.802988060098655253",
        "open": "1575.640160321265981070517410390242",
        "close": "1619.45588317949927066736839841291",
        "untrackedVolumeUSD": "648084181.7043940431469167720129828",
        "priceUSD": "1619.45588317949927066736839841291"
    },
    {
        "date": 1662249600,
        "volume": "246692.353492167869171739",
        "open": "1554.901987725990672667878130431881",
        "close": "1575.640160321265981070517410390242",
        "untrackedVolumeUSD": "384992048.4808344867118436726677843",
        "priceUSD": "1575.640160321265981070517410390242"
    },
    {
        "date": 1662163200,
        "volume": "286204.341285746745889017",
        "open": "1576.447484946132838834651498254034",
        "close": "1554.901987725990672667878130431881",
        "untrackedVolumeUSD": "445340735.6827328402585963844871129",
        "priceUSD": "1554.901987725990672667878130431881"
    },
    {
        "date": 1662076800,
        "volume": "604239.451581477136018997",
        "open": "1586.487967631418057352224894839498",
        "close": "1576.447484946132838834651498254034",
        "untrackedVolumeUSD": "965354892.8572873326997072001403962",
        "priceUSD": "1576.447484946132838834651498254034"
    },
    {
        "date": 1661990400,
        "volume": "616861.443781658767488052",
        "open": "1550.595890397765355908907424799271",
        "close": "1586.487967631418057352224894839498",
        "untrackedVolumeUSD": "960433094.4149290954393627888112607",
        "priceUSD": "1586.487967631418057352224894839498"
    },
    {
        "date": 1661904000,
        "volume": "793800.471121499316140034",
        "open": "1523.457389399116194191299029232413",
        "close": "1550.595890397765355908907424799271",
        "untrackedVolumeUSD": "1252402762.225415357889885690853424",
        "priceUSD": "1550.595890397765355908907424799271"
    },
    {
        "date": 1661817600,
        "volume": "673104.462744932043646213",
        "open": "1555.302537299398084400094493403356",
        "close": "1523.457389399116194191299029232413",
        "untrackedVolumeUSD": "1041751390.970417542149572647545684",
        "priceUSD": "1523.457389399116194191299029232413"
    },
    {
        "date": 1661731200,
        "volume": "524401.333393066892606607",
        "open": "1430.395834486597168037399717625599",
        "close": "1555.302537299398084400094493403356",
        "untrackedVolumeUSD": "782933408.2586430484951240859808072",
        "priceUSD": "1555.302537299398084400094493403356"
    },
    {
        "date": 1661644800,
        "volume": "469629.029751086542678758",
        "open": "1493.811455704440314099383689294187",
        "close": "1430.395834486597168037399717625599",
        "untrackedVolumeUSD": "695365287.0155864800179297673450342",
        "priceUSD": "1430.395834486597168037399717625599"
    },
    {
        "date": 1661558400,
        "volume": "521386.231634780360401633",
        "open": "1503.562628149576346550481971128205",
        "close": "1493.811455704440314099383689294187",
        "untrackedVolumeUSD": "774551879.1526187877551744729435575",
        "priceUSD": "1493.811455704440314099383689294187"
    },
    {
        "date": 1661472000,
        "volume": "727301.872096685822322094",
        "open": "1693.662645594171847391634498816532",
        "close": "1503.562628149576346550481971128205",
        "untrackedVolumeUSD": "1176373148.839745654225737709987713",
        "priceUSD": "1503.562628149576346550481971128205"
    },
    {
        "date": 1661385600,
        "volume": "386223.383702985826088766",
        "open": "1657.437615423424121115618750135402",
        "close": "1693.662645594171847391634498816532",
        "untrackedVolumeUSD": "655393030.7818738418591610747448729",
        "priceUSD": "1693.662645594171847391634498816532"
    },
    {
        "date": 1661299200,
        "volume": "521224.390789876428434276",
        "open": "1666.259443906178880876866356976088",
        "close": "1657.437615423424121115618750135402",
        "untrackedVolumeUSD": "861228241.2451205489721295274085367",
        "priceUSD": "1657.437615423424121115618750135402"
    },
    {
        "date": 1661212800,
        "volume": "808778.543927852746501256",
        "open": "1624.369303380885570343876433283331",
        "close": "1666.259443906178880876866356976088",
        "untrackedVolumeUSD": "1316888954.64799598036514791115371",
        "priceUSD": "1666.259443906178880876866356976088"
    },
    {
        "date": 1661126400,
        "volume": "615694.885225770093442032",
        "open": "1620.913895017338038389824238888478",
        "close": "1624.369303380885570343876433283331",
        "untrackedVolumeUSD": "971253645.8598564619946279252204581",
        "priceUSD": "1624.369303380885570343876433283331"
    },
    {
        "date": 1661040000,
        "volume": "573039.712599476594116688",
        "open": "1575.049406846600679847962742813391",
        "close": "1620.913895017338038389824238888478",
        "untrackedVolumeUSD": "922076428.5254933981802581217751818",
        "priceUSD": "1620.913895017338038389824238888478"
    },
    {
        "date": 1660953600,
        "volume": "630283.817682988487283196",
        "open": "1609.58555089641965123773976293502",
        "close": "1575.049406846600679847962742813391",
        "untrackedVolumeUSD": "1008920316.950125915637561906466526",
        "priceUSD": "1575.049406846600679847962742813391"
    },
    {
        "date": 1660867200,
        "volume": "775465.498441844897124058",
        "open": "1849.811322777741750051422864416683",
        "close": "1609.58555089641965123773976293502",
        "untrackedVolumeUSD": "1332983787.354574610829580218110326",
        "priceUSD": "1609.58555089641965123773976293502"
    },
    {
        "date": 1660780800,
        "volume": "379503.002819984469509127",
        "open": "1831.471256040449136008292147918772",
        "close": "1849.811322777741750051422864416683",
        "untrackedVolumeUSD": "705178886.3457854011734160890654658",
        "priceUSD": "1849.811322777741750051422864416683"
    },
    {
        "date": 1660694400,
        "volume": "598770.732675380565782433",
        "open": "1875.262789081693138206212642719346",
        "close": "1831.471256040449136008292147918772",
        "untrackedVolumeUSD": "1119791435.627876703601471638539511",
        "priceUSD": "1831.471256040449136008292147918772"
    },
    {
        "date": 1660608000,
        "volume": "418195.703129983276292195",
        "open": "1896.122432836570175434032241089274",
        "close": "1875.262789081693138206212642719346",
        "untrackedVolumeUSD": "788785776.7603552136249314336906248",
        "priceUSD": "1875.262789081693138206212642719346"
    },
    {
        "date": 1660521600,
        "volume": "585679.36863457449445986",
        "open": "1934.728712693098111129560973420641",
        "close": "1896.122432836570175434032241089274",
        "untrackedVolumeUSD": "1124314379.457861808431493508217095",
        "priceUSD": "1896.122432836570175434032241089274"
    },
    {
        "date": 1660435200,
        "volume": "348880.504682362726155632",
        "open": "1981.31436952246994775102060590944",
        "close": "1934.728712693098111129560973420641",
        "untrackedVolumeUSD": "687007390.6005456079174697974293208",
        "priceUSD": "1934.728712693098111129560973420641"
    },
    {
        "date": 1660348800,
        "volume": "352273.980102092722257587",
        "open": "1958.903416892567293717137760166185",
        "close": "1981.31436952246994775102060590944",
        "untrackedVolumeUSD": "700380228.109605590795181018172227",
        "priceUSD": "1981.31436952246994775102060590944"
    },
    {
        "date": 1660262400,
        "volume": "409023.151492292225872701",
        "open": "1878.208611090695029080890640634949",
        "close": "1958.903416892567293717137760166185",
        "untrackedVolumeUSD": "776495852.6609981329665650480135447",
        "priceUSD": "1958.903416892567293717137760166185"
    },
    {
        "date": 1660176000,
        "volume": "646544.661711948261132137",
        "open": "1851.24998779754541601985893106852",
        "close": "1878.208611090695029080890640634949",
        "untrackedVolumeUSD": "1224774132.780348404338200809299612",
        "priceUSD": "1878.208611090695029080890640634949"
    },
    {
        "date": 1660089600,
        "volume": "720283.801496020960014144",
        "open": "1705.613517081326912383379132403644",
        "close": "1851.24998779754541601985893106852",
        "untrackedVolumeUSD": "1282902352.937770707650324030214908",
        "priceUSD": "1851.24998779754541601985893106852"
    },
    {
        "date": 1660003200,
        "volume": "514567.552928527682883777",
        "open": "1774.773586490356444628071019632437",
        "close": "1705.613517081326912383379132403644",
        "untrackedVolumeUSD": "883683495.2703878203594988671587821",
        "priceUSD": "1705.613517081326912383379132403644"
    },
    {
        "date": 1659916800,
        "volume": "521587.232203414390251623",
        "open": "1696.063231117178994972350557155397",
        "close": "1774.773586490356444628071019632437",
        "untrackedVolumeUSD": "921396554.724048692224195622124645",
        "priceUSD": "1774.773586490356444628071019632437"
    },
    {
        "date": 1659830400,
        "volume": "278886.910190630685473684",
        "open": "1692.807306781228600344315229391612",
        "close": "1696.063231117178994972350557155397",
        "untrackedVolumeUSD": "473465205.7476762980203209164269283",
        "priceUSD": "1696.063231117178994972350557155397"
    },
    {
        "date": 1659744000,
        "volume": "279269.102222841425333765",
        "open": "1735.9265861350052274671065268099",
        "close": "1692.807306781228600344315229391612",
        "untrackedVolumeUSD": "481319771.7981539986471123781077136",
        "priceUSD": "1692.807306781228600344315229391612"
    },
    {
        "date": 1659657600,
        "volume": "563748.006744934844670809",
        "open": "1608.139939533599819521681854066809",
        "close": "1735.9265861350052274671065268099",
        "untrackedVolumeUSD": "947437839.3865542173451623610319636",
        "priceUSD": "1735.9265861350052274671065268099"
    },
    {
        "date": 1659571200,
        "volume": "499536.516494417041314361",
        "open": "1617.867917843883575075929470236413",
        "close": "1608.139939533599819521681854066809",
        "untrackedVolumeUSD": "809933062.7324197740758681864592077",
        "priceUSD": "1608.139939533599819521681854066809"
    },
    {
        "date": 1659484800,
        "volume": "505498.365571263621350596",
        "open": "1633.757273895149195655817788590644",
        "close": "1617.867917843883575075929470236413",
        "untrackedVolumeUSD": "827665249.4276988917527159247462304",
        "priceUSD": "1617.867917843883575075929470236413"
    },
    {
        "date": 1659398400,
        "volume": "772986.463408182498996585",
        "open": "1631.527674887434704381914183592022",
        "close": "1633.757273895149195655817788590644",
        "untrackedVolumeUSD": "1250482981.698179315067915333346016",
        "priceUSD": "1633.757273895149195655817788590644"
    },
    {
        "date": 1659312000,
        "volume": "526717.829506764550192309",
        "open": "1683.593063440322964263452519256328",
        "close": "1631.527674887434704381914183592022",
        "untrackedVolumeUSD": "872110237.8424287380704466236414221",
        "priceUSD": "1631.527674887434704381914183592022"
    },
    {
        "date": 1659225600,
        "volume": "402145.157427237022562222",
        "open": "1693.719593594312792254598930213691",
        "close": "1683.593063440322964263452519256328",
        "untrackedVolumeUSD": "686039587.3245856559020133554555011",
        "priceUSD": "1683.593063440322964263452519256328"
    },
    {
        "date": 1659139200,
        "volume": "449862.285703831072972006",
        "open": "1723.975290158357494736694333478117",
        "close": "1693.719593594312792254598930213691",
        "untrackedVolumeUSD": "769403175.9280738109577535202513894",
        "priceUSD": "1693.719593594312792254598930213691"
    },
    {
        "date": 1659052800,
        "volume": "711883.427652514096541066",
        "open": "1723.650966252407381146032253864081",
        "close": "1723.975290158357494736694333478117",
        "untrackedVolumeUSD": "1218933131.023635239545031782572743",
        "priceUSD": "1723.975290158357494736694333478117"
    },
    {
        "date": 1658966400,
        "volume": "851801.047859839743635302",
        "open": "1639.294733355766835685378261273548",
        "close": "1723.650966252407381146032253864081",
        "untrackedVolumeUSD": "1426358581.466744690728965547390273",
        "priceUSD": "1723.650966252407381146032253864081"
    },
    {
        "date": 1658880000,
        "volume": "825096.615057288523409014",
        "open": "1449.449059230600202585424552237458",
        "close": "1639.294733355766835685378261273548",
        "untrackedVolumeUSD": "1251847780.992790292531787971390109",
        "priceUSD": "1639.294733355766835685378261273548"
    },
    {
        "date": 1658793600,
        "volume": "679409.929122825768238315",
        "open": "1442.684598510619086794433238970584",
        "close": "1449.449059230600202585424552237458",
        "untrackedVolumeUSD": "952014969.6477387675827407377317976",
        "priceUSD": "1449.449059230600202585424552237458"
    },
    {
        "date": 1658707200,
        "volume": "724626.581519648227360946",
        "open": "1597.868836250534988124477277782571",
        "close": "1442.684598510619086794433238970584",
        "untrackedVolumeUSD": "1099439633.225169585438051288298382",
        "priceUSD": "1442.684598510619086794433238970584"
    },
    {
        "date": 1658620800,
        "volume": "521240.219600245227981608",
        "open": "1550.024241942749988068276969228899",
        "close": "1597.868836250534988124477277782571",
        "untrackedVolumeUSD": "834104225.8251862488460920367264805",
        "priceUSD": "1597.868836250534988124477277782571"
    },
    {
        "date": 1658534400,
        "volume": "517061.619839833425462787",
        "open": "1536.454970854210018146430163865269",
        "close": "1550.024241942749988068276969228899",
        "untrackedVolumeUSD": "795802058.3494766612098288454035475",
        "priceUSD": "1550.024241942749988068276969228899"
    },
    {
        "date": 1658448000,
        "volume": "583170.7051821909804807",
        "open": "1575.08339896225830509542896426358",
        "close": "1536.454970854210018146430163865269",
        "untrackedVolumeUSD": "922221480.6458640620480425873898222",
        "priceUSD": "1536.454970854210018146430163865269"
    },
    {
        "date": 1658361600,
        "volume": "714293.82088077168817054",
        "open": "1521.589911235647660839081965692546",
        "close": "1575.08339896225830509542896426358",
        "untrackedVolumeUSD": "1085909114.668269340010154205337086",
        "priceUSD": "1575.08339896225830509542896426358"
    },
    {
        "date": 1658275200,
        "volume": "847040.675861248027737063",
        "open": "1540.632369304936296655131735739593",
        "close": "1521.589911235647660839081965692546",
        "untrackedVolumeUSD": "1318848915.109896403805036576833488",
        "priceUSD": "1521.589911235647660839081965692546"
    },
    {
        "date": 1658188800,
        "volume": "1034273.203707494723135417",
        "open": "1585.994518790602764875322542893376",
        "close": "1540.632369304936296655131735739593",
        "untrackedVolumeUSD": "1598362002.136962273296656788794603",
        "priceUSD": "1540.632369304936296655131735739593"
    },
    {
        "date": 1658102400,
        "volume": "994751.801544041985069681",
        "open": "1338.347071773165205444115355282672",
        "close": "1585.994518790602764875322542893376",
        "untrackedVolumeUSD": "1455905866.822888995270647069334951",
        "priceUSD": "1585.994518790602764875322542893376"
    },
    {
        "date": 1658016000,
        "volume": "750321.866737564381406673",
        "open": "1355.395081970511552313081917735865",
        "close": "1338.347071773165205444115355282672",
        "untrackedVolumeUSD": "1015977289.385277331385607416927692",
        "priceUSD": "1338.347071773165205444115355282672"
    },
    {
        "date": 1657929600,
        "volume": "766885.993129034588286393",
        "open": "1233.418087599487384596217508708708",
        "close": "1355.395081970511552313081917735865",
        "untrackedVolumeUSD": "988132430.5918350623540503791663754",
        "priceUSD": "1355.395081970511552313081917735865"
    },
    {
        "date": 1657843200,
        "volume": "889999.305232611808779178",
        "open": "1191.857820826720641744198899046141",
        "close": "1233.418087599487384596217508708708",
        "untrackedVolumeUSD": "1090229645.26083491849146832706149",
        "priceUSD": "1233.418087599487384596217508708708"
    },
    {
        "date": 1657756800,
        "volume": "692777.842814664673337615",
        "open": "1113.964832820670698962670819495847",
        "close": "1191.857820826720641744198899046141",
        "untrackedVolumeUSD": "783954288.9655775288750723172238686",
        "priceUSD": "1191.857820826720641744198899046141"
    },
    {
        "date": 1657670400,
        "volume": "887532.263932624440341478",
        "open": "1037.681843269342561000071311583608",
        "close": "1113.964832820670698962670819495847",
        "untrackedVolumeUSD": "943862111.4884471432730567419662797",
        "priceUSD": "1113.964832820670698962670819495847"
    },
    {
        "date": 1657584000,
        "volume": "606874.936112304350931621",
        "open": "1098.000280161693500051386583107309",
        "close": "1037.681843269342561000071311583608",
        "untrackedVolumeUSD": "648691857.7768807077815834135792875",
        "priceUSD": "1037.681843269342561000071311583608"
    },
    {
        "date": 1657497600,
        "volume": "606057.634997944868426832",
        "open": "1166.216693586216812641880005395793",
        "close": "1098.000280161693500051386583107309",
        "untrackedVolumeUSD": "688856378.054759771978340319190036",
        "priceUSD": "1098.000280161693500051386583107309"
    },
    {
        "date": 1657411200,
        "volume": "403097.251042439167198809",
        "open": "1216.379790588969880234079620466635",
        "close": "1166.216693586216812641880005395793",
        "untrackedVolumeUSD": "476069933.6835907637805662104865245",
        "priceUSD": "1166.216693586216812641880005395793"
    },
    {
        "date": 1657324800,
        "volume": "386935.692390027382526993",
        "open": "1214.138430886530600752683895906423",
        "close": "1216.379790588969880234079620466635",
        "untrackedVolumeUSD": "470787142.6935105203029767557611198",
        "priceUSD": "1216.379790588969880234079620466635"
    },
    {
        "date": 1657238400,
        "volume": "826591.35619082795056437",
        "open": "1238.433504976075716769629326855619",
        "close": "1214.138430886530600752683895906423",
        "untrackedVolumeUSD": "1017326592.943850411807878434296814",
        "priceUSD": "1214.138430886530600752683895906423"
    },
    {
        "date": 1657152000,
        "volume": "605288.479128147937706541",
        "open": "1188.635129142901095545721061013802",
        "close": "1238.433504976075716769629326855619",
        "untrackedVolumeUSD": "729772803.1752403347438484162597503",
        "priceUSD": "1238.433504976075716769629326855619"
    },
    {
        "date": 1657065600,
        "volume": "850978.797830833595882763",
        "open": "1131.860786797637856082033694974668",
        "close": "1188.635129142901095545721061013802",
        "untrackedVolumeUSD": "972392232.0127005404138333995012519",
        "priceUSD": "1188.635129142901095545721061013802"
    },
    {
        "date": 1656979200,
        "volume": "796766.324197605264300444",
        "open": "1151.997453745552197581018826701972",
        "close": "1131.860786797637856082033694974668",
        "untrackedVolumeUSD": "899600391.6807743758300082621107699",
        "priceUSD": "1131.860786797637856082033694974668"
    },
    {
        "date": 1656892800,
        "volume": "642296.799353143322179758",
        "open": "1072.276625493786975346041906402502",
        "close": "1151.997453745552197581018826701972",
        "untrackedVolumeUSD": "710770141.1850746358917345273487455",
        "priceUSD": "1151.997453745552197581018826701972"
    },
    {
        "date": 1656806400,
        "volume": "434642.183781883405528327",
        "open": "1068.128413374784675670342991251436",
        "close": "1072.276625493786975346041906402502",
        "untrackedVolumeUSD": "462430202.6542941445181434527767047",
        "priceUSD": "1072.276625493786975346041906402502"
    },
    {
        "date": 1656720000,
        "volume": "470554.131651521233518835",
        "open": "1056.879163864628357132944938965711",
        "close": "1068.128413374784675670342991251436",
        "untrackedVolumeUSD": "494760934.1169324895064807863794036",
        "priceUSD": "1068.128413374784675670342991251436"
    },
    {
        "date": 1656633600,
        "volume": "1032371.424764565831972128",
        "open": "1068.654722772398568550216012380241",
        "close": "1056.879163864628357132944938965711",
        "untrackedVolumeUSD": "1101216885.910087921483788311937231",
        "priceUSD": "1056.879163864628357132944938965711"
    },
    {
        "date": 1656547200,
        "volume": "951485.471881940709302985",
        "open": "1095.986322474999975161595963239924",
        "close": "1068.654722772398568550216012380241",
        "untrackedVolumeUSD": "990171991.7049482781960449130537862",
        "priceUSD": "1068.654722772398568550216012380241"
    },
    {
        "date": 1656460800,
        "volume": "794602.867723931304611989",
        "open": "1140.03565751163309660712340973545",
        "close": "1095.986322474999975161595963239924",
        "untrackedVolumeUSD": "887883888.3102890234359217171251064",
        "priceUSD": "1095.986322474999975161595963239924"
    },
    {
        "date": 1656374400,
        "volume": "630118.200439829770913542",
        "open": "1191.936420069088557281646060544173",
        "close": "1140.03565751163309660712340973545",
        "untrackedVolumeUSD": "746268693.2285508604041017396668389",
        "priceUSD": "1140.03565751163309660712340973545"
    },
    {
        "date": 1656288000,
        "volume": "434374.677873712070225597",
        "open": "1197.916444234068504241763015307265",
        "close": "1191.936420069088557281646060544173",
        "untrackedVolumeUSD": "523094604.924996957949757035678339",
        "priceUSD": "1191.936420069088557281646060544173"
    },
    {
        "date": 1656201600,
        "volume": "476133.843292070664654861",
        "open": "1243.686625326435662599844164906735",
        "close": "1197.916444234068504241763015307265",
        "untrackedVolumeUSD": "586445131.5999999148519351663638643",
        "priceUSD": "1197.916444234068504241763015307265"
    },
    {
        "date": 1656115200,
        "volume": "483353.489253668484052272",
        "open": "1225.74843468071058921876467214072",
        "close": "1243.686625326435662599844164906735",
        "untrackedVolumeUSD": "588600133.6917988275581062229524373",
        "priceUSD": "1243.686625326435662599844164906735"
    },
    {
        "date": 1656028800,
        "volume": "788730.257234224241134866",
        "open": "1144.646395210410470093788480747366",
        "close": "1225.74843468071058921876467214072",
        "untrackedVolumeUSD": "936564635.4039810306833514653942413",
        "priceUSD": "1225.74843468071058921876467214072"
    },
    {
        "date": 1655942400,
        "volume": "886585.131992164381428526",
        "open": "1047.813198048487772386401207236174",
        "close": "1144.646395210410470093788480747366",
        "untrackedVolumeUSD": "983509263.3162673831698927721257684",
        "priceUSD": "1144.646395210410470093788480747366"
    },
    {
        "date": 1655856000,
        "volume": "895140.826014381079606613",
        "open": "1124.644236367616955441624236804306",
        "close": "1047.813198048487772386401207236174",
        "untrackedVolumeUSD": "970386708.3675612672628897425247088",
        "priceUSD": "1047.813198048487772386401207236174"
    },
    {
        "date": 1655769600,
        "volume": "972176.905202454814149189",
        "open": "1130.280173384804762601103615771316",
        "close": "1124.644236367616955441624236804306",
        "untrackedVolumeUSD": "1113398831.442364073337125674979366",
        "priceUSD": "1124.644236367616955441624236804306"
    },
    {
        "date": 1655683200,
        "volume": "1230370.657914297166170035",
        "open": "1125.230878245978682938585378376915",
        "close": "1130.280173384804762601103615771316",
        "untrackedVolumeUSD": "1363712709.660669014865228880804283",
        "priceUSD": "1130.280173384804762601103615771316"
    },
    {
        "date": 1655596800,
        "volume": "1508003.560699871809073687",
        "open": "995.1087996803623279878287430333024",
        "close": "1125.230878245978682938585378376915",
        "untrackedVolumeUSD": "1564196373.30602238432770269094362",
        "priceUSD": "1125.230878245978682938585378376915"
    },
    {
        "date": 1655510400,
        "volume": "1585513.022484262480794839",
        "open": "1086.394537233310411974725797299086",
        "close": "995.1087996803623279878287430333024",
        "untrackedVolumeUSD": "1550690874.683915105266158525922822",
        "priceUSD": "995.1087996803623279878287430333024"
    },
    {
        "date": 1655424000,
        "volume": "1000262.579324414203455861",
        "open": "1063.672794230852906274302860820026",
        "close": "1086.394537233310411974725797299086",
        "untrackedVolumeUSD": "1085083048.458418113249707442187454",
        "priceUSD": "1086.394537233310411974725797299086"
    },
    {
        "date": 1655337600,
        "volume": "1236579.637464428753261098",
        "open": "1234.501157512674422398661645704831",
        "close": "1063.672794230852906274302860820026",
        "untrackedVolumeUSD": "1413573001.257165553879104614149763",
        "priceUSD": "1063.672794230852906274302860820026"
    },
    {
        "date": 1655251200,
        "volume": "2280967.594832046121914023",
        "open": "1208.373063941825323725660185987681",
        "close": "1234.501157512674422398661645704831",
        "untrackedVolumeUSD": "2590007478.033924347275433802128488",
        "priceUSD": "1234.501157512674422398661645704831"
    },
    {
        "date": 1655164800,
        "volume": "1840854.644762231811253432",
        "open": "1205.398100287155312158028587306909",
        "close": "1208.373063941825323725660185987681",
        "untrackedVolumeUSD": "2178307230.877409196534667859394813",
        "priceUSD": "1208.373063941825323725660185987681"
    },
    {
        "date": 1655078400,
        "volume": "2465822.043677192091339416",
        "open": "1431.623096275168434349641261073942",
        "close": "1205.398100287155312158028587306909",
        "untrackedVolumeUSD": "3130947064.24101737854707661265675",
        "priceUSD": "1205.398100287155312158028587306909"
    },
    {
        "date": 1654992000,
        "volume": "1021375.117622773634336135",
        "open": "1525.849039186619870206763853347996",
        "close": "1431.623096275168434349641261073942",
        "untrackedVolumeUSD": "1504913969.126810828406859411684131",
        "priceUSD": "1431.623096275168434349641261073942"
    },
    {
        "date": 1654905600,
        "volume": "846178.817744411124221117",
        "open": "1658.564451941325222216960897299387",
        "close": "1525.849039186619870206763853347996",
        "untrackedVolumeUSD": "1326617811.525712195099110383951535",
        "priceUSD": "1525.849039186619870206763853347996"
    },
    {
        "date": 1654819200,
        "volume": "711254.599511416440185818",
        "open": "1785.754671998836641467514502673504",
        "close": "1658.564451941325222216960897299387",
        "untrackedVolumeUSD": "1224038743.370556330715377126568908",
        "priceUSD": "1658.564451941325222216960897299387"
    },
    {
        "date": 1654732800,
        "volume": "362372.920042835668897601",
        "open": "1794.866868605820358522321584040266",
        "close": "1785.754671998836641467514502673504",
        "untrackedVolumeUSD": "651779960.1608696699800797977568486",
        "priceUSD": "1785.754671998836641467514502673504"
    },
    {
        "date": 1654646400,
        "volume": "608733.988656632626616268",
        "open": "1815.891306134224166190927685779151",
        "close": "1794.866868605820358522321584040266",
        "untrackedVolumeUSD": "1097611595.108173700369070322701444",
        "priceUSD": "1794.866868605820358522321584040266"
    },
    {
        "date": 1654560000,
        "volume": "755389.651431576402306333",
        "open": "1857.297505602003543760212918195039",
        "close": "1815.891306134224166190927685779151",
        "untrackedVolumeUSD": "1348409181.865960676942746351177098",
        "priceUSD": "1815.891306134224166190927685779151"
    },
    {
        "date": 1654473600,
        "volume": "497456.150971724667098009",
        "open": "1806.412499607998739365183725100221",
        "close": "1857.297505602003543760212918195039",
        "untrackedVolumeUSD": "932096750.2261884872419252750070478",
        "priceUSD": "1857.297505602003543760212918195039"
    },
    {
        "date": 1654387200,
        "volume": "296388.323915367135147945",
        "open": "1800.171014193028293508379006637728",
        "close": "1806.412499607998739365183725100221",
        "untrackedVolumeUSD": "534364979.6162601804461951647801125",
        "priceUSD": "1806.412499607998739365183725100221"
    },
    {
        "date": 1654300800,
        "volume": "303804.444832722681667946",
        "open": "1774.343656340511503500559935961701",
        "close": "1800.171014193028293508379006637728",
        "untrackedVolumeUSD": "540115498.1272580870213472885781528",
        "priceUSD": "1800.171014193028293508379006637728"
    },
    {
        "date": 1654214400,
        "volume": "454073.806160486600325576",
        "open": "1837.129329193623699977644665284502",
        "close": "1774.343656340511503500559935961701",
        "untrackedVolumeUSD": "806184701.6601686198664975116446585",
        "priceUSD": "1774.343656340511503500559935961701"
    },
    {
        "date": 1654128000,
        "volume": "526193.519406200236366803",
        "open": "1820.477049969326866978369489729943",
        "close": "1837.129329193623699977644665284502",
        "untrackedVolumeUSD": "956428964.3870670714720344031286075",
        "priceUSD": "1837.129329193623699977644665284502"
    },
    {
        "date": 1654041600,
        "volume": "629241.682350182520437055",
        "open": "1943.059309948568601896220564849767",
        "close": "1820.477049969326866978369489729943",
        "untrackedVolumeUSD": "1180756608.789233989239102939468444",
        "priceUSD": "1820.477049969326866978369489729943"
    },
    {
        "date": 1653955200,
        "volume": "506071.259059784724596947",
        "open": "1995.857477813946834033815138305146",
        "close": "1943.059309948568601896220564849767",
        "untrackedVolumeUSD": "995942950.6070587062031012784728554",
        "priceUSD": "1943.059309948568601896220564849767"
    },
    {
        "date": 1653868800,
        "volume": "703363.833923487759575325",
        "open": "1812.284522876210063194996131284459",
        "close": "1995.857477813946834033815138305146",
        "untrackedVolumeUSD": "1347962246.525065224861643641395497",
        "priceUSD": "1995.857477813946834033815138305146"
    },
    {
        "date": 1653782400,
        "volume": "284570.066415073958612331",
        "open": "1795.536972646960553016366362328804",
        "close": "1812.284522876210063194996131284459",
        "untrackedVolumeUSD": "510630464.8876368578871538756810594",
        "priceUSD": "1812.284522876210063194996131284459"
    },
    {
        "date": 1653696000,
        "volume": "522546.130318718963572199",
        "open": "1720.144571483830330723475852163582",
        "close": "1795.536972646960553016366362328804",
        "untrackedVolumeUSD": "923110606.2791899962290599809205931",
        "priceUSD": "1795.536972646960553016366362328804"
    },
    {
        "date": 1653609600,
        "volume": "875582.696761761803775366",
        "open": "1793.613508977655882089205137787702",
        "close": "1720.144571483830330723475852163582",
        "untrackedVolumeUSD": "1540956777.197037148042796269485185",
        "priceUSD": "1720.144571483830330723475852163582"
    },
    {
        "date": 1653523200,
        "volume": "1064532.339530581884998483",
        "open": "1942.915999422501218431226199495668",
        "close": "1793.613508977655882089205137787702",
        "untrackedVolumeUSD": "1973330563.334835659693421351497276",
        "priceUSD": "1793.613508977655882089205137787702"
    },
    {
        "date": 1653436800,
        "volume": "518492.982548400737527786",
        "open": "1979.377629383218967707131422020793",
        "close": "1942.915999422501218431226199495668",
        "untrackedVolumeUSD": "1020195479.111532790938425129114482",
        "priceUSD": "1942.915999422501218431226199495668"
    },
    {
        "date": 1653350400,
        "volume": "434992.439151803433313031",
        "open": "1969.054856693696100073460986139882",
        "close": "1979.377629383218967707131422020793",
        "untrackedVolumeUSD": "853078074.7762241193139280290099335",
        "priceUSD": "1979.377629383218967707131422020793"
    },
    {
        "date": 1653264000,
        "volume": "568481.048820444612942139",
        "open": "2044.760701557745356586760234086823",
        "close": "1969.054856693696100073460986139882",
        "untrackedVolumeUSD": "1158605149.152025069112817039675217",
        "priceUSD": "1969.054856693696100073460986139882"
    },
    {
        "date": 1653177600,
        "volume": "334267.346915544860924318",
        "open": "1972.342569365083643234170607163543",
        "close": "2044.760701557745356586760234086823",
        "untrackedVolumeUSD": "670811694.3498494362496852538336377",
        "priceUSD": "2044.760701557745356586760234086823"
    },
    {
        "date": 1653091200,
        "volume": "287832.719314006458135134",
        "open": "1961.880627883651179548094397477442",
        "close": "1972.342569365083643234170607163543",
        "untrackedVolumeUSD": "566426600.7090525089196603005709973",
        "priceUSD": "1972.342569365083643234170607163543"
    },
    {
        "date": 1653004800,
        "volume": "579293.62464769910349155",
        "open": "2018.050345617587049303068075056243",
        "close": "1961.880627883651179548094397477442",
        "untrackedVolumeUSD": "1150799309.559062496766949148309718",
        "priceUSD": "1961.880627883651179548094397477442"
    },
    {
        "date": 1652918400,
        "volume": "755634.857751038813606845",
        "open": "1911.360631745224218678852622071227",
        "close": "2018.050345617587049303068075056243",
        "untrackedVolumeUSD": "1486182199.234540980130291771073434",
        "priceUSD": "2018.050345617587049303068075056243"
    },
    {
        "date": 1652832000,
        "volume": "624444.096818717188230067",
        "open": "2092.314039904112127963941060262338",
        "close": "1911.360631745224218678852622071227",
        "untrackedVolumeUSD": "1243992033.021689178084273946221696",
        "priceUSD": "1911.360631745224218678852622071227"
    },
    {
        "date": 1652745600,
        "volume": "498868.305108757289686752",
        "open": "2021.343684052158404024794097284441",
        "close": "2092.314039904112127963941060262338",
        "untrackedVolumeUSD": "1029216304.31887051912733326283312",
        "priceUSD": "2092.314039904112127963941060262338"
    },
    {
        "date": 1652659200,
        "volume": "866774.072352544907846483",
        "open": "2144.792751402377705443483476738151",
        "close": "2021.343684052158404024794097284441",
        "untrackedVolumeUSD": "1755771739.265248880538549464876195",
        "priceUSD": "2021.343684052158404024794097284441"
    },
    {
        "date": 1652572800,
        "volume": "461383.438416373377470934",
        "open": "2057.158163499839315506232147528776",
        "close": "2144.792751402377705443483476738151",
        "untrackedVolumeUSD": "956082046.6960191835114815498830417",
        "priceUSD": "2144.792751402377705443483476738151"
    },
    {
        "date": 1652486400,
        "volume": "595221.878089248037856483",
        "open": "2005.774660512115145241498028528466",
        "close": "2057.158163499839315506232147528776",
        "untrackedVolumeUSD": "1196561825.198271769782481469371611",
        "priceUSD": "2057.158163499839315506232147528776"
    },
    {
        "date": 1652400000,
        "volume": "974775.891249138295539706",
        "open": "1953.789644414178532719605636043812",
        "close": "2005.774660512115145241498028528466",
        "untrackedVolumeUSD": "2014254060.762693227599198254744215",
        "priceUSD": "2005.774660512115145241498028528466"
    },
    {
        "date": 1652313600,
        "volume": "2047092.423604189579602065",
        "open": "2070.234665853771437650029656534907",
        "close": "1953.789644414178532719605636043812",
        "untrackedVolumeUSD": "3925863917.577334487797860223986603",
        "priceUSD": "1953.789644414178532719605636043812"
    },
    {
        "date": 1652227200,
        "volume": "1810619.715262390203495071",
        "open": "2339.547755485952019467904699741646",
        "close": "2070.234665853771437650029656534907",
        "untrackedVolumeUSD": "4059241515.143270494195885982001438",
        "priceUSD": "2070.234665853771437650029656534907"
    },
    {
        "date": 1652140800,
        "volume": "1126497.63709188606333067",
        "open": "2235.563925803024662897992044750549",
        "close": "2339.547755485952019467904699741646",
        "untrackedVolumeUSD": "2647999186.644606977445017055206981",
        "priceUSD": "2339.547755485952019467904699741646"
    },
    {
        "date": 1652054400,
        "volume": "1196595.508200817985703095",
        "open": "2515.343197113886368719176958356036",
        "close": "2235.563925803024662897992044750549",
        "untrackedVolumeUSD": "2822803602.715017246663096610848232",
        "priceUSD": "2235.563925803024662897992044750549"
    },
    {
        "date": 1651968000,
        "volume": "652131.017700646618601207",
        "open": "2640.830696403987228453734251626462",
        "close": "2515.343197113886368719176958356036",
        "untrackedVolumeUSD": "1660919599.949083932100864303192516",
        "priceUSD": "2515.343197113886368719176958356036"
    },
    {
        "date": 1651881600,
        "volume": "334136.185202503668621721",
        "open": "2697.690235607902318588041473667156",
        "close": "2640.830696403987228453734251626462",
        "untrackedVolumeUSD": "889038412.1749972577533451279953507",
        "priceUSD": "2640.830696403987228453734251626462"
    },
    {
        "date": 1651795200,
        "volume": "647586.21660188063271769",
        "open": "2744.241858879705043943434032736319",
        "close": "2697.690235607902318588041473667156",
        "untrackedVolumeUSD": "1742677751.821559750718255497195533",
        "priceUSD": "2697.690235607902318588041473667156"
    },
    {
        "date": 1651708800,
        "volume": "545431.47331822603274519",
        "open": "2935.515434349736634818297426963092",
        "close": "2744.241858879705043943434032736319",
        "untrackedVolumeUSD": "1530465122.251968932335155811263055",
        "priceUSD": "2744.241858879705043943434032736319"
    },
    {
        "date": 1651622400,
        "volume": "499052.956694373337219995",
        "open": "2784.522997182539855619845386954291",
        "close": "2935.515434349736634818297426963092",
        "untrackedVolumeUSD": "1432468240.540198302789545532689567",
        "priceUSD": "2935.515434349736634818297426963092"
    },
    {
        "date": 1651536000,
        "volume": "409453.47939218898114567",
        "open": "2862.875834406408369303478168268906",
        "close": "2784.522997182539855619845386954291",
        "untrackedVolumeUSD": "1154333844.091249304788914748859705",
        "priceUSD": "2784.522997182539855619845386954291"
    },
    {
        "date": 1651449600,
        "volume": "466804.519671420173123421",
        "open": "2828.143246554750951750538597875881",
        "close": "2862.875834406408369303478168268906",
        "untrackedVolumeUSD": "1320092809.96601986159069662983509",
        "priceUSD": "2862.875834406408369303478168268906"
    },
    {
        "date": 1651363200,
        "volume": "499963.56838421793030261",
        "open": "2728.525622975685765135039600317473",
        "close": "2828.143246554750951750538597875881",
        "untrackedVolumeUSD": "1390063048.487829044529623939198452",
        "priceUSD": "2828.143246554750951750538597875881"
    },
    {
        "date": 1651276800,
        "volume": "557200.677294864581446095",
        "open": "2815.173427687771431333908609945474",
        "close": "2728.525622975685765135039600317473",
        "untrackedVolumeUSD": "1561173998.186000424134563168693289",
        "priceUSD": "2728.525622975685765135039600317473"
    },
    {
        "date": 1651190400,
        "volume": "502627.548485143793322585",
        "open": "2936.273015284375969614258259054055",
        "close": "2815.173427687771431333908609945474",
        "untrackedVolumeUSD": "1440645491.922824199918942707271522",
        "priceUSD": "2815.173427687771431333908609945474"
    },
    {
        "date": 1651104000,
        "volume": "485470.260068567803169633",
        "open": "2887.639253020695719259769146898925",
        "close": "2936.273015284375969614258259054055",
        "untrackedVolumeUSD": "1416912994.692993934270668592365826",
        "priceUSD": "2936.273015284375969614258259054055"
    },
    {
        "date": 1651017600,
        "volume": "344347.036846377741653693",
        "open": "2802.431856400751851901091369041568",
        "close": "2887.639253020695719259769146898925",
        "untrackedVolumeUSD": "985702460.4850036891144982446277172",
        "priceUSD": "2887.639253020695719259769146898925"
    },
    {
        "date": 1650931200,
        "volume": "416501.827236064533551946",
        "open": "3013.564543758703935902972612924166",
        "close": "2802.431856400751851901091369041568",
        "untrackedVolumeUSD": "1214970621.175774981309716799178631",
        "priceUSD": "2802.431856400751851901091369041568"
    },
    {
        "date": 1650844800,
        "volume": "431415.883199737074698035",
        "open": "2928.006342753099866991209971068576",
        "close": "3013.564543758703935902972612924166",
        "untrackedVolumeUSD": "1248616763.336975511934917249062259",
        "priceUSD": "3013.564543758703935902972612924166"
    },
    {
        "date": 1650758400,
        "volume": "191134.307378229522641157",
        "open": "2933.005494953831420618098844789785",
        "close": "2928.006342753099866991209971068576",
        "untrackedVolumeUSD": "561784919.982072428618001382088924",
        "priceUSD": "2928.006342753099866991209971068576"
    },
    {
        "date": 1650672000,
        "volume": "179204.358408109178900346",
        "open": "2961.217478673667413274229476517137",
        "close": "2933.005494953831420618098844789785",
        "untrackedVolumeUSD": "529322122.6898326160414742186333834",
        "priceUSD": "2933.005494953831420618098844789785"
    },
    {
        "date": 1650585600,
        "volume": "317422.096437324605971986",
        "open": "2982.342984776153255439783986079156",
        "close": "2961.217478673667413274229476517137",
        "untrackedVolumeUSD": "946710111.1979556348465362154574617",
        "priceUSD": "2961.217478673667413274229476517137"
    },
    {
        "date": 1650499200,
        "volume": "379033.584963036171012376",
        "open": "3081.146371233160338795807931005407",
        "close": "2982.342984776153255439783986079156",
        "untrackedVolumeUSD": "1167614702.494934102357193110582821",
        "priceUSD": "2982.342984776153255439783986079156"
    },
    {
        "date": 1650412800,
        "volume": "392263.225563028795178681",
        "open": "3101.983114454551091249779439575703",
        "close": "3081.146371233160338795807931005407",
        "untrackedVolumeUSD": "1212897753.080502048272697817582023",
        "priceUSD": "3081.146371233160338795807931005407"
    },
    {
        "date": 1650326400,
        "volume": "280073.622827727809266497",
        "open": "3060.0725008635041798635057684909",
        "close": "3101.983114454551091249779439575703",
        "untrackedVolumeUSD": "861624345.6578930037835566940433222",
        "priceUSD": "3101.983114454551091249779439575703"
    },
    {
        "date": 1650240000,
        "volume": "371076.965115495004534206",
        "open": "2990.145903992679574625183056832901",
        "close": "3060.0725008635041798635057684909",
        "untrackedVolumeUSD": "1099023115.614671841668481825090951",
        "priceUSD": "3060.0725008635041798635057684909"
    },
    {
        "date": 1650153600,
        "volume": "305729.615959609594382656",
        "open": "3068.783000161234570870486069658025",
        "close": "2990.145903992679574625183056832901",
        "untrackedVolumeUSD": "933594956.4036414215555955079968422",
        "priceUSD": "2990.145903992679574625183056832901"
    },
    {
        "date": 1650067200,
        "volume": "133243.298108299790217926",
        "open": "3036.916726163472952380191793196601",
        "close": "3068.783000161234570870486069658025",
        "untrackedVolumeUSD": "404850521.6958458366001717782219358",
        "priceUSD": "3068.783000161234570870486069658025"
    },
    {
        "date": 1649980800,
        "volume": "250934.009521458293243118",
        "open": "3018.201699523132896650449574773829",
        "close": "3036.916726163472952380191793196601",
        "untrackedVolumeUSD": "759087949.787958073212937591148101",
        "priceUSD": "3036.916726163472952380191793196601"
    },
    {
        "date": 1649894400,
        "volume": "361662.897593012118358049",
        "open": "3120.232376507404307367946753422443",
        "close": "3018.201699523132896650449574773829",
        "untrackedVolumeUSD": "1107080736.975104620990994282881796",
        "priceUSD": "3018.201699523132896650449574773829"
    },
    {
        "date": 1649808000,
        "volume": "410999.358040550642894972",
        "open": "3026.682929349256505329111295139209",
        "close": "3120.232376507404307367946753422443",
        "untrackedVolumeUSD": "1259142358.874983504199242428733686",
        "priceUSD": "3120.232376507404307367946753422443"
    },
    {
        "date": 1649721600,
        "volume": "535858.213397079256177227",
        "open": "2979.848670681815769322151148589663",
        "close": "3026.682929349256505329111295139209",
        "untrackedVolumeUSD": "1618130085.910103850653219929823442",
        "priceUSD": "3026.682929349256505329111295139209"
    },
    {
        "date": 1649635200,
        "volume": "714917.118703685484858082",
        "open": "3208.981844524235330993410227291659",
        "close": "2979.848670681815769322151148589663",
        "untrackedVolumeUSD": "2189310548.417170965344910081538526",
        "priceUSD": "2979.848670681815769322151148589663"
    },
    {
        "date": 1649548800,
        "volume": "227339.355505216355837425",
        "open": "3259.35749698209151582660908723117",
        "close": "3208.981844524235330993410227291659",
        "untrackedVolumeUSD": "740428241.4965466151752633627185679",
        "priceUSD": "3208.981844524235330993410227291659"
    },
    {
        "date": 1649462400,
        "volume": "241621.191344716658432579",
        "open": "3185.643106811893855806789583435922",
        "close": "3259.35749698209151582660908723117",
        "untrackedVolumeUSD": "778009974.1875562950884189008408789",
        "priceUSD": "3259.35749698209151582660908723117"
    },
    {
        "date": 1649376000,
        "volume": "465201.570876320564680209",
        "open": "3237.428663741225343007761456979365",
        "close": "3185.643106811893855806789583435922",
        "untrackedVolumeUSD": "1512394371.048201690072232588720939",
        "priceUSD": "3185.643106811893855806789583435922"
    },
    {
        "date": 1649289600,
        "volume": "425889.048752485221134706",
        "open": "3172.666817228957711183855227334003",
        "close": "3237.428663741225343007761456979365",
        "untrackedVolumeUSD": "1366844713.816157015119128187772444",
        "priceUSD": "3237.428663741225343007761456979365"
    },
    {
        "date": 1649203200,
        "volume": "631240.150457632465040407",
        "open": "3411.877198597870209674189629191154",
        "close": "3172.666817228957711183855227334003",
        "untrackedVolumeUSD": "2066330889.799517206274336354473798",
        "priceUSD": "3172.666817228957711183855227334003"
    },
    {
        "date": 1649116800,
        "volume": "360277.312130489387082195",
        "open": "3528.165280779663124119721154787331",
        "close": "3411.877198597870209674189629191154",
        "untrackedVolumeUSD": "1253269515.456177356286005834312682",
        "priceUSD": "3411.877198597870209674189629191154"
    },
    {
        "date": 1649030400,
        "volume": "365579.299712111709038585",
        "open": "3524.664730203202504368075079900737",
        "close": "3528.165280779663124119721154787331",
        "untrackedVolumeUSD": "1270760547.163757111140762488325949",
        "priceUSD": "3528.165280779663124119721154787331"
    },
    {
        "date": 1648944000,
        "volume": "280403.728224369432660858",
        "open": "3445.045572264009507113587665156184",
        "close": "3524.664730203202504368075079900737",
        "untrackedVolumeUSD": "980216733.5862208761228600453379658",
        "priceUSD": "3524.664730203202504368075079900737"
    },
    {
        "date": 1648857600,
        "volume": "375177.175689883437548937",
        "open": "3447.155669041068518573083419105755",
        "close": "3445.045572264009507113587665156184",
        "untrackedVolumeUSD": "1304878854.513916393569353814407138",
        "priceUSD": "3445.045572264009507113587665156184"
    },
    {
        "date": 1648771200,
        "volume": "418068.717718147148464234",
        "open": "3277.140096066202788489301953084423",
        "close": "3447.155669041068518573083419105755",
        "untrackedVolumeUSD": "1400207849.797196166713458619232634",
        "priceUSD": "3447.155669041068518573083419105755"
    },
    {
        "date": 1648684800,
        "volume": "401991.795109406380389454",
        "open": "3386.608562803339755408618971076469",
        "close": "3277.140096066202788489301953084423",
        "untrackedVolumeUSD": "1349148328.873750251289630891703529",
        "priceUSD": "3277.140096066202788489301953084423"
    },
    {
        "date": 1648598400,
        "volume": "386490.450247303487475632",
        "open": "3395.962357861953705773556811743904",
        "close": "3386.608562803339755408618971076469",
        "untrackedVolumeUSD": "1309472512.138957229324538614102881",
        "priceUSD": "3386.608562803339755408618971076469"
    },
    {
        "date": 1648512000,
        "volume": "435276.413175850951400074",
        "open": "3330.786394804607736479395076039",
        "close": "3395.962357861953705773556811743904",
        "untrackedVolumeUSD": "1483026464.530839233987020417452562",
        "priceUSD": "3395.962357861953705773556811743904"
    },
    {
        "date": 1648425600,
        "volume": "443329.141355185236875152",
        "open": "3293.075573456462995037291786885697",
        "close": "3330.786394804607736479395076039",
        "untrackedVolumeUSD": "1487821205.391166940754822852431195",
        "priceUSD": "3330.786394804607736479395076039"
    },
    {
        "date": 1648339200,
        "volume": "301848.176056405343975963",
        "open": "3140.723843347491459863983761030313",
        "close": "3293.075573456462995037291786885697",
        "untrackedVolumeUSD": "965185375.3882807014487463803881026",
        "priceUSD": "3293.075573456462995037291786885697"
    },
    {
        "date": 1648252800,
        "volume": "223360.642196814001621161",
        "open": "3104.149298394866397256792816384793",
        "close": "3140.723843347491459863983761030313",
        "untrackedVolumeUSD": "697893393.0857783133432700452431047",
        "priceUSD": "3140.723843347491459863983761030313"
    },
    {
        "date": 1648166400,
        "volume": "438246.510722854781577187",
        "open": "3111.93905839201140241167270174205",
        "close": "3104.149298394866397256792816384793",
        "untrackedVolumeUSD": "1370129618.732652519777406177113509",
        "priceUSD": "3104.149298394866397256792816384793"
    },
    {
        "date": 1648080000,
        "volume": "498924.984978939816310283",
        "open": "3029.918377061716976976103766556077",
        "close": "3111.93905839201140241167270174205",
        "untrackedVolumeUSD": "1525703155.465464861165380247258255",
        "priceUSD": "3111.93905839201140241167270174205"
    },
    {
        "date": 1647993600,
        "volume": "358635.329980369978567934",
        "open": "2968.2987135512962394280317351238",
        "close": "3029.918377061716976976103766556077",
        "untrackedVolumeUSD": "1066519621.644816724526334329363139",
        "priceUSD": "3029.918377061716976976103766556077"
    },
    {
        "date": 1647907200,
        "volume": "425851.699391282438917918",
        "open": "2894.663185913433645846677507786548",
        "close": "2968.2987135512962394280317351238",
        "untrackedVolumeUSD": "1274486103.064331102729885555253726",
        "priceUSD": "2968.2987135512962394280317351238"
    },
    {
        "date": 1647820800,
        "volume": "371401.231197439628478943",
        "open": "2860.263226237375532467916691703145",
        "close": "2894.663185913433645846677507786548",
        "untrackedVolumeUSD": "1075932557.324970179078080400430872",
        "priceUSD": "2894.663185913433645846677507786548"
    },
    {
        "date": 1647734400,
        "volume": "376868.46621136969874539",
        "open": "2946.509686617982069411403312659486",
        "close": "2860.263226237375532467916691703145",
        "untrackedVolumeUSD": "1088703565.619634357686514221230624",
        "priceUSD": "2860.263226237375532467916691703145"
    },
    {
        "date": 1647648000,
        "volume": "355091.419191433614627445",
        "open": "2943.785206073554932737923491607611",
        "close": "2946.509686617982069411403312659486",
        "untrackedVolumeUSD": "1048115055.49438365250805078260125",
        "priceUSD": "2946.509686617982069411403312659486"
    },
    {
        "date": 1647561600,
        "volume": "607973.39359605841671914",
        "open": "2813.539844583848429501381123101662",
        "close": "2943.785206073554932737923491607611",
        "untrackedVolumeUSD": "1732276391.764112657462709416978924",
        "priceUSD": "2943.785206073554932737923491607611"
    },
    {
        "date": 1647475200,
        "volume": "470069.847100381527111603",
        "open": "2767.959695463351434762423021946504",
        "close": "2813.539844583848429501381123101662",
        "untrackedVolumeUSD": "1314551804.885408710315347947983557",
        "priceUSD": "2813.539844583848429501381123101662"
    },
    {
        "date": 1647388800,
        "volume": "550003.127986007355585147",
        "open": "2622.152674265079995823280928528587",
        "close": "2767.959695463351434762423021946504",
        "untrackedVolumeUSD": "1479096479.315176965505145479832021",
        "priceUSD": "2767.959695463351434762423021946504"
    },
    {
        "date": 1647302400,
        "volume": "355079.899278626791986399",
        "open": "2588.780171791404552599492511121159",
        "close": "2622.152674265079995823280928528587",
        "untrackedVolumeUSD": "914661068.5892401692292422063362085",
        "priceUSD": "2622.152674265079995823280928528587"
    },
    {
        "date": 1647216000,
        "volume": "349078.943619030274953227",
        "open": "2515.105408317839259710042047173459",
        "close": "2588.780171791404552599492511121159",
        "untrackedVolumeUSD": "894155066.4763688046038910267359025",
        "priceUSD": "2588.780171791404552599492511121159"
    },
    {
        "date": 1647129600,
        "volume": "294455.204659916752311283",
        "open": "2570.473528517129711134776200066319",
        "close": "2515.105408317839259710042047173459",
        "untrackedVolumeUSD": "753425954.4930314232843972518927989",
        "priceUSD": "2515.105408317839259710042047173459"
    },
    {
        "date": 1647043200,
        "volume": "181868.224603956480349046",
        "open": "2564.698684654762022336282515609955",
        "close": "2570.473528517129711134776200066319",
        "untrackedVolumeUSD": "470015592.1806420743743445676907086",
        "priceUSD": "2570.473528517129711134776200066319"
    },
    {
        "date": 1646956800,
        "volume": "405781.504515801754750572",
        "open": "2613.290832558950638551254420027394",
        "close": "2564.698684654762022336282515609955",
        "untrackedVolumeUSD": "1049542734.264395125228401864214676",
        "priceUSD": "2564.698684654762022336282515609955"
    },
    {
        "date": 1646870400,
        "volume": "400495.332347751113606753",
        "open": "2727.207341567493896887424258594823",
        "close": "2613.290832558950638551254420027394",
        "untrackedVolumeUSD": "1046620669.287613119835979639093813",
        "priceUSD": "2613.290832558950638551254420027394"
    },
    {
        "date": 1646784000,
        "volume": "430292.515873682748958123",
        "open": "2577.970323911077352355802102191497",
        "close": "2727.207341567493896887424258594823",
        "untrackedVolumeUSD": "1165320762.653614883738476057591359",
        "priceUSD": "2727.207341567493896887424258594823"
    },
    {
        "date": 1646697600,
        "volume": "515665.185385873288752271",
        "open": "2489.847915434112324885780249176389",
        "close": "2577.970323911077352355802102191497",
        "untrackedVolumeUSD": "1320599026.294231464730152658046885",
        "priceUSD": "2577.970323911077352355802102191497"
    },
    {
        "date": 1646611200,
        "volume": "498364.355824869628386679",
        "open": "2556.033267477849485663453841105975",
        "close": "2489.847915434112324885780249176389",
        "untrackedVolumeUSD": "1265287481.696019540822445246712072",
        "priceUSD": "2489.847915434112324885780249176389"
    },
    {
        "date": 1646524800,
        "volume": "310458.083928884044366064",
        "open": "2663.18084514143631155522995117725",
        "close": "2556.033267477849485663453841105975",
        "untrackedVolumeUSD": "813395992.839380656995938996054391",
        "priceUSD": "2556.033267477849485663453841105975"
    },
    {
        "date": 1646438400,
        "volume": "250148.769555757838030017",
        "open": "2616.815553456486659929043613748662",
        "close": "2663.18084514143631155522995117725",
        "untrackedVolumeUSD": "660549780.1146375906029779937696737",
        "priceUSD": "2663.18084514143631155522995117725"
    },
    {
        "date": 1646352000,
        "volume": "547125.807009355010358869",
        "open": "2840.029379320861593487066333871337",
        "close": "2616.815553456486659929043613748662",
        "untrackedVolumeUSD": "1474005093.568629981824792892697323",
        "priceUSD": "2616.815553456486659929043613748662"
    },
    {
        "date": 1646265600,
        "volume": "359904.51226049384381854",
        "open": "2955.832277360311117226870002449169",
        "close": "2840.029379320861593487066333871337",
        "untrackedVolumeUSD": "1033534418.000931643323277019172239",
        "priceUSD": "2840.029379320861593487066333871337"
    },
    {
        "date": 1646179200,
        "volume": "559325.652358638367564174",
        "open": "2972.270397420131483917476524986784",
        "close": "2955.832277360311117226870002449169",
        "untrackedVolumeUSD": "1664328689.36226808686337321591244",
        "priceUSD": "2955.832277360311117226870002449169"
    },
    {
        "date": 1646092800,
        "volume": "548147.880090724020746839",
        "open": "2916.7505798919255364682248667028",
        "close": "2972.270397420131483917476524986784",
        "untrackedVolumeUSD": "1615130118.479590725605957754276889",
        "priceUSD": "2972.270397420131483917476524986784"
    },
    {
        "date": 1646006400,
        "volume": "634907.95039183007534396",
        "open": "2622.489799304209533427233092850758",
        "close": "2916.7505798919255364682248667028",
        "untrackedVolumeUSD": "1729754489.391314182064882051175481",
        "priceUSD": "2916.7505798919255364682248667028"
    },
    {
        "date": 1645920000,
        "volume": "637065.482698509801646309",
        "open": "2775.250507240461211124910915273258",
        "close": "2622.489799304209533427233092850758",
        "untrackedVolumeUSD": "1722673038.604189455700027583512681",
        "priceUSD": "2622.489799304209533427233092850758"
    },
    {
        "date": 1645833600,
        "volume": "373469.508965868043335615",
        "open": "2761.37952204450346186385950820778",
        "close": "2775.250507240461211124910915273258",
        "untrackedVolumeUSD": "1042450293.723810485088063768322684",
        "priceUSD": "2775.250507240461211124910915273258"
    },
    {
        "date": 1645747200,
        "volume": "646974.187159037304822928",
        "open": "2595.351657219327091771300841633766",
        "close": "2761.37952204450346186385950820778",
        "untrackedVolumeUSD": "1734281105.696387660818319330511331",
        "priceUSD": "2761.37952204450346186385950820778"
    },
    {
        "date": 1645660800,
        "volume": "1133026.034205105591968828",
        "open": "2587.828742231766456648951432357425",
        "close": "2595.351657219327091771300841633766",
        "untrackedVolumeUSD": "2779886857.316578783284648077948592",
        "priceUSD": "2595.351657219327091771300841633766"
    },
    {
        "date": 1645574400,
        "volume": "474453.284637849446085958",
        "open": "2644.124912409623748914449755797499",
        "close": "2587.828742231766456648951432357425",
        "untrackedVolumeUSD": "1267235009.58352266565768947753837",
        "priceUSD": "2587.828742231766456648951432357425"
    },
    {
        "date": 1645488000,
        "volume": "564697.157739458610664685",
        "open": "2574.482817800470313255968794109513",
        "close": "2644.124912409623748914449755797499",
        "untrackedVolumeUSD": "1458034769.3329371309904021221782",
        "priceUSD": "2644.124912409623748914449755797499"
    },
    {
        "date": 1645401600,
        "volume": "761602.919988216695055331",
        "open": "2629.096130119969849998769917028346",
        "close": "2574.482817800470313255968794109513",
        "untrackedVolumeUSD": "2027534330.798335091934683032427009",
        "priceUSD": "2574.482817800470313255968794109513"
    },
    {
        "date": 1645315200,
        "volume": "531371.068294372857981522",
        "open": "2759.077127933076725636409675655944",
        "close": "2629.096130119969849998769917028346",
        "untrackedVolumeUSD": "1409094553.308573569851260047922889",
        "priceUSD": "2629.096130119969849998769917028346"
    },
    {
        "date": 1645228800,
        "volume": "289613.219691988836147996",
        "open": "2784.20443623072378699948844655156",
        "close": "2759.077127933076725636409675655944",
        "untrackedVolumeUSD": "800674403.7456402303536068445156123",
        "priceUSD": "2759.077127933076725636409675655944"
    },
    {
        "date": 1645142400,
        "volume": "452746.069508561747122844",
        "open": "2888.429215941195134269828090820699",
        "close": "2784.20443623072378699948844655156",
        "untrackedVolumeUSD": "1287855646.979036602068704667580866",
        "priceUSD": "2784.20443623072378699948844655156"
    },
    {
        "date": 1645056000,
        "volume": "552452.983077258346794142",
        "open": "3129.969803351954434380180805491188",
        "close": "2888.429215941195134269828090820699",
        "untrackedVolumeUSD": "1660192824.018311355594389962793198",
        "priceUSD": "2888.429215941195134269828090820699"
    },
    {
        "date": 1644969600,
        "volume": "395919.799474450582347526",
        "open": "3185.351271203341440258484068524487",
        "close": "3129.969803351954434380180805491188",
        "untrackedVolumeUSD": "1236478349.344764019500070378678412",
        "priceUSD": "3129.969803351954434380180805491188"
    },
    {
        "date": 1644883200,
        "volume": "416968.611202596848657942",
        "open": "2937.031315564994830595135330745894",
        "close": "3185.351271203341440258484068524487",
        "untrackedVolumeUSD": "1283905446.059299975666649085232748",
        "priceUSD": "3185.351271203341440258484068524487"
    },
    {
        "date": 1644796800,
        "volume": "349877.916988567713286667",
        "open": "2878.592118093305314177583541190916",
        "close": "2937.031315564994830595135330745894",
        "untrackedVolumeUSD": "1012899104.2815287537058405047323",
        "priceUSD": "2937.031315564994830595135330745894"
    },
    {
        "date": 1644710400,
        "volume": "271855.845385947592379321",
        "open": "2912.492179286921250539654882563539",
        "close": "2878.592118093305314177583541190916",
        "untrackedVolumeUSD": "788705666.5113525494448947301164003",
        "priceUSD": "2878.592118093305314177583541190916"
    },
    {
        "date": 1644624000,
        "volume": "401146.245477813651982944",
        "open": "2928.791245188908395378016530787563",
        "close": "2912.492179286921250539654882563539",
        "untrackedVolumeUSD": "1169984667.784325806412824670056675",
        "priceUSD": "2912.492179286921250539654882563539"
    },
    {
        "date": 1644537600,
        "volume": "618872.274112267347187655",
        "open": "3072.084600324768449854405414828125",
        "close": "2928.791245188908395378016530787563",
        "untrackedVolumeUSD": "1874182513.555818023959101657354044",
        "priceUSD": "2928.791245188908395378016530787563"
    },
    {
        "date": 1644451200,
        "volume": "580344.9118939395530874",
        "open": "3246.333466222840399156260482720824",
        "close": "3072.084600324768449854405414828125",
        "untrackedVolumeUSD": "1846057416.20472778914848638350309",
        "priceUSD": "3072.084600324768449854405414828125"
    },
    {
        "date": 1644364800,
        "volume": "385144.566867621810696204",
        "open": "3127.380169623527896132256186681744",
        "close": "3246.333466222840399156260482720824",
        "untrackedVolumeUSD": "1218669926.070806633445318287778726",
        "priceUSD": "3246.333466222840399156260482720824"
    },
    {
        "date": 1644278400,
        "volume": "425296.487525052902966496",
        "open": "3148.45593597808440355834281390826",
        "close": "3127.380169623527896132256186681744",
        "untrackedVolumeUSD": "1322692103.72889704521079016602475",
        "priceUSD": "3127.380169623527896132256186681744"
    },
    {
        "date": 1644192000,
        "volume": "424831.847441855978824108",
        "open": "3064.326674763549548519864747529854",
        "close": "3148.45593597808440355834281390826",
        "untrackedVolumeUSD": "1317588852.703927445063714792310695",
        "priceUSD": "3148.45593597808440355834281390826"
    },
    {
        "date": 1644105600,
        "volume": "324018.672522302462126835",
        "open": "3019.886213788377823124062780498369",
        "close": "3064.326674763549548519864747529854",
        "untrackedVolumeUSD": "974443783.2582754608606416553777292",
        "priceUSD": "3064.326674763549548519864747529854"
    },
    {
        "date": 1644019200,
        "volume": "412211.956464544057979921",
        "open": "2987.221842328757866488340711229065",
        "close": "3019.886213788377823124062780498369",
        "untrackedVolumeUSD": "1241116458.746202868965269160688463",
        "priceUSD": "3019.886213788377823124062780498369"
    },
    {
        "date": 1643932800,
        "volume": "454334.19520301698756627",
        "open": "2690.028924725841543791486556772678",
        "close": "2987.221842328757866488340711229065",
        "untrackedVolumeUSD": "1289350647.438537872570704638705886",
        "priceUSD": "2987.221842328757866488340711229065"
    },
    {
        "date": 1643846400,
        "volume": "471539.312874116808916968",
        "open": "2681.56882216756074030142660998642",
        "close": "2690.028924725841543791486556772678",
        "untrackedVolumeUSD": "1244736912.815237379524225030225216",
        "priceUSD": "2690.028924725841543791486556772678"
    },
    {
        "date": 1643760000,
        "volume": "495991.22506251516841796",
        "open": "2794.533997356414254035690832814039",
        "close": "2681.56882216756074030142660998642",
        "untrackedVolumeUSD": "1345789018.777694077425298367065303",
        "priceUSD": "2681.56882216756074030142660998642"
    },
    {
        "date": 1643673600,
        "volume": "507038.314167189898100278",
        "open": "2687.372157454166201573759634784917",
        "close": "2794.533997356414254035690832814039",
        "untrackedVolumeUSD": "1399367782.684376996747385105100529",
        "priceUSD": "2794.533997356414254035690832814039"
    },
    {
        "date": 1643587200,
        "volume": "416065.75542280170015403",
        "open": "2608.526028717395957904271825483711",
        "close": "2687.372157454166201573759634784917",
        "untrackedVolumeUSD": "1077744546.706666588592326565112109",
        "priceUSD": "2687.372157454166201573759634784917"
    },
    {
        "date": 1643500800,
        "volume": "320361.716539300863383484",
        "open": "2595.636297141541721293566271196155",
        "close": "2608.526028717395957904271825483711",
        "untrackedVolumeUSD": "831240134.9727688035422491599206874",
        "priceUSD": "2608.526028717395957904271825483711"
    },
    {
        "date": 1643414400,
        "volume": "366516.967670989939168702",
        "open": "2549.505558228361314337981678804387",
        "close": "2595.636297141541721293566271196155",
        "untrackedVolumeUSD": "941121732.0768932578227053468865665",
        "priceUSD": "2595.636297141541721293566271196155"
    },
    {
        "date": 1643328000,
        "volume": "529252.842106222726450586",
        "open": "2424.998078022262407339338673084972",
        "close": "2549.505558228361314337981678804387",
        "untrackedVolumeUSD": "1292923650.315540384682673012196842",
        "priceUSD": "2549.505558228361314337981678804387"
    },
    {
        "date": 1643241600,
        "volume": "693812.242236924898914602",
        "open": "2465.432611647602732482682611124581",
        "close": "2424.998078022262407339338673084972",
        "untrackedVolumeUSD": "1675053153.933388474715364813574314",
        "priceUSD": "2424.998078022262407339338673084972"
    },
    {
        "date": 1643155200,
        "volume": "863351.738250873199581029",
        "open": "2459.062186559680821917056020428261",
        "close": "2465.432611647602732482682611124581",
        "untrackedVolumeUSD": "2190293573.883969050480781234592806",
        "priceUSD": "2465.432611647602732482682611124581"
    },
    {
        "date": 1643068800,
        "volume": "581007.525163276599155051",
        "open": "2442.241230000005762647467171251516",
        "close": "2459.062186559680821917056020428261",
        "untrackedVolumeUSD": "1410111952.361489697342432876131255",
        "priceUSD": "2459.062186559680821917056020428261"
    },
    {
        "date": 1642982400,
        "volume": "1104476.740635651444950788",
        "open": "2535.609678016283630655027479286846",
        "close": "2442.241230000005762647467171251516",
        "untrackedVolumeUSD": "2574711724.02379729865510154542587",
        "priceUSD": "2442.241230000005762647467171251516"
    },
    {
        "date": 1642896000,
        "volume": "816807.090631467236292069",
        "open": "2405.486825000000088194137428103443",
        "close": "2535.609678016283630655027479286846",
        "untrackedVolumeUSD": "2002500696.393270290761078471853772",
        "priceUSD": "2535.609678016283630655027479286846"
    },
    {
        "date": 1642809600,
        "volume": "859755.239402443250816882",
        "open": "2564.689095817281368298653982518085",
        "close": "2405.486825000000088194137428103443",
        "untrackedVolumeUSD": "2110609488.467047396805856442129591",
        "priceUSD": "2405.486825000000088194137428103443"
    },
    {
        "date": 1642723200,
        "volume": "1298566.056821474952547117",
        "open": "2996.131973409121370896887972561605",
        "close": "2564.689095817281368298653982518085",
        "untrackedVolumeUSD": "3596228283.907906200343876502886424",
        "priceUSD": "2564.689095817281368298653982518085"
    },
    {
        "date": 1642636800,
        "volume": "503886.042877193548377207",
        "open": "3096.402491795033797036478381245349",
        "close": "2996.131973409121370896887972561605",
        "untrackedVolumeUSD": "1583827419.424344579140394182982789",
        "priceUSD": "2996.131973409121370896887972561605"
    },
    {
        "date": 1642550400,
        "volume": "468308.603180715512299723",
        "open": "3164.960723171603522742465355533807",
        "close": "3096.402491795033797036478381245349",
        "untrackedVolumeUSD": "1458164457.804994861773945659566991",
        "priceUSD": "3096.402491795033797036478381245349"
    },
    {
        "date": 1642464000,
        "volume": "451050.61733799779456638",
        "open": "3211.195206331828031012668744270801",
        "close": "3164.960723171603522742465355533807",
        "untrackedVolumeUSD": "1420797607.193978862688498419048967",
        "priceUSD": "3164.960723171603522742465355533807"
    },
    {
        "date": 1642377600,
        "volume": "411455.720609775995378379",
        "open": "3351.79407641250356243179972297519",
        "close": "3211.195206331828031012668744270801",
        "untrackedVolumeUSD": "1336463005.212177823578211136935816",
        "priceUSD": "3211.195206331828031012668744270801"
    },
    {
        "date": 1642291200,
        "volume": "271607.004762840588607779",
        "open": "3338.641789379621900096269786061141",
        "close": "3351.79407641250356243179972297519",
        "untrackedVolumeUSD": "903534873.1995494274261112073369842",
        "priceUSD": "3351.79407641250356243179972297519"
    },
    {
        "date": 1642204800,
        "volume": "236613.434799306931627525",
        "open": "3304.655535926242303117927830600888",
        "close": "3338.641789379621900096269786061141",
        "untrackedVolumeUSD": "786682624.3608631196351021944167799",
        "priceUSD": "3338.641789379621900096269786061141"
    },
    {
        "date": 1642118400,
        "volume": "373303.759475234084004933",
        "open": "3245.926238913708488345082059898148",
        "close": "3304.655535926242303117927830600888",
        "untrackedVolumeUSD": "1219849178.718302702559196088826122",
        "priceUSD": "3304.655535926242303117927830600888"
    },
    {
        "date": 1642032000,
        "volume": "488515.893587689110957474",
        "open": "3368.733658955185568221298694794474",
        "close": "3245.926238913708488345082059898148",
        "untrackedVolumeUSD": "1621746260.057021825012929401269629",
        "priceUSD": "3245.926238913708488345082059898148"
    },
    {
        "date": 1641945600,
        "volume": "405561.674646488792299006",
        "open": "3238.311303199554208057982956131201",
        "close": "3368.733658955185568221298694794474",
        "untrackedVolumeUSD": "1343152277.054028753671507128653024",
        "priceUSD": "3368.733658955185568221298694794474"
    },
    {
        "date": 1641859200,
        "volume": "391965.347528138580738906",
        "open": "3077.106990411685018316741052180198",
        "close": "3238.311303199554208057982956131201",
        "untrackedVolumeUSD": "1237183162.173823213251797082368676",
        "priceUSD": "3238.311303199554208057982956131201"
    },
    {
        "date": 1641772800,
        "volume": "640154.68554275881633129",
        "open": "3150.99256164635649541236995026324",
        "close": "3077.106990411685018316741052180198",
        "untrackedVolumeUSD": "1956976343.102262660741140994719374",
        "priceUSD": "3077.106990411685018316741052180198"
    },
    {
        "date": 1641686400,
        "volume": "301159.000009014078490597",
        "open": "3091.16723860158903471117039955854",
        "close": "3150.99256164635649541236995026324",
        "untrackedVolumeUSD": "943302649.971517693357491946428406",
        "priceUSD": "3150.99256164635649541236995026324"
    },
    {
        "date": 1641600000,
        "volume": "491225.593684858092051266",
        "open": "3184.630951882621236193723277782167",
        "close": "3091.16723860158903471117039955854",
        "untrackedVolumeUSD": "1531347417.256690979647885145971292",
        "priceUSD": "3091.16723860158903471117039955854"
    },
    {
        "date": 1641513600,
        "volume": "579026.25523415521968926",
        "open": "3411.771705988366633586024880558311",
        "close": "3184.630951882621236193723277782167",
        "untrackedVolumeUSD": "1858110379.042675220531960698013501",
        "priceUSD": "3184.630951882621236193723277782167"
    },
    {
        "date": 1641427200,
        "volume": "427363.445958767366784351",
        "open": "3547.345039965550412347209391906607",
        "close": "3411.771705988366633586024880558311",
        "untrackedVolumeUSD": "1460526425.751934224830144280302354",
        "priceUSD": "3411.771705988366633586024880558311"
    },
    {
        "date": 1641340800,
        "volume": "448685.193736322079805474",
        "open": "3791.943802133068085847849097353705",
        "close": "3547.345039965550412347209391906607",
        "untrackedVolumeUSD": "1651894788.121812301494162532608283",
        "priceUSD": "3547.345039965550412347209391906607"
    },
    {
        "date": 1641254400,
        "volume": "325788.974161081111482037",
        "open": "3764.733867535288566071573376875641",
        "close": "3791.943802133068085847849097353705",
        "untrackedVolumeUSD": "1239151735.723393515377924367844801",
        "priceUSD": "3791.943802133068085847849097353705"
    },
    {
        "date": 1641168000,
        "volume": "259320.411692908567953262",
        "open": "3825.895239649412638083212199061639",
        "close": "3764.733867535288566071573376875641",
        "untrackedVolumeUSD": "981518774.1889660001423166156115526",
        "priceUSD": "3764.733867535288566071573376875641"
    },
    {
        "date": 1641081600,
        "volume": "213920.289671200922472833",
        "open": "3762.305313718545011414672067855501",
        "close": "3825.895239649412638083212199061639",
        "untrackedVolumeUSD": "807921739.5560856514497192515684179",
        "priceUSD": "3825.895239649412638083212199061639"
    },
    {
        "date": 1640995200,
        "volume": "204815.099998959454132942",
        "open": "3685.738411225097774213516916242401",
        "close": "3762.305313718545011414672067855501",
        "untrackedVolumeUSD": "762781009.3024257311115555731642198",
        "priceUSD": "3762.305313718545011414672067855501"
    },
    {
        "date": 1640908800,
        "volume": "271140.703288279294414022",
        "open": "3705.287059735795279266055135926516",
        "close": "3685.738411225097774213516916242401",
        "untrackedVolumeUSD": "1012180493.989636116037722536134367",
        "priceUSD": "3685.738411225097774213516916242401"
    },
    {
        "date": 1640822400,
        "volume": "271266.198348369624996492",
        "open": "3626.275571899173998782617192883557",
        "close": "3705.287059735795279266055135926516",
        "untrackedVolumeUSD": "1002146472.343797002342964057847555",
        "priceUSD": "3705.287059735795279266055135926516"
    },
    {
        "date": 1640736000,
        "volume": "302054.940442401888744671",
        "open": "3803.698310630977154494752048550093",
        "close": "3626.275571899173998782617192883557",
        "untrackedVolumeUSD": "1136765658.144633547984965308860343",
        "priceUSD": "3626.275571899173998782617192883557"
    },
    {
        "date": 1640649600,
        "volume": "477846.875288303630331914",
        "open": "4048.179525217269079779352624606787",
        "close": "3803.698310630977154494752048550093",
        "untrackedVolumeUSD": "1858455638.420605422740849211197052",
        "priceUSD": "3803.698310630977154494752048550093"
    },
    {
        "date": 1640563200,
        "volume": "233390.308719571589219036",
        "open": "4076.141945894847743391167738927186",
        "close": "4048.179525217269079779352624606787",
        "untrackedVolumeUSD": "951948515.8140435749162016920308004",
        "priceUSD": "4048.179525217269079779352624606787"
    },
    {
        "date": 1640476800,
        "volume": "250082.784359035225902597",
        "open": "4096.708304269309482872761230818243",
        "close": "4076.141945894847743391167738927186",
        "untrackedVolumeUSD": "1014644842.981440810225041057752423",
        "priceUSD": "4076.141945894847743391167738927186"
    },
    {
        "date": 1640390400,
        "volume": "318440.114249267727475821",
        "open": "4044.47764840738153066302606509724",
        "close": "4096.708304269309482872761230818243",
        "untrackedVolumeUSD": "1295442096.534901713494252202671426",
        "priceUSD": "4096.708304269309482872761230818243"
    },
    {
        "date": 1640304000,
        "volume": "273969.432953942744787427",
        "open": "4102.745135529301938338388494752014",
        "close": "4044.47764840738153066302606509724",
        "untrackedVolumeUSD": "1119799150.247390897137793616847925",
        "priceUSD": "4044.47764840738153066302606509724"
    },
    {
        "date": 1640217600,
        "volume": "368609.464512857148256366",
        "open": "3988.320857752696732347694680608509",
        "close": "4102.745135529301938338388494752014",
        "untrackedVolumeUSD": "1474117185.951812849109059164876247",
        "priceUSD": "4102.745135529301938338388494752014"
    },
    {
        "date": 1640131200,
        "volume": "267106.562551055100620353",
        "open": "4029.154988134320411409326014217",
        "close": "3988.320857752696732347694680608509",
        "untrackedVolumeUSD": "1071057905.037162300568228793455659",
        "priceUSD": "3988.320857752696732347694680608509"
    },
    {
        "date": 1640044800,
        "volume": "317588.726436032791717843",
        "open": "3952.041554098355353398242089509551",
        "close": "4029.154988134320411409326014217",
        "untrackedVolumeUSD": "1269525979.075316675155580906507092",
        "priceUSD": "4029.154988134320411409326014217"
    },
    {
        "date": 1639958400,
        "volume": "490878.774502560967869776",
        "open": "3929.362264624385200847916503457993",
        "close": "3952.041554098355353398242089509551",
        "untrackedVolumeUSD": "1892571684.751698728750525529077605",
        "priceUSD": "3952.041554098355353398242089509551"
    },
    {
        "date": 1639872000,
        "volume": "360963.706906219849542999",
        "open": "3960.168739930261984467563245251104",
        "close": "3929.362264624385200847916503457993",
        "untrackedVolumeUSD": "1424903640.077456884075507007903101",
        "priceUSD": "3929.362264624385200847916503457993"
    },
    {
        "date": 1639785600,
        "volume": "399466.572852712775251183",
        "open": "3882.215311455210161414568673589687",
        "close": "3960.168739930261984467563245251104",
        "untrackedVolumeUSD": "1558866861.480712269143771675269152",
        "priceUSD": "3960.168739930261984467563245251104"
    },
    {
        "date": 1639699200,
        "volume": "574655.053016446008154354",
        "open": "3968.806841723873923303185049044664",
        "close": "3882.215311455210161414568673589687",
        "untrackedVolumeUSD": "2221221482.017642204346285896538424",
        "priceUSD": "3882.215311455210161414568673589687"
    },
    {
        "date": 1639612800,
        "volume": "423343.023746534595764955",
        "open": "4018.186945755843059415232120756226",
        "close": "3968.806841723873923303185049044664",
        "untrackedVolumeUSD": "1709474713.213781132852986799342759",
        "priceUSD": "3968.806841723873923303185049044664"
    },
    {
        "date": 1639526400,
        "volume": "714154.776773605095485772",
        "open": "3862.052152929742586847448693625299",
        "close": "4018.186945755843059415232120756226",
        "untrackedVolumeUSD": "2763776052.802834699778010860371465",
        "priceUSD": "4018.186945755843059415232120756226"
    },
    {
        "date": 1639440000,
        "volume": "436722.084273585731963619",
        "open": "3781.569977668241721185090187756124",
        "close": "3862.052152929742586847448693625299",
        "untrackedVolumeUSD": "1656533364.749052905132101112330057",
        "priceUSD": "3862.052152929742586847448693625299"
    },
    {
        "date": 1639353600,
        "volume": "539575.909255343713750588",
        "open": "4140.61347705481573469794238776668",
        "close": "3781.569977668241721185090187756124",
        "untrackedVolumeUSD": "2102953744.474631426269212380353813",
        "priceUSD": "3781.569977668241721185090187756124"
    },
    {
        "date": 1639267200,
        "volume": "296667.273423538122964616",
        "open": "4081.026881065509077874889182882925",
        "close": "4140.61347705481573469794238776668",
        "untrackedVolumeUSD": "1209642301.021114714886137075497262",
        "priceUSD": "4140.61347705481573469794238776668"
    },
    {
        "date": 1639180800,
        "volume": "497378.400204111711603688",
        "open": "3904.088824748757280203828286378992",
        "close": "4081.026881065509077874889182882925",
        "untrackedVolumeUSD": "1990532447.68432278235110890838465",
        "priceUSD": "4081.026881065509077874889182882925"
    },
    {
        "date": 1639094400,
        "volume": "714059.310569156252872087",
        "open": "4129.111577914309932894545267295878",
        "close": "3904.088824748757280203828286378992",
        "untrackedVolumeUSD": "2907214131.421677847624661535853904",
        "priceUSD": "3904.088824748757280203828286378992"
    },
    {
        "date": 1639008000,
        "volume": "595584.63464449916962918",
        "open": "4428.106503590070032275159221804522",
        "close": "4129.111577914309932894545267295878",
        "untrackedVolumeUSD": "2534845387.753336352193772182150862",
        "priceUSD": "4129.111577914309932894545267295878"
    },
    {
        "date": 1638921600,
        "volume": "407079.374361250426387736",
        "open": "4300.927426670470287467711316131402",
        "close": "4428.106503590070032275159221804522",
        "untrackedVolumeUSD": "1771093420.724686139816853161310568",
        "priceUSD": "4428.106503590070032275159221804522"
    }
]
export const tvlChartMock = [
    {
        "date": 1685318400,
        "volume": "231891.986600058865141566",
        "open": "1911.463442626953148841666411304792",
        "close": "1897.060458156860544568128152550481",
        "untrackedVolumeUSD": "440567034.3680626798960622883215784",
        "priceUSD": "1897.060458156860544568128152550481"
    },
    {
        "date": 1685232000,
        "volume": "266150.071517540206252234",
        "open": "1828.063377059410750066117061297334",
        "close": "1911.463442626953148841666411304792",
        "untrackedVolumeUSD": "496045326.4606710805639208287825758",
        "priceUSD": "1911.463442626953148841666411304792"
    },
    {
        "date": 1685145600,
        "volume": "133538.127205566443764281",
        "open": "1832.141058075041976045500175529453",
        "close": "1828.063377059410750066117061297334",
        "untrackedVolumeUSD": "244076085.5749959698204059943378442",
        "priceUSD": "1828.063377059410750066117061297334"
    },
    {
        "date": 1685059200,
        "volume": "218908.713787882550192493",
        "open": "1810.911320048426174437240580918241",
        "close": "1832.141058075041976045500175529453",
        "untrackedVolumeUSD": "398323194.209429141946983881652811",
        "priceUSD": "1832.141058075041976045500175529453"
    },
    {
        "date": 1684972800,
        "volume": "256637.349274094062564294",
        "open": "1799.681265998766494733290412467148",
        "close": "1810.911320048426174437240580918241",
        "untrackedVolumeUSD": "460139494.682714015143495458791423",
        "priceUSD": "1810.911320048426174437240580918241"
    },
    {
        "date": 1684886400,
        "volume": "343691.591507924417194447",
        "open": "1851.681685682754083484461145814277",
        "close": "1799.681265998766494733290412467148",
        "untrackedVolumeUSD": "621738348.6556085301010870646020613",
        "priceUSD": "1799.681265998766494733290412467148"
    },
    {
        "date": 1684800000,
        "volume": "341059.210664484652088351",
        "open": "1820.892727779596075908148445336096",
        "close": "1851.681685682754083484461145814277",
        "untrackedVolumeUSD": "631569275.148500127732870237136473",
        "priceUSD": "1851.681685682754083484461145814277"
    },
    {
        "date": 1684713600,
        "volume": "237850.208892337971600321",
        "open": "1804.653479931751512760888816153473",
        "close": "1820.892727779596075908148445336096",
        "untrackedVolumeUSD": "431051349.9228763824521680155623798",
        "priceUSD": "1820.892727779596075908148445336096"
    },
    {
        "date": 1684627200,
        "volume": "187147.032163892694953034",
        "open": "1819.468155088755646652327979405198",
        "close": "1804.653479931751512760888816153473",
        "untrackedVolumeUSD": "339322272.8211748863228300078250224",
        "priceUSD": "1804.653479931751512760888816153473"
    },
    {
        "date": 1684540800,
        "volume": "137191.198500214463132355",
        "open": "1813.876326419036457114962680615717",
        "close": "1819.468155088755646652327979405198",
        "untrackedVolumeUSD": "249128123.4968979040584979283948938",
        "priceUSD": "1819.468155088755646652327979405198"
    },
    {
        "date": 1684454400,
        "volume": "182534.891051096299390869",
        "open": "1805.714679549776346304490693384817",
        "close": "1813.876326419036457114962680615717",
        "untrackedVolumeUSD": "330402299.8285589994800718207673685",
        "priceUSD": "1813.876326419036457114962680615717"
    },
    {
        "date": 1684368000,
        "volume": "218671.929413698229184163",
        "open": "1825.272905674184096690352938677376",
        "close": "1805.714679549776346304490693384817",
        "untrackedVolumeUSD": "396151172.5988308305739976272807342",
        "priceUSD": "1805.714679549776346304490693384817"
    },
    {
        "date": 1684281600,
        "volume": "234054.058794285313382598",
        "open": "1823.419794215579469078750515524987",
        "close": "1825.272905674184096690352938677376",
        "untrackedVolumeUSD": "424512106.5175696443979759374072882",
        "priceUSD": "1825.272905674184096690352938677376"
    },
    {
        "date": 1684195200,
        "volume": "210947.826909755616454376",
        "open": "1820.557266172119590393704962680451",
        "close": "1823.419794215579469078750515524987",
        "untrackedVolumeUSD": "383293594.1846998133784913827420356",
        "priceUSD": "1823.419794215579469078750515524987"
    },
    {
        "date": 1684108800,
        "volume": "244112.279806136978683203",
        "open": "1798.049900449482843870015141037912",
        "close": "1820.557266172119590393704962680451",
        "untrackedVolumeUSD": "445490728.9986262938406242818889503",
        "priceUSD": "1820.557266172119590393704962680451"
    },
    {
        "date": 1684022400,
        "volume": "164004.831402423808300484",
        "open": "1800.072949881754193305699049997668",
        "close": "1798.049900449482843870015141037912",
        "untrackedVolumeUSD": "295975714.2188040422711513845063757",
        "priceUSD": "1798.049900449482843870015141037912"
    },
    {
        "date": 1683936000,
        "volume": "229441.505295495161448125",
        "open": "1806.129934653484250064584386040876",
        "close": "1800.072949881754193305699049997668",
        "untrackedVolumeUSD": "413463146.4295394600829262031915096",
        "priceUSD": "1800.072949881754193305699049997668"
    },
    {
        "date": 1683849600,
        "volume": "447426.817929234694030075",
        "open": "1795.727059792628777118447434595319",
        "close": "1806.129934653484250064584386040876",
        "untrackedVolumeUSD": "791513584.9159126036572841265939486",
        "priceUSD": "1806.129934653484250064584386040876"
    },
    {
        "date": 1683763200,
        "volume": "463066.572824028687201212",
        "open": "1842.732027672917181305851185823911",
        "close": "1795.727059792628777118447434595319",
        "untrackedVolumeUSD": "838147359.3190693175291970697062029",
        "priceUSD": "1795.727059792628777118447434595319"
    },
    {
        "date": 1683676800,
        "volume": "412070.993377955608071985",
        "open": "1847.105102602251991178483435461088",
        "close": "1842.732027672917181305851185823911",
        "untrackedVolumeUSD": "761372612.9529490507832536681592895",
        "priceUSD": "1842.732027672917181305851185823911"
    },
    {
        "date": 1683590400,
        "volume": "294995.680084842054649558",
        "open": "1845.672287244064538950572013535085",
        "close": "1847.105102602251991178483435461088",
        "untrackedVolumeUSD": "544535784.368895046209046654867692",
        "priceUSD": "1847.105102602251991178483435461088"
    },
    {
        "date": 1683504000,
        "volume": "538829.18265591528173355",
        "open": "1878.207712408912982783715849682082",
        "close": "1845.672287244064538950572013535085",
        "untrackedVolumeUSD": "999164863.7099687712793412610928977",
        "priceUSD": "1845.672287244064538950572013535085"
    },
    {
        "date": 1683417600,
        "volume": "301258.681625869787076676",
        "open": "1895.977949520741882357985595192759",
        "close": "1878.207712408912982783715849682082",
        "untrackedVolumeUSD": "575249740.0665936757799814915856491",
        "priceUSD": "1878.207712408912982783715849682082"
    },
    {
        "date": 1683331200,
        "volume": "552044.004483530381902989",
        "open": "1992.458889215561967371998718369136",
        "close": "1895.977949520741882357985595192759",
        "untrackedVolumeUSD": "1061832096.974359639926244408092951",
        "priceUSD": "1895.977949520741882357985595192759"
    },
    {
        "date": 1683244800,
        "volume": "538901.33366699348310654",
        "open": "1875.108934649659371488059312145592",
        "close": "1992.458889215561967371998718369136",
        "untrackedVolumeUSD": "1045926781.788431312306308338166745",
        "priceUSD": "1992.458889215561967371998718369136"
    },
    {
        "date": 1683158400,
        "volume": "267636.953268390090768351",
        "open": "1906.534585058419761788325976830579",
        "close": "1875.108934649659371488059312145592",
        "untrackedVolumeUSD": "506331793.4593545616444789527445796",
        "priceUSD": "1875.108934649659371488059312145592"
    },
    {
        "date": 1683072000,
        "volume": "314958.567117755415731437",
        "open": "1875.148768044126340781767009970716",
        "close": "1906.534585058419761788325976830579",
        "untrackedVolumeUSD": "589528006.5161327848925914119717369",
        "priceUSD": "1906.534585058419761788325976830579"
    },
    {
        "date": 1682985600,
        "volume": "261335.753298761996957063",
        "open": "1828.041084215229655695333797558664",
        "close": "1875.148768044126340781767009970716",
        "untrackedVolumeUSD": "482839210.3315744517528926361609299",
        "priceUSD": "1875.148768044126340781767009970716"
    },
    {
        "date": 1682899200,
        "volume": "305337.148205721757166287",
        "open": "1877.156649414033945917909151222883",
        "close": "1828.041084215229655695333797558664",
        "untrackedVolumeUSD": "561524036.5923842466164777252248426",
        "priceUSD": "1828.041084215229655695333797558664"
    },
    {
        "date": 1682812800,
        "volume": "221200.793407931443579884",
        "open": "1904.117983750064465192310946688711",
        "close": "1877.156649414033945917909151222883",
        "untrackedVolumeUSD": "421651557.9635875161235326862348058",
        "priceUSD": "1877.156649414033945917909151222883"
    },
    {
        "date": 1682726400,
        "volume": "106597.083623594471906328",
        "open": "1895.367925835261829731835083838459",
        "close": "1904.117983750064465192310946688711",
        "untrackedVolumeUSD": "202703329.1470036830806408354113617",
        "priceUSD": "1904.117983750064465192310946688711"
    },
    {
        "date": 1682640000,
        "volume": "190469.088992659331083427",
        "open": "1905.483099404547118951828126486234",
        "close": "1895.367925835261829731835083838459",
        "untrackedVolumeUSD": "362092032.8586696161848076950761141",
        "priceUSD": "1895.367925835261829731835083838459"
    },
    {
        "date": 1682553600,
        "volume": "446049.396144160613822504",
        "open": "1866.336790043031022928070184684033",
        "close": "1905.483099404547118951828126486234",
        "untrackedVolumeUSD": "847752972.355370453856672616027857",
        "priceUSD": "1905.483099404547118951828126486234"
    },
    {
        "date": 1682467200,
        "volume": "460913.753693402863660679",
        "open": "1870.036217642510217542632166621046",
        "close": "1866.336790043031022928070184684033",
        "untrackedVolumeUSD": "867732174.7600775333831829578258683",
        "priceUSD": "1866.336790043031022928070184684033"
    },
    {
        "date": 1682380800,
        "volume": "245527.580510923055639584",
        "open": "1840.36991747234134761789387162059",
        "close": "1870.036217642510217542632166621046",
        "untrackedVolumeUSD": "450677873.4937383750897765701495278",
        "priceUSD": "1870.036217642510217542632166621046"
    },
    {
        "date": 1682294400,
        "volume": "266232.388323756914665809",
        "open": "1861.234909153041981863272850368677",
        "close": "1840.36991747234134761789387162059",
        "untrackedVolumeUSD": "491673755.1273492705873930502521121",
        "priceUSD": "1840.36991747234134761789387162059"
    },
    {
        "date": 1682208000,
        "volume": "173948.996636540481643865",
        "open": "1873.240896026831002010825569012719",
        "close": "1861.234909153041981863272850368677",
        "untrackedVolumeUSD": "323823175.9587835818641719139868079",
        "priceUSD": "1861.234909153041981863272850368677"
    },
    {
        "date": 1682121600,
        "volume": "145375.929340699043879773",
        "open": "1848.0318638316137913424585542316",
        "close": "1873.240896026831002010825569012719",
        "untrackedVolumeUSD": "270733100.7397369728951345727772883",
        "priceUSD": "1873.240896026831002010825569012719"
    },
    {
        "date": 1682035200,
        "volume": "435200.22945731460577187",
        "open": "1944.334686937760983295284000717671",
        "close": "1848.0318638316137913424585542316",
        "untrackedVolumeUSD": "824900385.6987992838509642176284442",
        "priceUSD": "1848.0318638316137913424585542316"
    },
    {
        "date": 1681948800,
        "volume": "365406.067515109056215509",
        "open": "1933.28227257040899962430664886029",
        "close": "1944.334686937760983295284000717671",
        "untrackedVolumeUSD": "711624311.3919459084557839843109403",
        "priceUSD": "1944.334686937760983295284000717671"
    },
    {
        "date": 1681862400,
        "volume": "405314.325427943989874682",
        "open": "2101.173760255638965619679857430764",
        "close": "1933.28227257040899962430664886029",
        "untrackedVolumeUSD": "810876111.2690389799153301947730868",
        "priceUSD": "1933.28227257040899962430664886029"
    },
    {
        "date": 1681776000,
        "volume": "247913.623347431184637075",
        "open": "2078.05600135112000576089386774471",
        "close": "2101.173760255638965619679857430764",
        "untrackedVolumeUSD": "519211112.3721607910687125010743864",
        "priceUSD": "2101.173760255638965619679857430764"
    },
    {
        "date": 1681689600,
        "volume": "261513.143666069345278772",
        "open": "2119.805385499220627696304507624473",
        "close": "2078.05600135112000576089386774471",
        "untrackedVolumeUSD": "546008742.3590165810933852101048444",
        "priceUSD": "2078.05600135112000576089386774471"
    },
    {
        "date": 1681603200,
        "volume": "233759.187376144765149278",
        "open": "2094.155863753542299673890769693077",
        "close": "2119.805385499220627696304507624473",
        "untrackedVolumeUSD": "492115949.9322780549193637824050139",
        "priceUSD": "2119.805385499220627696304507624473"
    },
    {
        "date": 1681516800,
        "volume": "213902.552911719484535983",
        "open": "2105.416570799290010016397669760698",
        "close": "2094.155863753542299673890769693077",
        "untrackedVolumeUSD": "448488339.1574399405982085628950387",
        "priceUSD": "2094.155863753542299673890769693077"
    },
    {
        "date": 1681430400,
        "volume": "493649.411146348671804703",
        "open": "2012.66690071158488074229969269125",
        "close": "2105.416570799290010016397669760698",
        "untrackedVolumeUSD": "1035604271.369327877558683597683684",
        "priceUSD": "2105.416570799290010016397669760698"
    },
    {
        "date": 1681344000,
        "volume": "354018.482922659172957617",
        "open": "1923.630496306856976220295688841021",
        "close": "2012.66690071158488074229969269125",
        "untrackedVolumeUSD": "698274609.1476302240568242326083056",
        "priceUSD": "2012.66690071158488074229969269125"
    },
    {
        "date": 1681257600,
        "volume": "348718.710192034831913604",
        "open": "1891.80384652140225971547240814288",
        "close": "1923.630496306856976220295688841021",
        "untrackedVolumeUSD": "661225114.3722226875775242418148829",
        "priceUSD": "1923.630496306856976220295688841021"
    },
    {
        "date": 1681171200,
        "volume": "281907.759256582385026384",
        "open": "1912.513794073961978030063549401295",
        "close": "1891.80384652140225971547240814288",
        "untrackedVolumeUSD": "539234917.1690550951334650923959151",
        "priceUSD": "1891.80384652140225971547240814288"
    },
    {
        "date": 1681084800,
        "volume": "220091.174551583771026352",
        "open": "1863.160356657281537768066137359126",
        "close": "1912.513794073961978030063549401295",
        "untrackedVolumeUSD": "413497082.3747682770181475777194885",
        "priceUSD": "1912.513794073961978030063549401295"
    },
    {
        "date": 1680998400,
        "volume": "183377.010533141634178776",
        "open": "1853.838633116650036787389059651635",
        "close": "1863.160356657281537768066137359126",
        "untrackedVolumeUSD": "338844982.527842179017489089242307",
        "priceUSD": "1863.160356657281537768066137359126"
    },
    {
        "date": 1680912000,
        "volume": "108429.28407883106910033",
        "open": "1866.46005432968839853817765364473",
        "close": "1853.838633116650036787389059651635",
        "untrackedVolumeUSD": "202164376.2262135817113434175441218",
        "priceUSD": "1853.838633116650036787389059651635"
    },
    {
        "date": 1680825600,
        "volume": "166368.820769642741066585",
        "open": "1868.719690680695327851023151779256",
        "close": "1866.46005432968839853817765364473",
        "untrackedVolumeUSD": "309660268.0087563997994736590405859",
        "priceUSD": "1866.46005432968839853817765364473"
    },
    {
        "date": 1680739200,
        "volume": "273116.817077594514559876",
        "open": "1910.782285333076342877619662784159",
        "close": "1868.719690680695327851023151779256",
        "untrackedVolumeUSD": "512878896.3600543771802013202742195",
        "priceUSD": "1868.719690680695327851023151779256"
    },
    {
        "date": 1680652800,
        "volume": "355946.45853615472642261",
        "open": "1868.099819361958723342975611977774",
        "close": "1910.782285333076342877619662784159",
        "untrackedVolumeUSD": "678425087.0055135191734838510119929",
        "priceUSD": "1910.782285333076342877619662784159"
    },
    {
        "date": 1680566400,
        "volume": "359645.988066544627205763",
        "open": "1812.746274556003534881309556575416",
        "close": "1868.099819361958723342975611977774",
        "untrackedVolumeUSD": "666475246.0048482843062000436419235",
        "priceUSD": "1868.099819361958723342975611977774"
    },
    {
        "date": 1680480000,
        "volume": "417818.808379928638767192",
        "open": "1791.080164986100016896051437259299",
        "close": "1812.746274556003534881309556575416",
        "untrackedVolumeUSD": "751037336.8142011795782931707100776",
        "priceUSD": "1812.746274556003534881309556575416"
    },
    {
        "date": 1680393600,
        "volume": "205736.283659103990180867",
        "open": "1821.247793365411277690749943877697",
        "close": "1791.080164986100016896051437259299",
        "untrackedVolumeUSD": "370957956.3838924234105646833222452",
        "priceUSD": "1791.080164986100016896051437259299"
    },
    {
        "date": 1680307200,
        "volume": "224054.57144943986889587",
        "open": "1824.470041002610326971472785919467",
        "close": "1821.247793365411277690749943877697",
        "untrackedVolumeUSD": "408420188.9044501579511605845047154",
        "priceUSD": "1821.247793365411277690749943877697"
    },
    {
        "date": 1680220800,
        "volume": "322941.34439841840759938",
        "open": "1789.555052757875246987588278784574",
        "close": "1824.470041002610326971472785919467",
        "untrackedVolumeUSD": "587314005.2451613005719923169818948",
        "priceUSD": "1824.470041002610326971472785919467"
    },
    {
        "date": 1680134400,
        "volume": "349311.038387045038813337",
        "open": "1792.512497969295447432797145617816",
        "close": "1789.555052757875246987588278784574",
        "untrackedVolumeUSD": "626469545.1350107039337262861984219",
        "priceUSD": "1789.555052757875246987588278784574"
    },
    {
        "date": 1680048000,
        "volume": "338394.192156840623492227",
        "open": "1773.163015611095064803421415265483",
        "close": "1792.512497969295447432797145617816",
        "untrackedVolumeUSD": "609674271.0910881825888814774982099",
        "priceUSD": "1792.512497969295447432797145617816"
    },
    {
        "date": 1679961600,
        "volume": "338270.279201283704780007",
        "open": "1711.941342472404142034627618096492",
        "close": "1773.163015611095064803421415265483",
        "untrackedVolumeUSD": "589590399.3466237557338434256949582",
        "priceUSD": "1773.163015611095064803421415265483"
    },
    {
        "date": 1679875200,
        "volume": "377897.82508833461890524",
        "open": "1778.393744144888451196475123346453",
        "close": "1711.941342472404142034627618096492",
        "untrackedVolumeUSD": "654507474.7605151772011250328102705",
        "priceUSD": "1711.941342472404142034627618096492"
    },
    {
        "date": 1679788800,
        "volume": "245580.64508237161898125",
        "open": "1739.485982883241573999626117763643",
        "close": "1778.393744144888451196475123346453",
        "untrackedVolumeUSD": "434203625.6040159637847627453507394",
        "priceUSD": "1778.393744144888451196475123346453"
    },
    {
        "date": 1679702400,
        "volume": "229302.97054413956242464",
        "open": "1750.23746407740148828949498444068",
        "close": "1739.485982883241573999626117763643",
        "untrackedVolumeUSD": "400167901.8461006515455936111104089",
        "priceUSD": "1739.485982883241573999626117763643"
    },
    {
        "date": 1679616000,
        "volume": "485870.988130640076027029",
        "open": "1817.419796415281933385453478015453",
        "close": "1750.23746407740148828949498444068",
        "untrackedVolumeUSD": "859001278.1628242748136289920188268",
        "priceUSD": "1750.23746407740148828949498444068"
    },
    {
        "date": 1679529600,
        "volume": "554985.073531052023485438",
        "open": "1735.923082512533607122017385845713",
        "close": "1817.419796415281933385453478015453",
        "untrackedVolumeUSD": "996118819.835712613428954754634217",
        "priceUSD": "1817.419796415281933385453478015453"
    },
    {
        "date": 1679443200,
        "volume": "729665.28782733121981034",
        "open": "1804.723853981992252187692033569856",
        "close": "1735.923082512533607122017385845713",
        "untrackedVolumeUSD": "1294446551.421710631391203726137905",
        "priceUSD": "1735.923082512533607122017385845713"
    },
    {
        "date": 1679356800,
        "volume": "540940.723476513988831421",
        "open": "1735.817452357071134573121537149779",
        "close": "1804.723853981992252187692033569856",
        "untrackedVolumeUSD": "964276508.2294305202299336807992134",
        "priceUSD": "1804.723853981992252187692033569856"
    },
    {
        "date": 1679270400,
        "volume": "734249.520178947518044631",
        "open": "1786.95045079608864954254118745312",
        "close": "1735.817452357071134573121537149779",
        "untrackedVolumeUSD": "1299627095.382668172970869111188593",
        "priceUSD": "1735.817452357071134573121537149779"
    },
    {
        "date": 1679184000,
        "volume": "531746.142975399702106044",
        "open": "1761.586594493817396948896028432956",
        "close": "1786.95045079608864954254118745312",
        "untrackedVolumeUSD": "957164811.5374785291812236600304793",
        "priceUSD": "1786.95045079608864954254118745312"
    },
    {
        "date": 1679097600,
        "volume": "708494.853591585965508285",
        "open": "1790.309779237911409051144753792634",
        "close": "1761.586594493817396948896028432956",
        "untrackedVolumeUSD": "1278628857.278912661387459923408279",
        "priceUSD": "1761.586594493817396948896028432956"
    },
    {
        "date": 1679011200,
        "volume": "856658.750459411581511196",
        "open": "1677.339241766784749106969941976656",
        "close": "1790.309779237911409051144753792634",
        "untrackedVolumeUSD": "1483427964.349996637248868060062701",
        "priceUSD": "1790.309779237911409051144753792634"
    },
    {
        "date": 1678924800,
        "volume": "464697.54935774384222595",
        "open": "1656.09455611491906115282755915722",
        "close": "1677.339241766784749106969941976656",
        "untrackedVolumeUSD": "771597006.4584665262158502160413315",
        "priceUSD": "1677.339241766784749106969941976656"
    },
    {
        "date": 1678838400,
        "volume": "1101849.563210910785008262",
        "open": "1707.740886404113486800093654603105",
        "close": "1656.09455611491906115282755915722",
        "untrackedVolumeUSD": "1838371342.464931617716169512065822",
        "priceUSD": "1656.09455611491906115282755915722"
    },
    {
        "date": 1678752000,
        "volume": "1371453.463863990299105012",
        "open": "1677.377799987160801693211600586151",
        "close": "1707.740886404113486800093654603105",
        "untrackedVolumeUSD": "2350853657.86304279115790301544161",
        "priceUSD": "1707.740886404113486800093654603105"
    },
    {
        "date": 1678665600,
        "volume": "1362569.768885819553279694",
        "open": "1604.08619646809384946590408502447",
        "close": "1677.377799987160801693211600586151",
        "untrackedVolumeUSD": "2233472662.619509240853259304606019",
        "priceUSD": "1677.377799987160801693211600586151"
    },
    {
        "date": 1678579200,
        "volume": "1260108.567188438545355273",
        "open": "1537.00693630001603225985404482192",
        "close": "1604.08619646809384946590408502447",
        "untrackedVolumeUSD": "1963592404.337166577984799420103568",
        "priceUSD": "1604.08619646809384946590408502447"
    },
    {
        "date": 1678492800,
        "volume": "1428107.419429952860277154",
        "open": "1438.952436182730183894397269785581",
        "close": "1537.00693630001603225985404482192",
        "untrackedVolumeUSD": "7174435766.092070300579681983784158",
        "priceUSD": "1537.00693630001603225985404482192"
    },
    {
        "date": 1678406400,
        "volume": "1424540.921841127999653614",
        "open": "1436.884234887294186231070231003034",
        "close": "1438.952436182730183894397269785581",
        "untrackedVolumeUSD": "2014172922.764809853986985245796694",
        "priceUSD": "1438.952436182730183894397269785581"
    },
    {
        "date": 1678320000,
        "volume": "635423.887058245996280666",
        "open": "1533.249931993276973292997119784472",
        "close": "1436.884234887294186231070231003034",
        "untrackedVolumeUSD": "948706276.5518931534394563223212729",
        "priceUSD": "1436.884234887294186231070231003034"
    },
    {
        "date": 1678233600,
        "volume": "446006.945899508747769025",
        "open": "1558.497352944871129604039531099086",
        "close": "1533.249931993276973292997119784472",
        "untrackedVolumeUSD": "692719162.7697509200956257997679637",
        "priceUSD": "1533.249931993276973292997119784472"
    },
    {
        "date": 1678147200,
        "volume": "433362.600019596727513632",
        "open": "1566.459158150707460972445779650172",
        "close": "1558.497352944871129604039531099086",
        "untrackedVolumeUSD": "674956563.4848943714188776842273242",
        "priceUSD": "1558.497352944871129604039531099086"
    },
    {
        "date": 1678060800,
        "volume": "312929.766195739740220035",
        "open": "1561.720646879236992011355277111412",
        "close": "1566.459158150707460972445779650172",
        "untrackedVolumeUSD": "489951349.2466272826655531369303763",
        "priceUSD": "1566.459158150707460972445779650172"
    },
    {
        "date": 1677974400,
        "volume": "344439.818975639268744491",
        "open": "1563.53440524256932244110818994215",
        "close": "1561.720646879236992011355277111412",
        "untrackedVolumeUSD": "540941368.3814594927745182103735407",
        "priceUSD": "1561.720646879236992011355277111412"
    },
    {
        "date": 1677888000,
        "volume": "245522.205685600966930899",
        "open": "1566.107746162059272364494064436453",
        "close": "1563.53440524256932244110818994215",
        "untrackedVolumeUSD": "384115265.339763336819052617588374",
        "priceUSD": "1563.53440524256932244110818994215"
    },
    {
        "date": 1677801600,
        "volume": "599341.147152078660091241",
        "open": "1649.462350134186807957625714581529",
        "close": "1566.107746162059272364494064436453",
        "untrackedVolumeUSD": "942062005.1617418138921093571715374",
        "priceUSD": "1566.107746162059272364494064436453"
    },
    {
        "date": 1677715200,
        "volume": "473225.829761970279748734",
        "open": "1661.767350687257586095407503050614",
        "close": "1649.462350134186807957625714581529",
        "untrackedVolumeUSD": "776079912.3306224969847512888810786",
        "priceUSD": "1649.462350134186807957625714581529"
    },
    {
        "date": 1677628800,
        "volume": "574801.244816350756223964",
        "open": "1607.235979674547156264623252823493",
        "close": "1661.767350687257586095407503050614",
        "untrackedVolumeUSD": "945888885.7883435881757268803837575",
        "priceUSD": "1661.767350687257586095407503050614"
    },
    {
        "date": 1677542400,
        "volume": "413195.982141877932972195",
        "open": "1630.946779267032425619365401824015",
        "close": "1607.235979674547156264623252823493",
        "untrackedVolumeUSD": "671891441.2501762126115958708916175",
        "priceUSD": "1607.235979674547156264623252823493"
    },
    {
        "date": 1677456000,
        "volume": "442185.715008110096563967",
        "open": "1638.150433543007982527812914529953",
        "close": "1630.946779267032425619365401824015",
        "untrackedVolumeUSD": "724319757.9584918271435199441516095",
        "priceUSD": "1630.946779267032425619365401824015"
    },
    {
        "date": 1677369600,
        "volume": "319144.832054008939623225",
        "open": "1592.311043490723346529806607209349",
        "close": "1638.150433543007982527812914529953",
        "untrackedVolumeUSD": "515161313.5165548106064452882822296",
        "priceUSD": "1638.150433543007982527812914529953"
    },
    {
        "date": 1677283200,
        "volume": "355977.295445490192061115",
        "open": "1606.873321450348252407526281830601",
        "close": "1592.311043490723346529806607209349",
        "untrackedVolumeUSD": "566391996.7633579820371139764300502",
        "priceUSD": "1592.311043490723346529806607209349"
    },
    {
        "date": 1677196800,
        "volume": "609409.298979400659668244",
        "open": "1646.6383932590925869668155200157",
        "close": "1606.873321450348252407526281830601",
        "untrackedVolumeUSD": "987865138.7088387517080023697520135",
        "priceUSD": "1606.873321450348252407526281830601"
    },
    {
        "date": 1677110400,
        "volume": "552608.120797425493157903",
        "open": "1641.386265676911620544371396274814",
        "close": "1646.6383932590925869668155200157",
        "untrackedVolumeUSD": "914933785.4633014300572090097507694",
        "priceUSD": "1646.6383932590925869668155200157"
    },
    {
        "date": 1677024000,
        "volume": "644663.343434712973908326",
        "open": "1655.306119564281285899801165671834",
        "close": "1641.386265676911620544371396274814",
        "untrackedVolumeUSD": "1051460733.428374277168080822337933",
        "priceUSD": "1641.386265676911620544371396274814"
    },
    {
        "date": 1676937600,
        "volume": "684179.415130536851513005",
        "open": "1698.580178697303768342312029684105",
        "close": "1655.306119564281285899801165671834",
        "untrackedVolumeUSD": "1146747038.277908349597392506608047",
        "priceUSD": "1655.306119564281285899801165671834"
    },
    {
        "date": 1676851200,
        "volume": "471621.861140514429680955",
        "open": "1683.050054302945418826008385671219",
        "close": "1698.580178697303768342312029684105",
        "untrackedVolumeUSD": "799438431.0102707894828125903523308",
        "priceUSD": "1698.580178697303768342312029684105"
    },
    {
        "date": 1676764800,
        "volume": "520180.811997188193455244",
        "open": "1688.793712939118325495588311145666",
        "close": "1683.050054302945418826008385671219",
        "untrackedVolumeUSD": "882239377.4158385995615530421738079",
        "priceUSD": "1683.050054302945418826008385671219"
    },
    {
        "date": 1676678400,
        "volume": "356043.728310635515479271",
        "open": "1695.292837289291138763088616878336",
        "close": "1688.793712939118325495588311145666",
        "untrackedVolumeUSD": "603690555.8817507087701118395684516",
        "priceUSD": "1688.793712939118325495588311145666"
    },
    {
        "date": 1676592000,
        "volume": "749697.675971211502744467",
        "open": "1639.213012957787044107372846767645",
        "close": "1695.292837289291138763088616878336",
        "untrackedVolumeUSD": "1257278337.845766464960087896477811",
        "priceUSD": "1695.292837289291138763088616878336"
    },
    {
        "date": 1676505600,
        "volume": "938323.649221964709739068",
        "open": "1673.691633412489551900627887896524",
        "close": "1639.213012957787044107372846767645",
        "untrackedVolumeUSD": "1585816908.951330024786519589200747",
        "priceUSD": "1639.213012957787044107372846767645"
    },
    {
        "date": 1676419200,
        "volume": "674214.753471232561736034",
        "open": "1557.554464799155679567032341719053",
        "close": "1673.691633412489551900627887896524",
        "untrackedVolumeUSD": "1079327210.512886689571084195660958",
        "priceUSD": "1673.691633412489551900627887896524"
    },
    {
        "date": 1676332800,
        "volume": "711895.942865130713487126",
        "open": "1506.463778481760965222698856303737",
        "close": "1557.554464799155679567032341719053",
        "untrackedVolumeUSD": "1090452491.058251811482113789228399",
        "priceUSD": "1557.554464799155679567032341719053"
    },
    {
        "date": 1676246400,
        "volume": "672892.817774172408761859",
        "open": "1513.823860482546752960554383857241",
        "close": "1506.463778481760965222698856303737",
        "untrackedVolumeUSD": "1004038978.919278300996062750605887",
        "priceUSD": "1506.463778481760965222698856303737"
    },
    {
        "date": 1676160000,
        "volume": "376573.138460598159895353",
        "open": "1539.418294281043509211422465264336",
        "close": "1513.823860482546752960554383857241",
        "untrackedVolumeUSD": "575318085.6823571928421607351568563",
        "priceUSD": "1513.823860482546752960554383857241"
    },
    {
        "date": 1676073600,
        "volume": "350117.193660385290693892",
        "open": "1513.53061205410133968769848186013",
        "close": "1539.418294281043509211422465264336",
        "untrackedVolumeUSD": "532895383.7526039760598817519531659",
        "priceUSD": "1539.418294281043509211422465264336"
    },
    {
        "date": 1675987200,
        "volume": "649126.496710991141991459",
        "open": "1545.390217171965255361909180137758",
        "close": "1513.53061205410133968769848186013",
        "untrackedVolumeUSD": "995640711.9285436541655211979309904",
        "priceUSD": "1513.53061205410133968769848186013"
    },
    {
        "date": 1675900800,
        "volume": "815489.42344054583022826",
        "open": "1648.43494908851061013444077720964",
        "close": "1545.390217171965255361909180137758",
        "untrackedVolumeUSD": "1305091280.575327615134505043835702",
        "priceUSD": "1545.390217171965255361909180137758"
    },
    {
        "date": 1675814400,
        "volume": "489612.817764520018103572",
        "open": "1671.954848940821098098684989757763",
        "close": "1648.43494908851061013444077720964",
        "untrackedVolumeUSD": "814027450.8840014037215106254055076",
        "priceUSD": "1648.43494908851061013444077720964"
    },
    {
        "date": 1675728000,
        "volume": "561736.520284762353939785",
        "open": "1612.988655183062547714979247687033",
        "close": "1671.954848940821098098684989757763",
        "untrackedVolumeUSD": "923526003.9979919557216048202331035",
        "priceUSD": "1671.954848940821098098684989757763"
    },
    {
        "date": 1675641600,
        "volume": "414616.306028438492392854",
        "open": "1632.524848737303164810744881528308",
        "close": "1612.988655183062547714979247687033",
        "untrackedVolumeUSD": "677741338.0619189967996547494151249",
        "priceUSD": "1612.988655183062547714979247687033"
    },
    {
        "date": 1675555200,
        "volume": "454508.223688181944380879",
        "open": "1664.822319026630533271014972347018",
        "close": "1632.524848737303164810744881528308",
        "untrackedVolumeUSD": "747238678.5254211533875765567974978",
        "priceUSD": "1632.524848737303164810744881528308"
    },
    {
        "date": 1675468800,
        "volume": "347580.906149166758606251",
        "open": "1664.896135488040345051365277143936",
        "close": "1664.822319026630533271014972347018",
        "untrackedVolumeUSD": "580633860.3203272749438863517212546",
        "priceUSD": "1664.822319026630533271014972347018"
    },
    {
        "date": 1675382400,
        "volume": "511232.522854478172144554",
        "open": "1643.965171515638679473327030709019",
        "close": "1664.896135488040345051365277143936",
        "untrackedVolumeUSD": "845031603.7347159125990653499769701",
        "priceUSD": "1664.896135488040345051365277143936"
    },
    {
        "date": 1675296000,
        "volume": "722912.594069627693674129",
        "open": "1642.407213601986373827245003334429",
        "close": "1643.965171515638679473327030709019",
        "untrackedVolumeUSD": "1206994827.489289506227512573238459",
        "priceUSD": "1643.965171515638679473327030709019"
    },
    {
        "date": 1675209600,
        "volume": "578676.715641080416870869",
        "open": "1586.724267051153424121673354385305",
        "close": "1642.407213601986373827245003334429",
        "untrackedVolumeUSD": "923761588.5947634151100201182122459",
        "priceUSD": "1642.407213601986373827245003334429"
    },
    {
        "date": 1675123200,
        "volume": "359623.930478426240743033",
        "open": "1563.443639670593290693367032796737",
        "close": "1586.724267051153424121673354385305",
        "untrackedVolumeUSD": "568653920.3772214056511204132625401",
        "priceUSD": "1586.724267051153424121673354385305"
    },
    {
        "date": 1675036800,
        "volume": "510896.365951565886884107",
        "open": "1646.712400754135044140242750364377",
        "close": "1563.443639670593290693367032796737",
        "untrackedVolumeUSD": "810858283.5448314055909302784802995",
        "priceUSD": "1563.443639670593290693367032796737"
    },
    {
        "date": 1674950400,
        "volume": "424253.136858413961000456",
        "open": "1569.237813178635386404705927172591",
        "close": "1646.712400754135044140242750364377",
        "untrackedVolumeUSD": "684738782.6008384908688961761426886",
        "priceUSD": "1646.712400754135044140242750364377"
    },
    {
        "date": 1674864000,
        "volume": "313985.018322918149058307",
        "open": "1594.582633603022788688822689590044",
        "close": "1569.237813178635386404705927172591",
        "untrackedVolumeUSD": "498186936.9504608800366929341909575",
        "priceUSD": "1569.237813178635386404705927172591"
    },
    {
        "date": 1674777600,
        "volume": "521407.515701662286022341",
        "open": "1603.295436855563260888623674967254",
        "close": "1594.582633603022788688822689590044",
        "untrackedVolumeUSD": "826842971.6242348935203198827081134",
        "priceUSD": "1594.582633603022788688822689590044"
    },
    {
        "date": 1674691200,
        "volume": "488521.915229850472576642",
        "open": "1618.411084858304210716432345124273",
        "close": "1603.295436855563260888623674967254",
        "untrackedVolumeUSD": "785386936.9248685465339444010212378",
        "priceUSD": "1603.295436855563260888623674967254"
    },
    {
        "date": 1674604800,
        "volume": "765534.753890314656432474",
        "open": "1551.669659048602680206152597538615",
        "close": "1618.411084858304210716432345124273",
        "untrackedVolumeUSD": "1204173836.17867300822157293368868",
        "priceUSD": "1618.411084858304210716432345124273"
    },
    {
        "date": 1674518400,
        "volume": "510807.600536000963109782",
        "open": "1628.734426119914479538993717916591",
        "close": "1551.669659048602680206152597538615",
        "untrackedVolumeUSD": "818244754.0661087593410148721589337",
        "priceUSD": "1551.669659048602680206152597538615"
    },
    {
        "date": 1674432000,
        "volume": "463150.637426717363544979",
        "open": "1627.68834868115402521765054717578",
        "close": "1628.734426119914479538993717916591",
        "untrackedVolumeUSD": "755263234.468207087828373525698932",
        "priceUSD": "1628.734426119914479538993717916591"
    },
    {
        "date": 1674345600,
        "volume": "438768.087924857768761264",
        "open": "1627.517080493400330008667508833769",
        "close": "1627.68834868115402521765054717578",
        "untrackedVolumeUSD": "715539390.38841616279725375150757",
        "priceUSD": "1627.68834868115402521765054717578"
    },
    {
        "date": 1674259200,
        "volume": "568410.41021231833195901",
        "open": "1657.759752408764281943596656998239",
        "close": "1627.517080493400330008667508833769",
        "untrackedVolumeUSD": "940784287.5784802241106958097516969",
        "priceUSD": "1627.517080493400330008667508833769"
    },
    {
        "date": 1674172800,
        "volume": "428950.398865509188353713",
        "open": "1550.601054458855875816859672128303",
        "close": "1657.759752408764281943596656998239",
        "untrackedVolumeUSD": "684330635.6604965775395842468101419",
        "priceUSD": "1657.759752408764281943596656998239"
    },
    {
        "date": 1674086400,
        "volume": "382845.369294376754315437",
        "open": "1512.690339106959587761422695071655",
        "close": "1550.601054458855875816859672128303",
        "untrackedVolumeUSD": "587260349.3079875709455621084675136",
        "priceUSD": "1550.601054458855875816859672128303"
    },
    {
        "date": 1674000000,
        "volume": "890134.65651355136636721",
        "open": "1569.217580068324378546807338807343",
        "close": "1512.690339106959587761422695071655",
        "untrackedVolumeUSD": "1382339328.551470154900946439612526",
        "priceUSD": "1512.690339106959587761422695071655"
    },
    {
        "date": 1673913600,
        "volume": "453091.522791037378090213",
        "open": "1579.010741975927715859483572703891",
        "close": "1569.217580068324378546807338807343",
        "untrackedVolumeUSD": "714419499.975277650668295063658543",
        "priceUSD": "1569.217580068324378546807338807343"
    },
    {
        "date": 1673827200,
        "volume": "592666.166224720147475518",
        "open": "1553.894080849592949568154315393411",
        "close": "1579.010741975927715859483572703891",
        "untrackedVolumeUSD": "927272685.9872079161375689641743656",
        "priceUSD": "1579.010741975927715859483572703891"
    },
    {
        "date": 1673740800,
        "volume": "496991.892386675680935769",
        "open": "1552.063380157812484020327712143448",
        "close": "1553.894080849592949568154315393411",
        "untrackedVolumeUSD": "764836615.4563311404644138813676039",
        "priceUSD": "1553.894080849592949568154315393411"
    },
    {
        "date": 1673654400,
        "volume": "861465.431218706421830123",
        "open": "1447.897493887515578066647531893699",
        "close": "1552.063380157812484020327712143448",
        "untrackedVolumeUSD": "1324236547.394969829518451674667432",
        "priceUSD": "1552.063380157812484020327712143448"
    },
    {
        "date": 1673568000,
        "volume": "644171.703848883707047376",
        "open": "1419.835994890141270663811071322135",
        "close": "1447.897493887515578066647531893699",
        "untrackedVolumeUSD": "917577682.3193643037034125501962233",
        "priceUSD": "1447.897493887515578066647531893699"
    },
    {
        "date": 1673481600,
        "volume": "784866.263588645932249705",
        "open": "1387.996794952931729737247494643574",
        "close": "1419.835994890141270663811071322135",
        "untrackedVolumeUSD": "1105912681.059535801525426155978784",
        "priceUSD": "1419.835994890141270663811071322135"
    },
    {
        "date": 1673395200,
        "volume": "364098.198449840678516597",
        "open": "1337.925269331333081225774756554687",
        "close": "1387.996794952931729737247494643574",
        "untrackedVolumeUSD": "489191087.0767286485315016987739612",
        "priceUSD": "1387.996794952931729737247494643574"
    },
    {
        "date": 1673308800,
        "volume": "315026.214126236344985369",
        "open": "1319.346163232257037208274413560918",
        "close": "1337.925269331333081225774756554687",
        "untrackedVolumeUSD": "418825903.012890557677194298647409",
        "priceUSD": "1337.925269331333081225774756554687"
    },
    {
        "date": 1673222400,
        "volume": "573090.513464299566442683",
        "open": "1289.502261736667635864992613007314",
        "close": "1319.346163232257037208274413560918",
        "untrackedVolumeUSD": "755399577.8843916439906206159091464",
        "priceUSD": "1319.346163232257037208274413560918"
    },
    {
        "date": 1673136000,
        "volume": "283053.126441465979979598",
        "open": "1262.187471475887798868540925134317",
        "close": "1289.502261736667635864992613007314",
        "untrackedVolumeUSD": "359688603.9639344912720211034064806",
        "priceUSD": "1289.502261736667635864992613007314"
    },
    {
        "date": 1673049600,
        "volume": "120139.602087278646090169",
        "open": "1268.093822020362841021061864554936",
        "close": "1262.187471475887798868540925134317",
        "untrackedVolumeUSD": "151886111.7107974296125342190004081",
        "priceUSD": "1262.187471475887798868540925134317"
    },
    {
        "date": 1672963200,
        "volume": "304117.999535752557132341",
        "open": "1250.936338246894559333010842690685",
        "close": "1268.093822020362841021061864554936",
        "untrackedVolumeUSD": "381929272.0726761940409982375249409",
        "priceUSD": "1268.093822020362841021061864554936"
    },
    {
        "date": 1672876800,
        "volume": "197159.190806849609014654",
        "open": "1253.344908056070900194344118212132",
        "close": "1250.936338246894559333010842690685",
        "untrackedVolumeUSD": "246569505.5563618936292101588890734",
        "priceUSD": "1250.936338246894559333010842690685"
    },
    {
        "date": 1672790400,
        "volume": "452198.459647351585678526",
        "open": "1211.782279533189386095412797387077",
        "close": "1253.344908056070900194344118212132",
        "untrackedVolumeUSD": "564870195.4740888019972802412283148",
        "priceUSD": "1253.344908056070900194344118212132"
    },
    {
        "date": 1672704000,
        "volume": "178245.067696616792253103",
        "open": "1215.162167595281247322041548145568",
        "close": "1211.782279533189386095412797387077",
        "untrackedVolumeUSD": "216069804.0643228898722597819519487",
        "priceUSD": "1211.782279533189386095412797387077"
    },
    {
        "date": 1672617600,
        "volume": "234416.328144361489173755",
        "open": "1200.124464022518289435833876325203",
        "close": "1215.162167595281247322041548145568",
        "untrackedVolumeUSD": "284268605.020864155986342400950858",
        "priceUSD": "1215.162167595281247322041548145568"
    },
    {
        "date": 1672531200,
        "volume": "168936.704749793250996972",
        "open": "1196.13702549973826005002222432329",
        "close": "1200.124464022518289435833876325203",
        "untrackedVolumeUSD": "202072238.1815474870489432906479291",
        "priceUSD": "1200.124464022518289435833876325203"
    },
    {
        "date": 1672444800,
        "volume": "183721.532781218289988867",
        "open": "1197.600863912900849286639767522666",
        "close": "1196.13702549973826005002222432329",
        "untrackedVolumeUSD": "220022412.3032816142145888350799252",
        "priceUSD": "1196.13702549973826005002222432329"
    },
    {
        "date": 1672358400,
        "volume": "184509.667624164223034639",
        "open": "1202.308319308984349566811962084928",
        "close": "1197.600863912900849286639767522666",
        "untrackedVolumeUSD": "220454550.1257735381365501037652229",
        "priceUSD": "1197.600863912900849286639767522666"
    },
    {
        "date": 1672272000,
        "volume": "223944.820073053559858649",
        "open": "1186.971320083901401047228206242491",
        "close": "1202.308319308984349566811962084928",
        "untrackedVolumeUSD": "267950536.9253045487181350105091051",
        "priceUSD": "1202.308319308984349566811962084928"
    },
    {
        "date": 1672185600,
        "volume": "339490.056718607576118977",
        "open": "1210.237684135963313298666708731873",
        "close": "1186.971320083901401047228206242491",
        "untrackedVolumeUSD": "406159759.5173244426082448082088551",
        "priceUSD": "1186.971320083901401047228206242491"
    },
    {
        "date": 1672099200,
        "volume": "286271.904610628300029356",
        "open": "1226.354880330688424221165310582482",
        "close": "1210.237684135963313298666708731873",
        "untrackedVolumeUSD": "347619732.6150789752838279011842161",
        "priceUSD": "1210.237684135963313298666708731873"
    },
    {
        "date": 1672012800,
        "volume": "120931.67837884172925577",
        "open": "1218.702734723673967977930275008837",
        "close": "1226.354880330688424221165310582482",
        "untrackedVolumeUSD": "147356901.5298935594427280909657903",
        "priceUSD": "1226.354880330688424221165310582482"
    },
    {
        "date": 1671926400,
        "volume": "200700.228421249875919198",
        "open": "1221.385954100501076083659295708535",
        "close": "1218.702734723673967977930275008837",
        "untrackedVolumeUSD": "243319638.2990741504627924236545301",
        "priceUSD": "1218.702734723673967977930275008837"
    },
    {
        "date": 1671840000,
        "volume": "82725.633599695485984905",
        "open": "1219.737676311395358218611685447785",
        "close": "1221.385954100501076083659295708535",
        "untrackedVolumeUSD": "100804327.0373606842501865386602174",
        "priceUSD": "1221.385954100501076083659295708535"
    },
    {
        "date": 1671753600,
        "volume": "250428.11758174260419022",
        "open": "1216.805311074263419188395370754825",
        "close": "1219.737676311395358218611685447785",
        "untrackedVolumeUSD": "306585199.1243961324122940492998516",
        "priceUSD": "1219.737676311395358218611685447785"
    },
    {
        "date": 1671667200,
        "volume": "278625.457271838890580155",
        "open": "1211.225659755964477226500671219718",
        "close": "1216.805311074263419188395370754825",
        "untrackedVolumeUSD": "337256431.0471961916688162233431837",
        "priceUSD": "1216.805311074263419188395370754825"
    },
    {
        "date": 1671580800,
        "volume": "198738.989588982716864639",
        "open": "1216.84557529055680163574055753491",
        "close": "1211.225659755964477226500671219718",
        "untrackedVolumeUSD": "240889991.6435319883663599872248799",
        "priceUSD": "1211.225659755964477226500671219718"
    },
    {
        "date": 1671494400,
        "volume": "407883.066863741055176804",
        "open": "1165.956993647043207746106985182746",
        "close": "1216.84557529055680163574055753491",
        "untrackedVolumeUSD": "491974921.4378822566607276572867528",
        "priceUSD": "1216.84557529055680163574055753491"
    },
    {
        "date": 1671408000,
        "volume": "332429.017974445390521609",
        "open": "1185.084001428402245690482548960943",
        "close": "1165.956993647043207746106985182746",
        "untrackedVolumeUSD": "391245094.6768876216116610576578414",
        "priceUSD": "1165.956993647043207746106985182746"
    },
    {
        "date": 1671321600,
        "volume": "202390.283490794121839496",
        "open": "1186.011260000000074158134863482399",
        "close": "1185.084001428402245690482548960943",
        "untrackedVolumeUSD": "239687904.043297797673799475475185",
        "priceUSD": "1185.084001428402245690482548960943"
    },
    {
        "date": 1671235200,
        "volume": "405377.13826253653582579",
        "open": "1164.720229164287026834693027070717",
        "close": "1186.011260000000074158134863482399",
        "untrackedVolumeUSD": "477549599.2575103810665761376224503",
        "priceUSD": "1186.011260000000074158134863482399"
    },
    {
        "date": 1671148800,
        "volume": "799438.211860346927693262",
        "open": "1263.996886495760901486913812890323",
        "close": "1164.720229164287026834693027070717",
        "untrackedVolumeUSD": "962458839.6212495157519549637632965",
        "priceUSD": "1164.720229164287026834693027070717"
    },
    {
        "date": 1671062400,
        "volume": "368541.719559275854094681",
        "open": "1309.661539739560004805228581307379",
        "close": "1263.996886495760901486913812890323",
        "untrackedVolumeUSD": "471085846.3412696803685464835508461",
        "priceUSD": "1263.996886495760901486913812890323"
    },
    {
        "date": 1670976000,
        "volume": "476295.572587121593151742",
        "open": "1318.827021209748314065431491306726",
        "close": "1309.661539739560004805228581307379",
        "untrackedVolumeUSD": "629336421.3442201064029371659538218",
        "priceUSD": "1309.661539739560004805228581307379"
    },
    {
        "date": 1670889600,
        "volume": "627146.826881153201233809",
        "open": "1274.611143782710476702946338445493",
        "close": "1318.827021209748314065431491306726",
        "untrackedVolumeUSD": "820868624.5168641284047267814999167",
        "priceUSD": "1318.827021209748314065431491306726"
    },
    {
        "date": 1670803200,
        "volume": "282053.290177251334690637",
        "open": "1263.208703042992436708008258670564",
        "close": "1274.611143782710476702946338445493",
        "untrackedVolumeUSD": "353910393.8300306748418277613444596",
        "priceUSD": "1274.611143782710476702946338445493"
    },
    {
        "date": 1670716800,
        "volume": "183137.788988185247818686",
        "open": "1266.614660879179194024297647797546",
        "close": "1263.208703042992436708008258670564",
        "untrackedVolumeUSD": "232676478.9675602015410704502958712",
        "priceUSD": "1263.208703042992436708008258670564"
    },
    {
        "date": 1670630400,
        "volume": "159052.012407566794096639",
        "open": "1261.524110360818482177433006336446",
        "close": "1266.614660879179194024297647797546",
        "untrackedVolumeUSD": "201721336.105904147052414283199333",
        "priceUSD": "1266.614660879179194024297647797546"
    },
    {
        "date": 1670544000,
        "volume": "276098.327360518534814132",
        "open": "1282.529343404963032932552844415571",
        "close": "1261.524110360818482177433006336446",
        "untrackedVolumeUSD": "352411992.7943938487934353286945917",
        "priceUSD": "1261.524110360818482177433006336446"
    },
    {
        "date": 1670457600,
        "volume": "358825.930847963561116267",
        "open": "1231.217888166617760426839471923467",
        "close": "1282.529343404963032932552844415571",
        "untrackedVolumeUSD": "450239596.7987844236592726555231408",
        "priceUSD": "1282.529343404963032932552844415571"
    },
    {
        "date": 1670371200,
        "volume": "312656.643365910069756697",
        "open": "1270.230947964217587710950632308095",
        "close": "1231.217888166617760426839471923467",
        "untrackedVolumeUSD": "386906955.4821568958177004783366664",
        "priceUSD": "1231.217888166617760426839471923467"
    },
    {
        "date": 1670284800,
        "volume": "362332.427500262946914614",
        "open": "1257.006124266697566822753996038765",
        "close": "1270.230947964217587710950632308095",
        "untrackedVolumeUSD": "454991822.1542377596231452346036883",
        "priceUSD": "1270.230947964217587710950632308095"
    },
    {
        "date": 1670198400,
        "volume": "351372.880014004271150922",
        "open": "1280.471619301194229576954218399296",
        "close": "1257.006124266697566822753996038765",
        "untrackedVolumeUSD": "449892463.2362307319341493872375943",
        "priceUSD": "1257.006124266697566822753996038765"
    },
    {
        "date": 1670112000,
        "volume": "248540.358292987418729859",
        "open": "1240.603949121483454432341668415836",
        "close": "1280.471619301194229576954218399296",
        "untrackedVolumeUSD": "313612731.8213755169724569792948251",
        "priceUSD": "1280.471619301194229576954218399296"
    },
    {
        "date": 1670025600,
        "volume": "250253.571448803346456336",
        "open": "1292.380097228987163620512707423318",
        "close": "1240.603949121483454432341668415836",
        "untrackedVolumeUSD": "317901475.2188260941394706690696342",
        "priceUSD": "1240.603949121483454432341668415836"
    },
    {
        "date": 1669939200,
        "volume": "331917.27887444308973691",
        "open": "1276.834206185891749200405636407027",
        "close": "1292.380097228987163620512707423318",
        "untrackedVolumeUSD": "424903831.9285118083138192130415193",
        "priceUSD": "1292.380097228987163620512707423318"
    },
    {
        "date": 1669852800,
        "volume": "352865.493882734447212384",
        "open": "1297.411640099043800638071902915701",
        "close": "1276.834206185891749200405636407027",
        "untrackedVolumeUSD": "451886511.7035117738546446684537566",
        "priceUSD": "1276.834206185891749200405636407027"
    },
    {
        "date": 1669766400,
        "volume": "670773.326594543608254786",
        "open": "1218.671024137370690558473170521782",
        "close": "1297.411640099043800638071902915701",
        "untrackedVolumeUSD": "854586575.9190095083953389852745382",
        "priceUSD": "1297.411640099043800638071902915701"
    },
    {
        "date": 1669680000,
        "volume": "363748.911203124680281523",
        "open": "1170.22934897191401673489758142137",
        "close": "1218.671024137370690558473170521782",
        "untrackedVolumeUSD": "438147318.6123067987916335230031211",
        "priceUSD": "1218.671024137370690558473170521782"
    },
    {
        "date": 1669593600,
        "volume": "465080.396908304471708602",
        "open": "1192.919049239307377880288636862315",
        "close": "1170.22934897191401673489758142137",
        "untrackedVolumeUSD": "544593399.4631171394244348592967882",
        "priceUSD": "1170.22934897191401673489758142137"
    },
    {
        "date": 1669507200,
        "volume": "269287.51844949834388629",
        "open": "1202.827865308465447924318856775841",
        "close": "1192.919049239307377880288636862315",
        "untrackedVolumeUSD": "326531692.9678727606632874299058959",
        "priceUSD": "1192.919049239307377880288636862315"
    },
    {
        "date": 1669420800,
        "volume": "358214.817781381485222083",
        "open": "1198.529049536224644802418017517451",
        "close": "1202.827865308465447924318856775841",
        "untrackedVolumeUSD": "435080352.2293160417354447144040618",
        "priceUSD": "1202.827865308465447924318856775841"
    },
    {
        "date": 1669334400,
        "volume": "451483.909334100317143893",
        "open": "1201.33158887383610389697403694381",
        "close": "1198.529049536224644802418017517451",
        "untrackedVolumeUSD": "536898391.0898417022824845533135026",
        "priceUSD": "1198.529049536224644802418017517451"
    },
    {
        "date": 1669248000,
        "volume": "447101.736558446371527914",
        "open": "1184.309758418303094257344892597572",
        "close": "1201.33158887383610389697403694381",
        "untrackedVolumeUSD": "536516261.9207973541946905374524914",
        "priceUSD": "1201.33158887383610389697403694381"
    },
    {
        "date": 1669161600,
        "volume": "593732.160193857179337346",
        "open": "1135.595753430623814518612786069873",
        "close": "1184.309758418303094257344892597572",
        "untrackedVolumeUSD": "691538080.8391077788985977165239986",
        "priceUSD": "1184.309758418303094257344892597572"
    },
    {
        "date": 1669075200,
        "volume": "780572.133277228328335463",
        "open": "1106.887346426861665775805851137555",
        "close": "1135.595753430623814518612786069873",
        "untrackedVolumeUSD": "868576582.8010762879291591105066673",
        "priceUSD": "1135.595753430623814518612786069873"
    },
    {
        "date": 1668988800,
        "volume": "979134.684364860353577733",
        "open": "1138.657100479870702900872203630991",
        "close": "1106.887346426861665775805851137555",
        "untrackedVolumeUSD": "1091432471.283946265259169243022453",
        "priceUSD": "1106.887346426861665775805851137555"
    },
    {
        "date": 1668902400,
        "volume": "542879.602895793485396525",
        "open": "1219.055477524759673689796772666279",
        "close": "1138.657100479870702900872203630991",
        "untrackedVolumeUSD": "638939332.639525595440233739050224",
        "priceUSD": "1138.657100479870702900872203630991"
    },
    {
        "date": 1668816000,
        "volume": "224872.591365744488043169",
        "open": "1209.30318128171719699890089992734",
        "close": "1219.055477524759673689796772666279",
        "untrackedVolumeUSD": "272385279.8155813581387063911513476",
        "priceUSD": "1219.055477524759673689796772666279"
    },
    {
        "date": 1668729600,
        "volume": "318654.061509396878585416",
        "open": "1199.967238985312205085188531414667",
        "close": "1209.30318128171719699890089992734",
        "untrackedVolumeUSD": "386568788.3941427309687804585973982",
        "priceUSD": "1209.30318128171719699890089992734"
    },
    {
        "date": 1668643200,
        "volume": "505920.60648120616879715",
        "open": "1213.948508627196817096424488299763",
        "close": "1199.967238985312205085188531414667",
        "untrackedVolumeUSD": "608528864.1157390373218940134381598",
        "priceUSD": "1199.967238985312205085188531414667"
    },
    {
        "date": 1668556800,
        "volume": "675980.19143092207789841",
        "open": "1248.109449922940806658879608437984",
        "close": "1213.948508627196817096424488299763",
        "untrackedVolumeUSD": "825163423.3260184098696322877627321",
        "priceUSD": "1213.948508627196817096424488299763"
    },
    {
        "date": 1668470400,
        "volume": "694410.697122427568782695",
        "open": "1242.937862533742126125897241118443",
        "close": "1248.109449922940806658879608437984",
        "untrackedVolumeUSD": "876586716.3896212100937140433915372",
        "priceUSD": "1248.109449922940806658879608437984"
    },
    {
        "date": 1668384000,
        "volume": "1021468.180829137117467158",
        "open": "1219.855750564753304427638075757563",
        "close": "1242.937862533742126125897241118443",
        "untrackedVolumeUSD": "1257768484.679000007544666944530184",
        "priceUSD": "1242.937862533742126125897241118443"
    },
    {
        "date": 1668297600,
        "volume": "581444.183603475565732509",
        "open": "1251.317193778265292495737816982528",
        "close": "1219.855750564753304427638075757563",
        "untrackedVolumeUSD": "717430092.7694216392936973273655578",
        "priceUSD": "1219.855750564753304427638075757563"
    },
    {
        "date": 1668211200,
        "volume": "850490.456535342734616422",
        "open": "1287.585338190992576439048928961108",
        "close": "1251.317193778265292495737816982528",
        "untrackedVolumeUSD": "1073804612.372964074391589622587441",
        "priceUSD": "1251.317193778265292495737816982528"
    },
    {
        "date": 1668124800,
        "volume": "1424251.082104780615437285",
        "open": "1296.428680635827479638427919586898",
        "close": "1287.585338190992576439048928961108",
        "untrackedVolumeUSD": "1794343001.874641037102657310394128",
        "priceUSD": "1287.585338190992576439048928961108"
    },
    {
        "date": 1668038400,
        "volume": "2438472.561743576361688708",
        "open": "1098.458691163302253852349430776776",
        "close": "1296.428680635827479638427919586898",
        "untrackedVolumeUSD": "2996668125.140269317599919569166917",
        "priceUSD": "1296.428680635827479638427919586898"
    },
    {
        "date": 1667952000,
        "volume": "3219300.796059922274027923",
        "open": "1333.692427597191420931045478905821",
        "close": "1098.458691163302253852349430776776",
        "untrackedVolumeUSD": "3845091524.915737298318729123072397",
        "priceUSD": "1098.458691163302253852349430776776"
    },
    {
        "date": 1667865600,
        "volume": "2338568.267049402839842613",
        "open": "1565.069802157205287792469954554942",
        "close": "1333.692427597191420931045478905821",
        "untrackedVolumeUSD": "3314411318.628327576261895585634947",
        "priceUSD": "1333.692427597191420931045478905821"
    },
    {
        "date": 1667779200,
        "volume": "593364.279756587584194063",
        "open": "1571.970725121304001334410675311333",
        "close": "1565.069802157205287792469954554942",
        "untrackedVolumeUSD": "935624692.0608885735031061378119865",
        "priceUSD": "1565.069802157205287792469954554942"
    },
    {
        "date": 1667692800,
        "volume": "359703.451498550562832972",
        "open": "1626.77622379102411073693248912975",
        "close": "1571.970725121304001334410675311333",
        "untrackedVolumeUSD": "579492374.2890954659280261442681825",
        "priceUSD": "1571.970725121304001334410675311333"
    },
    {
        "date": 1667606400,
        "volume": "293398.676450151432825816",
        "open": "1646.647026324186176225650244011932",
        "close": "1626.77622379102411073693248912975",
        "untrackedVolumeUSD": "481465200.0098325591812841326922263",
        "priceUSD": "1626.77622379102411073693248912975"
    },
    {
        "date": 1667520000,
        "volume": "756770.222879785582718772",
        "open": "1529.304096263005912959856349062099",
        "close": "1646.647026324186176225650244011932",
        "untrackedVolumeUSD": "1220837457.348773319681050451750209",
        "priceUSD": "1646.647026324186176225650244011932"
    },
    {
        "date": 1667433600,
        "volume": "356855.52992583633004212",
        "open": "1517.620713078554006032973981191753",
        "close": "1529.304096263005912959856349062099",
        "untrackedVolumeUSD": "549854167.2628869068409434285096314",
        "priceUSD": "1529.304096263005912959856349062099"
    },
    {
        "date": 1667347200,
        "volume": "843278.603188416080691374",
        "open": "1577.83224877893399354683576796395",
        "close": "1517.620713078554006032973981191753",
        "untrackedVolumeUSD": "1315634225.355644671889951608410095",
        "priceUSD": "1517.620713078554006032973981191753"
    },
    {
        "date": 1667260800,
        "volume": "378516.98759030438730957",
        "open": "1572.720229730455351033692119083047",
        "close": "1577.83224877893399354683576796395",
        "untrackedVolumeUSD": "599801915.6105740932296099856947582",
        "priceUSD": "1577.83224877893399354683576796395"
    },
    {
        "date": 1667174400,
        "volume": "624345.200464251711146333",
        "open": "1588.46706029912075337252864176776",
        "close": "1572.720229730455351033692119083047",
        "untrackedVolumeUSD": "988059544.5670922374250938359726671",
        "priceUSD": "1572.720229730455351033692119083047"
    },
    {
        "date": 1667088000,
        "volume": "434980.406298277465937287",
        "open": "1617.143006103515410829386830025178",
        "close": "1588.46706029912075337252864176776",
        "untrackedVolumeUSD": "696800907.3746210897489398770070763",
        "priceUSD": "1588.46706029912075337252864176776"
    },
    {
        "date": 1667001600,
        "volume": "666137.138735414071651749",
        "open": "1557.247718963406947597861476697103",
        "close": "1617.143006103515410829386830025178",
        "untrackedVolumeUSD": "1073513392.808275917807826551905209",
        "priceUSD": "1617.143006103515410829386830025178"
    },
    {
        "date": 1666915200,
        "volume": "648480.57027760657922292",
        "open": "1510.390353691249282308647600713915",
        "close": "1557.247718963406947597861476697103",
        "untrackedVolumeUSD": "990551610.1605330844719361384926022",
        "priceUSD": "1557.247718963406947597861476697103"
    },
    {
        "date": 1666828800,
        "volume": "642630.808489295382153404",
        "open": "1567.861807094520288869788932106172",
        "close": "1510.390353691249282308647600713915",
        "untrackedVolumeUSD": "994344483.5070843720871637249066214",
        "priceUSD": "1510.390353691249282308647600713915"
    },
    {
        "date": 1666742400,
        "volume": "819608.678754846690381111",
        "open": "1460.197283081249814687522034733243",
        "close": "1567.861807094520288869788932106172",
        "untrackedVolumeUSD": "1259412596.276358465813366541675462",
        "priceUSD": "1567.861807094520288869788932106172"
    },
    {
        "date": 1666656000,
        "volume": "918676.129168091250724968",
        "open": "1345.616292745401844642987367110919",
        "close": "1460.197283081249814687522034733243",
        "untrackedVolumeUSD": "1314606119.331162714096053646821447",
        "priceUSD": "1460.197283081249814687522034733243"
    },
    {
        "date": 1666569600,
        "volume": "414465.876933647532779289",
        "open": "1364.37086670639339466488104862123",
        "close": "1345.616292745401844642987367110919",
        "untrackedVolumeUSD": "557165282.4629339918269273141837884",
        "priceUSD": "1345.616292745401844642987367110919"
    },
    {
        "date": 1666483200,
        "volume": "292781.06005630040926013",
        "open": "1314.313003912999666914995456979141",
        "close": "1364.37086670639339466488104862123",
        "untrackedVolumeUSD": "389106271.9759111380390524965241304",
        "priceUSD": "1364.37086670639339466488104862123"
    },
    {
        "date": 1666396800,
        "volume": "189515.649480294073222858",
        "open": "1301.216954127304884640747819530381",
        "close": "1314.313003912999666914995456979141",
        "untrackedVolumeUSD": "247719032.672096529375299185973363",
        "priceUSD": "1314.313003912999666914995456979141"
    },
    {
        "date": 1666310400,
        "volume": "424313.120058724363074828",
        "open": "1281.895918307704080994075924540092",
        "close": "1301.216954127304884640747819530381",
        "untrackedVolumeUSD": "545355137.9884045816267396592060647",
        "priceUSD": "1301.216954127304884640747819530381"
    },
    {
        "date": 1666224000,
        "volume": "290590.754238703768797194",
        "open": "1284.200515474003751060653141186454",
        "close": "1281.895918307704080994075924540092",
        "untrackedVolumeUSD": "374654331.144413866610598277020415",
        "priceUSD": "1281.895918307704080994075924540092"
    },
    {
        "date": 1666137600,
        "volume": "313382.113796034699076441",
        "open": "1311.38802130763767426012938306964",
        "close": "1284.200515474003751060653141186454",
        "untrackedVolumeUSD": "406798014.1180710967881741003806285",
        "priceUSD": "1284.200515474003751060653141186454"
    },
    {
        "date": 1666051200,
        "volume": "431143.678982169876382037",
        "open": "1330.502956392938160886505804550747",
        "close": "1311.38802130763767426012938306964",
        "untrackedVolumeUSD": "568963402.8075446983453101879255059",
        "priceUSD": "1311.38802130763767426012938306964"
    },
    {
        "date": 1665964800,
        "volume": "461044.791179779010906015",
        "open": "1306.526486006612380881812742961407",
        "close": "1330.502956392938160886505804550747",
        "untrackedVolumeUSD": "609278589.6686231029124796756749459",
        "priceUSD": "1330.502956392938160886505804550747"
    },
    {
        "date": 1665878400,
        "volume": "215329.81647463113082966",
        "open": "1275.91346250637987999081534405624",
        "close": "1306.526486006612380881812742961407",
        "untrackedVolumeUSD": "278113279.1844527778555205199596449",
        "priceUSD": "1306.526486006612380881812742961407"
    },
    {
        "date": 1665792000,
        "volume": "223925.978698933707895116",
        "open": "1295.149732717195418842108011590024",
        "close": "1275.91346250637987999081534405624",
        "untrackedVolumeUSD": "287035290.7238266694773881669364184",
        "priceUSD": "1275.91346250637987999081534405624"
    },
    {
        "date": 1665705600,
        "volume": "532071.24645461246053439",
        "open": "1289.403401601435701338841214508262",
        "close": "1295.149732717195418842108011590024",
        "untrackedVolumeUSD": "701011851.5485878369435136587995253",
        "priceUSD": "1295.149732717195418842108011590024"
    },
    {
        "date": 1665619200,
        "volume": "582610.861385355926008567",
        "open": "1296.630150365266287831481041099231",
        "close": "1289.403401601435701338841214508262",
        "untrackedVolumeUSD": "727950659.5565197061237805299276271",
        "priceUSD": "1289.403401601435701338841214508262"
    },
    {
        "date": 1665532800,
        "volume": "175727.87981764044801344",
        "open": "1278.003681723359277660886379932932",
        "close": "1296.630150365266287831481041099231",
        "untrackedVolumeUSD": "227367261.3554244757665141028373159",
        "priceUSD": "1296.630150365266287831481041099231"
    },
    {
        "date": 1665446400,
        "volume": "296293.458075942738382006",
        "open": "1292.464713868673530832747429549476",
        "close": "1278.003681723359277660886379932932",
        "untrackedVolumeUSD": "379533339.8939944246264153250175125",
        "priceUSD": "1278.003681723359277660886379932932"
    },
    {
        "date": 1665360000,
        "volume": "273859.165152256152322774",
        "open": "1319.417407119561749546497784717757",
        "close": "1292.464713868673530832747429549476",
        "untrackedVolumeUSD": "359059711.4862819553131349525391881",
        "priceUSD": "1292.464713868673530832747429549476"
    },
    {
        "date": 1665273600,
        "volume": "154431.697598918323333186",
        "open": "1313.481352943806512332934276010947",
        "close": "1319.417407119561749546497784717757",
        "untrackedVolumeUSD": "204527566.1010020821276617570034608",
        "priceUSD": "1319.417407119561749546497784717757"
    },
    {
        "date": 1665187200,
        "volume": "134787.200464462724110317",
        "open": "1332.713142017844558632982489237189",
        "close": "1313.481352943806512332934276010947",
        "untrackedVolumeUSD": "178699447.8454172094984141783042153",
        "priceUSD": "1313.481352943806512332934276010947"
    },
    {
        "date": 1665100800,
        "volume": "308855.645671571898905756",
        "open": "1350.781122709413048706767254853051",
        "close": "1332.713142017844558632982489237189",
        "untrackedVolumeUSD": "413703072.4891781164111286690241228",
        "priceUSD": "1332.713142017844558632982489237189"
    },
    {
        "date": 1665014400,
        "volume": "519390.98297162331650102",
        "open": "1353.208490815833622269870106819884",
        "close": "1350.781122709413048706767254853051",
        "untrackedVolumeUSD": "708854312.929461689225279608060202",
        "priceUSD": "1350.781122709413048706767254853051"
    },
    {
        "date": 1664928000,
        "volume": "332917.311161491079569818",
        "open": "1362.079265260310293755356052278897",
        "close": "1353.208490815833622269870106819884",
        "untrackedVolumeUSD": "447660135.9900297587465513349752714",
        "priceUSD": "1353.208490815833622269870106819884"
    },
    {
        "date": 1664841600,
        "volume": "363485.408618709447416007",
        "open": "1323.86772774433694607452889310959",
        "close": "1362.079265260310293755356052278897",
        "untrackedVolumeUSD": "489904029.8125588935175281596147279",
        "priceUSD": "1362.079265260310293755356052278897"
    },
    {
        "date": 1664755200,
        "volume": "324346.640435112812175671",
        "open": "1276.988933068001344657406441014578",
        "close": "1323.86772774433694607452889310959",
        "untrackedVolumeUSD": "423921302.8495241396442682712821614",
        "priceUSD": "1323.86772774433694607452889310959"
    },
    {
        "date": 1664668800,
        "volume": "265861.652526971890928967",
        "open": "1312.461687591151521909614359353653",
        "close": "1276.988933068001344657406441014578",
        "untrackedVolumeUSD": "344930929.9568437455287344843232103",
        "priceUSD": "1276.988933068001344657406441014578"
    },
    {
        "date": 1664582400,
        "volume": "180786.561495442741313886",
        "open": "1325.434269220712381182719262898217",
        "close": "1312.461687591151521909614359353653",
        "untrackedVolumeUSD": "238581117.4667043910774431036330384",
        "priceUSD": "1312.461687591151521909614359353653"
    },
    {
        "date": 1664496000,
        "volume": "526017.266125212029381477",
        "open": "1337.045456855046794234655968633555",
        "close": "1325.434269220712381182719262898217",
        "untrackedVolumeUSD": "704961173.4076058861980659395666692",
        "priceUSD": "1325.434269220712381182719262898217"
    },
    {
        "date": 1664409600,
        "volume": "508213.377499555534650519",
        "open": "1338.554927912188001871128752689706",
        "close": "1337.045456855046794234655968633555",
        "untrackedVolumeUSD": "675233765.5152557586824383728941876",
        "priceUSD": "1337.045456855046794234655968633555"
    },
    {
        "date": 1664323200,
        "volume": "655314.750542713258115103",
        "open": "1330.469929804349017029121530495201",
        "close": "1338.554927912188001871128752689706",
        "untrackedVolumeUSD": "859814639.8024683676715599679531566",
        "priceUSD": "1338.554927912188001871128752689706"
    },
    {
        "date": 1664236800,
        "volume": "552439.207725413478470133",
        "open": "1333.572011523799041551279788445599",
        "close": "1330.469929804349017029121530495201",
        "untrackedVolumeUSD": "751462313.2548562109551723138143205",
        "priceUSD": "1330.469929804349017029121530495201"
    },
    {
        "date": 1664150400,
        "volume": "452478.890428999895953565",
        "open": "1294.037241128421501005053372339017",
        "close": "1333.572011523799041551279788445599",
        "untrackedVolumeUSD": "594155184.2993031782584851493100897",
        "priceUSD": "1333.572011523799041551279788445599"
    },
    {
        "date": 1664064000,
        "volume": "280146.615723264394645454",
        "open": "1316.871391663669728307175531847913",
        "close": "1294.037241128421501005053372339017",
        "untrackedVolumeUSD": "365588203.6870317292243800250558712",
        "priceUSD": "1294.037241128421501005053372339017"
    },
    {
        "date": 1663977600,
        "volume": "331706.773406121214268068",
        "open": "1327.573477266407111378861324015809",
        "close": "1316.871391663669728307175531847913",
        "untrackedVolumeUSD": "440566233.472283538476574508183892",
        "priceUSD": "1316.871391663669728307175531847913"
    },
    {
        "date": 1663891200,
        "volume": "587656.540713920343854335",
        "open": "1329.833632464102590133518854806843",
        "close": "1327.573477266407111378861324015809",
        "untrackedVolumeUSD": "768156159.1156094208092709871743426",
        "priceUSD": "1327.573477266407111378861324015809"
    },
    {
        "date": 1663804800,
        "volume": "590002.166941637652407363",
        "open": "1249.463876065697257155810361237672",
        "close": "1329.833632464102590133518854806843",
        "untrackedVolumeUSD": "756116222.2650067636146379669458748",
        "priceUSD": "1329.833632464102590133518854806843"
    },
    {
        "date": 1663718400,
        "volume": "724199.030193429117796342",
        "open": "1322.948710145728979004257042306221",
        "close": "1249.463876065697257155810361237672",
        "untrackedVolumeUSD": "954602277.801162923080168351493539",
        "priceUSD": "1249.463876065697257155810361237672"
    },
    {
        "date": 1663632000,
        "volume": "466774.271565378193970544",
        "open": "1378.714262011493411111542222189444",
        "close": "1322.948710145728979004257042306221",
        "untrackedVolumeUSD": "629622600.2261023428569154535198515",
        "priceUSD": "1322.948710145728979004257042306221"
    },
    {
        "date": 1663545600,
        "volume": "551493.208005550502804152",
        "open": "1334.179907332456638158668313398553",
        "close": "1378.714262011493411111542222189444",
        "untrackedVolumeUSD": "734130560.0409119416288392826111282",
        "priceUSD": "1378.714262011493411111542222189444"
    },
    {
        "date": 1663459200,
        "volume": "343031.853141644888134451",
        "open": "1469.283080224565691990624513935878",
        "close": "1334.179907332456638158668313398553",
        "untrackedVolumeUSD": "478913060.3945796477120307070861169",
        "priceUSD": "1334.179907332456638158668313398553"
    },
    {
        "date": 1663372800,
        "volume": "248444.986296165785747801",
        "open": "1429.912959911105386227540486172153",
        "close": "1469.283080224565691990624513935878",
        "untrackedVolumeUSD": "359513220.4076408260649442804792035",
        "priceUSD": "1469.283080224565691990624513935878"
    },
    {
        "date": 1663286400,
        "volume": "500605.93975963906905471",
        "open": "1471.100610402105934398390537846396",
        "close": "1429.912959911105386227540486172153",
        "untrackedVolumeUSD": "722938617.9249173886934848635923381",
        "priceUSD": "1429.912959911105386227540486172153"
    },
    {
        "date": 1663200000,
        "volume": "750141.377572492722014883",
        "open": "1634.533180271369250696695130720991",
        "close": "1471.100610402105934398390537846396",
        "untrackedVolumeUSD": "1169327380.471418586634228766024623",
        "priceUSD": "1471.100610402105934398390537846396"
    },
    {
        "date": 1663113600,
        "volume": "534886.672924778967991789",
        "open": "1573.490054981503794640315242191117",
        "close": "1634.533180271369250696695130720991",
        "untrackedVolumeUSD": "855924951.3496947464092799279284316",
        "priceUSD": "1634.533180271369250696695130720991"
    },
    {
        "date": 1663027200,
        "volume": "808455.630875662074148069",
        "open": "1712.135878751709871091042865136183",
        "close": "1573.490054981503794640315242191117",
        "untrackedVolumeUSD": "1333061545.46577062464057363843106",
        "priceUSD": "1573.490054981503794640315242191117"
    },
    {
        "date": 1662940800,
        "volume": "487776.532098742394840601",
        "open": "1762.624333986545986168562685822082",
        "close": "1712.135878751709871091042865136183",
        "untrackedVolumeUSD": "846855407.930334093532696218602054",
        "priceUSD": "1712.135878751709871091042865136183"
    },
    {
        "date": 1662854400,
        "volume": "303443.273915451959613054",
        "open": "1778.932461217125432999153717992146",
        "close": "1762.624333986545986168562685822082",
        "untrackedVolumeUSD": "535156266.661176542973608051037797",
        "priceUSD": "1762.624333986545986168562685822082"
    },
    {
        "date": 1662768000,
        "volume": "363908.449335267820563823",
        "open": "1716.743640690177498377577460016662",
        "close": "1778.932461217125432999153717992146",
        "untrackedVolumeUSD": "631462614.9493466711284779513743675",
        "priceUSD": "1778.932461217125432999153717992146"
    },
    {
        "date": 1662681600,
        "volume": "519772.071834639125788562",
        "open": "1632.644622602373034780650799451813",
        "close": "1716.743640690177498377577460016662",
        "untrackedVolumeUSD": "884517251.0164737312266601741107382",
        "priceUSD": "1716.743640690177498377577460016662"
    },
    {
        "date": 1662595200,
        "volume": "467917.834616349088052577",
        "open": "1628.188911106200898615393923121325",
        "close": "1632.644622602373034780650799451813",
        "untrackedVolumeUSD": "762772807.2964006058549478367551188",
        "priceUSD": "1632.644622602373034780650799451813"
    },
    {
        "date": 1662508800,
        "volume": "660049.147225092617674636",
        "open": "1561.77589014009460393673652614368",
        "close": "1628.188911106200898615393923121325",
        "untrackedVolumeUSD": "1024462731.914568097492906101746701",
        "priceUSD": "1628.188911106200898615393923121325"
    },
    {
        "date": 1662422400,
        "volume": "627139.232476520217641573",
        "open": "1619.45588317949927066736839841291",
        "close": "1561.77589014009460393673652614368",
        "untrackedVolumeUSD": "1021546071.016418115558574267234316",
        "priceUSD": "1561.77589014009460393673652614368"
    },
    {
        "date": 1662336000,
        "volume": "407358.802988060098655253",
        "open": "1575.640160321265981070517410390242",
        "close": "1619.45588317949927066736839841291",
        "untrackedVolumeUSD": "648084181.7043940431469167720129828",
        "priceUSD": "1619.45588317949927066736839841291"
    },
    {
        "date": 1662249600,
        "volume": "246692.353492167869171739",
        "open": "1554.901987725990672667878130431881",
        "close": "1575.640160321265981070517410390242",
        "untrackedVolumeUSD": "384992048.4808344867118436726677843",
        "priceUSD": "1575.640160321265981070517410390242"
    },
    {
        "date": 1662163200,
        "volume": "286204.341285746745889017",
        "open": "1576.447484946132838834651498254034",
        "close": "1554.901987725990672667878130431881",
        "untrackedVolumeUSD": "445340735.6827328402585963844871129",
        "priceUSD": "1554.901987725990672667878130431881"
    },
    {
        "date": 1662076800,
        "volume": "604239.451581477136018997",
        "open": "1586.487967631418057352224894839498",
        "close": "1576.447484946132838834651498254034",
        "untrackedVolumeUSD": "965354892.8572873326997072001403962",
        "priceUSD": "1576.447484946132838834651498254034"
    },
    {
        "date": 1661990400,
        "volume": "616861.443781658767488052",
        "open": "1550.595890397765355908907424799271",
        "close": "1586.487967631418057352224894839498",
        "untrackedVolumeUSD": "960433094.4149290954393627888112607",
        "priceUSD": "1586.487967631418057352224894839498"
    },
    {
        "date": 1661904000,
        "volume": "793800.471121499316140034",
        "open": "1523.457389399116194191299029232413",
        "close": "1550.595890397765355908907424799271",
        "untrackedVolumeUSD": "1252402762.225415357889885690853424",
        "priceUSD": "1550.595890397765355908907424799271"
    },
    {
        "date": 1661817600,
        "volume": "673104.462744932043646213",
        "open": "1555.302537299398084400094493403356",
        "close": "1523.457389399116194191299029232413",
        "untrackedVolumeUSD": "1041751390.970417542149572647545684",
        "priceUSD": "1523.457389399116194191299029232413"
    },
    {
        "date": 1661731200,
        "volume": "524401.333393066892606607",
        "open": "1430.395834486597168037399717625599",
        "close": "1555.302537299398084400094493403356",
        "untrackedVolumeUSD": "782933408.2586430484951240859808072",
        "priceUSD": "1555.302537299398084400094493403356"
    },
    {
        "date": 1661644800,
        "volume": "469629.029751086542678758",
        "open": "1493.811455704440314099383689294187",
        "close": "1430.395834486597168037399717625599",
        "untrackedVolumeUSD": "695365287.0155864800179297673450342",
        "priceUSD": "1430.395834486597168037399717625599"
    },
    {
        "date": 1661558400,
        "volume": "521386.231634780360401633",
        "open": "1503.562628149576346550481971128205",
        "close": "1493.811455704440314099383689294187",
        "untrackedVolumeUSD": "774551879.1526187877551744729435575",
        "priceUSD": "1493.811455704440314099383689294187"
    },
    {
        "date": 1661472000,
        "volume": "727301.872096685822322094",
        "open": "1693.662645594171847391634498816532",
        "close": "1503.562628149576346550481971128205",
        "untrackedVolumeUSD": "1176373148.839745654225737709987713",
        "priceUSD": "1503.562628149576346550481971128205"
    },
    {
        "date": 1661385600,
        "volume": "386223.383702985826088766",
        "open": "1657.437615423424121115618750135402",
        "close": "1693.662645594171847391634498816532",
        "untrackedVolumeUSD": "655393030.7818738418591610747448729",
        "priceUSD": "1693.662645594171847391634498816532"
    },
    {
        "date": 1661299200,
        "volume": "521224.390789876428434276",
        "open": "1666.259443906178880876866356976088",
        "close": "1657.437615423424121115618750135402",
        "untrackedVolumeUSD": "861228241.2451205489721295274085367",
        "priceUSD": "1657.437615423424121115618750135402"
    },
    {
        "date": 1661212800,
        "volume": "808778.543927852746501256",
        "open": "1624.369303380885570343876433283331",
        "close": "1666.259443906178880876866356976088",
        "untrackedVolumeUSD": "1316888954.64799598036514791115371",
        "priceUSD": "1666.259443906178880876866356976088"
    },
    {
        "date": 1661126400,
        "volume": "615694.885225770093442032",
        "open": "1620.913895017338038389824238888478",
        "close": "1624.369303380885570343876433283331",
        "untrackedVolumeUSD": "971253645.8598564619946279252204581",
        "priceUSD": "1624.369303380885570343876433283331"
    },
    {
        "date": 1661040000,
        "volume": "573039.712599476594116688",
        "open": "1575.049406846600679847962742813391",
        "close": "1620.913895017338038389824238888478",
        "untrackedVolumeUSD": "922076428.5254933981802581217751818",
        "priceUSD": "1620.913895017338038389824238888478"
    },
    {
        "date": 1660953600,
        "volume": "630283.817682988487283196",
        "open": "1609.58555089641965123773976293502",
        "close": "1575.049406846600679847962742813391",
        "untrackedVolumeUSD": "1008920316.950125915637561906466526",
        "priceUSD": "1575.049406846600679847962742813391"
    },
    {
        "date": 1660867200,
        "volume": "775465.498441844897124058",
        "open": "1849.811322777741750051422864416683",
        "close": "1609.58555089641965123773976293502",
        "untrackedVolumeUSD": "1332983787.354574610829580218110326",
        "priceUSD": "1609.58555089641965123773976293502"
    },
    {
        "date": 1660780800,
        "volume": "379503.002819984469509127",
        "open": "1831.471256040449136008292147918772",
        "close": "1849.811322777741750051422864416683",
        "untrackedVolumeUSD": "705178886.3457854011734160890654658",
        "priceUSD": "1849.811322777741750051422864416683"
    },
    {
        "date": 1660694400,
        "volume": "598770.732675380565782433",
        "open": "1875.262789081693138206212642719346",
        "close": "1831.471256040449136008292147918772",
        "untrackedVolumeUSD": "1119791435.627876703601471638539511",
        "priceUSD": "1831.471256040449136008292147918772"
    },
    {
        "date": 1660608000,
        "volume": "418195.703129983276292195",
        "open": "1896.122432836570175434032241089274",
        "close": "1875.262789081693138206212642719346",
        "untrackedVolumeUSD": "788785776.7603552136249314336906248",
        "priceUSD": "1875.262789081693138206212642719346"
    },
    {
        "date": 1660521600,
        "volume": "585679.36863457449445986",
        "open": "1934.728712693098111129560973420641",
        "close": "1896.122432836570175434032241089274",
        "untrackedVolumeUSD": "1124314379.457861808431493508217095",
        "priceUSD": "1896.122432836570175434032241089274"
    },
    {
        "date": 1660435200,
        "volume": "348880.504682362726155632",
        "open": "1981.31436952246994775102060590944",
        "close": "1934.728712693098111129560973420641",
        "untrackedVolumeUSD": "687007390.6005456079174697974293208",
        "priceUSD": "1934.728712693098111129560973420641"
    },
    {
        "date": 1660348800,
        "volume": "352273.980102092722257587",
        "open": "1958.903416892567293717137760166185",
        "close": "1981.31436952246994775102060590944",
        "untrackedVolumeUSD": "700380228.109605590795181018172227",
        "priceUSD": "1981.31436952246994775102060590944"
    },
    {
        "date": 1660262400,
        "volume": "409023.151492292225872701",
        "open": "1878.208611090695029080890640634949",
        "close": "1958.903416892567293717137760166185",
        "untrackedVolumeUSD": "776495852.6609981329665650480135447",
        "priceUSD": "1958.903416892567293717137760166185"
    },
    {
        "date": 1660176000,
        "volume": "646544.661711948261132137",
        "open": "1851.24998779754541601985893106852",
        "close": "1878.208611090695029080890640634949",
        "untrackedVolumeUSD": "1224774132.780348404338200809299612",
        "priceUSD": "1878.208611090695029080890640634949"
    },
    {
        "date": 1660089600,
        "volume": "720283.801496020960014144",
        "open": "1705.613517081326912383379132403644",
        "close": "1851.24998779754541601985893106852",
        "untrackedVolumeUSD": "1282902352.937770707650324030214908",
        "priceUSD": "1851.24998779754541601985893106852"
    },
    {
        "date": 1660003200,
        "volume": "514567.552928527682883777",
        "open": "1774.773586490356444628071019632437",
        "close": "1705.613517081326912383379132403644",
        "untrackedVolumeUSD": "883683495.2703878203594988671587821",
        "priceUSD": "1705.613517081326912383379132403644"
    },
    {
        "date": 1659916800,
        "volume": "521587.232203414390251623",
        "open": "1696.063231117178994972350557155397",
        "close": "1774.773586490356444628071019632437",
        "untrackedVolumeUSD": "921396554.724048692224195622124645",
        "priceUSD": "1774.773586490356444628071019632437"
    },
    {
        "date": 1659830400,
        "volume": "278886.910190630685473684",
        "open": "1692.807306781228600344315229391612",
        "close": "1696.063231117178994972350557155397",
        "untrackedVolumeUSD": "473465205.7476762980203209164269283",
        "priceUSD": "1696.063231117178994972350557155397"
    },
    {
        "date": 1659744000,
        "volume": "279269.102222841425333765",
        "open": "1735.9265861350052274671065268099",
        "close": "1692.807306781228600344315229391612",
        "untrackedVolumeUSD": "481319771.7981539986471123781077136",
        "priceUSD": "1692.807306781228600344315229391612"
    },
    {
        "date": 1659657600,
        "volume": "563748.006744934844670809",
        "open": "1608.139939533599819521681854066809",
        "close": "1735.9265861350052274671065268099",
        "untrackedVolumeUSD": "947437839.3865542173451623610319636",
        "priceUSD": "1735.9265861350052274671065268099"
    },
    {
        "date": 1659571200,
        "volume": "499536.516494417041314361",
        "open": "1617.867917843883575075929470236413",
        "close": "1608.139939533599819521681854066809",
        "untrackedVolumeUSD": "809933062.7324197740758681864592077",
        "priceUSD": "1608.139939533599819521681854066809"
    },
    {
        "date": 1659484800,
        "volume": "505498.365571263621350596",
        "open": "1633.757273895149195655817788590644",
        "close": "1617.867917843883575075929470236413",
        "untrackedVolumeUSD": "827665249.4276988917527159247462304",
        "priceUSD": "1617.867917843883575075929470236413"
    },
    {
        "date": 1659398400,
        "volume": "772986.463408182498996585",
        "open": "1631.527674887434704381914183592022",
        "close": "1633.757273895149195655817788590644",
        "untrackedVolumeUSD": "1250482981.698179315067915333346016",
        "priceUSD": "1633.757273895149195655817788590644"
    },
    {
        "date": 1659312000,
        "volume": "526717.829506764550192309",
        "open": "1683.593063440322964263452519256328",
        "close": "1631.527674887434704381914183592022",
        "untrackedVolumeUSD": "872110237.8424287380704466236414221",
        "priceUSD": "1631.527674887434704381914183592022"
    },
    {
        "date": 1659225600,
        "volume": "402145.157427237022562222",
        "open": "1693.719593594312792254598930213691",
        "close": "1683.593063440322964263452519256328",
        "untrackedVolumeUSD": "686039587.3245856559020133554555011",
        "priceUSD": "1683.593063440322964263452519256328"
    },
    {
        "date": 1659139200,
        "volume": "449862.285703831072972006",
        "open": "1723.975290158357494736694333478117",
        "close": "1693.719593594312792254598930213691",
        "untrackedVolumeUSD": "769403175.9280738109577535202513894",
        "priceUSD": "1693.719593594312792254598930213691"
    },
    {
        "date": 1659052800,
        "volume": "711883.427652514096541066",
        "open": "1723.650966252407381146032253864081",
        "close": "1723.975290158357494736694333478117",
        "untrackedVolumeUSD": "1218933131.023635239545031782572743",
        "priceUSD": "1723.975290158357494736694333478117"
    },
    {
        "date": 1658966400,
        "volume": "851801.047859839743635302",
        "open": "1639.294733355766835685378261273548",
        "close": "1723.650966252407381146032253864081",
        "untrackedVolumeUSD": "1426358581.466744690728965547390273",
        "priceUSD": "1723.650966252407381146032253864081"
    },
    {
        "date": 1658880000,
        "volume": "825096.615057288523409014",
        "open": "1449.449059230600202585424552237458",
        "close": "1639.294733355766835685378261273548",
        "untrackedVolumeUSD": "1251847780.992790292531787971390109",
        "priceUSD": "1639.294733355766835685378261273548"
    },
    {
        "date": 1658793600,
        "volume": "679409.929122825768238315",
        "open": "1442.684598510619086794433238970584",
        "close": "1449.449059230600202585424552237458",
        "untrackedVolumeUSD": "952014969.6477387675827407377317976",
        "priceUSD": "1449.449059230600202585424552237458"
    },
    {
        "date": 1658707200,
        "volume": "724626.581519648227360946",
        "open": "1597.868836250534988124477277782571",
        "close": "1442.684598510619086794433238970584",
        "untrackedVolumeUSD": "1099439633.225169585438051288298382",
        "priceUSD": "1442.684598510619086794433238970584"
    },
    {
        "date": 1658620800,
        "volume": "521240.219600245227981608",
        "open": "1550.024241942749988068276969228899",
        "close": "1597.868836250534988124477277782571",
        "untrackedVolumeUSD": "834104225.8251862488460920367264805",
        "priceUSD": "1597.868836250534988124477277782571"
    },
    {
        "date": 1658534400,
        "volume": "517061.619839833425462787",
        "open": "1536.454970854210018146430163865269",
        "close": "1550.024241942749988068276969228899",
        "untrackedVolumeUSD": "795802058.3494766612098288454035475",
        "priceUSD": "1550.024241942749988068276969228899"
    },
    {
        "date": 1658448000,
        "volume": "583170.7051821909804807",
        "open": "1575.08339896225830509542896426358",
        "close": "1536.454970854210018146430163865269",
        "untrackedVolumeUSD": "922221480.6458640620480425873898222",
        "priceUSD": "1536.454970854210018146430163865269"
    },
    {
        "date": 1658361600,
        "volume": "714293.82088077168817054",
        "open": "1521.589911235647660839081965692546",
        "close": "1575.08339896225830509542896426358",
        "untrackedVolumeUSD": "1085909114.668269340010154205337086",
        "priceUSD": "1575.08339896225830509542896426358"
    },
    {
        "date": 1658275200,
        "volume": "847040.675861248027737063",
        "open": "1540.632369304936296655131735739593",
        "close": "1521.589911235647660839081965692546",
        "untrackedVolumeUSD": "1318848915.109896403805036576833488",
        "priceUSD": "1521.589911235647660839081965692546"
    },
    {
        "date": 1658188800,
        "volume": "1034273.203707494723135417",
        "open": "1585.994518790602764875322542893376",
        "close": "1540.632369304936296655131735739593",
        "untrackedVolumeUSD": "1598362002.136962273296656788794603",
        "priceUSD": "1540.632369304936296655131735739593"
    },
    {
        "date": 1658102400,
        "volume": "994751.801544041985069681",
        "open": "1338.347071773165205444115355282672",
        "close": "1585.994518790602764875322542893376",
        "untrackedVolumeUSD": "1455905866.822888995270647069334951",
        "priceUSD": "1585.994518790602764875322542893376"
    },
    {
        "date": 1658016000,
        "volume": "750321.866737564381406673",
        "open": "1355.395081970511552313081917735865",
        "close": "1338.347071773165205444115355282672",
        "untrackedVolumeUSD": "1015977289.385277331385607416927692",
        "priceUSD": "1338.347071773165205444115355282672"
    },
    {
        "date": 1657929600,
        "volume": "766885.993129034588286393",
        "open": "1233.418087599487384596217508708708",
        "close": "1355.395081970511552313081917735865",
        "untrackedVolumeUSD": "988132430.5918350623540503791663754",
        "priceUSD": "1355.395081970511552313081917735865"
    },
    {
        "date": 1657843200,
        "volume": "889999.305232611808779178",
        "open": "1191.857820826720641744198899046141",
        "close": "1233.418087599487384596217508708708",
        "untrackedVolumeUSD": "1090229645.26083491849146832706149",
        "priceUSD": "1233.418087599487384596217508708708"
    },
    {
        "date": 1657756800,
        "volume": "692777.842814664673337615",
        "open": "1113.964832820670698962670819495847",
        "close": "1191.857820826720641744198899046141",
        "untrackedVolumeUSD": "783954288.9655775288750723172238686",
        "priceUSD": "1191.857820826720641744198899046141"
    },
    {
        "date": 1657670400,
        "volume": "887532.263932624440341478",
        "open": "1037.681843269342561000071311583608",
        "close": "1113.964832820670698962670819495847",
        "untrackedVolumeUSD": "943862111.4884471432730567419662797",
        "priceUSD": "1113.964832820670698962670819495847"
    },
    {
        "date": 1657584000,
        "volume": "606874.936112304350931621",
        "open": "1098.000280161693500051386583107309",
        "close": "1037.681843269342561000071311583608",
        "untrackedVolumeUSD": "648691857.7768807077815834135792875",
        "priceUSD": "1037.681843269342561000071311583608"
    },
    {
        "date": 1657497600,
        "volume": "606057.634997944868426832",
        "open": "1166.216693586216812641880005395793",
        "close": "1098.000280161693500051386583107309",
        "untrackedVolumeUSD": "688856378.054759771978340319190036",
        "priceUSD": "1098.000280161693500051386583107309"
    },
    {
        "date": 1657411200,
        "volume": "403097.251042439167198809",
        "open": "1216.379790588969880234079620466635",
        "close": "1166.216693586216812641880005395793",
        "untrackedVolumeUSD": "476069933.6835907637805662104865245",
        "priceUSD": "1166.216693586216812641880005395793"
    },
    {
        "date": 1657324800,
        "volume": "386935.692390027382526993",
        "open": "1214.138430886530600752683895906423",
        "close": "1216.379790588969880234079620466635",
        "untrackedVolumeUSD": "470787142.6935105203029767557611198",
        "priceUSD": "1216.379790588969880234079620466635"
    },
    {
        "date": 1657238400,
        "volume": "826591.35619082795056437",
        "open": "1238.433504976075716769629326855619",
        "close": "1214.138430886530600752683895906423",
        "untrackedVolumeUSD": "1017326592.943850411807878434296814",
        "priceUSD": "1214.138430886530600752683895906423"
    },
    {
        "date": 1657152000,
        "volume": "605288.479128147937706541",
        "open": "1188.635129142901095545721061013802",
        "close": "1238.433504976075716769629326855619",
        "untrackedVolumeUSD": "729772803.1752403347438484162597503",
        "priceUSD": "1238.433504976075716769629326855619"
    },
    {
        "date": 1657065600,
        "volume": "850978.797830833595882763",
        "open": "1131.860786797637856082033694974668",
        "close": "1188.635129142901095545721061013802",
        "untrackedVolumeUSD": "972392232.0127005404138333995012519",
        "priceUSD": "1188.635129142901095545721061013802"
    },
    {
        "date": 1656979200,
        "volume": "796766.324197605264300444",
        "open": "1151.997453745552197581018826701972",
        "close": "1131.860786797637856082033694974668",
        "untrackedVolumeUSD": "899600391.6807743758300082621107699",
        "priceUSD": "1131.860786797637856082033694974668"
    },
    {
        "date": 1656892800,
        "volume": "642296.799353143322179758",
        "open": "1072.276625493786975346041906402502",
        "close": "1151.997453745552197581018826701972",
        "untrackedVolumeUSD": "710770141.1850746358917345273487455",
        "priceUSD": "1151.997453745552197581018826701972"
    },
    {
        "date": 1656806400,
        "volume": "434642.183781883405528327",
        "open": "1068.128413374784675670342991251436",
        "close": "1072.276625493786975346041906402502",
        "untrackedVolumeUSD": "462430202.6542941445181434527767047",
        "priceUSD": "1072.276625493786975346041906402502"
    },
    {
        "date": 1656720000,
        "volume": "470554.131651521233518835",
        "open": "1056.879163864628357132944938965711",
        "close": "1068.128413374784675670342991251436",
        "untrackedVolumeUSD": "494760934.1169324895064807863794036",
        "priceUSD": "1068.128413374784675670342991251436"
    },
    {
        "date": 1656633600,
        "volume": "1032371.424764565831972128",
        "open": "1068.654722772398568550216012380241",
        "close": "1056.879163864628357132944938965711",
        "untrackedVolumeUSD": "1101216885.910087921483788311937231",
        "priceUSD": "1056.879163864628357132944938965711"
    },
    {
        "date": 1656547200,
        "volume": "951485.471881940709302985",
        "open": "1095.986322474999975161595963239924",
        "close": "1068.654722772398568550216012380241",
        "untrackedVolumeUSD": "990171991.7049482781960449130537862",
        "priceUSD": "1068.654722772398568550216012380241"
    },
    {
        "date": 1656460800,
        "volume": "794602.867723931304611989",
        "open": "1140.03565751163309660712340973545",
        "close": "1095.986322474999975161595963239924",
        "untrackedVolumeUSD": "887883888.3102890234359217171251064",
        "priceUSD": "1095.986322474999975161595963239924"
    },
    {
        "date": 1656374400,
        "volume": "630118.200439829770913542",
        "open": "1191.936420069088557281646060544173",
        "close": "1140.03565751163309660712340973545",
        "untrackedVolumeUSD": "746268693.2285508604041017396668389",
        "priceUSD": "1140.03565751163309660712340973545"
    },
    {
        "date": 1656288000,
        "volume": "434374.677873712070225597",
        "open": "1197.916444234068504241763015307265",
        "close": "1191.936420069088557281646060544173",
        "untrackedVolumeUSD": "523094604.924996957949757035678339",
        "priceUSD": "1191.936420069088557281646060544173"
    },
    {
        "date": 1656201600,
        "volume": "476133.843292070664654861",
        "open": "1243.686625326435662599844164906735",
        "close": "1197.916444234068504241763015307265",
        "untrackedVolumeUSD": "586445131.5999999148519351663638643",
        "priceUSD": "1197.916444234068504241763015307265"
    },
    {
        "date": 1656115200,
        "volume": "483353.489253668484052272",
        "open": "1225.74843468071058921876467214072",
        "close": "1243.686625326435662599844164906735",
        "untrackedVolumeUSD": "588600133.6917988275581062229524373",
        "priceUSD": "1243.686625326435662599844164906735"
    },
    {
        "date": 1656028800,
        "volume": "788730.257234224241134866",
        "open": "1144.646395210410470093788480747366",
        "close": "1225.74843468071058921876467214072",
        "untrackedVolumeUSD": "936564635.4039810306833514653942413",
        "priceUSD": "1225.74843468071058921876467214072"
    },
    {
        "date": 1655942400,
        "volume": "886585.131992164381428526",
        "open": "1047.813198048487772386401207236174",
        "close": "1144.646395210410470093788480747366",
        "untrackedVolumeUSD": "983509263.3162673831698927721257684",
        "priceUSD": "1144.646395210410470093788480747366"
    },
    {
        "date": 1655856000,
        "volume": "895140.826014381079606613",
        "open": "1124.644236367616955441624236804306",
        "close": "1047.813198048487772386401207236174",
        "untrackedVolumeUSD": "970386708.3675612672628897425247088",
        "priceUSD": "1047.813198048487772386401207236174"
    },
    {
        "date": 1655769600,
        "volume": "972176.905202454814149189",
        "open": "1130.280173384804762601103615771316",
        "close": "1124.644236367616955441624236804306",
        "untrackedVolumeUSD": "1113398831.442364073337125674979366",
        "priceUSD": "1124.644236367616955441624236804306"
    },
    {
        "date": 1655683200,
        "volume": "1230370.657914297166170035",
        "open": "1125.230878245978682938585378376915",
        "close": "1130.280173384804762601103615771316",
        "untrackedVolumeUSD": "1363712709.660669014865228880804283",
        "priceUSD": "1130.280173384804762601103615771316"
    },
    {
        "date": 1655596800,
        "volume": "1508003.560699871809073687",
        "open": "995.1087996803623279878287430333024",
        "close": "1125.230878245978682938585378376915",
        "untrackedVolumeUSD": "1564196373.30602238432770269094362",
        "priceUSD": "1125.230878245978682938585378376915"
    },
    {
        "date": 1655510400,
        "volume": "1585513.022484262480794839",
        "open": "1086.394537233310411974725797299086",
        "close": "995.1087996803623279878287430333024",
        "untrackedVolumeUSD": "1550690874.683915105266158525922822",
        "priceUSD": "995.1087996803623279878287430333024"
    },
    {
        "date": 1655424000,
        "volume": "1000262.579324414203455861",
        "open": "1063.672794230852906274302860820026",
        "close": "1086.394537233310411974725797299086",
        "untrackedVolumeUSD": "1085083048.458418113249707442187454",
        "priceUSD": "1086.394537233310411974725797299086"
    },
    {
        "date": 1655337600,
        "volume": "1236579.637464428753261098",
        "open": "1234.501157512674422398661645704831",
        "close": "1063.672794230852906274302860820026",
        "untrackedVolumeUSD": "1413573001.257165553879104614149763",
        "priceUSD": "1063.672794230852906274302860820026"
    },
    {
        "date": 1655251200,
        "volume": "2280967.594832046121914023",
        "open": "1208.373063941825323725660185987681",
        "close": "1234.501157512674422398661645704831",
        "untrackedVolumeUSD": "2590007478.033924347275433802128488",
        "priceUSD": "1234.501157512674422398661645704831"
    },
    {
        "date": 1655164800,
        "volume": "1840854.644762231811253432",
        "open": "1205.398100287155312158028587306909",
        "close": "1208.373063941825323725660185987681",
        "untrackedVolumeUSD": "2178307230.877409196534667859394813",
        "priceUSD": "1208.373063941825323725660185987681"
    },
    {
        "date": 1655078400,
        "volume": "2465822.043677192091339416",
        "open": "1431.623096275168434349641261073942",
        "close": "1205.398100287155312158028587306909",
        "untrackedVolumeUSD": "3130947064.24101737854707661265675",
        "priceUSD": "1205.398100287155312158028587306909"
    },
    {
        "date": 1654992000,
        "volume": "1021375.117622773634336135",
        "open": "1525.849039186619870206763853347996",
        "close": "1431.623096275168434349641261073942",
        "untrackedVolumeUSD": "1504913969.126810828406859411684131",
        "priceUSD": "1431.623096275168434349641261073942"
    },
    {
        "date": 1654905600,
        "volume": "846178.817744411124221117",
        "open": "1658.564451941325222216960897299387",
        "close": "1525.849039186619870206763853347996",
        "untrackedVolumeUSD": "1326617811.525712195099110383951535",
        "priceUSD": "1525.849039186619870206763853347996"
    },
    {
        "date": 1654819200,
        "volume": "711254.599511416440185818",
        "open": "1785.754671998836641467514502673504",
        "close": "1658.564451941325222216960897299387",
        "untrackedVolumeUSD": "1224038743.370556330715377126568908",
        "priceUSD": "1658.564451941325222216960897299387"
    },
    {
        "date": 1654732800,
        "volume": "362372.920042835668897601",
        "open": "1794.866868605820358522321584040266",
        "close": "1785.754671998836641467514502673504",
        "untrackedVolumeUSD": "651779960.1608696699800797977568486",
        "priceUSD": "1785.754671998836641467514502673504"
    },
    {
        "date": 1654646400,
        "volume": "608733.988656632626616268",
        "open": "1815.891306134224166190927685779151",
        "close": "1794.866868605820358522321584040266",
        "untrackedVolumeUSD": "1097611595.108173700369070322701444",
        "priceUSD": "1794.866868605820358522321584040266"
    },
    {
        "date": 1654560000,
        "volume": "755389.651431576402306333",
        "open": "1857.297505602003543760212918195039",
        "close": "1815.891306134224166190927685779151",
        "untrackedVolumeUSD": "1348409181.865960676942746351177098",
        "priceUSD": "1815.891306134224166190927685779151"
    },
    {
        "date": 1654473600,
        "volume": "497456.150971724667098009",
        "open": "1806.412499607998739365183725100221",
        "close": "1857.297505602003543760212918195039",
        "untrackedVolumeUSD": "932096750.2261884872419252750070478",
        "priceUSD": "1857.297505602003543760212918195039"
    },
    {
        "date": 1654387200,
        "volume": "296388.323915367135147945",
        "open": "1800.171014193028293508379006637728",
        "close": "1806.412499607998739365183725100221",
        "untrackedVolumeUSD": "534364979.6162601804461951647801125",
        "priceUSD": "1806.412499607998739365183725100221"
    },
    {
        "date": 1654300800,
        "volume": "303804.444832722681667946",
        "open": "1774.343656340511503500559935961701",
        "close": "1800.171014193028293508379006637728",
        "untrackedVolumeUSD": "540115498.1272580870213472885781528",
        "priceUSD": "1800.171014193028293508379006637728"
    },
    {
        "date": 1654214400,
        "volume": "454073.806160486600325576",
        "open": "1837.129329193623699977644665284502",
        "close": "1774.343656340511503500559935961701",
        "untrackedVolumeUSD": "806184701.6601686198664975116446585",
        "priceUSD": "1774.343656340511503500559935961701"
    },
    {
        "date": 1654128000,
        "volume": "526193.519406200236366803",
        "open": "1820.477049969326866978369489729943",
        "close": "1837.129329193623699977644665284502",
        "untrackedVolumeUSD": "956428964.3870670714720344031286075",
        "priceUSD": "1837.129329193623699977644665284502"
    },
    {
        "date": 1654041600,
        "volume": "629241.682350182520437055",
        "open": "1943.059309948568601896220564849767",
        "close": "1820.477049969326866978369489729943",
        "untrackedVolumeUSD": "1180756608.789233989239102939468444",
        "priceUSD": "1820.477049969326866978369489729943"
    },
    {
        "date": 1653955200,
        "volume": "506071.259059784724596947",
        "open": "1995.857477813946834033815138305146",
        "close": "1943.059309948568601896220564849767",
        "untrackedVolumeUSD": "995942950.6070587062031012784728554",
        "priceUSD": "1943.059309948568601896220564849767"
    },
    {
        "date": 1653868800,
        "volume": "703363.833923487759575325",
        "open": "1812.284522876210063194996131284459",
        "close": "1995.857477813946834033815138305146",
        "untrackedVolumeUSD": "1347962246.525065224861643641395497",
        "priceUSD": "1995.857477813946834033815138305146"
    },
    {
        "date": 1653782400,
        "volume": "284570.066415073958612331",
        "open": "1795.536972646960553016366362328804",
        "close": "1812.284522876210063194996131284459",
        "untrackedVolumeUSD": "510630464.8876368578871538756810594",
        "priceUSD": "1812.284522876210063194996131284459"
    },
    {
        "date": 1653696000,
        "volume": "522546.130318718963572199",
        "open": "1720.144571483830330723475852163582",
        "close": "1795.536972646960553016366362328804",
        "untrackedVolumeUSD": "923110606.2791899962290599809205931",
        "priceUSD": "1795.536972646960553016366362328804"
    },
    {
        "date": 1653609600,
        "volume": "875582.696761761803775366",
        "open": "1793.613508977655882089205137787702",
        "close": "1720.144571483830330723475852163582",
        "untrackedVolumeUSD": "1540956777.197037148042796269485185",
        "priceUSD": "1720.144571483830330723475852163582"
    },
    {
        "date": 1653523200,
        "volume": "1064532.339530581884998483",
        "open": "1942.915999422501218431226199495668",
        "close": "1793.613508977655882089205137787702",
        "untrackedVolumeUSD": "1973330563.334835659693421351497276",
        "priceUSD": "1793.613508977655882089205137787702"
    },
    {
        "date": 1653436800,
        "volume": "518492.982548400737527786",
        "open": "1979.377629383218967707131422020793",
        "close": "1942.915999422501218431226199495668",
        "untrackedVolumeUSD": "1020195479.111532790938425129114482",
        "priceUSD": "1942.915999422501218431226199495668"
    },
    {
        "date": 1653350400,
        "volume": "434992.439151803433313031",
        "open": "1969.054856693696100073460986139882",
        "close": "1979.377629383218967707131422020793",
        "untrackedVolumeUSD": "853078074.7762241193139280290099335",
        "priceUSD": "1979.377629383218967707131422020793"
    },
    {
        "date": 1653264000,
        "volume": "568481.048820444612942139",
        "open": "2044.760701557745356586760234086823",
        "close": "1969.054856693696100073460986139882",
        "untrackedVolumeUSD": "1158605149.152025069112817039675217",
        "priceUSD": "1969.054856693696100073460986139882"
    },
    {
        "date": 1653177600,
        "volume": "334267.346915544860924318",
        "open": "1972.342569365083643234170607163543",
        "close": "2044.760701557745356586760234086823",
        "untrackedVolumeUSD": "670811694.3498494362496852538336377",
        "priceUSD": "2044.760701557745356586760234086823"
    },
    {
        "date": 1653091200,
        "volume": "287832.719314006458135134",
        "open": "1961.880627883651179548094397477442",
        "close": "1972.342569365083643234170607163543",
        "untrackedVolumeUSD": "566426600.7090525089196603005709973",
        "priceUSD": "1972.342569365083643234170607163543"
    },
    {
        "date": 1653004800,
        "volume": "579293.62464769910349155",
        "open": "2018.050345617587049303068075056243",
        "close": "1961.880627883651179548094397477442",
        "untrackedVolumeUSD": "1150799309.559062496766949148309718",
        "priceUSD": "1961.880627883651179548094397477442"
    },
    {
        "date": 1652918400,
        "volume": "755634.857751038813606845",
        "open": "1911.360631745224218678852622071227",
        "close": "2018.050345617587049303068075056243",
        "untrackedVolumeUSD": "1486182199.234540980130291771073434",
        "priceUSD": "2018.050345617587049303068075056243"
    },
    {
        "date": 1652832000,
        "volume": "624444.096818717188230067",
        "open": "2092.314039904112127963941060262338",
        "close": "1911.360631745224218678852622071227",
        "untrackedVolumeUSD": "1243992033.021689178084273946221696",
        "priceUSD": "1911.360631745224218678852622071227"
    },
    {
        "date": 1652745600,
        "volume": "498868.305108757289686752",
        "open": "2021.343684052158404024794097284441",
        "close": "2092.314039904112127963941060262338",
        "untrackedVolumeUSD": "1029216304.31887051912733326283312",
        "priceUSD": "2092.314039904112127963941060262338"
    },
    {
        "date": 1652659200,
        "volume": "866774.072352544907846483",
        "open": "2144.792751402377705443483476738151",
        "close": "2021.343684052158404024794097284441",
        "untrackedVolumeUSD": "1755771739.265248880538549464876195",
        "priceUSD": "2021.343684052158404024794097284441"
    },
    {
        "date": 1652572800,
        "volume": "461383.438416373377470934",
        "open": "2057.158163499839315506232147528776",
        "close": "2144.792751402377705443483476738151",
        "untrackedVolumeUSD": "956082046.6960191835114815498830417",
        "priceUSD": "2144.792751402377705443483476738151"
    },
    {
        "date": 1652486400,
        "volume": "595221.878089248037856483",
        "open": "2005.774660512115145241498028528466",
        "close": "2057.158163499839315506232147528776",
        "untrackedVolumeUSD": "1196561825.198271769782481469371611",
        "priceUSD": "2057.158163499839315506232147528776"
    },
    {
        "date": 1652400000,
        "volume": "974775.891249138295539706",
        "open": "1953.789644414178532719605636043812",
        "close": "2005.774660512115145241498028528466",
        "untrackedVolumeUSD": "2014254060.762693227599198254744215",
        "priceUSD": "2005.774660512115145241498028528466"
    },
    {
        "date": 1652313600,
        "volume": "2047092.423604189579602065",
        "open": "2070.234665853771437650029656534907",
        "close": "1953.789644414178532719605636043812",
        "untrackedVolumeUSD": "3925863917.577334487797860223986603",
        "priceUSD": "1953.789644414178532719605636043812"
    },
    {
        "date": 1652227200,
        "volume": "1810619.715262390203495071",
        "open": "2339.547755485952019467904699741646",
        "close": "2070.234665853771437650029656534907",
        "untrackedVolumeUSD": "4059241515.143270494195885982001438",
        "priceUSD": "2070.234665853771437650029656534907"
    },
    {
        "date": 1652140800,
        "volume": "1126497.63709188606333067",
        "open": "2235.563925803024662897992044750549",
        "close": "2339.547755485952019467904699741646",
        "untrackedVolumeUSD": "2647999186.644606977445017055206981",
        "priceUSD": "2339.547755485952019467904699741646"
    },
    {
        "date": 1652054400,
        "volume": "1196595.508200817985703095",
        "open": "2515.343197113886368719176958356036",
        "close": "2235.563925803024662897992044750549",
        "untrackedVolumeUSD": "2822803602.715017246663096610848232",
        "priceUSD": "2235.563925803024662897992044750549"
    },
    {
        "date": 1651968000,
        "volume": "652131.017700646618601207",
        "open": "2640.830696403987228453734251626462",
        "close": "2515.343197113886368719176958356036",
        "untrackedVolumeUSD": "1660919599.949083932100864303192516",
        "priceUSD": "2515.343197113886368719176958356036"
    },
    {
        "date": 1651881600,
        "volume": "334136.185202503668621721",
        "open": "2697.690235607902318588041473667156",
        "close": "2640.830696403987228453734251626462",
        "untrackedVolumeUSD": "889038412.1749972577533451279953507",
        "priceUSD": "2640.830696403987228453734251626462"
    },
    {
        "date": 1651795200,
        "volume": "647586.21660188063271769",
        "open": "2744.241858879705043943434032736319",
        "close": "2697.690235607902318588041473667156",
        "untrackedVolumeUSD": "1742677751.821559750718255497195533",
        "priceUSD": "2697.690235607902318588041473667156"
    },
    {
        "date": 1651708800,
        "volume": "545431.47331822603274519",
        "open": "2935.515434349736634818297426963092",
        "close": "2744.241858879705043943434032736319",
        "untrackedVolumeUSD": "1530465122.251968932335155811263055",
        "priceUSD": "2744.241858879705043943434032736319"
    },
    {
        "date": 1651622400,
        "volume": "499052.956694373337219995",
        "open": "2784.522997182539855619845386954291",
        "close": "2935.515434349736634818297426963092",
        "untrackedVolumeUSD": "1432468240.540198302789545532689567",
        "priceUSD": "2935.515434349736634818297426963092"
    },
    {
        "date": 1651536000,
        "volume": "409453.47939218898114567",
        "open": "2862.875834406408369303478168268906",
        "close": "2784.522997182539855619845386954291",
        "untrackedVolumeUSD": "1154333844.091249304788914748859705",
        "priceUSD": "2784.522997182539855619845386954291"
    },
    {
        "date": 1651449600,
        "volume": "466804.519671420173123421",
        "open": "2828.143246554750951750538597875881",
        "close": "2862.875834406408369303478168268906",
        "untrackedVolumeUSD": "1320092809.96601986159069662983509",
        "priceUSD": "2862.875834406408369303478168268906"
    },
    {
        "date": 1651363200,
        "volume": "499963.56838421793030261",
        "open": "2728.525622975685765135039600317473",
        "close": "2828.143246554750951750538597875881",
        "untrackedVolumeUSD": "1390063048.487829044529623939198452",
        "priceUSD": "2828.143246554750951750538597875881"
    },
    {
        "date": 1651276800,
        "volume": "557200.677294864581446095",
        "open": "2815.173427687771431333908609945474",
        "close": "2728.525622975685765135039600317473",
        "untrackedVolumeUSD": "1561173998.186000424134563168693289",
        "priceUSD": "2728.525622975685765135039600317473"
    },
    {
        "date": 1651190400,
        "volume": "502627.548485143793322585",
        "open": "2936.273015284375969614258259054055",
        "close": "2815.173427687771431333908609945474",
        "untrackedVolumeUSD": "1440645491.922824199918942707271522",
        "priceUSD": "2815.173427687771431333908609945474"
    },
    {
        "date": 1651104000,
        "volume": "485470.260068567803169633",
        "open": "2887.639253020695719259769146898925",
        "close": "2936.273015284375969614258259054055",
        "untrackedVolumeUSD": "1416912994.692993934270668592365826",
        "priceUSD": "2936.273015284375969614258259054055"
    },
    {
        "date": 1651017600,
        "volume": "344347.036846377741653693",
        "open": "2802.431856400751851901091369041568",
        "close": "2887.639253020695719259769146898925",
        "untrackedVolumeUSD": "985702460.4850036891144982446277172",
        "priceUSD": "2887.639253020695719259769146898925"
    },
    {
        "date": 1650931200,
        "volume": "416501.827236064533551946",
        "open": "3013.564543758703935902972612924166",
        "close": "2802.431856400751851901091369041568",
        "untrackedVolumeUSD": "1214970621.175774981309716799178631",
        "priceUSD": "2802.431856400751851901091369041568"
    },
    {
        "date": 1650844800,
        "volume": "431415.883199737074698035",
        "open": "2928.006342753099866991209971068576",
        "close": "3013.564543758703935902972612924166",
        "untrackedVolumeUSD": "1248616763.336975511934917249062259",
        "priceUSD": "3013.564543758703935902972612924166"
    },
    {
        "date": 1650758400,
        "volume": "191134.307378229522641157",
        "open": "2933.005494953831420618098844789785",
        "close": "2928.006342753099866991209971068576",
        "untrackedVolumeUSD": "561784919.982072428618001382088924",
        "priceUSD": "2928.006342753099866991209971068576"
    },
    {
        "date": 1650672000,
        "volume": "179204.358408109178900346",
        "open": "2961.217478673667413274229476517137",
        "close": "2933.005494953831420618098844789785",
        "untrackedVolumeUSD": "529322122.6898326160414742186333834",
        "priceUSD": "2933.005494953831420618098844789785"
    },
    {
        "date": 1650585600,
        "volume": "317422.096437324605971986",
        "open": "2982.342984776153255439783986079156",
        "close": "2961.217478673667413274229476517137",
        "untrackedVolumeUSD": "946710111.1979556348465362154574617",
        "priceUSD": "2961.217478673667413274229476517137"
    },
    {
        "date": 1650499200,
        "volume": "379033.584963036171012376",
        "open": "3081.146371233160338795807931005407",
        "close": "2982.342984776153255439783986079156",
        "untrackedVolumeUSD": "1167614702.494934102357193110582821",
        "priceUSD": "2982.342984776153255439783986079156"
    },
    {
        "date": 1650412800,
        "volume": "392263.225563028795178681",
        "open": "3101.983114454551091249779439575703",
        "close": "3081.146371233160338795807931005407",
        "untrackedVolumeUSD": "1212897753.080502048272697817582023",
        "priceUSD": "3081.146371233160338795807931005407"
    },
    {
        "date": 1650326400,
        "volume": "280073.622827727809266497",
        "open": "3060.0725008635041798635057684909",
        "close": "3101.983114454551091249779439575703",
        "untrackedVolumeUSD": "861624345.6578930037835566940433222",
        "priceUSD": "3101.983114454551091249779439575703"
    },
    {
        "date": 1650240000,
        "volume": "371076.965115495004534206",
        "open": "2990.145903992679574625183056832901",
        "close": "3060.0725008635041798635057684909",
        "untrackedVolumeUSD": "1099023115.614671841668481825090951",
        "priceUSD": "3060.0725008635041798635057684909"
    },
    {
        "date": 1650153600,
        "volume": "305729.615959609594382656",
        "open": "3068.783000161234570870486069658025",
        "close": "2990.145903992679574625183056832901",
        "untrackedVolumeUSD": "933594956.4036414215555955079968422",
        "priceUSD": "2990.145903992679574625183056832901"
    },
    {
        "date": 1650067200,
        "volume": "133243.298108299790217926",
        "open": "3036.916726163472952380191793196601",
        "close": "3068.783000161234570870486069658025",
        "untrackedVolumeUSD": "404850521.6958458366001717782219358",
        "priceUSD": "3068.783000161234570870486069658025"
    },
    {
        "date": 1649980800,
        "volume": "250934.009521458293243118",
        "open": "3018.201699523132896650449574773829",
        "close": "3036.916726163472952380191793196601",
        "untrackedVolumeUSD": "759087949.787958073212937591148101",
        "priceUSD": "3036.916726163472952380191793196601"
    },
    {
        "date": 1649894400,
        "volume": "361662.897593012118358049",
        "open": "3120.232376507404307367946753422443",
        "close": "3018.201699523132896650449574773829",
        "untrackedVolumeUSD": "1107080736.975104620990994282881796",
        "priceUSD": "3018.201699523132896650449574773829"
    },
    {
        "date": 1649808000,
        "volume": "410999.358040550642894972",
        "open": "3026.682929349256505329111295139209",
        "close": "3120.232376507404307367946753422443",
        "untrackedVolumeUSD": "1259142358.874983504199242428733686",
        "priceUSD": "3120.232376507404307367946753422443"
    },
    {
        "date": 1649721600,
        "volume": "535858.213397079256177227",
        "open": "2979.848670681815769322151148589663",
        "close": "3026.682929349256505329111295139209",
        "untrackedVolumeUSD": "1618130085.910103850653219929823442",
        "priceUSD": "3026.682929349256505329111295139209"
    },
    {
        "date": 1649635200,
        "volume": "714917.118703685484858082",
        "open": "3208.981844524235330993410227291659",
        "close": "2979.848670681815769322151148589663",
        "untrackedVolumeUSD": "2189310548.417170965344910081538526",
        "priceUSD": "2979.848670681815769322151148589663"
    },
    {
        "date": 1649548800,
        "volume": "227339.355505216355837425",
        "open": "3259.35749698209151582660908723117",
        "close": "3208.981844524235330993410227291659",
        "untrackedVolumeUSD": "740428241.4965466151752633627185679",
        "priceUSD": "3208.981844524235330993410227291659"
    },
    {
        "date": 1649462400,
        "volume": "241621.191344716658432579",
        "open": "3185.643106811893855806789583435922",
        "close": "3259.35749698209151582660908723117",
        "untrackedVolumeUSD": "778009974.1875562950884189008408789",
        "priceUSD": "3259.35749698209151582660908723117"
    },
    {
        "date": 1649376000,
        "volume": "465201.570876320564680209",
        "open": "3237.428663741225343007761456979365",
        "close": "3185.643106811893855806789583435922",
        "untrackedVolumeUSD": "1512394371.048201690072232588720939",
        "priceUSD": "3185.643106811893855806789583435922"
    },
    {
        "date": 1649289600,
        "volume": "425889.048752485221134706",
        "open": "3172.666817228957711183855227334003",
        "close": "3237.428663741225343007761456979365",
        "untrackedVolumeUSD": "1366844713.816157015119128187772444",
        "priceUSD": "3237.428663741225343007761456979365"
    },
    {
        "date": 1649203200,
        "volume": "631240.150457632465040407",
        "open": "3411.877198597870209674189629191154",
        "close": "3172.666817228957711183855227334003",
        "untrackedVolumeUSD": "2066330889.799517206274336354473798",
        "priceUSD": "3172.666817228957711183855227334003"
    },
    {
        "date": 1649116800,
        "volume": "360277.312130489387082195",
        "open": "3528.165280779663124119721154787331",
        "close": "3411.877198597870209674189629191154",
        "untrackedVolumeUSD": "1253269515.456177356286005834312682",
        "priceUSD": "3411.877198597870209674189629191154"
    },
    {
        "date": 1649030400,
        "volume": "365579.299712111709038585",
        "open": "3524.664730203202504368075079900737",
        "close": "3528.165280779663124119721154787331",
        "untrackedVolumeUSD": "1270760547.163757111140762488325949",
        "priceUSD": "3528.165280779663124119721154787331"
    },
    {
        "date": 1648944000,
        "volume": "280403.728224369432660858",
        "open": "3445.045572264009507113587665156184",
        "close": "3524.664730203202504368075079900737",
        "untrackedVolumeUSD": "980216733.5862208761228600453379658",
        "priceUSD": "3524.664730203202504368075079900737"
    },
    {
        "date": 1648857600,
        "volume": "375177.175689883437548937",
        "open": "3447.155669041068518573083419105755",
        "close": "3445.045572264009507113587665156184",
        "untrackedVolumeUSD": "1304878854.513916393569353814407138",
        "priceUSD": "3445.045572264009507113587665156184"
    },
    {
        "date": 1648771200,
        "volume": "418068.717718147148464234",
        "open": "3277.140096066202788489301953084423",
        "close": "3447.155669041068518573083419105755",
        "untrackedVolumeUSD": "1400207849.797196166713458619232634",
        "priceUSD": "3447.155669041068518573083419105755"
    },
    {
        "date": 1648684800,
        "volume": "401991.795109406380389454",
        "open": "3386.608562803339755408618971076469",
        "close": "3277.140096066202788489301953084423",
        "untrackedVolumeUSD": "1349148328.873750251289630891703529",
        "priceUSD": "3277.140096066202788489301953084423"
    },
    {
        "date": 1648598400,
        "volume": "386490.450247303487475632",
        "open": "3395.962357861953705773556811743904",
        "close": "3386.608562803339755408618971076469",
        "untrackedVolumeUSD": "1309472512.138957229324538614102881",
        "priceUSD": "3386.608562803339755408618971076469"
    },
    {
        "date": 1648512000,
        "volume": "435276.413175850951400074",
        "open": "3330.786394804607736479395076039",
        "close": "3395.962357861953705773556811743904",
        "untrackedVolumeUSD": "1483026464.530839233987020417452562",
        "priceUSD": "3395.962357861953705773556811743904"
    },
    {
        "date": 1648425600,
        "volume": "443329.141355185236875152",
        "open": "3293.075573456462995037291786885697",
        "close": "3330.786394804607736479395076039",
        "untrackedVolumeUSD": "1487821205.391166940754822852431195",
        "priceUSD": "3330.786394804607736479395076039"
    },
    {
        "date": 1648339200,
        "volume": "301848.176056405343975963",
        "open": "3140.723843347491459863983761030313",
        "close": "3293.075573456462995037291786885697",
        "untrackedVolumeUSD": "965185375.3882807014487463803881026",
        "priceUSD": "3293.075573456462995037291786885697"
    },
    {
        "date": 1648252800,
        "volume": "223360.642196814001621161",
        "open": "3104.149298394866397256792816384793",
        "close": "3140.723843347491459863983761030313",
        "untrackedVolumeUSD": "697893393.0857783133432700452431047",
        "priceUSD": "3140.723843347491459863983761030313"
    },
    {
        "date": 1648166400,
        "volume": "438246.510722854781577187",
        "open": "3111.93905839201140241167270174205",
        "close": "3104.149298394866397256792816384793",
        "untrackedVolumeUSD": "1370129618.732652519777406177113509",
        "priceUSD": "3104.149298394866397256792816384793"
    },
    {
        "date": 1648080000,
        "volume": "498924.984978939816310283",
        "open": "3029.918377061716976976103766556077",
        "close": "3111.93905839201140241167270174205",
        "untrackedVolumeUSD": "1525703155.465464861165380247258255",
        "priceUSD": "3111.93905839201140241167270174205"
    },
    {
        "date": 1647993600,
        "volume": "358635.329980369978567934",
        "open": "2968.2987135512962394280317351238",
        "close": "3029.918377061716976976103766556077",
        "untrackedVolumeUSD": "1066519621.644816724526334329363139",
        "priceUSD": "3029.918377061716976976103766556077"
    },
    {
        "date": 1647907200,
        "volume": "425851.699391282438917918",
        "open": "2894.663185913433645846677507786548",
        "close": "2968.2987135512962394280317351238",
        "untrackedVolumeUSD": "1274486103.064331102729885555253726",
        "priceUSD": "2968.2987135512962394280317351238"
    },
    {
        "date": 1647820800,
        "volume": "371401.231197439628478943",
        "open": "2860.263226237375532467916691703145",
        "close": "2894.663185913433645846677507786548",
        "untrackedVolumeUSD": "1075932557.324970179078080400430872",
        "priceUSD": "2894.663185913433645846677507786548"
    },
    {
        "date": 1647734400,
        "volume": "376868.46621136969874539",
        "open": "2946.509686617982069411403312659486",
        "close": "2860.263226237375532467916691703145",
        "untrackedVolumeUSD": "1088703565.619634357686514221230624",
        "priceUSD": "2860.263226237375532467916691703145"
    },
    {
        "date": 1647648000,
        "volume": "355091.419191433614627445",
        "open": "2943.785206073554932737923491607611",
        "close": "2946.509686617982069411403312659486",
        "untrackedVolumeUSD": "1048115055.49438365250805078260125",
        "priceUSD": "2946.509686617982069411403312659486"
    },
    {
        "date": 1647561600,
        "volume": "607973.39359605841671914",
        "open": "2813.539844583848429501381123101662",
        "close": "2943.785206073554932737923491607611",
        "untrackedVolumeUSD": "1732276391.764112657462709416978924",
        "priceUSD": "2943.785206073554932737923491607611"
    },
    {
        "date": 1647475200,
        "volume": "470069.847100381527111603",
        "open": "2767.959695463351434762423021946504",
        "close": "2813.539844583848429501381123101662",
        "untrackedVolumeUSD": "1314551804.885408710315347947983557",
        "priceUSD": "2813.539844583848429501381123101662"
    },
    {
        "date": 1647388800,
        "volume": "550003.127986007355585147",
        "open": "2622.152674265079995823280928528587",
        "close": "2767.959695463351434762423021946504",
        "untrackedVolumeUSD": "1479096479.315176965505145479832021",
        "priceUSD": "2767.959695463351434762423021946504"
    },
    {
        "date": 1647302400,
        "volume": "355079.899278626791986399",
        "open": "2588.780171791404552599492511121159",
        "close": "2622.152674265079995823280928528587",
        "untrackedVolumeUSD": "914661068.5892401692292422063362085",
        "priceUSD": "2622.152674265079995823280928528587"
    },
    {
        "date": 1647216000,
        "volume": "349078.943619030274953227",
        "open": "2515.105408317839259710042047173459",
        "close": "2588.780171791404552599492511121159",
        "untrackedVolumeUSD": "894155066.4763688046038910267359025",
        "priceUSD": "2588.780171791404552599492511121159"
    },
    {
        "date": 1647129600,
        "volume": "294455.204659916752311283",
        "open": "2570.473528517129711134776200066319",
        "close": "2515.105408317839259710042047173459",
        "untrackedVolumeUSD": "753425954.4930314232843972518927989",
        "priceUSD": "2515.105408317839259710042047173459"
    },
    {
        "date": 1647043200,
        "volume": "181868.224603956480349046",
        "open": "2564.698684654762022336282515609955",
        "close": "2570.473528517129711134776200066319",
        "untrackedVolumeUSD": "470015592.1806420743743445676907086",
        "priceUSD": "2570.473528517129711134776200066319"
    },
    {
        "date": 1646956800,
        "volume": "405781.504515801754750572",
        "open": "2613.290832558950638551254420027394",
        "close": "2564.698684654762022336282515609955",
        "untrackedVolumeUSD": "1049542734.264395125228401864214676",
        "priceUSD": "2564.698684654762022336282515609955"
    },
    {
        "date": 1646870400,
        "volume": "400495.332347751113606753",
        "open": "2727.207341567493896887424258594823",
        "close": "2613.290832558950638551254420027394",
        "untrackedVolumeUSD": "1046620669.287613119835979639093813",
        "priceUSD": "2613.290832558950638551254420027394"
    },
    {
        "date": 1646784000,
        "volume": "430292.515873682748958123",
        "open": "2577.970323911077352355802102191497",
        "close": "2727.207341567493896887424258594823",
        "untrackedVolumeUSD": "1165320762.653614883738476057591359",
        "priceUSD": "2727.207341567493896887424258594823"
    },
    {
        "date": 1646697600,
        "volume": "515665.185385873288752271",
        "open": "2489.847915434112324885780249176389",
        "close": "2577.970323911077352355802102191497",
        "untrackedVolumeUSD": "1320599026.294231464730152658046885",
        "priceUSD": "2577.970323911077352355802102191497"
    },
    {
        "date": 1646611200,
        "volume": "498364.355824869628386679",
        "open": "2556.033267477849485663453841105975",
        "close": "2489.847915434112324885780249176389",
        "untrackedVolumeUSD": "1265287481.696019540822445246712072",
        "priceUSD": "2489.847915434112324885780249176389"
    },
    {
        "date": 1646524800,
        "volume": "310458.083928884044366064",
        "open": "2663.18084514143631155522995117725",
        "close": "2556.033267477849485663453841105975",
        "untrackedVolumeUSD": "813395992.839380656995938996054391",
        "priceUSD": "2556.033267477849485663453841105975"
    },
    {
        "date": 1646438400,
        "volume": "250148.769555757838030017",
        "open": "2616.815553456486659929043613748662",
        "close": "2663.18084514143631155522995117725",
        "untrackedVolumeUSD": "660549780.1146375906029779937696737",
        "priceUSD": "2663.18084514143631155522995117725"
    },
    {
        "date": 1646352000,
        "volume": "547125.807009355010358869",
        "open": "2840.029379320861593487066333871337",
        "close": "2616.815553456486659929043613748662",
        "untrackedVolumeUSD": "1474005093.568629981824792892697323",
        "priceUSD": "2616.815553456486659929043613748662"
    },
    {
        "date": 1646265600,
        "volume": "359904.51226049384381854",
        "open": "2955.832277360311117226870002449169",
        "close": "2840.029379320861593487066333871337",
        "untrackedVolumeUSD": "1033534418.000931643323277019172239",
        "priceUSD": "2840.029379320861593487066333871337"
    },
    {
        "date": 1646179200,
        "volume": "559325.652358638367564174",
        "open": "2972.270397420131483917476524986784",
        "close": "2955.832277360311117226870002449169",
        "untrackedVolumeUSD": "1664328689.36226808686337321591244",
        "priceUSD": "2955.832277360311117226870002449169"
    },
    {
        "date": 1646092800,
        "volume": "548147.880090724020746839",
        "open": "2916.7505798919255364682248667028",
        "close": "2972.270397420131483917476524986784",
        "untrackedVolumeUSD": "1615130118.479590725605957754276889",
        "priceUSD": "2972.270397420131483917476524986784"
    },
    {
        "date": 1646006400,
        "volume": "634907.95039183007534396",
        "open": "2622.489799304209533427233092850758",
        "close": "2916.7505798919255364682248667028",
        "untrackedVolumeUSD": "1729754489.391314182064882051175481",
        "priceUSD": "2916.7505798919255364682248667028"
    },
    {
        "date": 1645920000,
        "volume": "637065.482698509801646309",
        "open": "2775.250507240461211124910915273258",
        "close": "2622.489799304209533427233092850758",
        "untrackedVolumeUSD": "1722673038.604189455700027583512681",
        "priceUSD": "2622.489799304209533427233092850758"
    },
    {
        "date": 1645833600,
        "volume": "373469.508965868043335615",
        "open": "2761.37952204450346186385950820778",
        "close": "2775.250507240461211124910915273258",
        "untrackedVolumeUSD": "1042450293.723810485088063768322684",
        "priceUSD": "2775.250507240461211124910915273258"
    },
    {
        "date": 1645747200,
        "volume": "646974.187159037304822928",
        "open": "2595.351657219327091771300841633766",
        "close": "2761.37952204450346186385950820778",
        "untrackedVolumeUSD": "1734281105.696387660818319330511331",
        "priceUSD": "2761.37952204450346186385950820778"
    },
    {
        "date": 1645660800,
        "volume": "1133026.034205105591968828",
        "open": "2587.828742231766456648951432357425",
        "close": "2595.351657219327091771300841633766",
        "untrackedVolumeUSD": "2779886857.316578783284648077948592",
        "priceUSD": "2595.351657219327091771300841633766"
    },
    {
        "date": 1645574400,
        "volume": "474453.284637849446085958",
        "open": "2644.124912409623748914449755797499",
        "close": "2587.828742231766456648951432357425",
        "untrackedVolumeUSD": "1267235009.58352266565768947753837",
        "priceUSD": "2587.828742231766456648951432357425"
    },
    {
        "date": 1645488000,
        "volume": "564697.157739458610664685",
        "open": "2574.482817800470313255968794109513",
        "close": "2644.124912409623748914449755797499",
        "untrackedVolumeUSD": "1458034769.3329371309904021221782",
        "priceUSD": "2644.124912409623748914449755797499"
    },
    {
        "date": 1645401600,
        "volume": "761602.919988216695055331",
        "open": "2629.096130119969849998769917028346",
        "close": "2574.482817800470313255968794109513",
        "untrackedVolumeUSD": "2027534330.798335091934683032427009",
        "priceUSD": "2574.482817800470313255968794109513"
    },
    {
        "date": 1645315200,
        "volume": "531371.068294372857981522",
        "open": "2759.077127933076725636409675655944",
        "close": "2629.096130119969849998769917028346",
        "untrackedVolumeUSD": "1409094553.308573569851260047922889",
        "priceUSD": "2629.096130119969849998769917028346"
    },
    {
        "date": 1645228800,
        "volume": "289613.219691988836147996",
        "open": "2784.20443623072378699948844655156",
        "close": "2759.077127933076725636409675655944",
        "untrackedVolumeUSD": "800674403.7456402303536068445156123",
        "priceUSD": "2759.077127933076725636409675655944"
    },
    {
        "date": 1645142400,
        "volume": "452746.069508561747122844",
        "open": "2888.429215941195134269828090820699",
        "close": "2784.20443623072378699948844655156",
        "untrackedVolumeUSD": "1287855646.979036602068704667580866",
        "priceUSD": "2784.20443623072378699948844655156"
    },
    {
        "date": 1645056000,
        "volume": "552452.983077258346794142",
        "open": "3129.969803351954434380180805491188",
        "close": "2888.429215941195134269828090820699",
        "untrackedVolumeUSD": "1660192824.018311355594389962793198",
        "priceUSD": "2888.429215941195134269828090820699"
    },
    {
        "date": 1644969600,
        "volume": "395919.799474450582347526",
        "open": "3185.351271203341440258484068524487",
        "close": "3129.969803351954434380180805491188",
        "untrackedVolumeUSD": "1236478349.344764019500070378678412",
        "priceUSD": "3129.969803351954434380180805491188"
    },
    {
        "date": 1644883200,
        "volume": "416968.611202596848657942",
        "open": "2937.031315564994830595135330745894",
        "close": "3185.351271203341440258484068524487",
        "untrackedVolumeUSD": "1283905446.059299975666649085232748",
        "priceUSD": "3185.351271203341440258484068524487"
    },
    {
        "date": 1644796800,
        "volume": "349877.916988567713286667",
        "open": "2878.592118093305314177583541190916",
        "close": "2937.031315564994830595135330745894",
        "untrackedVolumeUSD": "1012899104.2815287537058405047323",
        "priceUSD": "2937.031315564994830595135330745894"
    },
    {
        "date": 1644710400,
        "volume": "271855.845385947592379321",
        "open": "2912.492179286921250539654882563539",
        "close": "2878.592118093305314177583541190916",
        "untrackedVolumeUSD": "788705666.5113525494448947301164003",
        "priceUSD": "2878.592118093305314177583541190916"
    },
    {
        "date": 1644624000,
        "volume": "401146.245477813651982944",
        "open": "2928.791245188908395378016530787563",
        "close": "2912.492179286921250539654882563539",
        "untrackedVolumeUSD": "1169984667.784325806412824670056675",
        "priceUSD": "2912.492179286921250539654882563539"
    },
    {
        "date": 1644537600,
        "volume": "618872.274112267347187655",
        "open": "3072.084600324768449854405414828125",
        "close": "2928.791245188908395378016530787563",
        "untrackedVolumeUSD": "1874182513.555818023959101657354044",
        "priceUSD": "2928.791245188908395378016530787563"
    },
    {
        "date": 1644451200,
        "volume": "580344.9118939395530874",
        "open": "3246.333466222840399156260482720824",
        "close": "3072.084600324768449854405414828125",
        "untrackedVolumeUSD": "1846057416.20472778914848638350309",
        "priceUSD": "3072.084600324768449854405414828125"
    },
    {
        "date": 1644364800,
        "volume": "385144.566867621810696204",
        "open": "3127.380169623527896132256186681744",
        "close": "3246.333466222840399156260482720824",
        "untrackedVolumeUSD": "1218669926.070806633445318287778726",
        "priceUSD": "3246.333466222840399156260482720824"
    },
    {
        "date": 1644278400,
        "volume": "425296.487525052902966496",
        "open": "3148.45593597808440355834281390826",
        "close": "3127.380169623527896132256186681744",
        "untrackedVolumeUSD": "1322692103.72889704521079016602475",
        "priceUSD": "3127.380169623527896132256186681744"
    },
    {
        "date": 1644192000,
        "volume": "424831.847441855978824108",
        "open": "3064.326674763549548519864747529854",
        "close": "3148.45593597808440355834281390826",
        "untrackedVolumeUSD": "1317588852.703927445063714792310695",
        "priceUSD": "3148.45593597808440355834281390826"
    },
    {
        "date": 1644105600,
        "volume": "324018.672522302462126835",
        "open": "3019.886213788377823124062780498369",
        "close": "3064.326674763549548519864747529854",
        "untrackedVolumeUSD": "974443783.2582754608606416553777292",
        "priceUSD": "3064.326674763549548519864747529854"
    },
    {
        "date": 1644019200,
        "volume": "412211.956464544057979921",
        "open": "2987.221842328757866488340711229065",
        "close": "3019.886213788377823124062780498369",
        "untrackedVolumeUSD": "1241116458.746202868965269160688463",
        "priceUSD": "3019.886213788377823124062780498369"
    },
    {
        "date": 1643932800,
        "volume": "454334.19520301698756627",
        "open": "2690.028924725841543791486556772678",
        "close": "2987.221842328757866488340711229065",
        "untrackedVolumeUSD": "1289350647.438537872570704638705886",
        "priceUSD": "2987.221842328757866488340711229065"
    },
    {
        "date": 1643846400,
        "volume": "471539.312874116808916968",
        "open": "2681.56882216756074030142660998642",
        "close": "2690.028924725841543791486556772678",
        "untrackedVolumeUSD": "1244736912.815237379524225030225216",
        "priceUSD": "2690.028924725841543791486556772678"
    },
    {
        "date": 1643760000,
        "volume": "495991.22506251516841796",
        "open": "2794.533997356414254035690832814039",
        "close": "2681.56882216756074030142660998642",
        "untrackedVolumeUSD": "1345789018.777694077425298367065303",
        "priceUSD": "2681.56882216756074030142660998642"
    },
    {
        "date": 1643673600,
        "volume": "507038.314167189898100278",
        "open": "2687.372157454166201573759634784917",
        "close": "2794.533997356414254035690832814039",
        "untrackedVolumeUSD": "1399367782.684376996747385105100529",
        "priceUSD": "2794.533997356414254035690832814039"
    },
    {
        "date": 1643587200,
        "volume": "416065.75542280170015403",
        "open": "2608.526028717395957904271825483711",
        "close": "2687.372157454166201573759634784917",
        "untrackedVolumeUSD": "1077744546.706666588592326565112109",
        "priceUSD": "2687.372157454166201573759634784917"
    },
    {
        "date": 1643500800,
        "volume": "320361.716539300863383484",
        "open": "2595.636297141541721293566271196155",
        "close": "2608.526028717395957904271825483711",
        "untrackedVolumeUSD": "831240134.9727688035422491599206874",
        "priceUSD": "2608.526028717395957904271825483711"
    },
    {
        "date": 1643414400,
        "volume": "366516.967670989939168702",
        "open": "2549.505558228361314337981678804387",
        "close": "2595.636297141541721293566271196155",
        "untrackedVolumeUSD": "941121732.0768932578227053468865665",
        "priceUSD": "2595.636297141541721293566271196155"
    },
    {
        "date": 1643328000,
        "volume": "529252.842106222726450586",
        "open": "2424.998078022262407339338673084972",
        "close": "2549.505558228361314337981678804387",
        "untrackedVolumeUSD": "1292923650.315540384682673012196842",
        "priceUSD": "2549.505558228361314337981678804387"
    },
    {
        "date": 1643241600,
        "volume": "693812.242236924898914602",
        "open": "2465.432611647602732482682611124581",
        "close": "2424.998078022262407339338673084972",
        "untrackedVolumeUSD": "1675053153.933388474715364813574314",
        "priceUSD": "2424.998078022262407339338673084972"
    },
    {
        "date": 1643155200,
        "volume": "863351.738250873199581029",
        "open": "2459.062186559680821917056020428261",
        "close": "2465.432611647602732482682611124581",
        "untrackedVolumeUSD": "2190293573.883969050480781234592806",
        "priceUSD": "2465.432611647602732482682611124581"
    },
    {
        "date": 1643068800,
        "volume": "581007.525163276599155051",
        "open": "2442.241230000005762647467171251516",
        "close": "2459.062186559680821917056020428261",
        "untrackedVolumeUSD": "1410111952.361489697342432876131255",
        "priceUSD": "2459.062186559680821917056020428261"
    },
    {
        "date": 1642982400,
        "volume": "1104476.740635651444950788",
        "open": "2535.609678016283630655027479286846",
        "close": "2442.241230000005762647467171251516",
        "untrackedVolumeUSD": "2574711724.02379729865510154542587",
        "priceUSD": "2442.241230000005762647467171251516"
    },
    {
        "date": 1642896000,
        "volume": "816807.090631467236292069",
        "open": "2405.486825000000088194137428103443",
        "close": "2535.609678016283630655027479286846",
        "untrackedVolumeUSD": "2002500696.393270290761078471853772",
        "priceUSD": "2535.609678016283630655027479286846"
    },
    {
        "date": 1642809600,
        "volume": "859755.239402443250816882",
        "open": "2564.689095817281368298653982518085",
        "close": "2405.486825000000088194137428103443",
        "untrackedVolumeUSD": "2110609488.467047396805856442129591",
        "priceUSD": "2405.486825000000088194137428103443"
    },
    {
        "date": 1642723200,
        "volume": "1298566.056821474952547117",
        "open": "2996.131973409121370896887972561605",
        "close": "2564.689095817281368298653982518085",
        "untrackedVolumeUSD": "3596228283.907906200343876502886424",
        "priceUSD": "2564.689095817281368298653982518085"
    },
    {
        "date": 1642636800,
        "volume": "503886.042877193548377207",
        "open": "3096.402491795033797036478381245349",
        "close": "2996.131973409121370896887972561605",
        "untrackedVolumeUSD": "1583827419.424344579140394182982789",
        "priceUSD": "2996.131973409121370896887972561605"
    },
    {
        "date": 1642550400,
        "volume": "468308.603180715512299723",
        "open": "3164.960723171603522742465355533807",
        "close": "3096.402491795033797036478381245349",
        "untrackedVolumeUSD": "1458164457.804994861773945659566991",
        "priceUSD": "3096.402491795033797036478381245349"
    },
    {
        "date": 1642464000,
        "volume": "451050.61733799779456638",
        "open": "3211.195206331828031012668744270801",
        "close": "3164.960723171603522742465355533807",
        "untrackedVolumeUSD": "1420797607.193978862688498419048967",
        "priceUSD": "3164.960723171603522742465355533807"
    },
    {
        "date": 1642377600,
        "volume": "411455.720609775995378379",
        "open": "3351.79407641250356243179972297519",
        "close": "3211.195206331828031012668744270801",
        "untrackedVolumeUSD": "1336463005.212177823578211136935816",
        "priceUSD": "3211.195206331828031012668744270801"
    },
    {
        "date": 1642291200,
        "volume": "271607.004762840588607779",
        "open": "3338.641789379621900096269786061141",
        "close": "3351.79407641250356243179972297519",
        "untrackedVolumeUSD": "903534873.1995494274261112073369842",
        "priceUSD": "3351.79407641250356243179972297519"
    },
    {
        "date": 1642204800,
        "volume": "236613.434799306931627525",
        "open": "3304.655535926242303117927830600888",
        "close": "3338.641789379621900096269786061141",
        "untrackedVolumeUSD": "786682624.3608631196351021944167799",
        "priceUSD": "3338.641789379621900096269786061141"
    },
    {
        "date": 1642118400,
        "volume": "373303.759475234084004933",
        "open": "3245.926238913708488345082059898148",
        "close": "3304.655535926242303117927830600888",
        "untrackedVolumeUSD": "1219849178.718302702559196088826122",
        "priceUSD": "3304.655535926242303117927830600888"
    },
    {
        "date": 1642032000,
        "volume": "488515.893587689110957474",
        "open": "3368.733658955185568221298694794474",
        "close": "3245.926238913708488345082059898148",
        "untrackedVolumeUSD": "1621746260.057021825012929401269629",
        "priceUSD": "3245.926238913708488345082059898148"
    },
    {
        "date": 1641945600,
        "volume": "405561.674646488792299006",
        "open": "3238.311303199554208057982956131201",
        "close": "3368.733658955185568221298694794474",
        "untrackedVolumeUSD": "1343152277.054028753671507128653024",
        "priceUSD": "3368.733658955185568221298694794474"
    },
    {
        "date": 1641859200,
        "volume": "391965.347528138580738906",
        "open": "3077.106990411685018316741052180198",
        "close": "3238.311303199554208057982956131201",
        "untrackedVolumeUSD": "1237183162.173823213251797082368676",
        "priceUSD": "3238.311303199554208057982956131201"
    },
    {
        "date": 1641772800,
        "volume": "640154.68554275881633129",
        "open": "3150.99256164635649541236995026324",
        "close": "3077.106990411685018316741052180198",
        "untrackedVolumeUSD": "1956976343.102262660741140994719374",
        "priceUSD": "3077.106990411685018316741052180198"
    },
    {
        "date": 1641686400,
        "volume": "301159.000009014078490597",
        "open": "3091.16723860158903471117039955854",
        "close": "3150.99256164635649541236995026324",
        "untrackedVolumeUSD": "943302649.971517693357491946428406",
        "priceUSD": "3150.99256164635649541236995026324"
    },
    {
        "date": 1641600000,
        "volume": "491225.593684858092051266",
        "open": "3184.630951882621236193723277782167",
        "close": "3091.16723860158903471117039955854",
        "untrackedVolumeUSD": "1531347417.256690979647885145971292",
        "priceUSD": "3091.16723860158903471117039955854"
    },
    {
        "date": 1641513600,
        "volume": "579026.25523415521968926",
        "open": "3411.771705988366633586024880558311",
        "close": "3184.630951882621236193723277782167",
        "untrackedVolumeUSD": "1858110379.042675220531960698013501",
        "priceUSD": "3184.630951882621236193723277782167"
    },
    {
        "date": 1641427200,
        "volume": "427363.445958767366784351",
        "open": "3547.345039965550412347209391906607",
        "close": "3411.771705988366633586024880558311",
        "untrackedVolumeUSD": "1460526425.751934224830144280302354",
        "priceUSD": "3411.771705988366633586024880558311"
    },
    {
        "date": 1641340800,
        "volume": "448685.193736322079805474",
        "open": "3791.943802133068085847849097353705",
        "close": "3547.345039965550412347209391906607",
        "untrackedVolumeUSD": "1651894788.121812301494162532608283",
        "priceUSD": "3547.345039965550412347209391906607"
    }
]


const defaultMarketScenarioConfigs = [
    {
        "type": "rangebound",
        "color": "#000000",
        "shape": "sinusoidal",
        "title": "Rangebound",
        "selected": true,
        "maxVolatility": 50,
        "minVolatility": 0.5,
        "marketDriftMode": "ranging",
        "volatilityMultiplier": 10
    },
    {
        "type": "downtrend",
        "color": "#EF8F3B",
        "drift": [
            -1.8,
            -1.6
        ],
        "shape": "linear",
        "title": "Downtrend",
        "maxDrift": -1,
        "minDrift": -3,
        "selected": true,
        "maxVolatility": 1,
        "minVolatility": 0.01,
        "marketDriftMode": "linear",
        "volatilityMultiplier": 0.2
    },
    {
        "type": "uptrend",
        "color": "#4D5B9E",
        "drift": [
            1,
            1.1
        ],
        "shape": "linear",
        "title": "Uptrend",
        "maxDrift": 2,
        "minDrift": 0.5,
        "selected": true,
        "maxVolatility": 1,
        "minVolatility": 0.01,
        "marketDriftMode": "linear",
        "volatilityMultiplier": 0.2
    }
]
