import Box from "@mui/material/Box";
import * as React from "react";
import {PageContentContainer} from "../styled/styled";
import {useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {openAlmanakDocs} from "../../components/layout/Sidebar";
import {useAppState} from "../../state/AppStateProvider";
import {useTheme} from "@mui/material";
import { PlayCircleOutline, Computer, Settings, Nature, QuestionMark, People, Assessment } from '@mui/icons-material';

export const Dashboard = () => {

const {setSnackBar} = useAppState();
    const [isDocsTokenGenerating, setIsDocsTokenGenerating] = useState(false);

    const openDocs = async (subUrl: string) => {
        setIsDocsTokenGenerating(true);
        try {
            await openAlmanakDocs(subUrl);
        } catch (err: any) {
            setSnackBar({open: true, message: "Could not open docs, please contact support", severity: "error"});
        } finally {
            setIsDocsTokenGenerating(false);
        }
    }

    const panels = [
        { title: 'Introduction', icon: <QuestionMark />, url: "/docs"},
        { title: 'Getting Started', icon: <PlayCircleOutline />, url: "/docs/getting-started"},
        { title: 'Configuration', icon: <Settings />, url: "docs/configuration"},
        { title: 'Simulator', icon: <Computer />, url: "docs/simulator"},
        { title: 'Agent Based Modeling', icon: <People />, url: "docs/abm"},
        { title: 'Results', icon: <Assessment />, url: "guides/results"},
    ];

    const PanelList = ({ isDocsTokenGenerating, openDocs }: any) => {
        const theme = useTheme();

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 2,
                    width: '100%',
                }}
            >
                {panels.map((panel, index) => (
                    <LoadingButton key={panel.title} sx={{width: "320px", px: 4, py: 2}} variant={"outlined"} loading={isDocsTokenGenerating} onClick={() => openDocs(panel.url)} startIcon={panel.icon}>
                        <span className={"text-bold"} style={{marginLeft: "8px", fontSize: "16px"}}>{panel.title}</span>
                    </LoadingButton>))
                }

            </Box>
        );
    };


    return <PageContentContainer>

        <Box sx={{
            marginTop: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap"
        }}>
            <Box sx={{my: 2, display: "flex", gap: 1, flexDirection: "column", maxWidth: "660px"}}>
                <span style={{
                    fontSize: "32px",
                    fontWeight: "700",
                    marginBottom: "32px"
                }}>Welcome to The Multiverse</span>
                <span className={"text-bold"}>
                    The end-to-end platform for managing autonomous financial agents in crypto
                </span>
                <span>
                    Almanak Multiverse is a platform for creating, testing, optimizing, deploying and monitoring financial agents in crypto. Trade, generate yield and manage risk using your private army of financially intelligent agents.
                </span>

                <span style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    marginTop: "32px"
                }}>New to Multiverse?</span>

                <span style={{marginBottom: "16px"}}>
                    Start with the Getting Started guide to learn how to create your first simulation.
                </span>

                <PanelList isDocsTokenGenerating={isDocsTokenGenerating} openDocs={openDocs}/>

            </Box>
        </Box>
    </PageContentContainer>

}
