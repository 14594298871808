import {Alert, Button, LinearProgress} from "@mui/material";
import Box from "@mui/material/Box";
import {GroupSimulation} from "../../../api/hasura";
import {useAppState} from "../../../state/AppStateProvider";
import {useNavigate} from "react-router-dom";


export const SimulationProgressView = ({simulation}: { simulation: GroupSimulation}) => {
    const {user} = useAppState();
    const navigate = useNavigate();
    return <Box sx={{display: "flex", flexDirection: "column", py: 2, gap: 2}}>
        {!user?.notification_email ? <Alert
                action={<Button size="small" onClick={() => navigate("/profile")} sx={{whiteSpace: "nowrap"}} variant={"contained"}>Update
                    e-mail</Button>}
                 severity={"warning"}>There is no notifications e-mail set for your account. To receive an e-mail when this simulation finishes, please add one in your profile.</Alert> :
        <Alert action={<Button size="small" onClick={() => navigate("/profile")} sx={{whiteSpace: "nowrap"}} variant={"contained"}>Update e-mail</Button>} severity={"info"}>You will receive an e-mail to {user.notification_email} when this simulation finishes</Alert>}
        <span style={{fontWeight: "bold", padding: "4px 0"}}>Running simulation {simulation.id} </span>
        {/*<span style={{fontWeight: "bold", padding: "4px 0 16px"}}>Completion: {progress.toFixed(0)}% </span>*/}
        <LinearProgress sx={{height: 16, borderRadius: "4px"}} />
    </Box>
}
