import {LibraryArtifact, LibraryArtifactVersion} from "../../widgets/StrategyLibraryWidget";
import Box from "@mui/material/Box";
import React from "react";
import {CenteredRowFlexBox, PanelBox} from "../simulation/create-simulation/styled";
import {formatDateValue} from "../simulation/create-simulation/PoolDetails";
import Chip from "@mui/material/Chip";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";


export const ArtifactVersionItem = ({artifact, artifactVersion, isDetails}: {artifact: LibraryArtifact, artifactVersion: LibraryArtifactVersion, isDetails?: boolean }) => {

    const navigate = useNavigate();


    const openArtifactDetails = () => {
        //console.log("item", artifactVersion);
        navigate(`/strategy-library/${artifact.id}`)
    };

    return <Box>
        <PanelBox sx={{p: 3}}>
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <span className={"title"}>{artifactVersion.name}</span>

                <span>{formatDateValue(artifactVersion.date_created)}</span>
            </Box>

            <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Description</div>
                <span style={{fontSize: "14px"}}>{artifactVersion.description?.length > 0 ? artifactVersion.description : "N/A"}</span>
            </Box>


            <CenteredRowFlexBox sx={{gap: "16px"}}>

                {artifactVersion.metadata.input_features && <Box>
                    <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Input Features</div>
                    <span style={{fontSize: "14px"}}>{artifactVersion.metadata.input_features}</span>
                </Box>}


                {artifactVersion.metadata.output_structure && <Box>
                    <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Output Structure</div>
                    <span style={{fontSize: "14px"}}>{artifactVersion.metadata.output_structure}</span>
                </Box>}

            </CenteredRowFlexBox>


            {artifactVersion.author_user && <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Author</div>
                <CenteredRowFlexBox sx={{gap: "16px"}}>
                    {artifactVersion.author_user.name && <span style={{fontSize: "14px"}}>{artifactVersion.author_user.name}</span>}
                    {artifactVersion.author_user.email && <span style={{fontSize: "14px"}}>{artifactVersion.author_user.email}</span>}
                </CenteredRowFlexBox>
            </Box>}

            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                <Box>
                    {artifactVersion.metadata?.tags && <Box>
                        <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Tags</div>
                        <CenteredRowFlexBox sx={{gap: "8px"}}>
                            {artifactVersion.metadata?.tags.map((tag: string) => <Chip label={tag}/>)}
                        </CenteredRowFlexBox>
                    </Box>}
                </Box>
                {!isDetails && <Button onClick={openArtifactDetails} variant={"contained"}>Show Details</Button>}
            </Box>
        </PanelBox>


    </Box>
}
