import * as React from "react";
import {useAppState} from "../state/AppStateProvider";
import {useNavigate} from "react-router-dom";

type AlmanakLogoProps = {
    size?: number
}
export const  AlmanakLogo = (props: AlmanakLogoProps) => {
    const {theme} = useAppState();
    const navigate = useNavigate();

    return <img
        onClick={() => navigate("/")}
        src={window.location.origin + (theme === "light" ? "/logo-black.png" : "/logo-white.png")}
        alt="logo"
        style={{height: `${props.size ?? 48}px`, cursor: "pointer"}}/>
}
