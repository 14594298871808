import * as React from "react";
import {PageContentContainer} from "../views/styled/styled";
import {CenteredRowFlexBox} from "../views/simulation/create-simulation/styled";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import {ArrowBackIosNew} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

// @ts-ignore
// import FeastUI from "@feast-dev/feast-ui";
// import "@feast-dev/feast-ui/dist/feast-ui.css";


export const FeatureStoreWidget = () => {

    const navigate = useNavigate();


    return <PageContentContainer>

        <Box>

        </Box>
        <CenteredRowFlexBox sx={{gap: "16px"}}>
            <Button variant="contained"
                    onClick={() => navigate(-1)}
                    startIcon={<ArrowBackIosNew/>}>Back</Button>
            <h2>FEAST UI</h2>
        </CenteredRowFlexBox>

        {/*<FeastUI/>*/}

    </PageContentContainer>

}
