import {Components, createTheme, ThemeOptions} from "@mui/material";


const sharedComponents: Components = {
    MuiToolbar: {
        styleOverrides: {
            root: {
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                whiteSpace: "nowrap",
            }
        }
    },
    MuiList: {
        styleOverrides: {
            root: {
                paddingTop: 0,
                paddingBottom: 0
            }
        }
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                fontWeight: 500,
            }
        }
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: "36px"
            }
        }
    },
    MuiButtonBase: {
        defaultProps: {
            disableRipple: true,
        }
    },
    MuiButton: {
        styleOverrides: {
            root: {
                fontWeight: 500,
                border: `1px solid transparent`,
                textTransform: "capitalize",
                "&.Mui-disabled": {
                    color: "#D1D5DB"
                },
            },
            sizeLarge: {
                borderRadius: "8px",
                fontSize: "15px",
                lineHeight: "26px",
                padding: "7px 22px",
            },
            sizeMedium: {
                borderRadius: "8px",
                fontSize: "14px",
                lineHeight: "24px",
                padding: "5px 16px",
            },
            sizeSmall: {
                borderRadius: "8px",
                fontSize: "13px",
                lineHeight: "22px",
                padding: "3px 10px",
            },
            iconSizeLarge: {
                position: "relative",
                top: "-1px",
            },
            iconSizeMedium: {
                position: "relative",
                top: "-1px",
            },
            iconSizeSmall: {
                position: "relative",
                top: "-1px",
            },
        }
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                borderRadius: "8px",
            },
        }
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                "& .MuiTableCell-head": {
                    fontWeight: "bold"
                },
            }
        }
    },
    MuiChip: {
        styleOverrides: {
            root: {
                borderRadius: "8px",
                fontWeight: "700",
            }
        }
    },
    MuiAlert: {
        styleOverrides: {
            root: {
                "& .MuiAlert-message": {
                    width: "100%"
                }
            }
        }
    }
};
const lightThemeOptions: ThemeOptions = {
    palette: {
        mode: "light",
        primary: {
            main: "#0B0D15",
        },
        secondary: {
            main: "#469CB7",
        },
        // background: {
        //     default: "#F9FAFB",
        //     paper: "#FFF",
        // },
        // error: {
        //     main: "#FF005C",
        //     light: "#FF8AB4",
        //     dark: "#CC004A",
        // },
        // warning: {
        //     main: "#FFF3D2",
        //     light: "#FFF3D2",
        //     dark: "#8E6700",
        // },
        // success: {
        //     main: "#356C58",
        //     light: "#356C58",
        //     dark: "#356C58",
        // },
        info: {
            main: "#00B2FF",
            dark: "#0288D1",
        },
        divider: "#BDBDBD",
        action: {
            disabled: "#EBEBEB",
            disabledBackground: "#EBEBEB",
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
    },
    components: {
        ...sharedComponents,
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    textAlign: "start",
                    color: "#111928",
                    fontSize: "14px",
                    fontWeight: 600
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        "fieldset.MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.15)"
                        }
                    }
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    background: "transparent",
                    transition: "background-color 200ms",
                    "&:hover": {background: "#0000002F !important"},
                    "&.Mui-selected": {
                        color: "#FFF",
                        backgroundColor: "#000",
                        fontWeight: 700
                    },
                    "&.Mui-selected:hover": {
                        backgroundColor: "#000000AF !important",
                    },
                    "&.Mui-disabled": {
                        color: "rgba(0, 0, 0, 0.1)",
                        border: "1px solid rgba(0, 0, 0, 0.1)"
                    }
                }

            }
        },
        MuiButton: {
            ...sharedComponents.MuiButton,
            styleOverrides: {
                ...sharedComponents.MuiButton?.styleOverrides,
                textPrimary: ({theme, ownerState}) => ({
                    color: "#469CB7",
                    fontWeight: "bold",
                    "&:focus,&:active": {
                        background: "#F9FAFB",
                    },
                    "&.MuiButton-textPrimary": {
                        "&:hover:not(:focus)": {color: "black"},
                        "&:focus,&:active": {background: "#F9FAFB"},
                    },
                    "&.MuiButton-textSecondary": {
                        "&:hover:not(:focus)": {color: "#FFF"},
                        "&:focus,&:active": {
                            background: "#F9FAFB",
                        },
                    },
                    ".MuiLoadingButton-loadingIndicator": {color: "black"},
                }),
                textSecondary: {
                    color: "#7C7CBA",
                },
                outlined: ({theme, ownerState}) => ({
                    borderColor: "currentColor",
                    "&:focus,&:active": {
                        background: "#F9FAFB",
                    },
                    "&.MuiButton-outlinedPrimary": {
                        borderColor: "#D1D5DB",
                        color: "black",
                        "&:hover:not(:focus)": {color: "black"},
                        "&:focus,&:active": {background: "#F9FAFB"},
                    },
                    "&.MuiButton-outlinedSecondary": {
                        "&:hover:not(:focus)": {color: "#FFF"},
                        "&:focus,&:active": {
                            color: "black",
                            background: "#F9FAFB",
                        },
                    },
                    ".MuiLoadingButton-loadingIndicator": {color: "black"},
                }),
                contained: ({theme, ownerState}) => ({
                    "&:focus:not(:active)": {
                        color: "black",
                        borderColor:
                            ownerState.color === "inherit"
                                ? "currentColor"
                                : theme.palette[ownerState.color ?? "primary"].main,
                        backgroundColor: "transparent",
                    },
                    ".MuiLoadingButton-loadingIndicator": {color: "black"},
                }),
            },
        },
    },

};
const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: "dark",
        text: {
            primary: "#FFF",
            secondary: "#000"
        },
        primary: {
            main: "#FFF",
        },
        secondary: {
            main: "#469CB7",
        },
        warning: {
            main: "#FFF3D2",
            light: "#FFF3D2",
            dark: "#8E6700",
        },
        success: {
            main: "#E5FFE1",
            light: "#E5FFE1",
            dark: "#356C58",
        },
        // background: {
        //     default: "#000000EE",
        //     paper: "red",
        // },
        // error: {
        //     main: "#FF005C",
        //     light: "#FF8AB4",
        //     dark: "#CC004A",
        // },
        // info: {
        //     main: "#00B2FF",
        //     dark: "#0288D1",
        // },
        // divider: "#BDBDBD",
        // action: {
        //     disabled: "#EBEBEB",
        //     disabledBackground: "#EBEBEB",
        // },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
    },
    components: {
        ...sharedComponents,
        MuiButton: {
            ...sharedComponents.MuiButton,
            styleOverrides: {
                ...sharedComponents.MuiButton?.styleOverrides,
                textPrimary: ({theme, ownerState}) => ({
                    color: "#469CB7",
                    fontWeight: "bold",
                }),
                contained: ({theme, ownerState}) => ({
                    ".MuiSvgIcon-root": {
                        color: "#000"
                    },
                }),
                outlined: ({theme, ownerState}) => ({
                    borderColor: "currentColor",
                    "&:focus,&:active": {
                        background: "#F9FAFB2F",
                    },
                    "&.MuiButton-outlinedPrimary": {
                        // borderColor: "#D1D5DB",
                        // color: "black",
                    },
                    "&.MuiButton-outlinedSecondary": {
                        "&:hover:not(:focus)": {color: "#FFF"},
                        "&:focus,&:active": {
                            color: "black",
                            background: "#F9FAFB",
                        },
                    },
                    ".MuiLoadingButton-loadingIndicator": {color: "black"},
                }),
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#FFF"
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: "#000 !important"
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    transition: "background-color 200ms",
                    // "&:hover": {background: "#0000002F !important"},
                    "&.Mui-selected": {
                        color: "#000",
                        backgroundColor: "#FFF",
                        fontWeight: 700
                    },
                    "&.Mui-selected:hover": {
                        backgroundColor: "#FFFFFFD0",
                    },
                    "&.Mui-disabled": {
                        // border: "none"
                    },
                    background: "transparent"
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                root: {
                    ".MuiStepLabel-label": {
                        color: "#FFF"
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: "#FFF",
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: "#FFF"
                }
            }
        },
        MuiAlert: {
            ...sharedComponents.MuiAlert,
            styleOverrides: {
                ...sharedComponents.MuiAlert?.styleOverrides,
                standardInfo: {
                    backgroundColor: "#0288D1",
                    color: "#FFF"
                },
                standardWarning: {
                    backgroundColor: "#8E6700",
                    color: "#FFF"
                },
            }
        }
    }
};


const lightTheme = createTheme(lightThemeOptions);
const darkTheme = createTheme(darkThemeOptions);
export {lightTheme, darkTheme};
